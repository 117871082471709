import { makeStyles, Theme, createStyles } from '@material-ui/core';

const RVUCalculatorStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '15px 168px',
      minWidth: 500,
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        padding: 16,
        minWidth: 0
      },
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        width: '78%',
        margin: 'auto',
        marginTop: 40
      },
      [theme.breakpoints.only('md')]: {
        padding: '15px 50px',
        minWidth: 0
      }
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: 20,
      borderBottom: '1px solid rgba(170, 170, 170, 0.4)',
      fontWeight: 500
    },
    removeIcon: {
      color: 'red',
      margin: 5
    },
    codeContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    cptCode: {
      padding: '20px 10px',
      fontWeight: 500
    },
    cptCodeMobile: {
      padding: '20px 5px 10px',
      fontWeight: 500
    },
    unitNum: {
      padding: '20px 5px',
      fontWeight: 500
    },
    divider: {
      margin: '5px 8px 5px 15px'
    },
    emptyViewContainer: {
      height: '90%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    dividerMobile: {
      margin: '0px 0px 0px 5px'
    },
    detailsSection: {
      paddingBottom: 30,
      border: '1px solid rgba(170, 170, 170, 0.4)',
      borderRight: 'none',
      [theme.breakpoints.down('md')]: {
        borderRight: '1px solid rgba(170, 170, 170, 0.4)'
      }
    },
    unitValue: {
      padding: 15
    },
    unitValueMobile: {
      padding: 5
    },
    footer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',

      marginTop: 18,
      marginBottom: 40
    },
    footerPaper: {
      width: '50%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 10
    },
    totalContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    footerButton: {
      width: '30%'
    },
    mobileHeader: {
      marginBottom: 12
    },
    footerButtonMobile: {
      width: '100%',
      margin: '10px 0px'
    },
    hideResult: {
      visibility: 'hidden',
      height: 87
    },
    error: {
      color: 'red',
      fontSize: 10,
      display: 'flex',
      justifyContent: 'flex-end'
    },
    codeText: {
      width: '100%',
      background: 'transparent',
      paddingLeft: 10,
      borderRadius: 3,
      height: 53
    },
    codeTextPadding: {
      paddingLeft: 10,
      width: '100%',
      background: 'transparent',

      borderRadius: 3,
      height: 53
    },
    totalIcon: {
      marginRight: 5
    },
    resultContainer: {
      border: '1px solid rgba(170, 170, 170, 0.4)'
    },
    emptyView: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center'
    },
    mobileButtonContainer: {
      width: '100%'
    },

    emptyWord: {
      marginTop: 25,
      [theme.breakpoints.down('md')]: {
        marginBottom: 25
      }
    },
    unitNumMobile: {
      padding: '20px 0px',
      fontWeight: 500
    },
    footerPaperMobile: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 10
    },
    noDivider: {
      visibility: 'hidden'
    },
    removeIconHide: {
      visibility: 'hidden',
      margin: 5
    }
  })
);

export default RVUCalculatorStyle;
