import { makeStyles, Theme, createStyles } from '@material-ui/core';

const InvitationStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '15px 168px',
      minWidth: 500,
      [theme.breakpoints.down('md')]: {
        padding: 16,
        minWidth: 0
      },
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        width: '78%',
        margin: 'auto'
      },
      [theme.breakpoints.only('md')]: {
        padding: '15px 50px',
        minWidth: 0
      }
    },
    registeryName: {
      paddingLeft: 12,
      paddingBottom: 12,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    link: {
      textDecoration: 'none'
    },
    inline: {
      display: 'inline'
    },
    headerContainer: {
      display: 'flex',

      marginTop: 24,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: 0
      }
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    search: {
      paddingRight: 17,
      [theme.breakpoints.down('md')]: {
        paddingRight: 5
      }
    },
    selectHeader: {
      display: 'flex',
      alignItems: 'center'
    },
    selectSection: {
      display: 'flex',
      alignItems: 'center'
    },
    inviteButton: {
      display: 'flex',
      alignItems: 'end',

      marginTop: 10,
      marginRight: 30
    },
    inviteIcon: {
      marginRight: 5
    },
    leftSection: {
      display: 'flex'
    },
    removeButton: {
      color: '#ff3b30',
      fontSize: 16,
      borderTop: '0.5px solid #aaaaaa'
    },
    selectPart: {
      marginTop: 12,
      display: 'flex',
      alignItems: 'center'
    },
    searchMobile: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginTop: 6,
      paddingRight: 6,
      marginBottom: 6
    },
    searchMobileDoctor: {
      marginTop: 11,
      paddingRight: 6,
      marginBottom: 6
    },
    doctorInfo: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 0px'
    },
    emptySearch: {
      margin: '16px auto 0px',
      textAlign: 'center'
    },
    noWrapWidth: {
      width: 200,
      [theme.breakpoints.down('md')]: {
        width: 100
      }
    },
    pending: {
      color: '#ffad2c',
      display: 'flex',
      justifyContent: 'center'
    },
    list: {
      height: 200,
      overflowY: 'scroll',
      width: '100%'
    },
    imageBox: {
      marginTop: '7%',
      marginBottom: 40,
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    doctorBox: {
      marginLeft: 10,
      display: 'flex',
      flexDirection: 'column'
    },
    cursor: {
      cursor: 'pointer'
    },
    doctorImage: {
      width: 46,
      height: 46
    },
    checkRoot: {
      '&$checked': {
        color: '#ffad2c'
      }
    },
    checked: {},
    icon: {
      color: '#ffad2c',
      margin: '0px 9px'
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    card: {
      marginBottom: 12
    }
  })
);

export default InvitationStyle;
