import React from 'react';
import privacyStyle from './Styles';

const Privacy = () => {
  const classes = privacyStyle();

  return (
    <div className={classes.container}>
      <p className={classes.topic}>PRIVACY POLICY</p>
      <p>
        MS Logger Inc. and its affiliates (MS Logger,” “we,” “our,” and/or “us”)
        values the privacy of individuals who use our application, websites, and
        related services (collectively, our “Services”). This privacy policy
        (the “Privacy Policy”) explains how we collect, use, and share
        information from users of our Services (“Users”) to provide a platform
        for the storage and sharing of medical information. By using our
        Services, you agree to the collection, use, disclosure, and procedures
        this Privacy Policy describes. Beyond the Privacy Policy, your use of
        our Services is also subject to our Terms of Service 
        <a href="https://mslogger.com/terms"> https://mslogger.com/terms</a>
      </p>

      <p>
        <b>Information We Collect </b>
        <span>
          We may collect a variety of information from or about you or your
          devices from various sources, as described below.
        </span>
      </p>
      <p>
        <b>A. Information You Provide to Us.</b>
      </p>
      <p>
        <b>Registration and Profile Information.</b>
        <span>
          When you sign up for an account, we ask you for your name, email
          address, and phone number.
        </span>
      </p>
      <p>
        <b>Communications.</b>
        <span>
          {' '}
          If you contact us directly, we may receive additional information
          about you. For example, when you contact our customer support team, we
          will receive your name, email address, phone number, the contents of a
          message or attachments that you may send to us, and other information
          you choose to provide. When we send you emails, we may track whether
          you open them to learn how to deliver a better customer experience and
          improve our Services.
        </span>
      </p>
      <p>
        <b>Personal Information</b>
        <span>
          {' '}
          In order to provide its service, MS Logger collects personal
          information that you provide and upload. The personal information you
          provide may include:
        </span>
      </p>
      <ul>
        <li>Name</li>
        <li>Country</li>
        <li>Phone number</li>
        <li>Email</li>
        <li>Education</li>
        <li>Experience</li>
        <li>Positions</li>
      </ul>
      <p>
        <b>Media.</b>
        <span>
          If you grant our mobile application permission to use your camera or
          microphone, you may upload media to the Services, such as photographs,
          videos, or audio.
        </span>
      </p>
      <p>
        <b>Payment Information.</b>
        <span>
          We work with third party partners to process payment information. In
          order to process payments, we or our partner may collect payment card
          information.
        </span>
      </p>
      <p>
        <b>B. Information We Collect When You Use Our Services.</b>
      </p>
      <p>
        <b>Location Information.</b>
        <span>
          We may infer your general location information, for example by using
          your internet protocol (IP) address.
        </span>
      </p>
      <p>
        <b>Device Information.</b>
        <span>
          {' '}
          We receive information about the device and software you use to access
          our Services in order to improve the customer experience, including IP
          address, web browser type, operating system version, phone carrier and
          manufacturer, application installations, device identifiers, mobile
          advertising identifiers, and push notification tokens.
        </span>
      </p>
      <p>
        <b>Usage Information.</b>
        <span>
          To help us understand how you use our Services and to help us improve
          them, we automatically receive information about your interactions
          with our Services, like the pages or other content you view, the
          searches you conduct, people you share information with, and the dates
          and times of your visits.
        </span>
      </p>
      <p>
        <b>Information from Cookies and Similar Technologies.</b>
        <span>
          We and third-party partners collect information using cookies, pixel
          tags, or similar technologies. Our third-party partners, such as
          analytics and advertising partners, may use these technologies to
          collect information about your online activities over time and across
          different services. Cookies are small text files containing a string
          of alphanumeric characters. We may use both session cookies and
          persistent cookies. A session cookie disappears after you close your
          browser. A persistent cookie remains after you close your browser and
          may be used by your browser on subsequent visits to our Services.
          <br></br>
          <br></br> Please review your web browser’s “Help” file to learn the
          proper way to modify your cookie settings. Please note that if you
          delete or choose not to accept cookies from the Service, you may not
          be able to utilize the features of the Service to their fullest
          potential.
        </span>
      </p>
      <p>
        <b>C. Information We Receive from Third Parties.</b>
      </p>
      <p>
        <b>Other third parties.</b>
        <span>
          We may receive additional information about you, such as demographic
          data, from third parties such as data or marketing partners and
          combine it with other information we have about you.
        </span>
      </p>
      <p>
        <b>How We Use the Information We Collect</b>
        <br></br>
        <span>We use the information we collect:</span>
      </p>
      <ul>
        <li>To provide, maintain, improve, and enhance our Services;</li>
        <li>To personalize your experience on our Services;</li>
        <li>
          To understand and analyze how you use our Services and develop new
          products, services, features, and functionality;
        </li>
        <li>
          To communicate with you, provide you with updates and other
          information relating to our Services, provide information that you
          request, respond to comments and questions, and otherwise provide
          customer support;
        </li>
        <li>
          For marketing and advertising purposes, such as developing and
          providing promotional and advertising materials that may be relevant,
          valuable or otherwise of interest to you;
        </li>
        <li>To facilitate transactions and payments;</li>
        <li>
          To find and prevent fraud, and respond to trust and safety issues that
          may arise;
        </li>
        <li>To facilitate transactions and payments;</li>
        <li>
          To find and prevent fraud, and respond to trust and safety issues that
          may arise;{' '}
        </li>
        <li>
          For compliance purposes, including enforcing our Terms of Service or
          other legal rights, or as may be required by applicable laws and
          regulations or requested by any judicial process or governmental
          agency; and
        </li>
        <li>
          For other purposes for which we provide specific notice at the time
          the information is collected.
        </li>
      </ul>
      <p>
        <b>How We Share the Information We Collect</b>
        <br></br>
        <b>Sharing with Designees, including Medical Professionals.</b>
        <span>
          MS Logger enables you to share your Dashboard with others, such as
          medical professionals. When you designate such individuals, MS Logger
          makes information, including the entered cases and database, available
          to your designees. If you wish to later limit the information
          available to such designees, visit the privacy settings within the
          Services.
        </span>
      </p>
      <p>
        <b>Patient information.</b>
        <span>
          MS Logger does not share any protected patient information with third
          parties, including companies, vendors, or researchers. This includes
          patients’ information entered by the user or shared by other users.
        </span>
      </p>
      <p>
        <b>Analytics Partners. </b>
        <span>
          We use Google Analytics, which uses cookies and similar technologies
          to collect and analyze information about use of the Services and
          report on activities and trends. This service may also collect
          information regarding the use of other websites, apps and online
          resources. You can learn about Google’s practices by going to{' '}
          <a href="https://www.google.com/policies/privacy/partners/">
            https://www.google.com/policies/privacy/partners/
          </a>
        </span>
      </p>
      <p>
        <b>Advertising Partners.</b>
        <span>
          We work with third party advertising partners to show you ads that we
          think may interest you. Some of our advertising partners are members
          of the Network Advertising Initiative(
          <a href="http://optout.networkadvertising.org/?c=1#!/">
            http://optout.networkadvertising.org/?c=1#!/
          </a>
          ) or the Digital Advertising Alliance
          <a href="http://optout.aboutads.info/?c=2&lang=EN">
            (http://optout.aboutads.info/?c=2&lang=EN)</a>. If you do not wish to
            receive personalized ads, please visit their opt-out pages to learn
            about how you may opt out of receiving web-based personalized ads
            from member companies. You can access any settings offered by your
            mobile operating system to limit ad tracking, or you can install the
            AppChoices mobile app to learn more about how you may opt out of
            personalized ads in mobile apps.
          
        </span>
      </p>

      <p>
        <b>As Required By Law and Similar Disclosures.</b>
        <span>
          We may access, preserve, and disclose your information if we believe
          doing so is required or appropriate to: (a) comply with law
          enforcement requests and legal process, such as a court order or
          subpoena; (b) respond to your requests; or (c) protect your, our, or
          others’ rights, property, or safety. For the avoidance of doubt, the
          disclosure of your information may occur if you post any objectionable
          content on or through the Services.
        </span>
      </p>
      <p>
        <b>Merger, Sale, or Other Asset Transfers.</b>
        <span>
          We may transfer your information to service providers, advisors,
          potential transactional partners, or other third parties in connection
          with the consideration, negotiation, or completion of a corporate
          transaction in which we are acquired by or merged with another
          company, or we sell, liquidate, or transfer all or a portion of our
          assets. The use of your information following any of these events will
          be governed by the provisions of this Privacy Policy in effect at the
          time the applicable information was collected.
        </span>
      </p>
      <p>
        <b>Consent.</b>
        <span>We may also disclose your information with your permission.</span>
      </p>
      <p>
        <b>Your Choices</b>
        <br></br>
        <br></br>
      </p>

      <p>
        <b>Marketing Communications.</b>
        <span>
          You can unsubscribe from our promotional emails via the link provided
          in the emails. Even if you opt-out of receiving promotional messages
          from us, you will continue to receive administrative messages from us.
        </span>
      </p>
      <br></br>
      <p>
        <b>Third Parties</b>
      </p>

      <div>
        <span>
          Our Services may contain links to other websites, products, or
          services that we do not own or operate. We are not responsible for the
          privacy practices of these third parties. Please be aware that this
          Privacy Policy does not apply to your activities on these third-party
          services or any information you disclose to these third parties. We
          encourage you to read their privacy policies before providing any
          information to them.
        </span>
      </div>
      <br></br>
      <p>
        <b>Security and HIPAA compliance</b>
      </p>

      <div>
        <span>
          Our platform is complaint with HIPAA regulations, including our
          HIPAA-compliant servers provided by Amazon AWS. We also make extreme
          efforts to protect your information and the information your enter by
          using physical and electronic safeguards designed to improve the
          security of the information we maintain. However, as our Services are
          hosted electronically, we can make no guarantees as to the security or
          privacy of your information.
        </span>
      </div>
      <br></br>
      <p>
        <b>Children’s Privacy</b>
      </p>

      <div>
        <span>
          We do not knowingly collect, maintain, or use personal information
          from children under 13 years of age, and no part of our Services are
          directed to children.
        </span>
      </div>
      <br></br>
      <p>
        <b>International Visitors</b>
      </p>

      <div>
        <span>
          Our Services are hosted in the United States and intended for visitors
          located within the United States. If you choose to use the Services
          from the European Union or other regions of the world with laws
          governing data collection and use that may differ from U.S. law, then
          please note that you are transferring your personal information
          outside of those regions to the United States for storage and
          processing. Also, we may transfer your data from the U.S. to other
          countries or regions in connection with storage and processing of
          data, fulfilling your requests, and operating the Services. By
          providing any information, including personal information, on or to
          the Services, you consent to such transfer, storage, and processing.
        </span>
      </div>
      <br></br>
      <p>
        <b>Update Your Information or Pose a Question</b>
      </p>

      <div>
        <span>
          You can update your account and profile information or close your
          account through your account settings. If you have questions about
          your privacy on the Services or this privacy policy, please contact us
          at info@mslogger.com.
        </span>
      </div>
      <br></br>
      <p>
        <b>Changes to this Privacy Policy</b>
      </p>

      <div>
        <span>
          We will post any adjustments to the Privacy Policy on this page, and
          the revised version will be effective when it is posted. If we
          materially change the ways in which we use or share personal
          information previously collected from you through the Services, we
          will notify you through the Services, by email, or other
          communication.
        </span>
      </div>
      <br></br>
      <p>
        <b>Contact Information</b>
      </p>
      <div>
        <span>
          If you have any questions, comments, or concerns about our processing
          activities, please email us at info@mslogger.com.
        </span>
      </div>
      <br></br>
    </div>
  );
};

export default Privacy;
