import { makeStyles, Theme, createStyles } from '@material-ui/core';

const VerifyInvitationStyle = makeStyles((theme: Theme) =>
  createStyles({
    py0: {},
    mb0: {
      marginBottom: 0
    },
    signUpButton: {
      width: '50%',
      marginBottom: 10
    },
    signUpButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '1005'
    },
    verifyDialog: {
      padding: '60px 15px 15px '
    },
    container: {
      padding: 15
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'center'
    }
  })
);

export default VerifyInvitationStyle;
