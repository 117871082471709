import React from 'react';
import { match, useParams } from 'react-router-dom';
import * as api from '../../../services/apiService';
import { setCookie } from '../../../utils/sessionUtils';
import history from '../../../utils/history';
import { setUser, getUser } from '../../../utils';
import { codeType, registryParams } from '../../../types';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const VerifyRegistrySharing = (props) => {
  const { registryId } = useParams<registryParams>();
  const { code } = useParams<codeType>();

  const verify = async () => {
    props.setexecuted(true);

    const { status_code: statusCode, data } = await api.verifyRegistry(
      registryId,
      code
    );

    if (statusCode === api.status.SUCCESS) {
      let message = ['success', data.message];
      props.setShowVerifyMessage(true);
      props.setVerifyMessage(message);
      history.push('/registry/' + registryId);
      setTimeout(() => props.setShowVerifyMessage(false), 3000);
    } else {
      let message = ['error', data.message];
      props.setShowEditCaseMessage(true);
      props.setCaseMessage(message);
      setTimeout(() => props.setShowEditCaseMessage(false), 3000);
      history.push('/dashboard');
    }
  };

  const verifySharing = async () => {
    if (!props.executed) {
      verify();
    } else {
      return;
    }
  };
  verifySharing();
  return <div></div>;
};

export default VerifyRegistrySharing;
