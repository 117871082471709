import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  Dialog,
  Divider,
  Snackbar,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import history from '../../../utils/history';
import { getUser } from '../../../utils';
import * as api from '../../../services/apiService';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import 'date-fns';
import ReactHookFormSelect from '../../../components/select/ReactHookFormSelect';
import SelectCodes from '../../../components/selectCodes/selectCodes';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import addCaseStyle from './Styles';
import { NavLink, Prompt } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  doctorParams,
  genderListType,
  hospitalDataType,
  implantListType,
  rolesDataType,
  caseProcedureType,
  cptModifierType,
} from '../../../types';
import PositionForm from '../../../components/PositionForm/PositionForm';
import ConfirmCloseDialog from '../../../components/Dialogs/ConfirmCloseDialog';
import { Alert } from '@material-ui/lab';
import { caseParams, months } from '../../../types/index';
import UpgradeDialog from '../../../components/Dialogs/UpgradeDialog';
import CaseProcedure from '../../../components/CaseProcedure/CaseProcedure';
import SelectModifier from '../../../components/SelectModifier/SelectModifier';
import DeleteDialog from '../../../components/Dialogs/DeleteDialog';
import MultipleSelect from '../../../components/select/MultipleSelect';

const AddCase = (props) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const classes = addCaseStyle();
  const [pathName, setPathName] = React.useState<string>('');
  const { caseId } = useParams<caseParams>();
  const { doctorId } = useParams<doctorParams>();
  const user = getUser();
  const location = useLocation().pathname;
  const [images, setImages] = React.useState<any[]>([]);
  const [notes, setNotes] = React.useState<string[]>([]);
  const [editNoteField, setEditNoteField] = React.useState<string>('');
  const [profileMessage, setProfileMessage] = React.useState(['', '']);
  const [
    showProfileFormMessage,
    setShowProfileFormMessage
  ] = React.useState<boolean>(false);
  const [showAddNoteText, setShowAddNoteText] = React.useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [surgeryDate, setSurgeryDate] = React.useState<Date>(new Date());
  const [procedureDate, setProcedureDate] = React.useState<Date>(new Date());
  const [genderList, setGenderList] = React.useState<genderListType[]>([]);
  const [raceList, setRaceList] = React.useState<any[]>([]);
  const [ticiGradeList, setTICIGradeList] = React.useState<any[]>([]);
  const [insuranceList, setInsuranceList] = React.useState<any[]>([]);
  const [neurosurgeryProcedureTypeList, setNeurosurgeryProcedureTypeList] = React.useState<any[]>([]);
  const [bedsideProcedureTypeList, setBedsideProcedureTypeList] = React.useState<any[]>([]);
  const [interventionalProcedureTypeList, setInterventionalProcedureTypeList] = React.useState<any[]>([]);
  const [radiologyProcedureTypeList, setRadiologyProcedureTypeList] = React.useState<any[]>([]);
  const [cranialApproachList, setCranialApproachList] = React.useState<any[]>([]);
  const [spineApproachList, setSpineApproachList] = React.useState<any[]>([]);
  const [cranialIndicationList, setCranialIndicationList] = React.useState<any[]>([]);
  const [spineIndicationList, setSpineIndicationList] = React.useState<any[]>([]);
  const [functionalIndicationList, setFunctionalIndicationList] = React.useState<any[]>([]);
  const [nervesIndicationList, setNervesIndicationList] = React.useState<any[]>([]);
  const [interIndicationList, setInterIndicationList] = React.useState<any[]>([]);
  const [interventionalIndicationList, setInterventionalIndicationList] = React.useState<any[]>([]);
  const [radiologyIndicationList, setRadiologyIndicationList] = React.useState<any[]>([]);
  const [locationList, setLocationList] = React.useState<any[]>([]);

  const [tumorLocationList, setTumorLocationList] = React.useState<any[]>([]);
  const [aneurysmLocationList, setAneurysmLocationList] = React.useState<any[]>([]);
  const [aneurysmLocationInterList, setAneurysmLocationInterList] = React.useState<any[]>([]);
  const [hematomaLocationList, setHematomaLocationList] = React.useState<any[]>([]);
  const [fractureLocationList, setFractureLocationList] = React.useState<any[]>([]);
  const [hydrocephalusLocationList, setHydrocephalusLocationList] = React.useState<any[]>([]);
  const [avmLocationList, setAVMLocationList] = React.useState<any[]>([]);
  const [bleedingLocationList, setBleedingLocationList] = React.useState<any[]>([]);
  const [dissectionLocationList, setDissectionLocationList] = React.useState<any[]>([]);
  const [strokeLocationList, setStrokeLocationList] = React.useState<any[]>([]);
  const [cptModifiersList, setCPTModifiersList] = React.useState<cptModifierType[]>([]);
  const [cptModifier, setCPTModifier] = React.useState<cptModifierType>();
  const [sidesList, setSidesList] = React.useState<any[]>([]);

  const [osteotomyList, setosteotomyList] = React.useState<any[]>([]);
  const [yesNoList, setYesNoList] = React.useState<any[]>([]);
  const [procedureType, setProcedureType] = React.useState<String>('');
  const [indicationType, setIndicationType] = React.useState<String>('');
  const [locationType, setLocationType] = React.useState<string>('');
  const [approachType, setApproachType] = React.useState<string>('');
  const [_procedureType, set_procedureType] = React.useState<string>('');
  const [neuroProcedure, setNeuroProcedure] = React.useState<string>('');
  const [roleList, setRoleList] = React.useState<rolesDataType[]>([]);
  const [selectedCptCodes, setSelectedCptCodes] = React.useState<any[]>([]);
  const [diagnosis, setDiagnosis] = React.useState<any[]>([]);
  const [hospitalList, setHospitalList] = React.useState<hospitalDataType[]>([]);
  const [addCaseMessage, setAddCaseMessage] = React.useState<any[]>([]);
  const [showAddCaseMessage, setShowAddCaseMessage] = React.useState<boolean>(
    false
  );
  const [isBlocking, setIsBlocking] = React.useState<boolean>(false);
  const [isOpenConfirmClose, setIsOpenConfirmClose] = React.useState(false);
  const [specialityList, setSpecialityList] = React.useState<any[]>([]);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [onRefresh, setOnRefresh] = React.useState<boolean>(false);
  const [openSelectDialog, setOpenSelectDialog] = React.useState<boolean>(
    false
  );
  const [openModifierDialog, setOpenModifierDialog] = React.useState<boolean>(false);
  const [openDiscard, setOpenDiscard] = React.useState<boolean>(false);
  const [searchPath, setSearchPath] = React.useState<string>('');
  const [media, setMedia] = React.useState<any[]>([]);
  const [showEndDate, setShowEndDate] = React.useState<boolean>(true);
  const [viewPositionForm, setViewPositionForm] = React.useState<boolean>(
    false
  );
  const [messageError, setMessageError] = React.useState<string>('');
  const [currentPlan, setCurrentPlan] = React.useState<any>();
  const [tokenError, setTokenError] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [userToken, setUserToken] = React.useState<string>('');
  const [newPlan, setNewPlan] = React.useState<boolean>(false);
  const [isOpen, setisOpen] = React.useState<boolean>(false);
  const [sumOfRvu, setSumOfRvu] = React.useState<number>(0);
  const [totalReimbursable, setTotalReimbursable] = React.useState<number>(0);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [caseProcedures, setCaseProcedures] = React.useState<caseProcedureType[]>([]);
  const [openDeleteImage, setOpenDeleteImage] = React.useState<boolean>(false);
  const [imageIndex, setImageIndex] = React.useState<any>();
  const [imageId, setImageId] = React.useState<any>();
  const today = new Date();

  const addCaseProcedureForm = () => {
    const procedure = {
      id: new Date().toString(),
      type: '',
      date: `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? "0" : ""}${today.getMonth() + 1}-${today.getDate()}`,
      procedure_name: '',
      approach: '',
      minimally_invasive: '',
      navigation: '',
      robotic: '',
      indication: '',
      side: '',
      implant: '',
      implant_type: '',
      location: '',
      osteotomy: '',
      _fusion: '',
      fusion_levels: '',
      decompression_levels: '',
      interbody_fusion: '',
      extension_to_pelvis: '',
      morphogenic_protein: '',
      target: '',
      nerve: '',
      _procedure: '',
      tici_grade: '',
      access: '',
      vascular_closure_device: '',
      duration_of_radiation: '',
      duration_of_procedure: '',
      date_of_procedure: `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? "0" : ""}${today.getMonth() + 1}-${today.getDate()}`,
      findings: '',
      vendors: '',
      complications: '',
      outcome: '',
    };

    setCaseProcedures([...caseProcedures, procedure]);
  }

  const handleDelete = (id) => {
    const updatedCaseProcedure = caseProcedures.filter((item) => item.id !== id);
    setCaseProcedures(updatedCaseProcedure);
  };

  const [procedureInitData, setProcedureInitData] = React.useState({
    patient_first_name: '',
    patient_last_name: '',
    gender: '',
    type: '',
    procedure_name: '',
    approach: '',
    minimally_invasive: '',
    navigation: '',
    robotic: '',
    indication: '',
    side: '',
    implant: '',
    implant_type: '',
    location: '',
    osteotomy: '',
    _fusion: '',
    fusion_levels: '',
    decompression_levels: '',
    interbody_fusion: '',
    extension_to_pelvis: '',
    morphogenic_protein: '',
    instrumentation: '',
    target: '',
    nerve: '',
    _procedure: '',
    tici_grade: '',
    access: '',
    vascular_closure_device: '',
    duration_of_radiation: '',
    duration_of_procedure: '',
    date_of_procedure: '',
    mrn: '',
    insurance: '',
    age: '',
    primary_surgeon: '',
    race: '',
    hospital_id: '',
    role: '',
    findings: '',
    vendors: '',
    complications: '',
    outcome: '',
    procedure_diagnosis: '',
    notes: [],
    images: []
  });

  const validationSchema = yup.object().shape({
    patient_first_name: yup.string().trim().required('This field is required'),
    patient_last_name: yup.string().trim().required('This field is required'),
    type: yup.string().nullable(true),
    gender: yup.string().required('This field is required'),
    mrn: yup.string().nullable(true),
    insurance: yup.string().nullable(true),
    age: yup.mixed().nullable().typeError('Age must be a number').test('is-number', 'Age must be a number', value => {
      if (value === null || value === undefined || value === '') {
        return true;
      }
      return !isNaN(value);
    }),
    primary_surgeon: yup.string().nullable(true),
    race: yup.string().nullable(true),
    procedure_name: yup.string().nullable(true),
    approach: yup.string().nullable(true),
    minimally_invasive: yup.string().nullable(true),
    navigation: yup.string().nullable(true),
    robotic: yup.string().nullable(true),
    indication: yup.string().nullable(true),
    implant: yup.string().nullable(true),
    implant_type: yup.string().nullable(true),
    location: yup.string().nullable(true),
    osteotomy: yup.string().nullable(true),
    _fusion: yup.string().nullable(true),
    fusion_levels: yup.string().nullable(true),
    decompression_levels: yup.string().nullable(true),
    interbody_fusion: yup.string().nullable(true),
    extension_to_pelvis: yup.string().nullable(true),
    morphogenic_protein: yup.string().nullable(true),
    instrumentation: yup.string().nullable(true),
    target: yup.string().nullable(true),
    nerve: yup.string().nullable(true),
    _procedure: yup.string().nullable(true),
    tici_grade: yup.string().nullable(true),
    duration_of_radiation: yup.string().nullable(true),
    duration_of_procedure: yup.string().nullable(true),
    date_of_procedure: yup.string().nullable(true),
    hospital_id: yup.string().required('This field is required'),
    role: yup.string().nullable(true),
    findings: yup.string().nullable(true),
    vendors: yup.string().nullable(true),
    complications: yup.string().nullable(true),
    outcome: yup.string().nullable(true),
    notes: yup.array().of(
      yup.object().shape({
        notes: yup.string().nullable(false)
      })
    )
  });

  const {
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
    register,
    watch
  } = useForm({
    defaultValues: procedureInitData,
    resolver: yupResolver(validationSchema),
    mode: 'all'
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'notes'
    }
  );

  const setFormValue = (name, value) => {
    switch (name) {
      case 'indication':
        setIndicationType(value);
        break;
      case 'location':
        setLocationType(value);
        break;
      case 'approach':
        setApproachType(value);
        break;
      case '_procedure':
        set_procedureType(value);
        break;
    }
  }

  const isMultipleSelection = (): boolean => {
    return isNeurosurgery() || isInterventional();
  }

  const collectList = (list) => {
    if (Array.isArray(list)) {
      return list.join(', ');
    }
    return list;
  }

  const collectCaseProcedureList = (list: any): string[] | string => {
    if (Array.isArray(list)) {
      return list.join(', ');
    }
    return list;
  }

  const extractList = (list: any): void => {
    return list ? list.split(', ') : '';
  }

  const getProcedureImages = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getProcedureMedia(user.id, caseId);
    if (statusCode === api.status.SUCCESS) {
      setImages(data);
    }
  }, [caseId, setValue, user.id])

  const getProcedureData = React.useCallback(async () => {
    if (caseId) {
      const { data, status_code: statusCode } = await api.getProcedureById(
        user.id,
        caseId
      );

      if (statusCode === api.status.SUCCESS) {
        getProcedureImages();
        for (let key in data) {
          data[key] = data[key] == 'undefined' ? '' : data[key];
        }

        for (let procedure of data.case_procedures) {
          procedure['implant'] = procedure['implant'] === true ? 'Yes' : procedure['implant'] === false ? 'No' : '';
          procedure['_fusion'] = procedure['_fusion'] === true ? 'Yes' : procedure['_fusion'] === false ? 'No' : '';
          procedure['interbody_fusion'] = procedure['interbody_fusion'] === true ? 'Yes' : procedure['interbody_fusion'] === false ? 'No' : '';
          procedure['extension_to_pelvis'] = procedure['extension_to_pelvis'] === true ? 'Yes' : procedure['extension_to_pelvis'] === false ? 'No' : '';
          procedure['morphogenic_protein'] = procedure['morphogenic_protein'] === true ? 'Yes' : procedure['morphogenic_protein'] === false ? 'No' : '';
          procedure['minimally_invasive'] = procedure['minimally_invasive'] === true ? 'Yes' : procedure['minimally_invasive'] === false ? 'No' : '';
          procedure['navigation'] = procedure['navigation'] === true ? 'Yes' : procedure['navigation'] === false ? 'No' : '';
          procedure['robotic'] = procedure['robotic'] === true ? 'Yes' : procedure['robotic'] === false ? 'No' : '';
          if (isMultipleSelection()) {
            procedure['indication'] = procedure['indication'] ? procedure['indication'].split(', ') : [];
            procedure['approach'] = procedure['approach'] ? procedure['approach'].split(', ') : [];
            procedure['location'] = procedure['location'] ? procedure['location'].split(', ') : [];
            procedure['_procedure'] = procedure['_procedure'] ? procedure['_procedure'].split(', ') : [];
          }
        }

        setCPTModifier(data.cpt_modifier)
        setCaseProcedures(data.case_procedures);
        setProcedureType(data.type);
        if (!isMultipleSelection()) {
          setIndicationType(data.indication);
        }
        if (isInterventional()) {
          setNeuroProcedure(data.type);
        }
        const {
          hospital,
          procedure_cpt,
          procedure_icd,
          media,
          ...otherValues
        } = data;
        const cpt_id = procedure_cpt.map((cpt) => cpt.cpt_lookup);
        const icd_id = procedure_icd.map((icd) => icd.icd_lookup);

        setSelectedCptCodes(cpt_id);
        setDiagnosis(icd_id);

        const newValues = {
          hospital_id: hospital?.id + '',
          cpt_id,
          images: [],
          icd_id,
          ...otherValues
        };
        setNotes(newValues.notes);
        setProcedureInitData(newValues);
        setValue('notes', newValues.notes);
        setValue('patient_first_name', newValues.patient_first_name);
        setValue('patient_last_name', newValues.patient_last_name);
        setValue('gender', newValues.gender);
        setValue('mrn', newValues.mrn);
        setValue('insurance', newValues.insurance);
        setValue('age', newValues.age);
        setValue('primary_surgeon', newValues.primary_surgeon);
        setValue('race', newValues.race);
        setValue('hospital_id', hospital ? newValues.hospital_id : '');
        setValue('type', newValues.type);
        setValue('procedure_name', newValues.procedure_name);
        setValue('approach', newValues.approach);
        setValue('minimally_invasive', newValues.minimally_invasive === true ? 'Yes' : newValues.minimally_invasive === false ? 'No' : '');
        setValue('navigation', newValues.navigation === true ? 'Yes' : newValues.navigation === false ? 'No' : '');
        setValue('robotic', newValues.robotic === true ? 'Yes' : newValues.robotic === false ? 'No' : '');
        isMultipleSelection() ? setIndicationType(newValues.indication ? newValues.indication.split(', ') : '') : setValue('indication', newValues.indication);
        isMultipleSelection() ? setLocationType(newValues.location ? newValues.location.split(', ') : '') : setValue('location', newValues.location);
        isMultipleSelection() ? setApproachType(newValues.approach ? newValues.approach.split(', ') : '') : setValue('approach', newValues.approach);
        isMultipleSelection() ? set_procedureType(newValues._procedure ? newValues._procedure.split(', ') : '') : setValue('_procedure', newValues._procedure);
        setValue('side', newValues.side);
        setValue('implant', newValues.implant === true ? 'Yes' : newValues.implant === false ? 'No' : '');
        setValue('implant_type', newValues.implant_type);
        setValue('osteotomy', newValues.osteotomy);
        setValue('_fusion', newValues._fusion === true ? 'Yes' : newValues._fusion === false ? 'No' : '');
        setValue('fusion_levels', newValues.fusion_levels);
        setValue('decompression_levels', newValues.decompression_levels);
        setValue('interbody_fusion', newValues.interbody_fusion === true ? 'Yes' : newValues.interbody_fusion === false ? 'No' : '');
        setValue('extension_to_pelvis', newValues.extension_to_pelvis === true ? 'Yes' : newValues.extension_to_pelvis === false ? 'No' : '');
        setValue('morphogenic_protein', newValues.morphogenic_protein === true ? 'Yes' : newValues.morphogenic_protein === false ? 'No' : '')
        setValue('instrumentation', newValues.instrumentation);
        setValue('target', newValues.target);
        setValue('nerve', newValues.nerve);
        setValue('tici_grade', newValues.tici_grade);
        setValue('access', newValues.access);
        setValue('vascular_closure_device', newValues.vascular_closure_device);
        setValue('duration_of_radiation', newValues.duration_of_radiation);
        setValue('duration_of_procedure', newValues.duration_of_procedure);
        setValue('role', newValues.role ? newValues.role : '');
        setValue('findings', newValues.findings);
        setValue('vendors', newValues.vendors);
        setValue('complications', newValues.complications);
        setValue('outcome', newValues.outcome);
        setValue('procedure_diagnosis', newValues.procedure_diagnosis);
        setValue('cpt_modifier_id', newValues.cpt_modifier_id);
        setSelectedDate(newValues.date_of_birth);
        setSurgeryDate(newValues.date);
        setProcedureDate(newValues.date_of_procedure);
      }
    }
  }, [caseId, setValue, user.id]);

  const getSharedProcedureById = React.useCallback(async () => {
    if (caseId) {
      const { data, status_code: statusCode } = await api.getProcedureById(
        doctorId,
        caseId
      );
      if (statusCode === api.status.SUCCESS) {
        getProcedureImages();
        const {
          hospital,
          procedure_cpt,
          procedure_icd,
          media,
          ...otherValues
        } = data;
        const cpt_id = procedure_cpt.map((cpt) => cpt.cpt_lookup);
        const icd_id = procedure_icd.map((icd) => icd.icd_lookup);

        setSelectedCptCodes(cpt_id);
        setDiagnosis(icd_id);

        const newValues = {
          hospital_id: hospital.id + '',
          cpt_id,
          images: [],
          icd_id,
          ...otherValues
        };
        setMedia(media);
        setNotes(newValues.notes);
        setProcedureInitData(newValues);
        setValue('notes', newValues.notes);
        setValue('patient_first_name', newValues.patient_first_name);
        setValue('patient_last_name', newValues.patient_last_name);
        setValue('gender', newValues.gender);
        setValue('mrn', newValues.mrn);
        setValue('insurance', newValues.insurance);
        setValue('age', newValues.age);
        setValue('primary_surgeon', newValues.primary_surgeon);
        setValue('race', newValues.race);
        setValue('hospital_id', hospital ? newValues.hospital_id : '');
        setValue('type', newValues.type);
        setValue('procedure_name', newValues.procedure_name);
        setValue('approach', newValues.approach);
        setValue('minimally_invasive', newValues.minimally_invasive === true ? 'Yes' : newValues.minimally_invasive === false ? 'No' : '');
        setValue('navigation', newValues.navigation === true ? 'Yes' : newValues.navigation === false ? 'No' : '');
        setValue('robotic', newValues.robotic === true ? 'Yes' : newValues.robotic === false ? 'No' : '');
        setValue('side', newValues.side);
        setValue('implant', newValues.implant === true ? 'Yes' : newValues.implant === false ? 'No' : '');
        setValue('implant_type', newValues.implant_type);
        setValue('osteotomy', newValues.osteotomy);
        setValue('_fusion', newValues._fusion === true ? 'Yes' : newValues._fusion === false ? 'No' : '');
        setValue('fusion_levels', newValues.fusion_levels);
        setValue('decompression_levels', newValues.decompression_levels);
        setValue('interbody_fusion', newValues.interbody_fusion === true ? 'Yes' : newValues.interbody_fusion === false ? 'No' : '');
        setValue('extension_to_pelvis', newValues.extension_to_pelvis === true ? 'Yes' : newValues.extension_to_pelvis === false ? 'No' : '');
        setValue('morphogenic_protein', newValues.morphogenic_protein === true ? 'Yes' : newValues.morphogenic_protein === false ? 'No' : '')
        setValue('instrumentation', newValues.instrumentation);
        setValue('target', newValues.target);
        setValue('nerve', newValues.nerve);
        setValue('_procedure', newValues._procedure);
        setValue('tici_grade', newValues.tici_grade);
        setValue('access', newValues.access);
        setValue('vascular_closure_device', newValues.vascular_closure_device);
        setValue('duration_of_radiation', newValues.duration_of_radiation);
        setValue('duration_of_procedure', newValues.duration_of_procedure);
        setValue('role', newValues.role ? newValues.role : '');
        setValue('findings', newValues.findings);
        setValue('vendors', newValues.vendors);
        setValue('complications', newValues.complications);
        setValue('outcome', newValues.outcome);
        setValue('procedure_diagnosis', newValues.procedure_diagnosis);
        setSelectedDate(newValues.date_of_birth);
        setSurgeryDate(newValues.date);
        setProcedureDate(newValues.date_of_procedure);
      }
    }
  }, [caseId, setValue, user.id]);

  const getSpecialityList = React.useCallback(async () => {
    const { data } = await api.getSpecialites();
    const specialityList = data.map((category) => {
      return {
        label: category.name,
        value: category.id + ''
      };
    });
    setSpecialityList(specialityList);
  }, []);

  useEffect(() => {
    if (!props.showEditCaseMessage) {
      getSpecialityList();
    }
  }, []);

  const getHospitals = async (id) => {
    const { data, status_code: statusCode } = await api.getUserHospitals(
      parseInt(id, 10)
    );
    if (statusCode === api.status.SUCCESS) {
      const hospitalList = data.map(({ name, id }) => ({
        id,
        value: id + '',
        label: name
      }));
      setHospitalList(hospitalList);
    }
  };
  useEffect(() => {
    if (
      (viewPositionForm || hospitalList.length === 0) &&
      !props.showEditCaseMessage
    ) {
      if (
        location.includes('/edit-shared-case/') ||
        location.includes('/new-shared-case/')
      ) {
        getHospitals(doctorId);
      } else {
        getHospitals(user?.id);
      }
    }
  }, [viewPositionForm, hospitalList.length]);

  useEffect(() => {
    if (!props.showEditCaseMessage) {
      if (location.includes('/edit-shared-case/')) {
        getSharedProcedureById();
      } else {
        getProcedureData();
      }
    }
  }, []);
  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  const getRoles = async () => {
    const { data, status_code: statusCode } = await api.getRoles();
    if (statusCode === api.status.SUCCESS) {
      const roleList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setRoleList(roleList);
    }
  };

  const getGenders = async () => {
    const { data, status_code: statusCode } = await api.getGenders();
    if (statusCode === api.status.SUCCESS) {
      const genderList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setGenderList(genderList);
    }
  };

  const getCPTModifierPercentage = () => {
    let originalPercentage = Number(cptModifier?.percentage);
    let percentage = originalPercentage % 1 == 0 ? originalPercentage.toFixed(0) : originalPercentage;
    return `${percentage}% ${cptModifier?.additional_info}`
  }

  const getRaceList = async () => {
    const { data, status_code: statusCode } = await api.getRaceList();
    if (statusCode === api.status.SUCCESS) {
      const raceList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setRaceList(raceList);
    }
  }

  const getTICIGradeList = async () => {
    const { data, status_code: statusCode } = await api.getTICIGradeList();
    if (statusCode === api.status.SUCCESS) {
      const ticiList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setTICIGradeList(ticiList);
    }
  }

  useEffect(() => {
    if (ticiGradeList.length === 0 && !props.showEditCaseMessage) {
      getTICIGradeList();
    }
  }, [ticiGradeList.length]);

  useEffect(() => {
    if (raceList.length === 0 && !props.showEditCaseMessage) {
      getRaceList();
    }
  }, [raceList.length]);

  const getInsuranceList = async () => {
    const { data, status_code: statusCode } = await api.getInsuranceList();
    if (statusCode === api.status.SUCCESS) {
      const insuranceList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setInsuranceList(insuranceList);
    }
  }

  useEffect(() => {
    if (insuranceList.length === 0 && !props.showEditCaseMessage) {
      getInsuranceList();
    }
  }, [insuranceList.length]);

  const getNeurosurgeryProcedureTypeList = async () => {
    const { data, status_code: statusCode } = await api.getNeurosurgeryProcedureTypeList();
    if (statusCode === api.status.SUCCESS) {
      const procedureList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setNeurosurgeryProcedureTypeList(procedureList);
    }
  }

  const getBedsideProcedureTypeList = async () => {
    const { data, status_code: statusCode } = await api.getBedsideProcedureTypeList();
    if (statusCode === api.status.SUCCESS) {
      const procedureList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setBedsideProcedureTypeList(procedureList);
    }
  }

  const getInterventionalProcedureTypeList = async () => {
    const { data, status_code: statusCode } = await api.getInterventionalProcedureTypeList();
    if (statusCode === api.status.SUCCESS) {
      const procedureList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setInterventionalProcedureTypeList(procedureList);
    }
  }

  const getRadiologyProcedureTypeList = async () => {
    const { data, status_code: statusCode } = await api.getRadiologyProcedureTypeList();
    if (statusCode === api.status.SUCCESS) {
      const procedureList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setRadiologyProcedureTypeList(procedureList);
    }
  }

  useEffect(() => {
    if (neurosurgeryProcedureTypeList.length === 0) {
      getNeurosurgeryProcedureTypeList();
    }
    if (interventionalProcedureTypeList.length === 0) {
      getInterventionalProcedureTypeList();
    }
    if (bedsideProcedureTypeList.length === 0) {
      getBedsideProcedureTypeList();
    }
    if (radiologyProcedureTypeList.length === 0) {
      getRadiologyProcedureTypeList();
    }
  }, [neurosurgeryProcedureTypeList.length, interventionalProcedureTypeList.length, bedsideProcedureTypeList.length, radiologyProcedureTypeList.length]);

  const getCranialApproachList = async () => {
    const { data, status_code: statusCode } = await api.getCranialApproachList();
    if (statusCode === api.status.SUCCESS) {
      const approachList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setCranialApproachList(approachList);
    }
  }

  const getSpineApproachList = async () => {
    const { data, status_code: statusCode } = await api.getSpineApproachList();
    if (statusCode === api.status.SUCCESS) {
      const approachList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setSpineApproachList(approachList);
    }
  }

  useEffect(() => {
    if (cranialApproachList.length === 0) {
      getCranialApproachList();
    }
    if (spineApproachList.length === 0) {
      getSpineApproachList();
    }
  }, [cranialApproachList.length, spineApproachList.length]);

  const getCranialIndicationList = async () => {
    const { data, status_code: statusCode } = await api.getCranialIndicationList();
    if (statusCode === api.status.SUCCESS) {
      const indicationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setCranialIndicationList(indicationList);
    }
  }

  const getSpineIndicationList = async () => {
    const { data, status_code: statusCode } = await api.getSpineIndicationList();
    if (statusCode === api.status.SUCCESS) {
      const indicationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setSpineIndicationList(indicationList);
    }
  }

  const getFunctionalIndicationList = async () => {
    const { data, status_code: statusCode } = await api.getFunctionalIndicationList();
    if (statusCode === api.status.SUCCESS) {
      const indicationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setFunctionalIndicationList(indicationList);
    }
  }

  const getNervesIndicationList = async () => {
    const { data, status_code: statusCode } = await api.getNervesIndicationList();
    if (statusCode === api.status.SUCCESS) {
      const indicationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setNervesIndicationList(indicationList);
    }
  }

  const getInterventionalIndicationList = async () => {
    const { data, status_code: statusCode } = await api.getInterventionalIndicationList();
    if (statusCode === api.status.SUCCESS) {
      const indicationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setInterventionalIndicationList(indicationList);
    }
  }

  const getInterIndicationList = async () => {
    const { data, status_code: statusCode } = await api.getInterIndicationList();
    if (statusCode === api.status.SUCCESS) {
      const indicationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setInterIndicationList(indicationList);
    }
  }

  const getRadiologyIndicationList = async () => {
    const { data, status_code: statusCode } = await api.getRadiologyIndicationList();
    if (statusCode === api.status.SUCCESS) {
      const indicationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setRadiologyIndicationList(indicationList);
    }
  }

  const getSidesList = async () => {
    const { data, status_code: statusCode } = await api.getSidesList();
    if (statusCode === api.status.SUCCESS) {
      const sides = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setSidesList(sides);
    }
  }

  useEffect(() => {
    if (sidesList.length === 0 && !props.showEditCaseMessage) {
      getSidesList();
    }
  }, [sidesList.length]);

  const getSpineLocationList = async () => {
    const { data, status_code: statusCode } = await api.getSpineLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setLocationList(locationList);
    }
  }

  const getTumorLocationList = async () => {
    const { data, status_code: statusCode } = await api.getTumorLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setTumorLocationList(locationList);
    }
  }

  const getAneurysmLocationList = async () => {
    const { data, status_code: statusCode } = await api.getAneurysmLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setAneurysmLocationList(locationList);
    }
  }

  const getAneurysmLocationInterList = async () => {
    const { data, status_code: statusCode } = await api.getAneurysmLocationInterList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setAneurysmLocationInterList(locationList);
    }
  }

  const getHematomaLocationList = async () => {
    const { data, status_code: statusCode } = await api.getHematomaLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setHematomaLocationList(locationList);
    }
  }

  const getFractureLocationList = async () => {
    const { data, status_code: statusCode } = await api.getFractureLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setFractureLocationList(locationList);
    }
  }

  const getHydrocephalusLocationList = async () => {
    const { data, status_code: statusCode } = await api.getHydrocephalusLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setHydrocephalusLocationList(locationList);
    }
  }

  const getAVMLocationList = async () => {
    const { data, status_code: statusCode } = await api.getAVMLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setAVMLocationList(locationList);
    }
  }

  const getBleedingLocationList = async () => {
    const { data, status_code: statusCode } = await api.getBleedingLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setBleedingLocationList(locationList);
    }
  }

  const getDissectionLocationList = async () => {
    const { data, status_code: statusCode } = await api.getDissectionLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setDissectionLocationList(locationList);
    }
  }

  const getStrokeLocationList = async () => {
    const { data, status_code: statusCode } = await api.getStrokeLocationList();
    if (statusCode === api.status.SUCCESS) {
      const locationList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setStrokeLocationList(locationList);
    }
  }

  const getOsteotomyList = async () => {
    const { data, status_code: statusCode } = await api.getOsteotomyList();
    if (statusCode === api.status.SUCCESS) {
      const osteotomyList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setosteotomyList(osteotomyList);
    }
  }

  useEffect(() => {
    if (cranialIndicationList.length === 0) {
      getCranialIndicationList();
    }
    if (spineIndicationList.length === 0) {
      getSpineIndicationList();
    }
    if (functionalIndicationList.length === 0) {
      getFunctionalIndicationList();
    }
    if (nervesIndicationList.length === 0) {
      getNervesIndicationList();
    }
    if (interventionalIndicationList.length === 0) {
      getInterventionalIndicationList();
    }
    if (interIndicationList.length === 0) {
      getInterIndicationList();
    }
    if (radiologyIndicationList.length === 0) {
      getRadiologyIndicationList();
    }

  }, [cranialIndicationList.length, spineIndicationList.length, functionalIndicationList.length, nervesIndicationList.length, interventionalIndicationList.length, interIndicationList.length, radiologyIndicationList.length]);

  useEffect(() => {
    if (yesNoList.length === 0 && !props.showEditCaseMessage) {
      setYesNoList([
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ]);
    }
  }, [yesNoList.length])

  useEffect(() => {
    if (locationList.length === 0) {
      getSpineLocationList();
    }
    if (tumorLocationList.length === 0) {
      getTumorLocationList();
    }
    if (aneurysmLocationList.length === 0) {
      getAneurysmLocationList();
    }
    if (hematomaLocationList.length === 0) {
      getHematomaLocationList();
    }
    if (fractureLocationList.length === 0) {
      getFractureLocationList();
    }
    if (hydrocephalusLocationList.length === 0) {
      getHydrocephalusLocationList();
    }
    if (avmLocationList.length === 0) {
      getAVMLocationList();
    }
    if (bleedingLocationList.length === 0) {
      getBleedingLocationList();
    }
    if (dissectionLocationList.length === 0) {
      getDissectionLocationList();
    }
    if (strokeLocationList.length === 0) {
      getStrokeLocationList();
    }
    if (aneurysmLocationInterList.length === 0) {
      getAneurysmLocationInterList();
    }
  }, [locationList.length, tumorLocationList.length, aneurysmLocationList.length,
  hematomaLocationList.length, fractureLocationList.length, hydrocephalusLocationList.length,
  avmLocationList.length, bleedingLocationList.length, dissectionLocationList.length, aneurysmLocationInterList.length]);

  useEffect(() => {
    if (osteotomyList.length === 0 && !props.showEditCaseMessage) {
      getOsteotomyList();
    }
  }, [osteotomyList.length]);

  useEffect(() => {
    if (genderList.length === 0 && !props.showEditCaseMessage) {
      getGenders();
    }
  }, [genderList.length]);

  useEffect(() => {
    if (roleList.length === 0 && !props.showEditCaseMessage) {
      getRoles();
    }
  }, [roleList.length]);

  const uploadImages = async (id, procedureId, files, onUploadProgress) => {
    const { status_code: statusCode } = await api.uploadProcedureMedia(
      id,
      procedureId,
      files,
      onUploadProgress
    );
    if (statusCode === api.status.SUCCESS) {
    }
  };

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    const formData = new FormData();
    let newValues = { ...values };
    images.forEach((file) => {
      formData.append('images', file);
    });
    if (!values.hasOwnProperty('notes')) {
      newValues = { ...values, notes: [] };
    }
    const newNote = newValues['notes'].map((a) => a.note);

    if (!values.hasOwnProperty('case_procedures')) {
      newValues = { ...values, case_procedures: [...caseProcedures] };
      for (let procedure of newValues.case_procedures) {
        procedure['implant'] = procedure['implant'] === 'Yes' ? '1' : procedure['implant'] === 'No' ? '0' : '';
        procedure['_fusion'] = procedure['_fusion'] === 'Yes' ? '1' : procedure['_fusion'] === 'No' ? '0' : '';
        procedure['interbody_fusion'] = procedure['interbody_fusion'] === 'Yes' ? '1' : procedure['interbody_fusion'] === 'No' ? '0' : '';
        procedure['extension_to_pelvis'] = procedure['extension_to_pelvis'] === 'Yes' ? '1' : procedure['extension_to_pelvis'] === 'No' ? '0' : '';
        procedure['morphogenic_protein'] = procedure['morphogenic_protein'] === 'Yes' ? '1' : procedure['morphogenic_protein'] === 'No' ? '0' : '';
        procedure['minimally_invasive'] = procedure['minimally_invasive'] === 'Yes' ? '1' : procedure['minimally_invasive'] === 'No' ? '0' : '';
        procedure['navigation'] = procedure['navigation'] === 'Yes' ? '1' : procedure['navigation'] === 'No' ? '0' : '';
        procedure['robotic'] = procedure['robotic'] === 'Yes' ? '1' : procedure['robotic'] === 'No' ? '0' : '';

        if (isMultipleSelection()) {
          procedure['indication'] = collectList(procedure['indication']);
          procedure['approach'] = collectList(procedure['approach']);
          procedure['location'] = collectList(procedure['location']);
          procedure['_procedure'] = collectList(procedure['_procedure']);
        }


        if (typeof (procedure.id) === 'string') {
          delete procedure.id;
        }
      }
    }

    let year;
    let monthName;
    let date;
    let formattedBirthDate;

    if (selectedDate != null) {
      year = selectedDate.getFullYear();
      monthName = months[selectedDate.getMonth()];
      date = selectedDate.getDate();
      formattedBirthDate = `${monthName} ${date}, ${year}`;
    }

    const yearSurgery = surgeryDate.getFullYear();
    const monthNameSurgery = months[surgeryDate.getMonth()];
    const dateSurgery = surgeryDate.getDate();
    let formattedSurgeryDate = `${monthNameSurgery} ${dateSurgery}, ${yearSurgery}`;

    const yearProcedure = procedureDate?.getFullYear();
    const monthNameProcedure = procedureDate ? months[procedureDate.getMonth()] : '';
    const dateProcedure = procedureDate?.getDate();
    let formattedProcedureDate = `${monthNameProcedure} ${dateProcedure}, ${yearProcedure}`;

    if (isNeurosurgery() || isInterventional() || isRadiology()) {
      formattedSurgeryDate = formattedProcedureDate;
    } else {
      formattedProcedureDate = formattedSurgeryDate;
    }

    const cptCodes: any[] = [];
    const diagnosisCodes: any[] = [];

    for (let index = 0; index < selectedCptCodes.length; index++) {
      cptCodes.push(selectedCptCodes[index].id);
    }
    for (let index = 0; index < diagnosis.length; index++) {
      diagnosisCodes.push(diagnosis[index].id);
    }

    formData.append('date_of_birth', formattedBirthDate);
    formData.append('date', formattedSurgeryDate);
    formData.append('date_of_procedure', formattedProcedureDate);
    formData.append('cpt_id', JSON.stringify(cptCodes));
    formData.append('icd_id', JSON.stringify(diagnosisCodes));

    if (getValues('hospital_id')) {
      formData.append('hospital_id', getValues('hospital_id'));
    }

    formData.append('notes', JSON.stringify(newNote));
    formData.append('patient_first_name', newValues['patient_first_name']);
    formData.append('patient_last_name', newValues['patient_last_name']);
    formData.append('gender', newValues['gender']);
    formData.append('type', newValues['type']);
    formData.append('procedure_name', newValues['procedure_name']);
    formData.append('minimally_invasive', newValues['minimally_invasive'] === 'Yes' ? '1' : newValues['minimally_invasive'] === 'No' ? '0' : '');
    formData.append('navigation', newValues['navigation'] === 'Yes' ? '1' : newValues['navigation'] === 'No' ? '0' : '');
    formData.append('robotic', newValues['robotic'] === 'Yes' ? '1' : newValues['robotic'] === 'No' ? '0' : '');
    formData.append('indication', isMultipleSelection() ? collectList(indicationType) : newValues['indication']);
    formData.append('location', isMultipleSelection() ? collectList(locationType) : newValues['location']);
    formData.append('approach', isMultipleSelection() ? collectList(approachType) : newValues['approach']);
    formData.append('_procedure', isMultipleSelection() ? collectList(_procedureType) : newValues['_procedure']);
    formData.append('side', newValues['side']);
    formData.append('implant', newValues['implant'] === 'Yes' ? '1' : newValues['implant'] === 'No' ? '0' : '');
    formData.append('implant_type', newValues['implant_type']);
    formData.append('osteotomy', newValues['osteotomy']);
    formData.append('_fusion', newValues['_fusion'] === 'Yes' ? '1' : newValues['_fusion'] === 'No' ? '0' : '');
    formData.append('fusion_levels', newValues['fusion_levels']);
    formData.append('decompression_levels', newValues['decompression_levels']);
    formData.append('interbody_fusion', newValues['interbody_fusion'] === 'Yes' ? '1' : newValues['interbody_fusion'] === 'No' ? '0' : '');
    formData.append('extension_to_pelvis', newValues['extension_to_pelvis'] === 'Yes' ? '1' : newValues['extension_to_pelvis'] === 'No' ? '0' : '');
    formData.append('morphogenic_protein', newValues['morphogenic_protein'] === 'Yes' ? '1' : newValues['morphogenic_protein'] === 'No' ? '0' : '');
    formData.append('instrumentation', newValues.instrumentation);
    formData.append('target', newValues['target']);
    formData.append('nerve', newValues['nerve']);
    formData.append('access', newValues['access']);
    formData.append('vascular_closure_device', newValues['vascular_closure_device']);
    formData.append('duration_of_radiation', newValues['duration_of_radiation']);
    formData.append('duration_of_procedure', newValues['duration_of_procedure']);
    formData.append('mrn', newValues['mrn']);
    formData.append('insurance', newValues['insurance']);
    formData.append('age', newValues['age']);
    formData.append('primary_surgeon', newValues['primary_surgeon']);
    formData.append('race', newValues['race']);
    if (newValues['role']) {
      formData.append('role', newValues['role']);
    }

    formData.append('findings', newValues['findings']);
    formData.append('vendors', newValues['vendors']);
    formData.append('complications', newValues['complications']);
    formData.append('outcome', newValues['outcome']);
    formData.append('case_procedures', JSON.stringify(newValues.case_procedures));
    formData.append('cpt_modifier_id', cptModifier && cptCodes.length > 0 ? cptModifier?.id : '');
    formData.append('procedure_diagnosis', newValues['procedure_diagnosis']);
    if (location.includes('/new-shared-case/')) {
      const { status_code: statusCode, data } = await api.createProcedure(
        doctorId,
        formData
      );
      if (statusCode === api.status.SUCCESS) {
        props.setShowEditCaseMessage(true);
        let message = ['success', 'Case addded successfully'];
        props.setCaseMessage(message);
        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
        history.push('/shared-dashboard/' + doctorId);
      } else {
        setShowAddCaseMessage(true);

        let message = ['error', data];

        setAddCaseMessage(message);

        setTimeout(() => setShowAddCaseMessage(false), 1000);
      }
    } else {
      const { status_code: statusCode, data } = await api.createProcedure(
        user.id,
        formData
      );
      if (statusCode === api.status.SUCCESS) {
        props.setShowEditCaseMessage(true);
        let message = ['success', 'Case addded successfully'];
        props.setCaseMessage(message);
        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
        history.push('/dashboard');
      } else {
        setShowAddCaseMessage(true);

        let message = ['error', data];

        setAddCaseMessage(message);

        setTimeout(() => setShowAddCaseMessage(false), 1000);
      }
    }
  };

  const EmptyNote = (notes) => {
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].length === 0) {
        notes.splice(i, 1);
      }
    }
    return notes;
  };

  const onEditSubmit = async (values: any) => {
    setSubmitting(true);
    setIsBlocking(false);
    let newValues = { ...values };
    if (!values.hasOwnProperty('notes')) {
      newValues = { ...values, notes: [] };
    }
    if (typeof selectedDate !== 'string' && selectedDate) {
      const year = selectedDate.getFullYear();
      const monthName = months[selectedDate.getMonth()];
      const date = selectedDate.getDate();
      const formattedBirthDate = `${monthName} ${date}, ${year}`;
      newValues['date_of_birth'] = formattedBirthDate;
    } else {
      if (selectedDate) {
        newValues['date_of_birth'] = selectedDate;
      }
    }

    if (typeof surgeryDate !== 'string') {
      const yearSurgery = surgeryDate.getFullYear();
      const monthNameSurgery = months[surgeryDate.getMonth()];
      const dateSurgery = surgeryDate.getDate();
      const formattedSurgeryDate = `${monthNameSurgery} ${dateSurgery}, ${yearSurgery}`;
      newValues['date'] = formattedSurgeryDate;
    } else {
      newValues['date'] = surgeryDate;
    }

    if (typeof procedureDate !== 'string') {
      const yearProcedure = procedureDate?.getFullYear();
      const monthNameProcedure = procedureDate ? months[procedureDate.getMonth()] : '';
      const dateProcedure = procedureDate?.getDate();
      const formattedProcedureDate = `${monthNameProcedure} ${dateProcedure}, ${yearProcedure}`;
      newValues['date_of_procedure'] = formattedProcedureDate;
    } else {
      newValues['date_of_procedure'] = procedureDate;
    }

    const cptCodes: any[] = [];
    const diagnosisCodes: any[] = [];

    for (let index = 0; index < selectedCptCodes.length; index++) {
      cptCodes.push(selectedCptCodes[index].id);
    }
    for (let index = 0; index < diagnosis.length; index++) {
      diagnosisCodes.push(diagnosis[index].id);
    }

    newValues['cpt_id'] = cptCodes;
    newValues['icd_id'] = diagnosisCodes;

    newValues['hospital_id'] = parseInt(getValues('hospital_id'), 10);
    if (newValues['mrn'] === null) {
      newValues['mrn'] = '';
    }
    if (newValues['role'] === null) {
      newValues['role'] = '';
    }
    const newEditeNote = newValues['notes'].map((a) => a.note);
    const filterNote = EmptyNote(newEditeNote);
    newValues['notes'] = filterNote;

    newValues['implant'] = newValues['implant'] === 'Yes' ? '1' : newValues['implant'] === 'No' ? '0' : '';
    newValues['_fusion'] = newValues['_fusion'] === 'Yes' ? '1' : newValues['_fusion'] === 'No' ? '0' : '';
    newValues['interbody_fusion'] = newValues['interbody_fusion'] === 'Yes' ? '1' : newValues['interbody_fusion'] === 'No' ? '0' : '';
    newValues['extension_to_pelvis'] = newValues['extension_to_pelvis'] === 'Yes' ? '1' : newValues['extension_to_pelvis'] === 'No' ? '0' : '';
    newValues['morphogenic_protein'] = newValues['morphogenic_protein'] === 'Yes' ? '1' : newValues['morphogenic_protein'] === 'No' ? '0' : '';
    newValues['minimally_invasive'] = newValues['minimally_invasive'] === 'Yes' ? '1' : newValues['minimally_invasive'] === 'No' ? '0' : '';
    newValues['navigation'] = newValues['navigation'] === 'Yes' ? '1' : newValues['navigation'] === 'No' ? '0' : '';
    newValues['robotic'] = newValues['robotic'] === 'Yes' ? '1' : newValues['robotic'] === 'No' ? '0' : '';
    if (isMultipleSelection()) {
      newValues['indication'] = collectList(indicationType);
      newValues['location'] = collectList(locationType);
      newValues['approach'] = collectList(approachType);
      newValues['_procedure'] = collectList(_procedureType);
    }

    if (isNeurosurgery() || isInterventional() || isRadiology()) {
      const pDate = new Date(newValues['date_of_procedure']);
      newValues['date'] = `${months[pDate.getMonth()]} ${pDate.getDate()}, ${pDate.getFullYear()}`;
    } else {
      const sDate = new Date(newValues['date']);
      newValues['date_of_procedure'] = `${months[sDate.getMonth()]} ${sDate.getDate()}, ${sDate.getFullYear()}`;
    }

    newValues['case_procedures'] = caseProcedures;
    for (let procedure of newValues.case_procedures) {
      procedure['implant'] = procedure['implant'] === 'Yes' ? '1' : procedure['implant'] === 'No' ? '0' : '';
      procedure['_fusion'] = procedure['_fusion'] === 'Yes' ? '1' : procedure['_fusion'] === 'No' ? '0' : '';
      procedure['interbody_fusion'] = procedure['interbody_fusion'] === 'Yes' ? '1' : procedure['interbody_fusion'] === 'No' ? '0' : '';
      procedure['extension_to_pelvis'] = procedure['extension_to_pelvis'] === 'Yes' ? '1' : procedure['extension_to_pelvis'] === 'No' ? '0' : '';
      procedure['morphogenic_protein'] = procedure['morphogenic_protein'] === 'Yes' ? '1' : procedure['morphogenic_protein'] === 'No' ? '0' : '';
      procedure['minimally_invasive'] = procedure['minimally_invasive'] === 'Yes' ? '1' : procedure['minimally_invasive'] === 'No' ? '0' : '';
      procedure['navigation'] = procedure['navigation'] === 'Yes' ? '1' : procedure['navigation'] === 'No' ? '0' : '';
      procedure['robotic'] = procedure['robotic'] === 'Yes' ? '1' : procedure['robotic'] === 'No' ? '0' : '';

      if (isMultipleSelection()) {
        procedure['indication'] = collectList(procedure['indication']);
        procedure['location'] = collectList(procedure['location']);
        procedure['approach'] = collectList(procedure['approach']);
        procedure['_procedure'] = collectList(procedure['_procedure']);
      }

      if (typeof (procedure.id) === 'string') {
        delete procedure.id;
      }
    }

    newValues['cpt_modifier_id'] = cptModifier && cptCodes.length > 0 ? cptModifier.id : '';
    if (location.includes('/edit-shared-case/')) {
      const { status_code: statusCode, data } = await api.editProcedureById(
        doctorId,
        caseId,
        newValues
      );
      if (statusCode === api.status.SUCCESS) {
        if (images && images.length > 0) {
          uploadImages(doctorId, caseId, images, api.config.onUploadProgress);
        }
        props.setShowEditCaseMessage(true);
        props.setCaseMessage(['success', 'Case info is successfully updated!']);

        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
        history.push('/shared-case/' + doctorId + '/' + caseId);
      } else {
        setAddCaseMessage(['error', data]);
        setShowAddCaseMessage(true);
        setTimeout(() => setShowAddCaseMessage(false), 1000);
      }
    } else {
      const { status_code: statusCode, data } = await api.editProcedureById(
        user.id,
        caseId,
        newValues
      );
      if (statusCode === api.status.SUCCESS) {
        if (images && images.length > 0) {
          uploadImages(user.id, caseId, images, api.config.onUploadProgress);
        }
        props.setShowEditCaseMessage(true);
        props.setCaseMessage(['success', 'Case info is successfully updated!']);

        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
        history.push('/case/' + caseId);
      } else {
        props.setShowEditCaseMessage(true);
        props.setCaseMessage(['error', { data }]);
        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
      }
    }
  };
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setIsBlocking(true);
  };

  const handleSurgeryDateChange = (date: any) => {
    setSurgeryDate(date);
    setIsBlocking(true);
  };

  const handleProcedureDateChange = (date: any) => {
    setProcedureDate(date);
    setIsBlocking(true);
  };

  const handleDialogClose = () => {
    setOpenSelectDialog(false);
  };

  const handleCloseDialog = () => {
    setisOpen(false);
  };

  const spliceCptCode = (code: any) => {
    setSelectedCptCodes(selectedCptCodes.filter((item) => item !== code));
  };

  const spliceImage = (index: any) => {
    setImages((images) => images.filter((img, i) => i !== index));
  };

  const deleteImage = async (index: any, id: any) => {
    const { status_code: statusCode } = await api.deleteImage(
      user.id,
      caseId,
      id
    );
    if (statusCode === api.status.SUCCESS) {
      setImages((media) => media.filter((img, i) => i !== index));
      setOpenDeleteImage(false);
    }
  };

  const spliceDiagnose = (code: any) => {
    setDiagnosis(diagnosis.filter((item) => item !== code));
  };

  const handleCapture = async ({ target }: any) => {
    setIsBlocking(true);
    if (target.files.length > 0) {
      let newImages = [...images, target.files[0]];
      setImages(newImages);
    }
  };

  const getCurrentPlan = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getCurrentPlan();
    if (statusCode === api.status.SUCCESS) {
      setCurrentPlan(data);
    }
  }, []);

  const getUserToken = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getUserToken();
    setNewPlan(false);
    if (statusCode === api.status.SUCCESS) {
      setUserToken(data.token);
    } else if (statusCode === api.status.ERROR_UPDATE) {
      setTokenError(true);
      setMessageError(data);
    }
  }, []);

  const getCPTModifiersList = async () => {
    const { data, status_code: statusCode } = await api.getCPTModifiers();
    if (statusCode === api.status.SUCCESS) {
      setCPTModifiersList(data);
    }
  }

  useEffect(() => {
    if (cptModifiersList.length == 0) {
      getCPTModifiersList();
    }
  }, [cptModifiersList.length])

  const isEmptyProcedure = () => {
    return procedureType === '';
  }

  const isEmptyIndication = () => {
    const indication = indicationType === null ? '' : indicationType;
    return indication === '' || indication.includes('');
  }

  const isOtherIndication = () => {
    const indication = indicationType === null ? '' : indicationType;
    return indication === 'Other' || indication.includes('Other');
  }

  const isOtherProcedure = () => {
    return procedureType === 'Other';
  }

  const isNeurosurgery = () => {
    return user.user_profile[0].speciality?.name === 'Neurological Surgery'
  }

  const isInterventional = () => {
    return user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology';
  }

  const isRadiology = () => {
    return user.user_profile[0].speciality?.name == 'Interventional Radiology';
  }

  const isTumor = () => {
    return indicationType === 'Tumor' || indicationType.includes('Tumor');
  }

  const isPainIndication = () => {
    return indicationType === 'Pain' || indicationType.includes('Pain');
  }

  const isCarotid = () => {
    return indicationType === 'Carotid stenosis' || indicationType.includes('Carotid stenosis');
  }
  const isAVF = () => {
    return indicationType === 'AVF' || indicationType.includes('AVF');
  }

  const isAVM = () => {
    return indicationType === 'AVM' || indicationType.includes('AVM');
  }

  const isAbscess = () => {
    return indicationType === 'Abscess' || indicationType.includes('Abscess');
  }

  const isAneurysm = () => {
    return indicationType === 'Aneurysm' || indicationType.includes('Anyurysm');
  }

  const isHematoma = () => {
    return indicationType === 'Hematoma' || indicationType.includes('Hematoma');
  }

  const isFracture = () => {
    return indicationType === 'Fracture' || indicationType.includes('Fracture');
  }

  const isHydrocephalus = () => {
    return indicationType === 'Hydrocephalus' || indicationType.includes('Hydrocephalus');
  }

  const isBleeding = () => {
    return indicationType === 'Bleeding' || indicationType.includes('Bleeding');
  }

  const isDissection = () => {
    return indicationType === 'Dissection' || indicationType.includes('Dissection');
  }

  const isVasospasm = () => {
    return indicationType === 'Vasospasm' || indicationType.includes('Vasospasm');
  }

  const isStroke = () => {
    return indicationType === 'Stroke' || indicationType.includes('Stroke');
  }

  const isCranial = () => {
    return procedureType === 'Cranial';
  }

  const isSpine = () => {
    return procedureType === 'Spine';
  }

  const isFunctional = () => {
    return procedureType === 'Functional';
  }

  const isPeripheral = () => {
    return procedureType === 'Peripheral nerves';
  }

  const isInter = () => {
    return procedureType === 'Neurointerventional';
  }

  const isBedside = () => {
    return procedureType === 'Bedside';
  }

  const isPain = () => {
    return procedureType === 'Pain';
  }

  const isRadiosurgery = () => {
    return procedureType === 'Radiosurgery';
  }

  const isThrombectomy = () => {
    return neuroProcedure === 'Thrombectomy' || _procedureType.includes('Thrombectomy');
  }

  useEffect(() => {
    const unblock = history.block((location) => {
      setPathName(location.pathname);
    });
    return (() => {
      unblock();
    })
  }, [history, pathName]);

  React.useEffect(() => {
    getUserToken();
    if (isOpen) {
      getCurrentPlan();
    }
  }, [isOpen]);

  React.useEffect(() => {
    let maxRvu = 0;
    let sumOfRestOfRvus = 0;
    let sumRvu = selectedCptCodes.reduce(function (sum, item) {
      return (sum = sum + item.rvu_value);
    }, 0);
    setSumOfRvu(sumRvu.toFixed(3));
    for (let i = 0; i < selectedCptCodes.length; i++) {
      if (selectedCptCodes[i].rvu_value > maxRvu) {
        maxRvu = selectedCptCodes[i].rvu_value;
      }
    }
    sumOfRestOfRvus = sumRvu - maxRvu;
    const sumOfTotalReimbursable = maxRvu + (sumOfRestOfRvus * 0.5);
    setTotalReimbursable(sumOfTotalReimbursable);
  }, [selectedCptCodes]);

  const updateCaseProcedures = (index, procedure) => {
    setCaseProcedures(procedures => {
      return procedures[index] = procedure;
    });
  }

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  const handleFormChange = () => {
    setIsFormDirty(true);
  }

  const handleDiscard = () => {
    setTimeout(() => {
      setOpenDiscard(false);
      setIsFormDirty(false);
      if (nextLocation) {
        history.push(nextLocation);
      } else {
        history.goBack();
      }
    }, 1);
  }

  const handleRouteChange = (nextLocation) => {
    if (isFormDirty) {
      setNextLocation(nextLocation.pathname);
      setOpenDiscard(true);
      return false;
    }
    return true;
  }

  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<any>(null);
  const [openedImage, setOpenedImage] = useState<any>(null);
  const [newImageName, setNewImageName] = useState('');

  const handleRenameClick = (index) => {
    setSelectedImageIndex(index);
    setNewImageName(images[index].name);
    setRenameDialogOpen(true);
  };

  const handleRenameClose = () => {
    setRenameDialogOpen(false);
    setNewImageName('');
    setSelectedImageIndex(null);
  };

  const handleRenameSave = () => {
    const updatedImages = [...images];
    const selectedImage = updatedImages[selectedImageIndex];
    const updatedImage = new File([selectedImage], newImageName, {
      type: selectedImage.type,
    });
    updatedImages[selectedImageIndex] = updatedImage;
    setImages(updatedImages);

    handleRenameClose();
  };

  const handleDeleteImage = (index, imageId) => {
    setImageIndex(index);
    setImageId(imageId);
    setOpenDeleteImage(true);
  }

  const removeImage = (index) => {
    setImages((media) => media.filter((img, i) => i !== index));
  }

  return (
    <div className={classes.root}>
      <DeleteDialog
        isOpen={openDeleteImage}
        title={'Delete image'}
        firstOption={'Delete'}
        secondOption={'Cancel'}
        handleOptionOne={() => deleteImage(imageIndex, imageId)}
        handleOptionTwo={() => setOpenDeleteImage(false)}
        onClose={() => { }}
      >
        {'Note: Deleting this image is permanent and cannot be undone.'}
      </DeleteDialog>
      <Dialog
        maxWidth={false}
        open={openSelectDialog}
        onClose={handleDialogClose}
        classes={{ paper: classes.selectPaper }}
      >
        <div className={classes.dialog}>
          <SelectCodes
            index={-1}
            selectedCodeObjCptCodes={selectedCptCodes}
            selectedCodeObjDiagnosis={diagnosis}
            diagnosis={diagnosis}
            selectedCptCodes={selectedCptCodes}
            setSelectedCptCodes={setSelectedCptCodes}
            setDiagnosis={setDiagnosis}
            searchPath={searchPath}
            closeDialog={setOpenSelectDialog}
          />
        </div>
      </Dialog>
      <Dialog
        open={openModifierDialog}
        maxWidth="lg"
        onClose={() => setOpenModifierDialog(false)}
        classes={{ paper: classes.dialogContainer }}
      >
        <div className={classes.modifierDialog}>
          <SelectModifier
            cptModifiers={cptModifiersList}
            setOpenModifierDialog={setOpenModifierDialog}
            setCPTModifier={setCPTModifier}
          />
        </div>
      </Dialog>

      <Prompt
        when={isFormDirty}
        message={handleRouteChange}
      />
      <ConfirmCloseDialog
        isOpen={openDiscard}
        title={'Discard New Case'}
        firstOption={'Back'}
        secondOption={'Discard'}
        handleOptionOne={() => setOpenDiscard(false)}
        handleOptionTwo={handleDiscard}
        onClose={() => { }}
      >
        By leaving this page you will discard the information you entered.
      </ConfirmCloseDialog>
      <form
        onKeyPress={onKeyPress}
        onChange={handleFormChange}
        onSubmit={handleSubmit(caseId ? onEditSubmit : onSubmit)}
      >
        <Prompt when={caseId ? isBlocking : false} message={() => false} />
        <Box className={classes.addCaseHeader}>
          {caseId ? (
            <>
              <Typography
                onClick={() => {
                  setIsOpenConfirmClose(true);
                  if (location.includes('/edit-shared-case/')) {
                    history.push('/shared-dashboard/' + doctorId);
                  } else {
                    history.push('/dashboard');
                  }
                }}
                variant="h1"
                className={classes.link}
              >
                {'Dashboard > '}
              </Typography>
              <Typography
                onClick={() => {
                  setIsOpenConfirmClose(true);
                  if (location.includes('/edit-shared-case/')) {
                    history.push('/shared-case/' + doctorId + '/' + caseId);
                  } else {
                    history.push('/case/' + caseId);
                  }
                }}
                variant="h1"
                className={classes.link}
              >
                {procedureInitData.patient_first_name +
                  ' ' +
                  procedureInitData.patient_last_name +
                  ' > '}
              </Typography>
              <Typography color="primary" className={classes.pageName}>
                Edit case
              </Typography>
            </>
          ) : (
            <>
              <Typography
                onClick={() => {
                  if (location.includes('/new-shared-case')) {
                    history.push('/shared-dashboard/' + doctorId);
                  } else {
                    history.push('/dashboard');
                  }
                }}
                variant="h1"
                className={classes.link}
              >
                {'Dashboard > '}
              </Typography>
              <Typography color="primary" className={classes.pageName}>
                Add Case
              </Typography>
            </>
          )}
        </Box>
        <Box my={2}>
          <Paper>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h2" className={classes.header}>
                Patient info
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Box className={classes.InputContainerSecond}>
              <Grid className={classes.py0} container item={true} spacing={2}>
                <Grid
                  className={[classes.py0, classes.mb0Mobile].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    as={TextField}
                    className={classes.mt0}
                    required
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk
                      }
                    }}
                    variant="outlined"
                    name="patient_last_name"
                    control={control}
                    label="Patient last name "
                    margin="normal"
                    helperText={
                      errors.patient_last_name &&
                      errors.patient_last_name.message
                    }
                    fullWidth
                    error={errors.patient_last_name ? true : false}
                  />
                </Grid>

                <Grid
                  className={[classes.py0, classes.mb0Mobile].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    className={classes.mt0}
                    as={TextField}
                    required
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk
                      }
                    }}
                    label="Patient first name "
                    control={control}
                    name="patient_first_name"
                    error={errors.patient_first_name ? true : false}
                    helperText={
                      errors.patient_first_name &&
                      errors.patient_first_name.message
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Grid
                  className={[classes.py0mb, classes.mbGender].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <ReactHookFormSelect
                    required
                    name="gender"
                    label="Gender "
                    control={control}
                    className={classes.mt0}
                    defaultValue=""
                    variant="outlined"
                    margin="normal"
                    setIsBlocking={() => { }}
                    error={errors.gender ? true : false}
                    helperText={errors.gender && errors.gender.message}
                    onClick={handleFormChange}
                  >
                    {genderList.length > 0
                      ? genderList.map((gender: any) => (
                        <MenuItem key={gender.value} value={gender.value}>
                          {gender.label}
                        </MenuItem>
                      ))
                      : null}
                  </ReactHookFormSelect>
                </Grid>

                <Grid
                  className={[
                    classes.surgeryDateField,
                    classes.py0,
                    classes.mb0DateMobile
                  ].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className={classes.mt0}
                      variant="inline"
                      autoOk={true}
                      inputVariant="outlined"
                      value={selectedDate ? selectedDate : null}
                      onChange={handleDateChange}
                      label="Date of birth "
                      name="date_of_birth"
                      format="MMM dd, yyyy"
                      fullWidth
                      maxDate={new Date()}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid
                  className={classes.py0}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    as={TextField}
                    className={classes.mt0}
                    variant="outlined"
                    name="age"
                    defaultValue=""
                    control={control}
                    label="Age"
                    margin="normal"
                    fullWidth
                    error={errors.age ? true : false}
                    helperText={errors.age && errors.age.message}
                  />
                </Grid>

                <Grid
                  className={[classes.py0mb, classes.mbGender].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <ReactHookFormSelect
                    name="race"
                    label="Race "
                    control={control}
                    className={classes.mt0}
                    defaultValue=""
                    variant="outlined"
                    margin="normal"
                    setIsBlocking={() => { }}
                    error={errors.race ? true : false}
                    helperText={errors.race && errors.race.message}
                    onClick={handleFormChange}
                  >
                    {raceList.length > 0
                      ? raceList.map((race: any) => (
                        <MenuItem key={race.value} value={race.value}>
                          {race.label}
                        </MenuItem>
                      ))
                      : null}
                  </ReactHookFormSelect>
                </Grid>

                <Grid
                  className={[classes.py0, classes.fieldMarginBottom].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    as={TextField}
                    className={classes.mt0}
                    variant="outlined"
                    name="mrn"
                    defaultValue=""
                    control={control}
                    label="Medical record number"
                    margin="normal"
                    fullWidth
                    error={errors.mrn ? true : false}
                  />
                </Grid>

                <Grid
                  className={classes.py0}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <ReactHookFormSelect
                    name="insurance"
                    label="Insurance "
                    control={control}
                    className={classes.mt0}
                    defaultValue=""
                    variant="outlined"
                    margin="normal"
                    setIsBlocking={() => { }}
                    error={errors.insurance ? true : false}
                    helperText={errors.insurance}
                    onClick={handleFormChange}
                  >
                    {insuranceList.length > 0
                      ? insuranceList.map((insurance: any) => (
                        <MenuItem key={insurance.value} value={insurance.value}>
                          {insurance.label}
                        </MenuItem>
                      ))
                      : null}
                  </ReactHookFormSelect>
                </Grid>

                <Grid
                  className={[classes.py0, classes.mb0Mobile].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <ReactHookFormSelect
                    required
                    name="hospital_id"
                    label={hospitalList.length > 0 ? "Institution" : "Institution"}
                    control={control}
                    className={classes.mt0}
                    defaultValue=""
                    variant="outlined"
                    margin="normal"
                    setIsBlocking={setIsBlocking}
                    error={errors.hospital_id ? true : false}
                    helperText={errors.hospital_id && errors.hospital_id.message}
                  >
                    {hospitalList.length > 0 ? (
                      hospitalList.map((hospital: any) => (
                        <MenuItem key={hospital.value} value={hospital.value}>
                          {hospital.label}
                        </MenuItem>
                      ))
                    ) : null}

                    <MenuItem
                      key="add new position"
                      value=""
                      onClick={() => {
                        setShowEndDate(false);
                        setViewPositionForm(true);
                      }}
                    >
                      <AddIcon /> Add position
                    </MenuItem>
                  </ReactHookFormSelect>

                </Grid>

                <Grid
                  className={classes.py0}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    as={TextField}
                    className={classes.mt0}
                    variant="outlined"
                    name="primary_surgeon"
                    defaultValue=""
                    control={control}
                    label="Primary surgeon (If not you)"
                    margin="normal"
                    fullWidth
                    error={errors.primary_surgeon ? true : false}
                    helperText={errors.primary_surgeon && errors.primary_surgeon.message}
                  />
                </Grid>

              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box mb={2}>
          <Paper>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h2" className={classes.header}>
                Diagnosis
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Box className={classes.InputContainer}>
              <Grid className={classes.py0} container item={true} spacing={2}>
                <Grid
                  className={classes.py0}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    as={TextField}
                    className={classes.mt0}
                    variant="outlined"
                    name="procedure_diagnosis"
                    defaultValue=""
                    control={control}
                    label="Diagnosis"
                    margin="normal"
                    fullWidth
                    error={errors.procedure_diagnosis ? true : false}
                    helperText={errors.procedure_diagnosis && errors.procedure_diagnosis.message}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box mb={2}>
          <Paper>
            <Box>
              <Box className={classes.title}>
                <Typography variant="h2" className={classes.inline}>
                  ICD-9/10
                </Typography>

                <AddIcon
                  onClick={() => {
                    setSearchPath('icd_lookup');
                    setOpenSelectDialog(true);
                  }}
                  color="secondary"
                  className={classes.cursor}
                ></AddIcon>
              </Box>

              <Box mt={1} mb={2}>
                {caseId && diagnosis.length > 0 ? <Divider></Divider> : null}
              </Box>

              <Grid container item={true} xs={12} spacing={1}>
                {diagnosis.length > 0
                  ? diagnosis.map((item: any, index) => (
                    <>
                      <Grid
                        className={
                          index % 2 === 0
                            ? classes.withpadding
                            : classes.withOutpadding
                        }
                        key={item.id}
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                      >
                        <Box
                          className={
                            index % 2 === 0
                              ? classes.cptBoxL
                              : classes.cptBoxR
                          }
                        >
                          <Typography variant="h6">
                            <span className={classes.codeBox}>
                              {item.code}
                            </span>
                            <LinesEllipsis
                              text={item.value}
                              maxLine="3"
                              ellipsis="..."
                              trimRight
                              basedOn="words"
                            />
                          </Typography>
                          <DeleteOutlineIcon
                            onClick={() => spliceDiagnose(item)}
                            className={classes.cursor}
                            color="error"
                          ></DeleteOutlineIcon>
                        </Box>
                      </Grid>
                      {index < diagnosis.length - 1 ? (
                        <Grid
                          item={true}
                          xs={12}
                          className={classes.dividerMobile}
                        >
                          <Divider></Divider>
                        </Grid>
                      ) : null}
                    </>
                  ))
                  : null}
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box mb={2}>
          <Paper>
            <Box>
              <Box className={classes.title}>
                <Typography variant="h2" className={classes.inline}>
                  CPT Codes and RVUs
                </Typography>
                <AddIcon
                  onClick={() => {
                    setSearchPath('cpt_lookups');
                    setOpenSelectDialog(true);
                  }}
                  color="secondary"
                  className={classes.cursor}
                ></AddIcon>
              </Box>
              <Box mt={1} mb={2}>
                {selectedCptCodes.length > 0 ? <Divider></Divider> : null}
              </Box>
              <Grid container item={true} xs={12} spacing={1}>
                {sumOfRvu > 0 ? (
                  <Grid item={true} xs={12} className={classes.rvuContainer}>
                    <Box className={classes.rvuRow}>
                      <Typography
                        variant="subtitle1"
                        className={classes.boldText}
                      >
                        Total RVUs
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.boldText}
                      >
                        {sumOfRvu}
                      </Typography>
                    </Box>
                    {selectedCptCodes.length > 1 ? (
                      <Box>
                        <Divider />
                        <Box className={classes.rvuRow}>
                          <Typography
                            variant="subtitle1"
                            className={classes.boldText}
                          >
                            Total Reimbursable RVUs
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.boldText}
                          >
                            {totalReimbursable.toFixed(3)}
                          </Typography>
                        </Box>
                      </Box>) : null}
                  </Grid>
                ) : null}
                {selectedCptCodes.length > 0
                  ? selectedCptCodes.map((item: any, index) => (
                    <>
                      <Grid
                        className={
                          index % 2 === 0
                            ? classes.withpadding
                            : classes.withOutpadding
                        }
                        key={item.id}
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                      >
                        <Box
                          className={
                            index % 2 === 0
                              ? classes.cptBoxL
                              : classes.cptBoxR
                          }
                        >
                          <Typography variant="h6">
                            <span className={classes.codeBox}>
                              {item.code}
                            </span>
                            <LinesEllipsis
                              text={item.value}
                              maxLine="3"
                              ellipsis="..."
                              trimRight
                              basedOn="words"
                            />
                          </Typography>
                          <DeleteOutlineIcon
                            onClick={() => {
                              spliceCptCode(item);
                              setSumOfRvu(
                                (prevSumOfRvu) =>
                                  prevSumOfRvu - item?.rvu_value
                              );
                            }}
                            className={classes.cursor}
                            color="error"
                          ></DeleteOutlineIcon>
                        </Box>
                      </Grid>
                      {index < selectedCptCodes.length - 1 ? (
                        <Grid
                          item={true}
                          xs={12}
                          className={classes.dividerMobile}
                        >
                          <Divider></Divider>
                        </Grid>
                      ) : null}
                    </>
                  ))
                  : null}
              </Grid>
            </Box>
          </Paper>
        </Box>

        {selectedCptCodes.length > 0 ? (<Box mb={2}>
          <Paper>
            <Box>
              <Box className={classes.title}>
                <Typography variant="h2" className={classes.inline}>
                  CPT Modifiers
                </Typography>
                {!cptModifier ? (<AddIcon
                  onClick={() => {
                    setOpenModifierDialog(true);
                  }}
                  color="secondary"
                  className={classes.cursor}
                ></AddIcon>) : (
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() => {
                      setOpenModifierDialog(true);
                    }}
                  />)}
              </Box>
              <Box mt={1} mb={2}>
                {cptModifier ? <Divider></Divider> : null}
              </Box>
              <Grid container item={true} xs={12} spacing={1}>
                {cptModifier ? (
                  <Grid item={true} xs={12} className={classes.rvuContainer}>
                    <Box className={classes.rvuRow}>
                      <Typography
                        variant="subtitle1"
                        className={classes.boldText}
                      >
                        Total Reimbursable RVUs After CPT Modifier
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.boldText}
                      >
                        {cptModifier ? (totalReimbursable * (cptModifier?.percentage / 100)).toFixed(3) : 0}
                      </Typography>
                    </Box>
                  </Grid>
                ) : null}
                {cptModifier
                  ? [cptModifier].map((item: any, index) => (
                    <>
                      <Grid
                        className={classes.withpadding}
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                      >
                        <Box
                          className={classes.cptBoxL}
                        >
                          <Typography variant="h6">
                            <span className={classes.codeBox}>
                              {`Modifier: ${cptModifier?.id}`}
                            </span>
                            <LinesEllipsis
                              text={`Description: ${cptModifier?.description}`}
                              maxLine="5"
                              ellipsis="..."
                              trimRight
                              basedOn="words"
                            />
                            <span className={classes.codeBox}>
                              {`Percentage: ${getCPTModifierPercentage()}`}
                            </span>
                          </Typography>
                          <DeleteOutlineIcon
                            onClick={() => {
                              setCPTModifier(undefined);
                            }}
                            className={classes.cursor}
                            color="error"
                          ></DeleteOutlineIcon>
                        </Box>
                      </Grid>
                      {cptModifier ? (
                        <Grid
                          item={true}
                          xs={12}
                          className={classes.dividerMobile}
                        >
                          <Divider></Divider>
                        </Grid>
                      ) : null}
                    </>
                  ))
                  : null}
              </Grid>
            </Box>
          </Paper>
        </Box>) : null}

        <Box mb={2}>
          <Paper>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h2" className={classes.header}>
                Procedure details
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Box className={classes.InputContainer}>
              <Grid className={classes.py0} container item={true} spacing={2}>
                {isNeurosurgery() || isInterventional() || isRadiology() ?
                  (<Grid
                    className={classes.dateOfSurgery}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        required
                        variant="inline"
                        inputVariant="outlined"
                        className={classes.mt0}
                        autoOk={true}
                        value={procedureDate}
                        onChange={handleProcedureDateChange}
                        label="Date of procedure "
                        name="date_of_procedure"
                        format="MM/dd/yyyy"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>) : null}

                {isNeurosurgery() || isInterventional() || isRadiology() ? (
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      required
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      name="procedure_name"
                      variant="outlined"
                      control={control}
                      label="Procedure name"
                      margin="normal"
                      fullWidth
                      error={errors.procedure_name ? true : false}
                    />
                  </Grid>) : null}

                <Grid
                  className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  {isNeurosurgery() || isInterventional() || isRadiology() ? (
                    <ReactHookFormSelect
                      required
                      name="type"
                      label="Procedure type "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.type ? true : false}
                      helperText={errors.insurance}
                      onClick={(e) => {
                        handleFormChange();
                        if (e.target.value != 0 && e.target.value != undefined && !isRadiology()) {
                          if (e.target.value == procedureInitData.type) {
                            getProcedureData();
                          } else if (e.target.value == 'Other') {
                            setProcedureType('');
                          } else {
                            setProcedureType(e.target.value);
                            setIndicationType('');
                            setValue('_procedure', '');
                            setValue('approach', '');
                            setValue('indication', '');
                            setValue('location', '');
                          }
                          if (isInterventional()) {
                            if (e.target.value == 'Other') {
                              setNeuroProcedure('');
                              setValue('tici_grade', '');
                            } else {
                              setNeuroProcedure(e.target.value);
                              setValue('tici_grade', '');
                            }
                          }
                        }
                      }}
                    >
                      {isNeurosurgery() && neurosurgeryProcedureTypeList.length > 0
                        ? neurosurgeryProcedureTypeList.map((type: any) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))
                        : isInterventional() && interventionalProcedureTypeList.length > 0
                          ? interventionalProcedureTypeList.map((type: any) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))
                          : isRadiology() && radiologyProcedureTypeList.length > 0
                            ? radiologyProcedureTypeList.map((type: any) => (
                              <MenuItem key={type.value} value={type.value}>
                                {type.label}
                              </MenuItem>
                            )) : null}
                    </ReactHookFormSelect>
                  ) : (
                    <Controller
                      required
                      as={TextField}
                      className={classes.mt0}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      variant="outlined"
                      name="type"
                      control={control}
                      label="Procedure type "
                      margin="normal"
                      helperText={errors.type}
                      fullWidth
                      error={errors.type ? true : false}
                    />)}
                </Grid>

                {isNeurosurgery() && procedureType == 'Cranial' ||
                  isNeurosurgery() && procedureType == 'Spine' ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    {isMultipleSelection() ? (<MultipleSelect
                      name="approach"
                      label="Approach "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      selectedValues={approachType ? approachType : []}
                      setFormValue={setFormValue}
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.approach ? true : false}
                      helperText={errors.approach}
                      onClick={handleFormChange}
                    >
                      {procedureType === "Cranial" && cranialApproachList.length > 0
                        ? cranialApproachList.map((approach: any) => (
                          <MenuItem key={approach.value} value={approach.value}>
                            {approach.label}
                          </MenuItem>
                        ))
                        : procedureType === "Spine" && spineApproachList.length > 0
                          ? spineApproachList.map((approach: any) => (
                            <MenuItem key={approach.value} value={approach.value}>
                              {approach.label}
                            </MenuItem>
                          )) : null}
                    </MultipleSelect>) : (
                      <ReactHookFormSelect
                        name="approach"
                        label="Approach "
                        control={control}
                        className={classes.mt0}
                        defaultValue=""
                        variant="outlined"
                        margin="normal"
                        setIsBlocking={() => { }}
                        error={errors.approach ? true : false}
                        helperText={errors.approach}
                        onClick={handleFormChange}
                      >
                        {procedureType === "Cranial" && cranialApproachList.length > 0
                          ? cranialApproachList.map((approach: any) => (
                            <MenuItem key={approach.value} value={approach.value}>
                              {approach.label}
                            </MenuItem>
                          ))
                          : procedureType === "Spine" && spineApproachList.length > 0
                            ? spineApproachList.map((approach: any) => (
                              <MenuItem key={approach.value} value={approach.value}>
                                {approach.label}
                              </MenuItem>
                            )) : null}
                      </ReactHookFormSelect>
                    )}
                  </Grid>) : null}

                {isNeurosurgery() && isSpine() ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="minimally_invasive"
                      label="Minimally invasive "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.minimally_invasive ? true : false}
                      helperText={errors.minimally_invasive}
                      onClick={handleFormChange}
                    >
                      {yesNoList.length > 0
                        ? yesNoList.map((implant: any) => (
                          <MenuItem key={implant.value} value={implant.value}>
                            {implant.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isNeurosurgery() && isSpine() ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="navigation"
                      label="Navigation "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.navigation ? true : false}
                      helperText={errors.navigation}
                      onClick={handleFormChange}
                    >
                      {yesNoList.length > 0
                        ? yesNoList.map((implant: any) => (
                          <MenuItem key={implant.value} value={implant.value}>
                            {implant.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isNeurosurgery() && isSpine() ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="robotic"
                      label="Robotic "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.robotic ? true : false}
                      helperText={errors.robotic}
                      onClick={handleFormChange}
                    >
                      {yesNoList.length > 0
                        ? yesNoList.map((implant: any) => (
                          <MenuItem key={implant.value} value={implant.value}>
                            {implant.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isNeurosurgery() && (isBedside() || isPain() || isRadiosurgery()) ?
                  (<Grid
                    className={classes.py0}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      variant="outlined"
                      name="indication"
                      defaultValue=""
                      control={control}
                      label="Indication"
                      margin="normal"
                      fullWidth
                      error={errors.indication ? true : false}
                      helperText={errors.indication && errors.indication.message}
                    />
                  </Grid>) : isNeurosurgery() && !isOtherProcedure() && !isEmptyProcedure() ||
                    isInterventional() || isRadiology() ? (
                    <Grid
                      className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                      item={true}
                      xs={12}
                      sm={4}
                      md={6}
                      lg={6}
                    >
                      {isMultipleSelection() ? (<MultipleSelect
                        name="indication"
                        label="Indication "
                        control={control}
                        className={classes.mt0}
                        defaultValue=""
                        variant="outlined"
                        margin="normal"
                        setIsBlocking={() => { }}
                        error={errors.indication ? true : false}
                        helperText={errors.indication}
                        setFormValue={setFormValue}
                        selectedValues={indicationType ? indicationType : []}
                        onClick={(e) => {
                          handleFormChange();
                          if (e.target.value != 0 && e.target.value != undefined && !isRadiology()) {
                            if (e.target.value == 'Other') {
                              setIndicationType('Other');
                              setValue('location', '');
                            } else {
                              setIndicationType(e.target.value);
                              setValue('location', '');
                            }
                          }
                        }}
                      >
                        {isInterventional() && interIndicationList.length > 0
                          ? interIndicationList.map((indication: any) => (
                            <MenuItem key={indication.value} value={indication.value}>
                              {indication.label}
                            </MenuItem>
                          ))
                          : isRadiology() && radiologyIndicationList.length > 0
                            ? radiologyIndicationList.map((indication: any) => (
                              <MenuItem key={indication.value} value={indication.value}>
                                {indication.label}
                              </MenuItem>
                            ))
                            : procedureType === "Cranial" && cranialIndicationList.length > 0
                              ? cranialIndicationList.map((indication: any) => (
                                <MenuItem key={indication.value} value={indication.value}>
                                  {indication.label}
                                </MenuItem>
                              ))
                              : procedureType === "Spine" && spineIndicationList.length > 0
                                ? spineIndicationList.map((indication: any) => (
                                  <MenuItem key={indication.value} value={indication.value}>
                                    {indication.label}
                                  </MenuItem>
                                ))
                                : procedureType === "Functional" && functionalIndicationList.length > 0
                                  ? functionalIndicationList.map((indication: any) => (
                                    <MenuItem key={indication.value} value={indication.value}>
                                      {indication.label}
                                    </MenuItem>
                                  ))
                                  : procedureType === "Peripheral nerves" && nervesIndicationList.length > 0
                                    ? nervesIndicationList.map((indication: any) => (
                                      <MenuItem key={indication.value} value={indication.value}>
                                        {indication.label}
                                      </MenuItem>
                                    ))
                                    : interIndicationList.length > 0
                                      ? interIndicationList.map((indication: any) => (
                                        <MenuItem key={indication.value} value={indication.value}>
                                          {indication.label}
                                        </MenuItem>
                                      )) : null}
                      </MultipleSelect>) : (
                        <ReactHookFormSelect
                          name="indication"
                          label="Indication "
                          control={control}
                          className={classes.mt0}
                          defaultValue=""
                          variant="outlined"
                          margin="normal"
                          setIsBlocking={() => { }}
                          error={errors.indication ? true : false}
                          helperText={errors.indication}
                          setFormValue={setFormValue}
                          onClick={(e) => {
                            handleFormChange();
                            if (e.target.value != 0 && e.target.value != undefined && !isRadiology()) {
                              if (e.target.value == 'Other') {
                                setIndicationType('Other');
                                setValue('location', '');
                              } else {
                                setIndicationType(e.target.value);
                                setValue('location', '');
                              }
                            }
                          }}
                        >
                          {isInterventional() && interIndicationList.length > 0
                            ? interIndicationList.map((indication: any) => (
                              <MenuItem key={indication.value} value={indication.value}>
                                {indication.label}
                              </MenuItem>
                            ))
                            : isRadiology() && radiologyIndicationList.length > 0
                              ? radiologyIndicationList.map((indication: any) => (
                                <MenuItem key={indication.value} value={indication.value}>
                                  {indication.label}
                                </MenuItem>
                              ))
                              : procedureType === "Cranial" && cranialIndicationList.length > 0
                                ? cranialIndicationList.map((indication: any) => (
                                  <MenuItem key={indication.value} value={indication.value}>
                                    {indication.label}
                                  </MenuItem>
                                ))
                                : procedureType === "Spine" && spineIndicationList.length > 0
                                  ? spineIndicationList.map((indication: any) => (
                                    <MenuItem key={indication.value} value={indication.value}>
                                      {indication.label}
                                    </MenuItem>
                                  ))
                                  : procedureType === "Functional" && functionalIndicationList.length > 0
                                    ? functionalIndicationList.map((indication: any) => (
                                      <MenuItem key={indication.value} value={indication.value}>
                                        {indication.label}
                                      </MenuItem>
                                    ))
                                    : procedureType === "Peripheral nerves" && nervesIndicationList.length > 0
                                      ? nervesIndicationList.map((indication: any) => (
                                        <MenuItem key={indication.value} value={indication.value}>
                                          {indication.label}
                                        </MenuItem>
                                      ))
                                      : interIndicationList.length > 0
                                        ? interIndicationList.map((indication: any) => (
                                          <MenuItem key={indication.value} value={indication.value}>
                                            {indication.label}
                                          </MenuItem>
                                        )) : null}
                        </ReactHookFormSelect>
                      )}
                    </Grid>) : null}

                {isNeurosurgery() || isInterventional() ? (<Grid
                  className={classes.py0}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <ReactHookFormSelect
                    name="side"
                    label="Side "
                    control={control}
                    className={classes.mt0}
                    defaultValue=""
                    variant="outlined"
                    margin="normal"
                    setIsBlocking={() => { }}
                    error={errors.side ? true : false}
                    helperText={errors.side}
                    onClick={handleFormChange}
                  >
                    {sidesList.length > 0
                      ? sidesList.map((side: any) => (
                        <MenuItem key={side.value} value={side.value}>
                          {side.label}
                        </MenuItem>
                      ))
                      : null}
                  </ReactHookFormSelect>
                </Grid>) : null}

                {isNeurosurgery() && isInter() && !isEmptyProcedure() ||
                  isNeurosurgery() && isBedside() && !isEmptyProcedure() ?
                  (<Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    {isMultipleSelection() ? (<MultipleSelect
                      name="_procedure"
                      label="Procedure "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      selectedValues={_procedureType ? _procedureType : []}
                      setFormValue={setFormValue}
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors._procedure ? true : false}
                      helperText={errors._procedure}
                      onClick={(e) => {
                        handleFormChange();
                        if (e.target.value != 0 && e.target.value != undefined) {
                          if (e.target.value == 'Other') {
                            setNeuroProcedure('');
                            setValue('tici_grade', '');
                          } else {
                            setNeuroProcedure(e.target.value);
                            setValue('tici_grade', '');
                          }
                        }
                      }}
                    >
                      {interventionalProcedureTypeList.length > 0 && (isInterventional() ||
                        isNeurosurgery() && isInter())
                        ? interventionalProcedureTypeList.map((type: any) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))
                        : isNeurosurgery() && (isBedside() || isPain() || isRadiosurgery()) && bedsideProcedureTypeList.length > 0
                          ? bedsideProcedureTypeList.map((type: any) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          )) : null}
                    </MultipleSelect>) : (
                      <ReactHookFormSelect
                        name="_procedure"
                        label="Procedure "
                        control={control}
                        className={classes.mt0}
                        defaultValue=""
                        variant="outlined"
                        margin="normal"
                        setIsBlocking={() => { }}
                        error={errors._procedure ? true : false}
                        helperText={errors._procedure}
                        onClick={(e) => {
                          handleFormChange();
                          if (e.target.value != 0 && e.target.value != undefined) {
                            if (e.target.value == 'Other') {
                              setNeuroProcedure('');
                              setValue('tici_grade', '');
                            } else {
                              setNeuroProcedure(e.target.value);
                              setValue('tici_grade', '');
                            }
                          }
                        }}
                      >
                        {interventionalProcedureTypeList.length > 0 && (isInterventional() ||
                          isNeurosurgery() && isInter())
                          ? interventionalProcedureTypeList.map((type: any) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))
                          : isNeurosurgery() && (isBedside() || isPain() || isRadiosurgery()) && bedsideProcedureTypeList.length > 0
                            ? bedsideProcedureTypeList.map((type: any) => (
                              <MenuItem key={type.value} value={type.value}>
                                {type.label}
                              </MenuItem>
                            )) : null}
                      </ReactHookFormSelect>
                    )}
                  </Grid>) : null}

                {(isNeurosurgery() && isInter()) && (isCarotid() || isPainIndication() || isOtherIndication()) ||
                  (isInterventional() && (isCarotid() || isPainIndication() || isOtherIndication())) || isRadiology() ?
                  (<Grid
                    className={classes.py0}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      variant="outlined"
                      name="location"
                      defaultValue=""
                      control={control}
                      label="Location"
                      margin="normal"
                      fullWidth
                      error={errors.location ? true : false}
                      helperText={errors.location && errors.location.message}
                    />
                  </Grid>) : (isNeurosurgery() && !(isFunctional() || isPeripheral())) &&
                    !(isEmptyIndication() || isOtherIndication()) ||
                    isNeurosurgery() && isSpine() ?
                    (<Grid
                      className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                      item={true}
                      xs={12}
                      sm={4}
                      md={6}
                      lg={6}
                    >
                      {isMultipleSelection() ? (
                        <MultipleSelect
                          name="location"
                          label="Location "
                          control={control}
                          className={classes.mt0}
                          defaultValue=""
                          selectedValues={locationType ? locationType : []}
                          setFormValue={setFormValue}
                          variant="outlined"
                          margin="normal"
                          setIsBlocking={() => { }}
                          error={errors.location ? true : false}
                          helperText={errors.location}
                          onClick={handleFormChange}
                        >
                          {isCranial() && (isTumor() || isAVM() || isAVF() || isAbscess()) && tumorLocationList.length > 0
                            ? tumorLocationList.map((location: any) => (
                              <MenuItem key={location.value} value={location.value}>
                                {location.label}
                              </MenuItem>
                            ))
                            : isCranial() && isAneurysm() && aneurysmLocationList.length > 0
                              ? aneurysmLocationList.map((location: any) => (
                                <MenuItem key={location.value} value={location.value}>
                                  {location.label}
                                </MenuItem>
                              ))
                              : isCranial() && isHematoma() && hematomaLocationList.length > 0
                                ? hematomaLocationList.map((location: any) => (
                                  <MenuItem key={location.value} value={location.value}>
                                    {location.label}
                                  </MenuItem>
                                ))
                                : isCranial() && isFracture() && fractureLocationList.length > 0
                                  ? fractureLocationList.map((location: any) => (
                                    <MenuItem key={location.value} value={location.value}>
                                      {location.label}
                                    </MenuItem>
                                  ))
                                  : isCranial() && isHydrocephalus() && hydrocephalusLocationList.length > 0
                                    ? hydrocephalusLocationList.map((location: any) => (
                                      <MenuItem key={location.value} value={location.value}>
                                        {location.label}
                                      </MenuItem>
                                    ))
                                    : isSpine() && locationList.length > 0
                                      ? locationList.map((location: any) => (
                                        <MenuItem key={location.value} value={location.value}>
                                          {location.label}
                                        </MenuItem>
                                      ))
                                      : isInter() && (isAVM() || isAVF()) && avmLocationList.length > 0
                                        ? avmLocationList.map((location: any) => (
                                          <MenuItem key={location.value} value={location.value}>
                                            {location.label}
                                          </MenuItem>
                                        ))
                                        : isInter() && isBleeding() && bleedingLocationList.length > 0
                                          ? bleedingLocationList.map((location: any) => (
                                            <MenuItem key={location.value} value={location.value}>
                                              {location.label}
                                            </MenuItem>
                                          ))
                                          : isInter() && isAneurysm() && aneurysmLocationInterList.length > 0
                                            ? aneurysmLocationInterList.map((location: any) => (
                                              <MenuItem key={location.value} value={location.value}>
                                                {location.label}
                                              </MenuItem>
                                            )) : isStroke() && strokeLocationList.length > 0
                                              ? strokeLocationList.map((location: any) => (
                                                <MenuItem key={location.value} value={location.value}>
                                                  {location.label}
                                                </MenuItem>
                                              ))
                                              : isInter() && (isDissection() || isVasospasm() || isStroke()) && dissectionLocationList.length > 0
                                                ? dissectionLocationList.map((location: any) => (
                                                  <MenuItem key={location.value} value={location.value}>
                                                    {location.label}
                                                  </MenuItem>
                                                ))
                                                : null}
                        </MultipleSelect>
                      ) : (<ReactHookFormSelect
                        name="location"
                        label="Location "
                        control={control}
                        className={classes.mt0}
                        defaultValue=""
                        variant="outlined"
                        margin="normal"
                        setIsBlocking={() => { }}
                        error={errors.location ? true : false}
                        helperText={errors.location}
                        onClick={handleFormChange}
                      >
                        {isCranial() && (isTumor() || isAVM() || isAVF() || isAbscess()) && tumorLocationList.length > 0
                          ? tumorLocationList.map((location: any) => (
                            <MenuItem key={location.value} value={location.value}>
                              {location.label}
                            </MenuItem>
                          ))
                          : isCranial() && isAneurysm() && aneurysmLocationList.length > 0
                            ? aneurysmLocationList.map((location: any) => (
                              <MenuItem key={location.value} value={location.value}>
                                {location.label}
                              </MenuItem>
                            ))
                            : isCranial() && isHematoma() && hematomaLocationList.length > 0
                              ? hematomaLocationList.map((location: any) => (
                                <MenuItem key={location.value} value={location.value}>
                                  {location.label}
                                </MenuItem>
                              ))
                              : isCranial() && isFracture() && fractureLocationList.length > 0
                                ? fractureLocationList.map((location: any) => (
                                  <MenuItem key={location.value} value={location.value}>
                                    {location.label}
                                  </MenuItem>
                                ))
                                : isCranial() && isHydrocephalus() && hydrocephalusLocationList.length > 0
                                  ? hydrocephalusLocationList.map((location: any) => (
                                    <MenuItem key={location.value} value={location.value}>
                                      {location.label}
                                    </MenuItem>
                                  ))
                                  : isSpine() && locationList.length > 0
                                    ? locationList.map((location: any) => (
                                      <MenuItem key={location.value} value={location.value}>
                                        {location.label}
                                      </MenuItem>
                                    ))
                                    : isInter() && (isAVM() || isAVF()) && avmLocationList.length > 0
                                      ? avmLocationList.map((location: any) => (
                                        <MenuItem key={location.value} value={location.value}>
                                          {location.label}
                                        </MenuItem>
                                      ))
                                      : isInter() && isBleeding() && bleedingLocationList.length > 0
                                        ? bleedingLocationList.map((location: any) => (
                                          <MenuItem key={location.value} value={location.value}>
                                            {location.label}
                                          </MenuItem>
                                        ))
                                        : isInter() && isAneurysm() && aneurysmLocationInterList.length > 0
                                          ? aneurysmLocationInterList.map((location: any) => (
                                            <MenuItem key={location.value} value={location.value}>
                                              {location.label}
                                            </MenuItem>
                                          )) : isStroke() && strokeLocationList.length > 0
                                            ? strokeLocationList.map((location: any) => (
                                              <MenuItem key={location.value} value={location.value}>
                                                {location.label}
                                              </MenuItem>
                                            ))
                                            : isInter() && (isDissection() || isVasospasm() || isStroke()) && dissectionLocationList.length > 0
                                              ? dissectionLocationList.map((location: any) => (
                                                <MenuItem key={location.value} value={location.value}>
                                                  {location.label}
                                                </MenuItem>
                                              ))
                                              : null}
                      </ReactHookFormSelect>)}
                    </Grid>) : isInterventional() && !isEmptyIndication() ? (
                      <Grid
                        className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                        item={true}
                        xs={12}
                        sm={4}
                        md={6}
                        lg={6}
                      >
                        {isMultipleSelection() ? (<MultipleSelect
                          name="location"
                          label="Location "
                          control={control}
                          className={classes.mt0}
                          defaultValue=""
                          selectedValues={locationType ? locationType : []}
                          setFormValue={setFormValue}
                          variant="outlined"
                          margin="normal"
                          setIsBlocking={() => { }}
                          error={errors.location ? true : false}
                          helperText={errors.location}
                          onClick={handleFormChange}
                        >
                          {(isAVM() || isAVF()) && avmLocationList.length > 0
                            ? avmLocationList.map((location: any) => (
                              <MenuItem key={location.value} value={location.value}>
                                {location.label}
                              </MenuItem>
                            ))
                            : isBleeding() && bleedingLocationList.length > 0
                              ? bleedingLocationList.map((location: any) => (
                                <MenuItem key={location.value} value={location.value}>
                                  {location.label}
                                </MenuItem>
                              )) : isAneurysm() && aneurysmLocationInterList.length > 0
                                ? aneurysmLocationInterList.map((location: any) => (
                                  <MenuItem key={location.value} value={location.value}>
                                    {location.label}
                                  </MenuItem>
                                )) : isStroke() && strokeLocationList.length > 0
                                  ? strokeLocationList.map((location: any) => (
                                    <MenuItem key={location.value} value={location.value}>
                                      {location.label}
                                    </MenuItem>
                                  ))
                                  : (isDissection() || isVasospasm() || isStroke()) && dissectionLocationList.length > 0
                                    ? dissectionLocationList.map((location: any) => (
                                      <MenuItem key={location.value} value={location.value}>
                                        {location.label}
                                      </MenuItem>
                                    )) : null}
                        </MultipleSelect>) : (
                          <ReactHookFormSelect
                            name="location"
                            label="Location "
                            control={control}
                            className={classes.mt0}
                            defaultValue=""
                            variant="outlined"
                            margin="normal"
                            setIsBlocking={() => { }}
                            error={errors.location ? true : false}
                            helperText={errors.location}
                            onClick={handleFormChange}
                          >
                            {(isAVM() || isAVF()) && avmLocationList.length > 0
                              ? avmLocationList.map((location: any) => (
                                <MenuItem key={location.value} value={location.value}>
                                  {location.label}
                                </MenuItem>
                              ))
                              : isBleeding() && bleedingLocationList.length > 0
                                ? bleedingLocationList.map((location: any) => (
                                  <MenuItem key={location.value} value={location.value}>
                                    {location.label}
                                  </MenuItem>
                                )) : isAneurysm() && aneurysmLocationInterList.length > 0
                                  ? aneurysmLocationInterList.map((location: any) => (
                                    <MenuItem key={location.value} value={location.value}>
                                      {location.label}
                                    </MenuItem>
                                  )) : isStroke() && strokeLocationList.length > 0
                                    ? strokeLocationList.map((location: any) => (
                                      <MenuItem key={location.value} value={location.value}>
                                        {location.label}
                                      </MenuItem>
                                    ))
                                    : (isDissection() || isVasospasm() || isStroke()) && dissectionLocationList.length > 0
                                      ? dissectionLocationList.map((location: any) => (
                                        <MenuItem key={location.value} value={location.value}>
                                          {location.label}
                                        </MenuItem>
                                      )) : null}
                          </ReactHookFormSelect>
                        )}
                      </Grid>) : null}

                {isNeurosurgery() && isInter() && isThrombectomy() ||
                  isInterventional() && isThrombectomy() ?
                  (<Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="tici_grade"
                      label="TICI grade "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.tici_grade ? true : false}
                      helperText={errors.tici_grade}
                      onClick={handleFormChange}
                    >
                      {ticiGradeList.length > 0
                        ? ticiGradeList.map((grade: any) => (
                          <MenuItem key={grade.value} value={grade.value}>
                            {grade.label}
                          </MenuItem>
                        )) : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isNeurosurgery() &&
                  procedureType === 'Spine' && procedureType !== '' ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="osteotomy"
                      label="Osteotomy "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.osteotomy ? true : false}
                      helperText={errors.osteotomy}
                      onClick={handleFormChange}
                    >
                      {osteotomyList.length > 0
                        ? osteotomyList.map((osteotomy: any) => (
                          <MenuItem key={osteotomy.value} value={osteotomy.value}>
                            {osteotomy.label}
                          </MenuItem>
                        )) : null}

                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isNeurosurgery() &&
                  procedureType === 'Spine' && procedureType !== '' ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="_fusion"
                      label="Fusion "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors._fusion ? true : false}
                      helperText={errors._fusion}
                      onClick={handleFormChange}
                    >
                      {yesNoList.length > 0
                        ? yesNoList.map((fusion: any) => (
                          <MenuItem key={fusion.value} value={fusion.value}>
                            {fusion.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isNeurosurgery() &&
                  procedureType === 'Spine' && procedureType !== '' ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="interbody_fusion"
                      label="Interbody fusion "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.interbody_fusion ? true : false}
                      helperText={errors.interbody_fusion}
                      onClick={handleFormChange}
                    >
                      {yesNoList.length > 0
                        ? yesNoList.map((interbody_fusion: any) => (
                          <MenuItem key={interbody_fusion.value} value={interbody_fusion.value}>
                            {interbody_fusion.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isNeurosurgery() &&
                  procedureType === 'Spine' && procedureType !== '' ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="extension_to_pelvis"
                      label="Extension to pelvis "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.extension_to_pelvis ? true : false}
                      helperText={errors.extension_to_pelvis}
                      onClick={handleFormChange}
                    >
                      {yesNoList.length > 0
                        ? yesNoList.map((extension_to_pelvis: any) => (
                          <MenuItem key={extension_to_pelvis.value} value={extension_to_pelvis.value}>
                            {extension_to_pelvis.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isNeurosurgery() && isSpine() ? (
                  <Grid
                    className={classes.py0}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      variant="outlined"
                      name="fusion_levels"
                      defaultValue=""
                      control={control}
                      label="Fusion levels"
                      margin="normal"
                      fullWidth
                      error={errors.fusion_levels ? true : false}
                      helperText={errors.fusion_levels && errors.fusion_levels.message}
                    />
                  </Grid>) : null}

                {isNeurosurgery() && isSpine() ? (
                  <Grid
                    className={classes.py0}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      variant="outlined"
                      name="decompression_levels"
                      defaultValue=""
                      control={control}
                      label="Decompression levels"
                      margin="normal"
                      fullWidth
                      error={errors.fusion_levels ? true : false}
                      helperText={errors.fusion_levels && errors.fusion_levels.message}
                    />
                  </Grid>) : null}

                {isNeurosurgery() &&
                  procedureType === 'Spine' && procedureType !== '' ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="morphogenic_protein"
                      label="Morphogenic protein "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.morphogenic_protein ? true : false}
                      helperText={errors.morphogenic_protein}
                      onClick={handleFormChange}
                    >
                      {yesNoList.length > 0
                        ? yesNoList.map((morphogenic_protein: any) => (
                          <MenuItem key={morphogenic_protein.value} value={morphogenic_protein.value}>
                            {morphogenic_protein.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isInterventional() || isRadiology() ||
                  isNeurosurgery() && !isSpine() && !isEmptyProcedure() && !isOtherProcedure() ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="implant"
                      label="Implant "
                      control={control}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={() => { }}
                      error={errors.implant ? true : false}
                      helperText={errors.implant}
                      onClick={handleFormChange}
                    >
                      {yesNoList.length > 0
                        ? yesNoList.map((implant: any) => (
                          <MenuItem key={implant.value} value={implant.value}>
                            {implant.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>) : null}

                {isInterventional() || isRadiology() ||
                  isNeurosurgery() && !isSpine() && !isEmptyProcedure() && !isOtherProcedure() ?
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      className={classes.mt0}
                      as={TextField}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                      label="Implant type "
                      control={control}
                      name="implant_type"
                      error={errors.implant ? true : false}
                      helperText={
                        errors.implant &&
                        errors.implant.message
                      }
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </Grid> : null}

                {isNeurosurgery() && procedureType !== '' ||
                  isInterventional() && procedureType !== '' ||
                  !user.user_profile[0].speciality || user.user_profile[0].speciality.name ?
                  (<Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      name="vendors"
                      variant="outlined"
                      control={control}
                      label="Vendors"
                      margin="normal"
                      fullWidth
                      error={errors.vendors ? true : false}
                    />
                  </Grid>) : null}

                {isInterventional() || isRadiology() ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      name="access"
                      control={control}
                      label="Access"
                      margin="normal"
                      fullWidth
                      error={errors.access ? true : false}
                    />
                  </Grid>
                ) : null}

                {isInterventional() || isRadiology() ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      name="vascular_closure_device"
                      control={control}
                      label="Vascular closure device (if used)"
                      margin="normal"
                      fullWidth
                      error={errors.vascular_closure_device ? true : false}
                    />
                  </Grid>
                ) : null}

                {isNeurosurgery() && !isEmptyProcedure() && isInter() ||
                  isInterventional() || isRadiology() ?
                  (<Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      name="duration_of_radiation"
                      variant="outlined"
                      control={control}
                      label="Duration of radiation"
                      margin="normal"
                      fullWidth
                      error={errors.duration_of_radiation ? true : false}
                    />
                  </Grid>) : null}

                {isNeurosurgery() ||
                  isInterventional() ?
                  (<Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      name="duration_of_procedure"
                      variant="outlined"
                      control={control}
                      label="Duration of procedure"
                      margin="normal"
                      fullWidth
                      error={errors.duration_of_procedure ? true : false}
                    />
                  </Grid>) : null}

                {isNeurosurgery() &&
                  procedureType === 'Functional' && procedureType !== '' ?
                  (<Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      name="target"
                      variant="outlined"
                      control={control}
                      label="Target "
                      margin="normal"
                      fullWidth
                      error={errors.target ? true : false}
                    />
                  </Grid>) : null}

                {isNeurosurgery() &&
                  procedureType === 'Peripheral nerves' && procedureType !== '' ?
                  (<Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      name="nerve"
                      variant="outlined"
                      control={control}
                      label="Nerve "
                      margin="normal"
                      fullWidth
                      error={errors.nerve ? true : false}
                    />
                  </Grid>) : null}

                {!isNeurosurgery() && !isInterventional() && !isRadiology() ?
                  (<Grid
                    className={classes.dateOfSurgery}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        required
                        variant="inline"
                        inputVariant="outlined"
                        className={classes.mt0}
                        autoOk={true}
                        value={surgeryDate}
                        onChange={handleSurgeryDateChange}
                        label="Date of procedure "
                        name="date"
                        format="MM/dd/yyyy"
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>) : null}

                {!isRadiology() ? (
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      defaultValue=""
                      className={classes.mt0}
                      name="findings"
                      variant="outlined"
                      control={control}
                      label="Findings"
                      margin="normal"
                      fullWidth
                      error={errors.findings ? true : false}
                    />
                  </Grid>) : null}

                {!isRadiology() ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      name="complications"
                      control={control}
                      label="Complications"
                      margin="normal"
                      fullWidth
                      error={errors.complications ? true : false}
                    />
                  </Grid>) : null}

                {!isRadiology() ? (
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      defaultValue=""
                      as={TextField}
                      className={classes.mt0}
                      variant="outlined"
                      name="outcome"
                      control={control}
                      label="Outcome"
                      margin="normal"
                      fullWidth
                      error={errors.outcome ? true : false}
                    />
                  </Grid>) : null}
              </Grid>
            </Box>
          </Paper>
        </Box>

        {caseProcedures.map((procedure, index) => {
          return <CaseProcedure
            caseId={caseId}
            key={procedure.id}
            index={index}
            procedure={procedure}
            isMultipleSelection={isMultipleSelection}
            onDelete={handleDelete}
            handleFormChange={handleFormChange} />
        })}
        <Box className={classes.center}>
          <Button onClick={addCaseProcedureForm} className={classes.addAnotherProcedureButton}>{<AddIcon />} Add another procedure</Button>
        </Box>

        <Box mb={2}>
          <Paper>
            <Box p={3} className={classes.notes}>
              <Box className={classes.noteTitle}>
                <Typography variant="h2" className={classes.inline}>
                  Notes
                </Typography>

                <Box ml={'20px'} className={classes.noteTitle}>
                  <AddIcon
                    onClick={() => setShowAddNoteText(true)}
                    color="secondary"
                    className={classes.cursor}
                  ></AddIcon>
                </Box>
              </Box>
              {caseId && notes && notes.length > 0 ? (
                <Box mt={1} mb={2}>
                  <Divider></Divider>
                </Box>
              ) : null}
              <Grid
                container
                item={true}
                xs={12}
                className={classes.notesContainer}
              >
                {fields.map((item, index) => {
                  return (
                    <Grid
                      item={true}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      key={item.id}
                      className={classes.noteFieldEdited}
                    >
                      <TextField
                        name={`notes[${index}].note`}
                        defaultValue={`${item.note}`}
                        label="Note"
                        inputRef={register({ required: true })}
                        variant="outlined"
                        fullWidth
                        rowsMax={3}
                        multiline
                        rows={3}
                      />

                      <DeleteOutlineIcon
                        onClick={() => remove(index)}
                        className={classes.cursor}
                        color="error"
                      ></DeleteOutlineIcon>
                    </Grid>
                  );
                })}
                {showAddNoteText ? (
                  <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    className={classes.noteFieldEdited}
                  >
                    <TextField
                      variant="outlined"
                      name="note"
                      value={editNoteField}
                      onChange={(e) => {
                        setEditNoteField(e.target.value);
                      }}
                      label="Note"
                      margin="normal"
                      fullWidth
                      rowsMax={3}
                      multiline
                      rows={3}
                    />
                    <Button
                      onClick={() => {
                        if (editNoteField.length) {
                          append({ note: editNoteField, id: 0 });
                        }

                        setEditNoteField('');
                        setShowAddNoteText(false);
                      }}
                      color="secondary"
                      className={classes.cursor}
                    >Done</Button>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Paper>
        </Box>
        {currentPlan?.plan?.name !== 'Free' ? (
          <Box mb={2}>
            <Paper>
              <Box>
                <Box className={classes.titleImage}>
                  <div>Images</div>
                  <label htmlFor="raised-button-file">
                    <AddIcon
                      color="secondary"
                      className={classes.cursor}
                    ></AddIcon>
                  </label>
                  <input
                    onChange={handleCapture}
                    id="raised-button-file"
                    type="file"
                    hidden
                    onClick={(event) => (event.currentTarget.value = '')}
                    accept="image/*"
                  />
                </Box>
                {caseId && images.length > 0 ? (
                  <Box className={classes.hrContainer}>
                    <Divider />
                  </Box>
                ) : null}
                <Grid
                  item={true}
                  className={classes.imageContainer}
                  xs={12}
                  sm={12}
                  md={12}
                  container
                >
                  {images.length > 0
                    ? images.map((image, index) => (
                      <Grid
                        item={true}
                        xs={6}
                        sm={4}
                        md={3}
                        lg={4}
                        xl={2}
                        key={Image + '' + index}
                      >
                        <Box className={classes.imgBox} mx={2} my={0.5}>
                          <Box className={classes.image}>
                            <img
                              className={classes.caseImage}
                              src={image.urls ? image.urls.href_small : URL.createObjectURL(image)}
                              alt={image.urls ? image.urls.href_small : URL.createObjectURL(image)}
                              onClick={() => setOpenedImage(image)}
                            />
                          </Box>
                          <Box className={classes.imageFooter}>
                            <Divider className={classes.divider} />
                            <Box className={classes.imageName}>
                              <Typography noWrap variant="overline" align="left">
                                {image.image_name ? image.image_name : image.name}
                              </Typography>
                              <DeleteOutlineIcon
                                onClick={() => image.id ? handleDeleteImage(index, image.id) : removeImage(index)}
                                className={classes.cursor}
                                color="error"
                              ></DeleteOutlineIcon>
                              {!image.id ? (<Button
                                onClick={() => handleRenameClick(index)}
                                color="secondary"
                                className={classes.cursor}
                              >
                                Rename
                              </Button>) : null}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))
                    : null}
                  {/* Code to display the selected image */}
                  {openedImage && (
                    <Dialog open={true} onClose={() => setOpenedImage(null)}>
                      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: 5 }}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={() => setOpenedImage(null)}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      <img
                        className={classes.openedImage}
                        src={openedImage.urls.href_original}
                        alt={openedImage.urls.href_original}
                      />
                    </Dialog>
                  )}
                </Grid>
              </Box>
            </Paper>

            {/* Rename Dialog */}
            <Dialog open={renameDialogOpen} onClose={handleRenameClose}>
              <DialogTitle>Rename Image</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="New Image Name"
                  fullWidth
                  value={newImageName}
                  onChange={(e) => setNewImageName(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRenameClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleRenameSave} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        ) : null}
        <Box className={classes.buttonsContainer}>
          {caseId ? (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.saveButtonEdited}
            >
              Save
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={
                isBlocking ? classes.addCaseButtonEdited : classes.addCaseButton
              }
            >
              Add
            </Button>
          )}
        </Box>
      </form>
      <Dialog
        onClose={() => setViewPositionForm(false)}
        aria-labelledby="simple-dialog-title"
        open={viewPositionForm}
      >
        {' '}
        <Box className={classes.formTitle}>
          <Typography variant="subtitle1">Add Current Position</Typography>
          <ClearIcon
            className={classes.clearIconDialog}
            onClick={() => setViewPositionForm(false)}
          />
        </Box>
        <PositionForm
          title="position"
          handleEndDateChange={handleEndDateChange}
          handleStartDateChange={handleStartDateChange}
          showEndDate={showEndDate}
          value={0}
          setIsBlocking={setIsBlocking}
          setOnRefresh={setOnRefresh}
          specialityList={specialityList}
          startDate={startDate}
          setShowProfileFormMessage={setShowProfileFormMessage}
          setProfileMessage={setProfileMessage}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setShowEndDate={setShowEndDate}
          setViewPositionForm={setViewPositionForm}
          endDate={endDate}
        ></PositionForm>
      </Dialog>
      <Snackbar open={showProfileFormMessage}>
        <Alert onClose={() => setShowProfileFormMessage(false)} color="success">
          Your position is successfully added!
        </Alert>
      </Snackbar>
      <UpgradeDialog
        isOpen={isOpen && !tokenError}
        onClose={() => setisOpen(false)}
        handleChange={() => { }}
        handleCloseDialog={handleCloseDialog}
        userToken={userToken}
        setNewPlan={setNewPlan}
        subscriptionId={currentPlan?.subscr_id}
      />
      <Snackbar open={showError} color="warning">
        <Alert onClose={() => setShowError(false)} severity="warning">
          You can't manage your plan since you have cancelled subscription
        </Alert>
      </Snackbar>
      <Snackbar open={showAddCaseMessage}>
        <Alert
          action={
            addCaseMessage[0] === 'error' &&
              addCaseMessage[1] === 'You need to upgrade your plan' ? (
              <Button
                onClick={() => {
                  if (!tokenError) {
                    setisOpen(true);
                    setShowAddCaseMessage(false);
                  } else {
                    setShowError(true);
                    setShowAddCaseMessage(false);
                  }
                }}
              >
                Manage plans
              </Button>
            ) : null
          }
          onClose={() => setShowAddCaseMessage(false)}
          color={addCaseMessage[0]}
        >
          {addCaseMessage[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default AddCase;
