import React from 'react';
import HomePageStyle from './Styles';
import Footer from '../../../components/footer/Footer';
import history from '../../../utils/history';
import videoPoster from '../../../assets/img/MS-video-posterr.png';
import loginRight from '../../../assets/img/MS-Login-right-img.png';
import appleStore from '../../../assets/img/MS-mbl-site-apple-img.png';
import playStore from '../../../assets/img/MS-mbl-site-play-store-img.png';

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
const HomePage = () => {
  const classes = HomePageStyle();
  const theme = useTheme();
  const matchesWeb = useMediaQuery(theme.breakpoints.down('md'));
  const matchesbigScreen = useMediaQuery(theme.breakpoints.up(2000));
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down(1200));
  const isDarkTheme = useTheme().palette.type === 'dark';
  return (
    <div>
      <section className="heroSection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heroContent">
                <h1>Empowering Surgeons and Interventionalists with AI-Driven Solutions.</h1>
                <p>
                  MS Logger is a cutting-edge HIPAA-compliant application that allows surgeons and interventionalists to build their patient database, log their procedures quickly and securely, and maximize their financial returns, by leveraging the power of AI and natural language processing (NLP).
                </p>
                <a onClick={() => history.push('/plans')} className="bgBlue pointer">Start your own plan</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="videoPopup" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="videoLink">
                <iframe src="https://www.youtube.com/embed/QfiKTsodeqU" title="MS Logger" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="videoSec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="videoHeading text-center">
              </div>
              <div className="video">
                <button type="button" id="openPopup" className="btn" data-toggle="modal" data-target="#videoPopup">
                  <img style={{ width: '100%' }} src={videoPoster} alt=""></img>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mblSite homePage">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mblSiteImg">
                <img style={{ width: '100%' }} src={loginRight} alt=""></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mblSiteContent">
                <h2>Mobile Site and Application</h2>
                <p>Our mobile site and application allow you to add and check your patients and procedures anytime and anywhere. You can also use it to search, add patient data and images, follow-up information, export cases and much more.</p>
                <h4>Get MS Logger now and start building your cases database.</h4>
                <div className="mblAppImg d-flex">
                  <div className="appleImg">
                    <a className="pointer" onClick={() => window.open('https://apps.apple.com/us/app/ms-logger/id1484770342', '_blank')}>
                      <img style={{ width: '100%' }} src={appleStore} alt=""></img>
                    </a>
                  </div>
                  <div className="playStoreImg">
                    <a className="pointer" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.medsolace.caselog', '_blank')}>
                      <img style={{ width: '100%' }} src={playStore} alt=""></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </div>
  );
};

export default HomePage;
