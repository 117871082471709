import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { classes } from 'istanbul-lib-coverage';
import React, { useState } from 'react';
import calculateRvu from '../../../assets/calculateRvu.svg';
import result from '../../../assets/result.svg';
import RVUCalculatorStyle from './Styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import * as api from '../../../services/apiService';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Calculatorempty from '../../../assets/Calculatorempty.svg';
import CalculatoremptyDark from '../../../assets/dark/CalculatoremptyDark.svg';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { removeAllListeners } from 'process';
import Autocomplete from '@material-ui/lab/Autocomplete';

const RVUCalculator = () => {
  const classes = RVUCalculatorStyle();
  const isDarkTheme = useTheme().palette.type === 'dark';
  const theme = useTheme();
  const [rvuForUnit, setrvuForUnit] = useState<any[]>(Array(7).fill(''));
  const [rvuForManyUnitHide, setrvuForManyUnitHide] = useState<any[]>([]);
  const [rvuForManyUnit, setrvuForManyUnit] = useState<any[]>([]);
  const [code, setcode] = useState<any[]>([]);
  const [numberofUnit, setnumberofUnit] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [viewResult, setViewResult] = useState<boolean>(false);
  const [showResetButton, setShowResetButton] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorIndex, setErrorIndex] = useState<number>(-1);
  const [cptCode, setCptCode] = useState<any[]>([{ code: '', value: '' }]);
  const [selectedSubcategory, setSelectedSubcategory] = useState<any[]>(
    Array(7).fill(1)
  );
  const [resultRvu, setResultRvu] = useState<any[]>(Array(7).fill(1));
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const doSearch = React.useCallback(async (codeValue, index, values) => {
    if (codeValue.length > 0) {
      const { status_code: statusCode, data } = await api.onTermSearch(
        'cpt_lookups',
        codeValue
      );
      if (statusCode === api.status.SUCCESS) {
        setCptCode(data);
        if (data[0]?.rvu_value) {
          let newValue = [...values];
          newValue[index] = data[0]?.rvu_value;
          setrvuForUnit(newValue);
        } else {
          setrvuForUnit(values);
        }
      }
    } else {
      setCptCode([{ code: '', value: '' }]);
    }
  }, []);

  const handleSubcategoryChange = (index, value) => {
    let newCode = [...code];
    newCode[index] = value;
    setcode(newCode);
  };

  const handleChange = (e, index, newValue) => {
    let newCode = [...code];
    newCode[index] = newValue;
    setcode(newCode);
    if (newValue.length > 0) {
      doSearch(newValue.split('-')[0], index, rvuForUnit);
    }
  };

  const handleChangeUnit = (e, index) => {
    let unit = e.target.value;
    let newValue = [...numberofUnit];
    newValue[index] = unit;
    setnumberofUnit(newValue);
  };

  const numberOfUnitValidation = (code, numOfUnit) => {
    setIsError(false);
    setErrorIndex(-1);
    if (code.length >= numOfUnit.length) {
      for (let i = 0; i < code.length; i++) {
        if (code[i]) {
          if (
            numOfUnit[i] === undefined ||
            numOfUnit[i] === '0' ||
            numOfUnit[i] % 1 !== 0
          ) {
            setIsError(true);
            setErrorIndex(i);
            break;
          }
        }
      }
    }
  };

  const findRvuForManyUnit = React.useCallback(
    (numberofUnit, rvuForUnit) => {
      let newValue = [...rvuForManyUnit];
      for (let i = 0; i < numberofUnit.length; i++) {
        if (rvuForUnit[i] !== '' && numberofUnit[i] % 1 === 0) {
          let unit = rvuForUnit[i] * numberofUnit[i];

          newValue[i] = unit;
        }
      }
      setrvuForManyUnitHide(newValue);
    },
    [numberofUnit, rvuForUnit]
  );

  const calculate = (data) => {
    setrvuForManyUnit(rvuForManyUnitHide);
    numberOfUnitValidation(code, numberofUnit);
    if (rvuForManyUnitHide.length !== 0 && code.length !== 0) {
      let sumRvu = rvuForManyUnitHide.reduce(function (sum, item) {
        if (item) {
          return (sum = sum + item);
        } else {
          return (sum = sum + 0);
        }
      }, 0);
      setTotal(sumRvu.toFixed(3));
    } else {
      setViewResult(false);
    }
  };

  const clear = (index: any) => {
    let element: HTMLElement = document.getElementById(
      'code' + index
    ) as HTMLElement;

    element.remove();
    let elementRes: HTMLElement = document.getElementById(
      'result' + index
    ) as HTMLElement;
    elementRes.remove();
    selectedSubcategory.push(1);
    rvuForManyUnit.push(0);
    rvuForManyUnitHide.push(0);
    rvuForUnit.push(0);
    numberofUnit.push('');
  };

  const handleRestRow = (index) => {
    clear(index);
    doSearch('', index, rvuForUnit);
    let newValueForUnit = [...rvuForUnit];
    newValueForUnit[index] = 0;
    let newValueForManyUnit = [...rvuForManyUnit];
    newValueForManyUnit[index] = 0;
    let newValueForManyUnitHide = [...rvuForManyUnitHide];
    newValueForManyUnitHide[index] = 0;
    let newValueForCode = [...code];
    newValueForCode[index] = '';
    let newValueForNumber = [...numberofUnit];
    newValueForNumber[index] = '';
    setrvuForUnit(newValueForUnit);
    setrvuForManyUnit(newValueForManyUnit);
    setrvuForManyUnitHide(newValueForManyUnitHide);
    setcode(newValueForCode);
    setnumberofUnit(newValueForNumber);
    calculate(newValueForManyUnitHide);
  };

  React.useEffect(() => {
    if (isCalculated) {
      setIsCalculated(false);
    }
  }, [viewResult, isCalculated, isReset]);

  React.useEffect(() => {}, [total, rvuForManyUnitHide]);

  React.useEffect(() => {
    findRvuForManyUnit(numberofUnit, rvuForUnit);
  }, [numberofUnit, rvuForUnit, findRvuForManyUnit]);

  return (
    <Box className={classes.root}>
      {matchesMobile ? (
        <Grid item={true} xs={12} className={classes.mobileHeader}>
          <Typography variant="subtitle2">RVU Calculator</Typography>
        </Grid>
      ) : null}
      <Paper elevation={matchesMobile ? 0 : 1}>
        <Grid container item={true} xs={12}>
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={6}
            className={classes.detailsSection}
          >
            <Box className={classes.header}>
              <img
                src={calculateRvu}
                alt="calculateRvu"
                className={classes.totalIcon}
              />
              <Typography variant="h2">RVU Calculator</Typography>
            </Box>
            <Grid item={true} container xs={12} spacing={1}>
              <Grid item={true} xs={8}>
                <Typography variant="h3" className={classes.cptCode}>
                  CPT Code
                </Typography>
              </Grid>
              <Grid item={true} xs={4}>
                <Typography
                  variant="h3"
                  className={
                    matchesMobile ? classes.unitNumMobile : classes.unitNum
                  }
                >
                  Number of Units
                </Typography>
              </Grid>
            </Grid>
            <form>
              {selectedSubcategory.map((el, i) => (
                <Grid
                  item={true}
                  container
                  xs={12}
                  spacing={1}
                  id={'code' + i}
                  key={'code' + i}
                >
                  <Grid item={true} xs={8} className={classes.codeContainer}>
                    {viewResult ? (
                      <RemoveCircleIcon
                        className={
                          code[i] === undefined
                            ? classes.removeIconHide
                            : classes.removeIcon
                        }
                        onClick={() => handleRestRow(i)}
                      />
                    ) : null}

                    <Autocomplete
                      id={'combo-box-demo' + i}
                      options={cptCode}
                      onChange={handleSubcategoryChange}
                      value={code[i]}
                      getOptionLabel={(option) =>
                        option.code
                          ? option.code + ' - ' + option.value + ''
                          : ''
                      }
                      renderOption={(option) =>
                        option.code + '  ' + option.value + ''
                      }
                      style={{ width: '100%' }}
                      onInputChange={(event, newValue) => {
                        let newCode = [...code];
                        newCode[i] = newValue;
                        setcode(newCode);
                        handleChange(event, i, newValue);
                      }}
                      getOptionSelected={(option, value) =>
                        option.code === value.code
                      }
                      onSelect={(e: any) => {
                        let newCode = [...code];
                        newCode[i] = e.target.value.split('-')[0];
                        setcode(newCode);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={!isCalculated ? classes.codeText : ''}
                          label="CPT Code"
                          variant="outlined"
                          placeholder="CPT Code"
                          name={'code' + i}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="00.00"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      required
                      value={numberofUnit[i]}
                      onChange={(e) => handleChangeUnit(e, i)}
                    />
                  </Grid>
                  {i === errorIndex && isError ? (
                    <Grid item={true} xs={12} className={classes.error}>
                      Number of unit must be an integer numbrr above 0
                    </Grid>
                  ) : null}
                  {i !== selectedSubcategory.length - 1 ? (
                    <Grid item={true} xs={12} className={classes.divider}>
                      <Divider />
                    </Grid>
                  ) : null}
                </Grid>
              ))}
            </form>
          </Grid>
          {matchesMobile ? (
            <Box
              bgcolor="background.default"
              borderColor="background.default"
              border={1}
              className={classes.mobileButtonContainer}
            >
              <Button
                variant="contained"
                color="secondary"
                className={
                  matchesMobile
                    ? classes.footerButtonMobile
                    : classes.footerButton
                }
                onClick={() => {
                  if (!isCalculated) {
                    setShowResetButton(true);
                    setIsCalculated(true);
                    setViewResult(true);
                    calculate(rvuForManyUnit);
                  } else {
                    calculate(rvuForManyUnit);
                  }
                }}
              >
                {viewResult ? 'Recalculate' : 'Calculate'}
              </Button>
            </Box>
          ) : null}
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={6}
            className={classes.resultContainer}
          >
            <Box className={classes.header}>
              <img src={result} alt="result" className={classes.totalIcon} />
              <Typography variant="h2">Result</Typography>
            </Box>
            {viewResult ? (
              matchesMobile ? (
                <Grid item={true} container xs={12} spacing={1}>
                  <Grid item={true} xs={4}>
                    <Typography variant="h3" className={classes.cptCodeMobile}>
                      CPT Code
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography variant="h3" className={classes.cptCodeMobile}>
                      wRVU Value per Unit
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography
                      variant="h3"
                      className={
                        matchesMobile ? classes.unitNumMobile : classes.unitNum
                      }
                    >
                      Total wRVUs
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ) : (
                <Grid item={true} container xs={12} spacing={1}>
                  <Grid item={true} xs={8}>
                    <Typography variant="h3" className={classes.cptCode}>
                      wRVU Value per Unit
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={4}>
                    <Typography
                      variant="h3"
                      className={
                        matchesMobile ? classes.unitNumMobile : classes.unitNum
                      }
                    >
                      Total wRVUs
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )
            ) : null}
            {viewResult ? (
              !matchesMobile ? (
                resultRvu.map((el, i) => (
                  <Grid
                    item={true}
                    container
                    xs={12}
                    spacing={1}
                    key={'code' + i}
                    id={'result' + i}
                    className={!rvuForManyUnit[i] ? classes.hideResult : ''}
                  >
                    {rvuForManyUnit[i] > -1 && rvuForManyUnit[i] !== 0 ? (
                      <>
                        <Grid item={true} xs={8}>
                          <Typography
                            variant="h3"
                            className={classes.unitValue}
                          >
                            {rvuForUnit[i]}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={4}>
                          <Typography
                            variant="h3"
                            className={classes.unitValue}
                            noWrap
                          >
                            {rvuForManyUnit[i].toFixed(3)}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                    {i !== 6 && rvuForManyUnit[i] !== 0 ? (
                      <Grid
                        item={true}
                        xs={12}
                        className={
                          !rvuForManyUnit[i]
                            ? classes.noDivider
                            : classes.divider
                        }
                      >
                        <Divider />
                      </Grid>
                    ) : null}
                  </Grid>
                ))
              ) : (
                resultRvu.map((el, i) => (
                  <Grid
                    item={true}
                    container
                    xs={12}
                    spacing={1}
                    key={'code' + i}
                    id={'result' + i}
                  >
                    {rvuForManyUnit[i] > -1 && rvuForManyUnit[i] !== 0 ? (
                      <>
                        <Grid item={true} xs={4}>
                          <Typography
                            variant="h3"
                            className={classes.unitValueMobile}
                          >
                            {code[i]}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={4}>
                          <Typography
                            variant="h3"
                            className={classes.unitValueMobile}
                            noWrap
                          >
                            {rvuForUnit[i]}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={4}>
                          <Typography
                            variant="h3"
                            className={classes.unitValueMobile}
                            noWrap
                          >
                            {rvuForManyUnit[i].toFixed(3)}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                    {rvuForManyUnit[i] !== 0 ? (
                      <Grid
                        item={true}
                        xs={12}
                        className={
                          rvuForManyUnit[i] === undefined || total === 0
                            ? classes.noDivider
                            : classes.dividerMobile
                        }
                      >
                        <Divider />
                      </Grid>
                    ) : null}
                  </Grid>
                ))
              )
            ) : (
              <Box className={classes.emptyViewContainer}>
                <Box className={classes.emptyView}>
                  <img
                    src={!isDarkTheme ? Calculatorempty : CalculatoremptyDark}
                    alt="Calculatorempty"
                  />
                  {
                    <Typography variant="caption" className={classes.emptyWord}>
                      {!matchesMobile
                        ? 'Start calculating to get the result!'
                        : ''}
                    </Typography>
                  }
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Box className={classes.footer}>
        {!matchesMobile ? (
          <Button
            variant="contained"
            color="secondary"
            className={classes.footerButton}
            onClick={() => {
              if (!isCalculated) {
                setShowResetButton(true);
                setIsCalculated(true);
                setViewResult(true);
                calculate(rvuForManyUnit);
              } else {
                calculate(rvuForManyUnit);
              }
            }}
          >
            {viewResult ? 'Recalculate' : 'Calculate'}
          </Button>
        ) : null}
        {viewResult ? (
          <Paper
            className={
              matchesMobile ? classes.footerPaperMobile : classes.footerPaper
            }
          >
            <Box className={classes.totalContainer}>
              <ListAltIcon color="secondary" className={classes.totalIcon} />
              <Typography variant="h2">Total:</Typography>
            </Box>
            <Typography variant="subtitle1">{total}</Typography>
          </Paper>
        ) : null}
      </Box>
    </Box>
  );
};

export default RVUCalculator;
