import { makeStyles, Theme, createStyles } from '@material-ui/core';

const registriesStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '80%',
      margin: 'auto',
      paddingTop: 40,
      [theme.breakpoints.down('sm')]: {
        width: '92%',
        paddingTop: 18
      },
      [theme.breakpoints.only('md')]: {
        width: '93%',
        paddingTop: 18
      },
      display: 'flex',
      flexDirection: 'column'
    },
    view: {
      width: '70%',
      marginBottom: 15,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    sortButton: {
      marginRight: 13,
      [theme.breakpoints.down('sm')]: {
        width: '46%'
      }
    },
    createButton: {
      [theme.breakpoints.down('sm')]: {
        width: '46%'
      }
    },
    endIcon: {
      marginLeft: 30
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse'
      }
    },
    buttonHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        marginBottom: 16
      }
    },
    paper: {
      position: 'relative',
      padding: 12,
      textAlign: 'left',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        marginLeft: 0
      },
      border: `1px solid rgba(170, 170, 170, 0.4)`
    },
    alignLeft: {
      textAlign: 'left'
    },
    paperLeft: {
      position: 'relative',
      padding: 12,
      textAlign: 'left',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginRight: 20
      },
      border: `1px solid rgba(170, 170, 170, 0.4)`
    },
    paperRight: {
      position: 'relative',
      padding: 12,
      textAlign: 'left',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginLeft: 20
      },
      border: `1px solid rgba(170, 170, 170, 0.4)`
    },
    mobileHeader: {
      marginBottom: 16,
      [theme.breakpoints.up('md')]: {
        display: 'none'
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: 0
      }
    },
    cursor: {
      cursor: 'pointer'
    },
    imageBox: {
      margin: '15% auto 0px',
      [theme.breakpoints.down('md')]: {
        margin: '35% auto 0px'
      }
    },
    emptyRegistries: {
      margin: '5px auto 0px',
      textAlign: 'center'
    },
    registeryBox: {
      marginTop: 25
    },
    registeryCard: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10
    },
    noWrapWidth: {
      width: 200,
      [theme.breakpoints.down('sm')]: {
        width: 100
      }
    },

    indicator: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: '50%'
    },
    groupIcon: {
      position: 'absolute',
      top: 10,
      right: 10
    }
  })
);

export default registriesStyle;
