import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getUser } from '../../utils';
import positionFormStyle from './Styles';
import * as yup from 'yup';
import { months } from '../../scenes/app/profile/profile';
import parse from 'autosuggest-highlight/parse';
import * as api from '../../services/apiService';
import throttle from 'lodash/throttle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TodayIcon from '@material-ui/icons/Today';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import ReactHookFormSelect from '../select/ReactHookFormSelect';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

import DeleteDialog from '../Dialogs/DeleteDialog';

interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

interface PositionFormPropsType {
  editedPosition?: any;
  specialityList: any[];
  showEndDate: boolean;
  value: number;
  endDate: Date;
  startDate: Date;
  title: string;
  setIsBlocking: (isBlock: boolean) => void;
  setOnRefresh: (refresh: boolean) => void;
  setViewPositionForm: (view: boolean) => void;
  setStartDate: (startDate: Date) => void;
  setShowEndDate: (view: boolean) => void;
  setEndDate: (endDate: Date) => void;
  handleEndDateChange: (date: any) => void;
  handleStartDateChange: (date: any) => void;
  setShowProfileFormMessage: (view: boolean) => void;
  setProfileMessage: (message: string[]) => void;
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const PositionForm: React.FC<PositionFormPropsType> = (props) => {
  const {
    editedPosition,
    specialityList,
    showEndDate,
    endDate,
    startDate,
    title,
    setOnRefresh,
    setViewPositionForm,
    setStartDate,
    setShowEndDate,
    setEndDate,
    handleEndDateChange,
    handleStartDateChange,
    setIsBlocking,
    setShowProfileFormMessage,
    setProfileMessage
  } = props;
  const classes = positionFormStyle();
  const user = getUser();
  const userId = getUser() ? getUser().id : null;
  const placeTypeObj: PlaceType = {
    description: '',
    place_id: '',
    structured_formatting: {
      main_text: '',
      secondary_text: '',
      main_text_matched_substrings: [
        {
          offset: 0,
          length: 0
        }
      ]
    }
  };
  const [placeValue, setPlaceValue] = React.useState<any>(placeTypeObj);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef<boolean>(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const validationSchema = yup.object().shape({
    speciality: yup.string().required('This field is required'),
    hospital: yup.string().required('This field is required')
  });
  const [
    isOpenPositionConfirm,
    setIsOpenPositionConfirm
  ] = React.useState<boolean>(false);
  const [
    isOpenExperienceConfirm,
    setIsOpenExperienceConfirm
  ] = React.useState<boolean>(false);

  const handlePositionConfirmClose = () => {
    setIsOpenPositionConfirm(false);
  };

  const handlePositionConfirmOpen = () => {
    setIsOpenPositionConfirm(true);
  };

  const handleExperienceConfirmClose = () => {
    setIsOpenExperienceConfirm(false);
  };

  const handleExperienceConfirmOpen = () => {
    setIsOpenExperienceConfirm(true);
  };

  const { handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      hospital: '',
      speciality: '',
      startDate
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  useEffect(() => {
    if (editedPosition) {
      setPlaceValue({
        description: editedPosition.hospital.address,
        place_id: editedPosition.hospital.googlePlaceId,
        structured_formatting: {
          main_text: editedPosition.hospital.name,
          secondary_text: editedPosition.hospital.name
        }
      });
      setValue('speciality', editedPosition.speciality?.id);
      setValue('hospital', {
        address: editedPosition.hospital.address,
        name: editedPosition.hospital.name,
        google_place_id: editedPosition.hospital.googlePlaceId
      });
      setStartDate(editedPosition.startDate);
      setEndDate(editedPosition.endDate);
      if (editedPosition.endDate) {
        setShowEndDate(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedPosition]);

  const onSubmit = async (values: any) => {
    if (typeof startDate !== 'string') {
      const year = startDate.getFullYear();
      const monthName = months[startDate.getMonth()];
      const date = startDate.getDate();
      const newStartDate = `${monthName} ${date}, ${year}`;
      values['start_date'] = newStartDate;
    } else {
      values['start_date'] = startDate;
    }

    values['speciality_id'] = values.speciality;
    if (showEndDate) {
      if (typeof endDate !== 'string') {
        let endDateZero;
        const yearEnd = endDate.getFullYear();
        const monthNameEnd = months[endDate.getMonth()];
        endDateZero = endDate.getDate();
        if (endDateZero < 10) {
          endDateZero = '0' + endDateZero.toString();
        }
        const NEWEndDate = `${monthNameEnd} ${endDateZero}, ${yearEnd}`;
        values['end_date'] = NEWEndDate;
      } else {
        values['end_date'] = endDate;
      }
    }

    if (editedPosition) {
      if (!(values['end_date'] <= startDate)) {
        if (placeValue) {
          values['hospital'] = {
            address: placeValue.description,
            name: placeValue.structured_formatting.main_text,
            google_place_id: placeValue.place_id
          };
        }
        const { status_code: statusCode, data } = await api.editExperience(
          user.id,
          editedPosition.id,
          values
        );
        if (statusCode === api.status.SUCCESS) {
          setViewPositionForm(false);
          setOnRefresh(true);
          setShowEndDate(false);
          setShowProfileFormMessage(true);
          if (title === 'position') {
            let message = [
              'success',
              'Current Position  updated  successfully'
            ];
            setProfileMessage(message);
          } else {
            setProfileMessage(['success', 'Experience  updated  successfully']);
          }
          setTimeout(() => setShowProfileFormMessage(false), 1000);
        } else {
          setShowProfileFormMessage(true);
          setProfileMessage(['error', `${data}`]);
          setTimeout(() => setShowProfileFormMessage(false), 1000);
        }
      } else {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 1000);
      }
    } else {
      if (!(endDate <= startDate) || title === 'position') {
        values['hospital'] = {
          address: placeValue.description,
          name: placeValue.structured_formatting.main_text,
          google_place_id: placeValue.place_id
        };
        const { status_code: statusCode, data } = await api.addExperience(
          user.id,
          values
        );
        if (statusCode === api.status.SUCCESS) {
          setViewPositionForm(false);
          setOnRefresh(true);
          setShowEndDate(false);
          setShowProfileFormMessage(true);
          if (title === 'position') {
            setProfileMessage([
              'success',
              'Current position  added  successfully'
            ]);
          } else {
            setProfileMessage(['success', 'Experience  added  successfully']);
          }
          setTimeout(() => setShowProfileFormMessage(false), 1000);
        } else {
          setShowProfileFormMessage(true);
          setProfileMessage(['error', `${data}`]);
          setTimeout(() => setShowProfileFormMessage(false), 1000);
        }
      } else {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 1000);
      }
    }
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCmKRYmEuGJPNFbpworGazTY7Do8WG2S2s&libraries=places',
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );
  const deletePosition = async (positionData: any) => {
    const { status_code, data } = await api.deleteExperience(
      userId,
      positionData.id
    );
    if (status_code === api.status.SUCCESS) {
      setOnRefresh(true);
      setShowProfileFormMessage(true);
      if (title === 'position') {
        setProfileMessage(['success', 'position  deleted  successfully']);
      } else {
        setProfileMessage(['success', 'Experience  deleted  successfully']);
      }
      setTimeout(() => setShowProfileFormMessage(false), 1000);
    } else {
      setShowProfileFormMessage(true);
      setProfileMessage(['error', `${data}`]);
      setTimeout(() => setShowProfileFormMessage(false), 1000);
    }
  };

  const handleDeletePosition = () => {
    deletePosition(editedPosition);
    handlePositionConfirmClose();
    setViewPositionForm(false);
  };

  const handleDeleteExperience = () => {
    deletePosition(editedPosition);
    handleExperienceConfirmClose();
    setViewPositionForm(false);
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(placeValue ? [placeValue] : []);

      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (placeValue) {
          newOptions = [placeValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [placeValue, inputValue, fetch]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => setIsBlocking(true)}
      >
        <Snackbar open={showAlert} color="error">
          <Alert onClose={() => setShowAlert(false)} severity="error">
            End date must be greater than start date{' '}
          </Alert>
        </Snackbar>
        <Box className={classes.positionBox}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Autocomplete
                id="google-map-demo"
                defaultValue={
                  editedPosition
                    ? {
                        description: editedPosition.hospital.address,
                        place_id: editedPosition.hospital.googlePlaceId,
                        structured_formatting: {
                          main_text: editedPosition.hospital.name,
                          secondary_text: editedPosition.hospital.name
                        }
                      }
                    : null
                }
                options={options}
                className={classes.mt16}
                getOptionLabel={(option: any) =>
                  typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                autoComplete
                includeInputInList
                filterSelectedOptions
                onClick={(e) => e.stopPropagation()}
                value={placeValue?.description}
                onChange={(event: any, newValue: PlaceType | null) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setPlaceValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <Controller
                    required
                    error={errors.hospital ? true : false}
                    helperText={errors.hospital && errors.hospital.message}
                    as={TextField}
                    {...params}
                    control={control}
                    name="hospital"
                    label="Institution"
                    variant="outlined"
                    defaultValue=""
                    fullWidth
                  />
                )}
                renderOption={(option: any) => {
                  const matches =
                    option?.structured_formatting.main_text_matched_substrings;
                  let parts;
                  if (matches) {
                    parts = parse(
                      option?.structured_formatting.main_text,
                      matches &&
                        matches?.map((match: any) => [
                          match.offset,
                          match.offset
                        ])
                    );
                  }

                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item>
                        {parts &&
                          parts.map((part, index) => (
                            <span
                              key={part.text + index}
                              className={
                                part.highlight
                                  ? classes.highlightpart
                                  : classes.part
                              }
                            >
                              {part.text}
                            </span>
                          ))}
                        <Typography variant="caption" color="textSecondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ReactHookFormSelect
                className={classes.mt16}
                name="speciality"
                label="Speciality "
                control={control}
                defaultValue={''}
                variant="outlined"
                margin="normal"
                setIsBlocking={setIsBlocking}
                error={errors.speciality ? true : false}
                helperText={errors.speciality && errors.speciality.message}
              >
                {specialityList.length > 0
                  ? specialityList.map((gender: any) => (
                      <MenuItem key={gender.value} value={gender.value}>
                        {gender.label}
                      </MenuItem>
                    ))
                  : null}
              </ReactHookFormSelect>
            </Grid>
            <Grid
              className={classes.dateField}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  required
                  variant="dialog"
                  inputVariant="outlined"
                  value={startDate}
                  onChange={handleStartDateChange}
                  label="start date "
                  name="start_date"
                  format="MMM dd, yyyy"
                  fullWidth
                  maxDate={new Date()}
                  autoOk={true}
                  keyboardIcon={<TodayIcon />}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {showEndDate ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.endDateBOX}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    variant="inline"
                    inputVariant="outlined"
                    value={endDate}
                    onChange={handleEndDateChange}
                    label="End date "
                    name="end_date"
                    format="MMM dd, yyyy"
                    fullWidth
                    maxDate={new Date()}
                    autoOk={true}
                    keyboardIcon={<TodayIcon />}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}
            {title === 'position' && editedPosition ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.root,
                        checked: classes.checked
                      }}
                      className={classes.checkboxIcon}
                      checked={!showEndDate}
                      onClick={() => setShowEndDate(!showEndDate)}
                    />
                  }
                  label="I currently work in this role"
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {editedPosition ? (
                <Box className={classes.buttons}>
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    onClick={() => {
                      if (title === 'position') {
                        handlePositionConfirmOpen();
                      } else {
                        handleExperienceConfirmOpen();
                      }
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    className={classes.saveButton}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    Save
                  </Button>
                </Box>
              ) : (
                <Button
                  className={classes.addButton}
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Add
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </form>

      <DeleteDialog
        isOpen={isOpenPositionConfirm}
        title={'Delete Current Position'}
        firstOption={'Delete'}
        secondOption={'Cancel'}
        handleOptionOne={handleDeletePosition}
        handleOptionTwo={handlePositionConfirmClose}
        onClose={handlePositionConfirmClose}
        height={198}
        width={507}
      >
        Are you sure you want to delete this position?
      </DeleteDialog>

      <DeleteDialog
        isOpen={isOpenExperienceConfirm}
        title={'Delete Experience'}
        firstOption={'Delete'}
        secondOption={'Cancel'}
        handleOptionOne={handleDeleteExperience}
        handleOptionTwo={handleExperienceConfirmClose}
        onClose={handleExperienceConfirmClose}
        height={198}
        width={507}
      >
        Are you sure you want to delete this Experience?
      </DeleteDialog>
    </div>
  );
};

export default PositionForm;
