import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import FooterStyle from './Styles';
import Email from '../../assets/PromotionalSite/Email.svg';
import Phone from '../../assets/PromotionalSite/Phone.svg';
import Location from '../../assets/PromotionalSite/Location.svg';
import LinkedIn from '../../assets/PromotionalSite/LinkedIn.svg';
import EmailDark from '../../assets/PromotionalSiteDark/Email.svg';
import PhoneDark from '../../assets/PromotionalSiteDark/Phone.svg';
import LocationDark from '../../assets/PromotionalSiteDark/Location.svg';
import LinkedInDark from '../../assets/PromotionalSiteDark/LinkedIn.svg';
import history from '../../utils/history';
import logo from '../../assets/img/MS-logo-img.png';

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const openLink = (url) => {
    window.open(url, '_blank');
  }

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="footerLogoContent">
              <div className="footerLogo">
                <img style={{ width: '100%' }} src={logo} alt=""></img>
                <p>MS Logger is the most advanced HIPAA-compliant application that allows healthcare providers to build their patient database, log their procedures quickly and securely, and export the data for billing, research, or archiving.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="footerNavigation footerHeading">
              <h4>Navigation</h4>
              <ul className="footerLinks">
                <li><a onClick={() => {
                  history.push('/');
                  scrollToTop();
                }
                } className='pointer'>Home</a></li>
                <li><a onClick={() => {
                  history.push('/about');
                  scrollToTop();
                }} className='pointer'>About MS Logger</a></li>
                <li><a onClick={() => {
                  history.push('/plans');
                  scrollToTop();
                }} className='pointer'>Plan & Pricing</a></li>
                <li><a onClick={() => {
                  history.push('/ourteam');
                  scrollToTop();
                }} className='pointer'>Our Team</a></li>
                <li><a onClick={() => {
                  history.push('/contact');
                  scrollToTop();
                }} className='pointer'>Contact</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footerSocialicon footerHeading">
              <h4>Social Media</h4>
              <ul className="footerSocialLinks">
                <li><a onClick={() => openLink('https://twitter.com/MS_Logger')}><i className="fab fa-twitter pointer"></i></a></li>
                <li><a onClick={() => openLink('https://www.instagram.com/ms_logger/')}><i className="fab fa-instagram pointer"></i></a></li>
                <li><a onClick={() => openLink('https://www.facebook.com/mslogger')}><i className="fab fa-facebook pointer"></i></a></li><br></br>
                <li><a onClick={() => openLink('https://www.linkedin.com/company/72060014')}><i className="fa-brands fa-linkedin-in pointer"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="newsLetter footerHeading">
              <h4>Subscribe</h4>
              <p>Join our subscription list to receive recent updates and offers.</p>
              <form action="">
                <div className="form-group mb-0">
                  <input type="email" className="form-control" placeholder="email@email.com" required></input>
                </div>
                <div className="form-group mb-0">
                  <input type="submit" value="Subscribe"></input>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="copyRight">
              <p>Copyrights © 2023 MS Logger</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
