import { makeStyles, Theme, createStyles } from '@material-ui/core';

const EditRegistryStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '15px 168px',
      minWidth: 500,
      [theme.breakpoints.down('md')]: {
        padding: 16,
        minWidth: 0
      },
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        width: '78%',
        margin: 'auto'
      },
      [theme.breakpoints.only('md')]: {
        padding: '15px 50px',
        minWidth: 0
      }
    },
    formPaper: {
      border: `1px solid rgba(170, 170, 170, 0.4)`,
      [theme.breakpoints.down('md')]: {
        padding: '23px 20px 10px'
      },
      padding: '23px 35px 10px'
    },
    headerContainer: {
      display: 'flex',

      marginTop: 24,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: 0
      }
    },
    link: {
      textDecoration: 'none'
    },
    registeryName: {
      paddingLeft: 12,
      paddingBottom: 14,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    header: {
      display: 'flex',
      alignItems: 'center'
    },
    headerIcon: {
      marginRight: 8
    },
    inline: {
      display: 'inline'
    },
    pr: {
      paddingLeft: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    selectHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 437,
      overflow: 'overlay'
    },
    sharedPaper: {
      border: `1px solid rgba(0, 0, 0, 0.25)`
    },
    secHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: '12px 12px'
    },
    field: {
      padding: 20,
      [theme.breakpoints.down('sm')]: {
        padding: 10
      }
    },
    formControl: {
      width: '100%'
    },
    saveButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 16
    },
    saveButton: {
      width: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },

    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

export default EditRegistryStyle;
