import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import IsoIcon from '@material-ui/icons/Iso';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PaymentIcon from '@material-ui/icons/Payment';
import EmailIcon from '@material-ui/icons/Email';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/MSLoggerlogo.png';
import MenuIcon from '@material-ui/icons/Menu';
import useAuth from '../../services/authService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { NavLink, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { headerStyles } from './Styles';
import { getUser } from '../../utils';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DeleteDialog from '../Dialogs/DeleteDialog';
import history from '../../utils/history';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FolderIcon from '@material-ui/icons/Folder';
import DashboardIcon from '@material-ui/icons/Dashboard';
import avatar from '../../assets/avatar.png';
import avatarDark from '../../assets/dark/account_circle-24px-3.png';
import * as api from '../../services/apiService';
import CheckIcon from '@material-ui/icons/Check';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { doctorParams } from '../../types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
interface HeaderPropsType {
  theme: string;
  setUser: (user: any) => {};
  setTheme: (theme: string) => void;
}

const Header = (props) => {
  const {
    setUser,
    setTheme,
    editUserName,
    setEditUserName,
    isHandledInvitation,
    setIsHandledInvitation
  } = props;
  const { logout } = useAuth();
  const user = getUser();
  const classes = headerStyles();
  const location = useLocation().pathname;
  const { doctorId } = useParams<doctorParams>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [openList, setOpenList] = React.useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [loggedOut, setLoggedOut] = React.useState<boolean>(false);
  const [profilePicture, setProfilePicture] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [doctorName, setDoctorName] = React.useState('');
  const [doctorID, setDoctorID] = React.useState('');
  const [doctorList, setDoctorList] = React.useState([]);
  const [openCOllapse, setOpenCOllapse] = React.useState<boolean>(false);

  const dashboardPath = [
    '/case/',
    '/dashboard',
    '/shared-dashboard/',
    '/edit-case/',
    '/new-case'
  ];
  const dashboardCheckPath = [
    '/case/',
    '/dashboard',
    '/edit-case/',
    '/new-case',
    '/profile',
    '/users'
  ];
  const registryPath = [
    '/registries',
    '/new-registry',
    '/registry/',
    '/registrycase/',
    '/edit-Registry-case/',
    '/new-Case-registry/',
    '/registry/Invitation/',
    '/edit-registry/'
  ];

  const sharedPath = [
    '/shared-case/',
    '/shared-dashboard/',
    '/new-shared-case/',
    '/edit-shared-case/'
  ];

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const onLogout = () => {
    handleClose();
    setUser(null);
    logout();
    localStorage.clear();
  };

  const getUserInfo = React.useCallback(async (userId) => {
    const { data, statusCode } = await api.getUserInfo({
      toCamel: true,
      userId
    });

    if (statusCode === api.status.SUCCESS) {
      setUserName(`${data.firstName} ${data.lastName}`);
      setProfilePicture(`${data.profileImage.hrefSmall}`);
      setEditUserName(false);
    }
  }, [profilePicture]);

  React.useEffect(() => {
    let mounted = true;
    if (user && mounted) {
      getUserInfo(user.id);
    }
    return () => {
      mounted = false;
    };
  }, [editUserName]);

  React.useEffect(() => {
    let mounted = true;
    if (user && mounted) {
      getDoctors(user.id, 'MY_TEAMS');
    }

    return () => {
      mounted = false;
    };
  }, [isHandledInvitation]);

  const handleClickOpen = () => {
    setOpenCOllapse(!openCOllapse);
  };

  const isDoctor = (user) => {
    return user.user_role?.name == 'SPECIALIST' || user.user_role?.name == 'FELLOW' || user.user_role?.name == 'RESIDENT';
  }

  const getDoctors = React.useCallback(async (userId, filter) => {
    const { data, status_code: statusCode } = await api.getDoctors(
      userId,
      filter
    );
    if (statusCode === api.status.SUCCESS) {
      setDoctorList(data.doctors);
      setIsHandledInvitation(false);
    } else {
    }
  }, []);

  const getDoctorName = (doctorList) => {
    let doctor = doctorList.filter(
      (doctor) => doctor.id === parseInt(location.split('/')[2], 10)
    );
    setDoctorName(doctor[0]?.name);
  };

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.up('lg'));
  React.useEffect(() => { }, [user?.verified]);
  useEffect(() => {
    if (doctorList.length > 0) {
      getDoctorName(doctorList);
    }
  }, [doctorList]);
  return (
    <AppBar color="primary" position="relative">
      <DeleteDialog
        isOpen={isOpen}
        title={'Logout'}
        firstOption={'Yes'}
        secondOption={'No'}
        handleOptionOne={onLogout}
        handleOptionTwo={handleClose}
        onClose={handleClose}
        height={174}
        width={390}
      >
        Are you sure you want to logout?
      </DeleteDialog>
      <Toolbar className={classes.root}>
        <img
          alt="caselogLogo"
          className={classes.logo}
          src={logo}
          onClick={() => history.push('/')}
        ></img>
        {matchesMobile ? (
          user?.id > 0 && user?.verified ? (
            <>
              <Box
                className={classes.cursor}
                ml={6}
                onClick={() => {
                  if (!location.includes('/shared-dashboard/')) {
                    if (doctorID.includes('/shared-dashboard/')) {
                      history.goBack();
                      setDoctorID('');
                    } else {
                      history.push('/dashboard');
                    }
                  }
                }}
              >
                <Box
                  className={
                    dashboardPath.some((path) => location.includes(path))
                      ? classes.userName
                      : classes.disabled
                  }
                >
                  Dashboard
                </Box>
              </Box>
              {!sharedPath.some((path) => location.includes(path)) &&
                (doctorID === '' || doctorID === undefined) ? (
                <Box ml={6}>
                  <NavLink
                    to="/registries"
                    className={
                      registryPath.some((path) => location.includes(path))
                        ? classes.userName
                        : classes.disabled
                    }
                  >
                    {' '}
                    Registries
                  </NavLink>
                </Box>
              ) : null}
              <Box
                className={classes.cursor}
                mr={
                  sharedPath.some((path) => location.includes(path)) ||
                    doctorID.includes('/shared-dashboard/')
                    ? 'auto'
                    : 0
                }
                ml={6}
              >
                <Box
                  onClick={() => {
                    if (sharedPath.some((path) => location.includes(path))) {
                      setDoctorID(location);
                    }

                    history.push('/RVU-calculator');
                  }}
                  className={
                    location === '/RVU-calculator'
                      ? classes.userName
                      : classes.disabled
                  }
                >
                  {' '}
                  RVU calculator
                </Box>
              </Box>
              {!sharedPath.some((path) => location.includes(path)) &&
                (doctorID === '' || doctorID === undefined) ? (
                <Box mr="auto" ml={6}>
                  <NavLink
                    to="/users"
                    className={
                      location === '/users'
                        ? classes.userName
                        : classes.disabled
                    }
                  >
                    {' '}
                    Users
                  </NavLink>
                </Box>
              ) : null}
              <Box mr={2}>
                <NavLink to="/profile" className={classes.userName}>
                  {userName}
                </NavLink>
                {sharedPath.some((path) => location.includes(path)) ? (
                  <Typography variant="h5">
                    {doctorList && doctorList.length > 0
                      ? isDoctor(doctorList[0])
                        ? `Dr. ${doctorName}`
                        : doctorName
                      : null}
                  </Typography>) : null}
              </Box>
              <Box
                onClick={() => setOpenList(!openList)}
                className={classes.avatorBox}
              >
                <Avatar
                  className={classes.small}
                  src={profilePicture !== '' ? profilePicture : props.theme === 'darkTheme' ? avatarDark : avatar}
                />

                {openList ? (
                  <ClickAwayListener onClickAway={() => setOpenList(false)}>
                    <Paper className={classes.poper}>
                      <Typography
                        variant="h3"
                        className={classes.link}
                        onClick={() => {
                          setOpenList(false);
                          history.push('/profile');
                        }}
                      >
                        {' '}
                        <Avatar
                          className={[classes.small, classes.ml5].join(' ')}
                          src={profilePicture !== '' ? profilePicture : props.theme === 'darkTheme' ? avatarDark : avatar}
                        />
                        <Box className={classes.userInfo}>
                          {userName}
                          <Typography
                            color="secondary"
                            className={classes.profileVisit}
                          >
                            Visit profile
                          </Typography>
                        </Box>
                      </Typography>
                      {doctorList && doctorList.length > 0 ? (
                        <Typography
                          variant="h3"
                          className={classes.linkTopBorder}
                          onClick={() => {
                            setOpenList(false);
                            setDoctorID('');
                            history.push('/dashboard');
                          }}
                        >
                          <CheckIcon
                            className={
                              dashboardCheckPath.some((path) =>
                                location.includes(path)
                              )
                                ? classes.checkIcon
                                : classes.hiddenIcon
                            }
                          />
                          My dashboard
                        </Typography>
                      ) : null}
                      {doctorList && doctorList.length > 0
                        ? doctorList.map((doctor: any, index: number) => (
                          <Typography
                            variant="h3"
                            className={
                              index === doctorList.length - 1
                                ? classes.linkBottomBorder
                                : classes.linkDoctorList
                            }
                            key={doctor.id}
                            onClick={() => {
                              setOpenList(false);
                              history.push('/shared-dashboard/' + doctor.id);
                              window.location.reload();
                              setDoctorName(doctor.name);
                            }}
                          >
                            <CheckIcon
                              className={
                                location.includes('/shared-dashboard/')
                                  ? doctor.id !==
                                    parseInt(location.split('/')[2], 10)
                                    ? classes.hiddenIcon
                                    : classes.checkIcon
                                  : doctor.id !==
                                    parseInt(doctorID.split('/')[2], 10)
                                    ? classes.hiddenIcon
                                    : classes.checkIcon
                              }
                            />
                            {isDoctor(doctor) ? `Dr. ${doctor.name}` : doctor.name}’s dashboard

                          </Typography>
                        ))
                        : null}
                      <Typography
                        className={[classes.link, classes.fw200].join(' ')}
                        variant="h3"
                        onClick={() => {
                          setOpenList(false);
                          history.push('/MyPlan');
                        }}
                      >
                        <CardMembershipIcon className={classes.checkIcon} />
                        Current plan details
                      </Typography>
                      <Typography
                        className={[classes.link, classes.fw200].join(' ')}
                        variant="h3"
                        onClick={() => {
                          handleOpen();
                          setOpenList(false);
                          history.push(location);
                        }}
                      >
                        <ExitToAppIcon className={classes.checkIcon} /> Logout
                      </Typography>
                    </Paper>
                  </ClickAwayListener>
                ) : null}
              </Box>
            </>
          ) : location !== '/signin' && location !== '/signup' ? (
            <>
              {' '}
              <Box ml={6}>
                <NavLink
                  to="/"
                  className={
                    location === '/' ? classes.userName : classes.disabled
                  }
                >
                  Home
                </NavLink>
              </Box>
              <Box ml={6}>
                <NavLink
                  to="/about"
                  className={
                    location === '/about' ? classes.userName : classes.disabled
                  }
                >
                  About MS Logger
                </NavLink>
              </Box>
              <Box ml={6}>
                <NavLink
                  to="/Plans"
                  className={
                    location === '/Plans' ? classes.userName : classes.disabled
                  }
                >
                  Plans and pricing
                </NavLink>
              </Box>
              {/* <Box ml={6}>
                <NavLink
                  to="/OurTeam"
                  className={
                    location === '/OurTeam'
                      ? classes.userName
                      : classes.disabled
                  }
                >
                  Our Team
                </NavLink>
              </Box> */}
              <Box ml={6}>
                <NavLink
                  to="/Contact"
                  className={
                    location === '/Contact'
                      ? classes.userName
                      : classes.disabled
                  }
                >
                  Contact usf
                </NavLink>
              </Box>
            </>
          ) : null
        ) : (
          <>
            <Box ml="auto">
              <MenuIcon onClick={() => setOpenDrawer(true)} />
              <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
              >
                {user?.id > 0 && user?.verified ? (
                  <List className={classes.menuList}>
                    {' '}
                    <ListItem
                      button
                      key="back"
                      className={classes.disabledItem}
                    >
                      <ChevronRightIcon onClick={() => setOpenDrawer(false)} />{' '}
                    </ListItem>
                    <Divider />
                    <ListItem
                      key="Dashboard"
                      onClick={() => {
                        handleClickOpen();
                      }}
                      className={
                        dashboardPath.some((path) => location.includes(path))
                          ? classes.activeItem
                          : classes.disabledItem
                      }
                    >
                      <Typography variant="h1" className={classes.listItem}>
                        {' '}
                        <DashboardIcon /> <Box pl={2}>Dashboards</Box>{' '}
                        {openCOllapse ? (
                          <ExpandLess className={classes.collapsedIcon} />
                        ) : (
                          <ExpandMore className={classes.collapsedIcon} />
                        )}
                      </Typography>
                    </ListItem>
                    <Collapse in={openCOllapse} timeout="auto" unmountOnExit>
                      <List>
                        <Typography
                          variant="h1"
                          className={
                            location === '/dashboard' && doctorList.length === 0
                              ? classes.linkDoctorListMobileActiveMb0
                              : location === '/dashboard' &&
                                doctorList.length !== 0
                                ? classes.linkDoctorListMobileActive
                                : location !== '/dashboard' &&
                                  doctorList.length === 0
                                  ? classes.linkDoctorListMobileMb0
                                  : classes.linkDoctorListMobile
                          }
                          onClick={() => {
                            setOpenDrawer(false);
                            setDoctorID('');
                            history.push('/dashboard');
                          }}
                        >
                          <CheckIcon
                            className={
                              !dashboardCheckPath.some((path) =>
                                location.includes(path)
                              )
                                ? classes.hiddenIcon
                                : classes.checkIcon
                            }
                          />
                          My dashboard
                        </Typography>

                        {doctorList && doctorList.length > 0
                          ? doctorList.map((doctor: any, index) => (
                            <Typography
                              variant="h1"
                              className={
                                doctor.id ===
                                  parseInt(location.split('/')[2], 10)
                                  ? classes.linkDoctorListMobileActive
                                  : classes.linkDoctorListMobile
                              }
                              key={doctor.id}
                              onClick={() => {
                                setOpenDrawer(false);
                                history.push(
                                  '/shared-dashboard/' + doctor.id
                                );
                                setDoctorName(doctor.name);
                              }}
                            >
                              <CheckIcon
                                className={
                                  location.includes('/shared-dashboard/')
                                    ? doctor.id !==
                                      parseInt(location.split('/')[2], 10)
                                      ? classes.hiddenIcon
                                      : classes.checkIcon
                                    : doctor.id !==
                                      parseInt(doctorID.split('/')[2], 10)
                                      ? classes.hiddenIcon
                                      : classes.checkIcon
                                }
                              />
                              {isDoctor(doctor) ? `Dr. ${doctor.name}` : doctor.name}’s dashboard
                            </Typography>
                          ))
                          : null}
                      </List>
                    </Collapse>
                    {!sharedPath.some((path) => location.includes(path)) &&
                      !doctorID.includes('/shared-dashboard/') ? (
                      <NavLink to="/registries" className={classes.editLink}>
                        <ListItem
                          button
                          onClick={() => {
                            setOpenDrawer(false);
                          }}
                          key="Registries"
                          className={
                            registryPath.some((path) => location.includes(path))
                              ? classes.activeItem
                              : classes.disabledItem
                          }
                        >
                          <FolderIcon /> <Box pl={2}>Registries</Box>
                        </ListItem>
                      </NavLink>
                    ) : null}
                    <NavLink to="/RVU-calculator" className={classes.editLink}>
                      <ListItem
                        button
                        key="RVU-calculator"
                        onClick={() => {
                          if (
                            sharedPath.some((path) => location.includes(path))
                          ) {
                            setDoctorID(location);
                          }
                          setOpenDrawer(false);
                        }}
                        className={
                          location === '/RVU-calculator'
                            ? classes.activeItem
                            : classes.disabledItem
                        }
                      >
                        <IsoIcon /> <Box pl={2}>RVU calculator</Box>
                      </ListItem>
                    </NavLink>
                    {!sharedPath.some((path) => location.includes(path)) &&
                      !doctorID.includes('/shared-dashboard/') ? (
                      <NavLink to="/users" className={classes.editLink}>
                        <ListItem
                          button
                          key="users"
                          onClick={() => {
                            setOpenDrawer(false);
                          }}
                          className={
                            location === '/users'
                              ? classes.activeItem
                              : classes.disabledItem
                          }
                        >
                          <PeopleAltIcon /> <Box pl={2}>Users</Box>
                        </ListItem>
                      </NavLink>
                    ) : null}
                    <NavLink to="/Profile" className={classes.editLink}>
                      <ListItem
                        button
                        key="Profile"
                        onClick={() => {
                          setOpenDrawer(false);
                        }}
                        className={
                          location === '/Profile'
                            ? [classes.activeItem, classes.mb].join(' ')
                            : [classes.disabledItem, classes.mb].join(' ')
                        }
                      >
                        <AccountBoxIcon /> <Box pl={2}>Profile</Box>{' '}
                      </ListItem>
                    </NavLink>
                    <NavLink to="/MyPlan" className={classes.editLink}>
                      <ListItem
                        button
                        key="MyPlan"
                        onClick={() => {
                          setOpenDrawer(false);
                        }}
                        className={
                          location === '/MyPlan'
                            ? [classes.activeItem, classes.mb].join(' ')
                            : [classes.disabledItem, classes.mb].join(' ')
                        }
                      >
                        <CardMembershipIcon />{' '}
                        <Box pl={2}>Current plan details</Box>{' '}
                      </ListItem>
                    </NavLink>
                    <ListItem
                      button
                      key="Logout"
                      className={classes.logout}
                      onClick={() => setIsOpen(true)}
                    >
                      {' '}
                      <ExitToAppIcon /> <Box pl={2}>Logout</Box>
                    </ListItem>
                  </List>
                ) : (
                  <List className={classes.menuList}>
                    <>
                      {' '}
                      <ListItem
                        button
                        key="back"
                        className={classes.disabledItem}
                      >
                        <ChevronRightIcon
                          onClick={() => setOpenDrawer(false)}
                        />{' '}
                      </ListItem>
                      <Divider />
                      <ListItem
                        button
                        key="home"
                        onClick={() => {
                          history.push('/');
                          setOpenDrawer(false);
                        }}
                        className={
                          location === '/'
                            ? classes.activeItem
                            : classes.disabledItem
                        }
                      >
                        <DynamicFeedIcon /> <Box pl={2}>Home</Box>
                      </ListItem>
                      <ListItem
                        button
                        key="about"
                        onClick={() => {
                          history.push('/about');
                          setOpenDrawer(false);
                        }}
                        className={
                          location === '/about'
                            ? classes.activeItem
                            : classes.disabledItem
                        }
                      >
                        <LocalHospitalIcon /> <Box pl={2}>About MS Logger</Box>
                      </ListItem>
                      <ListItem
                        button
                        key="plans"
                        onClick={() => {
                          history.push('/plans');
                          setOpenDrawer(false);
                        }}
                        className={
                          location === '/plans'
                            ? classes.activeItem
                            : classes.disabledItem
                        }
                      >
                        <PaymentIcon /> <Box pl={2}>Plans and pricing</Box>
                      </ListItem>
                      {/* <ListItem
                        button
                        key="OurTeam"
                        onClick={() => {
                          history.push('/OurTeam');
                          setOpenDrawer(false);
                        }}
                        className={
                          location === '/OurTeam'
                            ? classes.activeItem
                            : classes.disabledItem
                        }
                      >
                        <EmailIcon /> <Box pl={2}>Our Team</Box>
                      </ListItem> */}
                      <ListItem
                        button
                        key="Contact"
                        onClick={() => {
                          history.push('/Contact');
                          setOpenDrawer(false);
                        }}
                        className={
                          location === '/Contact'
                            ? classes.activeItem
                            : classes.disabledItem
                        }
                      >
                        <EmailIcon /> <Box pl={2}>Contact us</Box>
                      </ListItem>
                    </>
                  </List>
                )}
              </Drawer>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
