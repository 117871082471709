import React from 'react';
import privacyStyle from '../privacy/Styles';

const HippaSecurity = () => {
  const classes = privacyStyle();

  return (
    <div className={classes.container}>
      <p className={classes.topic}>
        MS Logger Security
      </p>
      <p>
        In this document, we describe in detail the security measures that MS Logger uses to be as
        possible and go beyond HIPAA-standards.
      </p>
      <ul>
        <li>MS Logger uses client-server architecture. Clients consists of different types of web
          browsers, Android and iOS devices.</li>
        <li>Communication between clients and server is achieved through secure HTTPs channel.</li>
        <li>MS Logger uses AWS services to manage its infrastructure (Databases, Servers and
          Storage Servers).
          <ul>
            <li>MS Logger uses EC2 instances for servers.</li>
            <li>MS Logger uses RDS for managing database server.</li>
            <li>MS Logger uses S3 service to store all files securely</li>
            <li>MS Logger uses VPC to manage communication between EC2, RDS and S3
              services in a secure way.</li>
            <li>All of PHI-related files are private by default, any related file can be accessible
              only by generating a special url using the application server, this url is designed
              to be valid for a specified period of time (2 hours usually). And after 2 hours the
              url be invalid and new once should be generated by our application server only
              and only if the logged in user is the user who owns the data.</li>
            <li>We don’t store files on AWS S3 with their real names, as implied by HIPAA:
              <b>Customers should not include PHI in metadata or identifiers, such as
                document names and parameter names.</b></li>
            <li>We use security groups to specify who is only allowed to access resources inside
              our VPC.</li>
            <li>As indicated in Architecting for HIPAA Security and Compliance on Amazon Web
              Services , all the services we are using are HIPAA compliant. As indicated in the
              corresponding paper,<b>“Customers who execute an AWS BAA may use any
                AWS service in an account designated as a HIPAA Account, but they may
                only process, store and transmit PHI using the HIPAA-eligible services
                defined in the AWS BAA. AWS maintains a standards-based risk
                management program to ensure that the HIPAA-eligible services
                specifically support the administrative, technical, and physical safeguards
                required under HIPAA. Using these services to store, process, and transmit
                PHI allows our customers and AWS to address the HIPAA requirements
                applicable to the AWS utility-based operating model.”</b></li>
          </ul><br/>
        </li>
        <li>In MS Logger, we’ve signed a BAA with AWS to insure that our servers are matches
          HIPAA servers standards. This is achieved by isolating our servers from the rest of
          typical servers.</li>
        <li>In MS Logger, we use gmail service in our message center, gmail service is HIPAA
          compliant and can be used to transfer PHI as stated by google’s HIPAA implementation
          guide.</li>
        <li>In MS Logger, we use FCM to manage our notifications, as FCM is not a HIPAA
          compliant service, we prohibit transmitting any PHI through FCM.</li>
        <li>MS Logger stores all the logs in a secure and private place where only admin can
          access these logs. in addition, we use AWS encryption service to encrypt all log files.</li>
        <li>Authentication: We have designed a robust authentication system that ensures that the
          user who’s accessing PHI is the one who claims to be.</li>
      </ul>
    </div>
  );
};

export default HippaSecurity;
