import { makeStyles, Theme, createStyles } from '@material-ui/core';

const contactUsStyle = makeStyles((theme: Theme) =>
  createStyles({
   container: {
    width: '85%',
    margin: 'auto',
    marginTop: 150,
   },
   topic: {
    textAlign: 'center',
    fontWeight: 'bold',
   }
  })
);

export default contactUsStyle;
