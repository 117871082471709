import { makeStyles, Theme, createStyles } from '@material-ui/core';

const addCaseStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '80%',
      margin: 'auto',
      paddingTop: 40,
      [theme.breakpoints.down('lg')]: {
        width: '100%',

        padding: '10px 20px'
      }
    },
    selectPaper: {
      [theme.breakpoints.down('sm')]: {
        width: 343
      }
    },
    codeValue: {
      WebkitLineClamp: 2
    },
    link: {
      display: 'inline',
      cursor: 'pointer'
    },
    imageFooter: {
      width: '100%',

      fontSize: 12
    },
    image: {
      width: 156,
      height: 140,
      [theme.breakpoints.down('sm')]: {
        width: 135
      }
    },
    rvuContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0px 40px 10px',
      borderBottom: '1px solid rgba(170, 170, 170, 0.4)'
    },
    boldText: {
      fontWeight: 500
    },
    note: {
      width: '100%'
    },
    imageName: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    withOutpadding: {
      paddingBottom: 8,
      [theme.breakpoints.up('sm')]: {}
    },
    withpadding: {
      paddingRight: 16,
      paddingBottom: 8,

      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        margin: 0,
        marginBottom: 4
      }
    },
    withpaddingNote: {
      paddingRight: 23,
      paddingBottom: 8,

      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        margin: 0
      }
    },
    divider: {
      marginTop: 4,
      marginBottom: 4
    },
    withOutpaddingNote: {
      paddingBottom: 8,
      [theme.breakpoints.up('sm')]: {}
    },
    cptBoxR: {
      border: '1px solid rgba(170, 170, 170, 0.8)',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 5,
      margin: '0px 36px 0px 0px',
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        margin: 0,
        padding: '0px 12px'
      }
    },
    codeBox: {
      fontWeight: 500
    },
    cptBoxL: {
      border: '1px solid rgba(170, 170, 170, 0.8)',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 5,
      margin: '0px 10px 10px 25px',
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        margin: 0,
        padding: '0px 12px'
      }
    },
    formTitle: {
      textAlign: 'center',
      marginTop: 10,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        alignItems: 'baseline'
      }
    },
    clearIconDialog: {
      position: 'absolute',
      top: 10,
      right: 10,
      color: '#aaaaaa'
    },
    dividerMobile: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    paper: { height: 637, width: 343 },
    notes: {
      padding: '21px 32px',

      [theme.breakpoints.down('sm')]: {
        padding: '18px 12px 2px'
      }
    },
    pageName: {
      fontSize: 18,
      cursor: 'pointer',
      display: 'inline'
    },
    inline: {
      display: 'inline'
    },
    test: {
      padding: '0px'
    },
    asterisk: {
      color: 'red'
    },
    dialog: {
      width: 600,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    dialog1: {
      padding: '40px',

    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 2,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    imageContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        padding: '4px 9px'
      },
      padding: '14px 9px'
    },
    addCaseButton: {
      marginBottom: 15,
      width: '30%',
      backgroundColor: 'rgba(74, 165, 255, 0.6)',
      [theme.breakpoints.up('sm')]: {
        width: 319,
        height: 39
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    },
    addCaseButtonEdited: {
      marginBottom: 15,
      width: '30%',
      backgroundColor: '#4aa5ff',
      color: '#ffffff',
      [theme.breakpoints.up('sm')]: {
        width: 319,
        height: 39
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    },
    addCaseHeader: {
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 0
      },
      paddingLeft: 24
    },
    InputContainer: {
      [theme.breakpoints.down('lg')]: {
        padding: '17px 8px'
      },
      [theme.breakpoints.only('md')]: {
        padding: '5px 8px'
      },
      padding: '7px 40px 12px'
    },
    InputContainerSecond: {
      [theme.breakpoints.down('lg')]: {
        padding: '20px 8px 12px'
      },
      [theme.breakpoints.only('md')]: {
        padding: '0px 8px 12px'
      },
      padding: '7px 40px 12px'
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '21px 30px',
      paddingBottom: 13,
      [theme.breakpoints.down('sm')]: {
        padding: '21px 12px 2px'
      }
    },
    procedureTitle: {
      display: 'flex',
    },
    new: {
      margin: 0,
      padding: 0,
      fontSize: 9,
      color: 'red',
    },
    addProcedure: {
      alignItems: 'center',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      padding: '21px 27px 12px',
      [theme.breakpoints.down('sm')]: {
        padding: '11px 12px'
      }
    },
    titleImage: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#4aa5ff',
      fontSize: 16,
      padding: '14px 32px',
      paddingBottom: 0,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        padding: '15px 12px 2px'
      }
    },
    cursor: {
      cursor: 'pointer',
      color: 'red',
    },
    notesContainer: {
      display: 'flex',
      alignItems: 'baseline'
    },
    noteField: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: '5px 5px 8px'
      }
    },
    noteFieldEdited: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        margin: '5px 5px 8px'
      }
    },
    notesField: {
      flexBasis: '100%'
    },
    dateField: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 12
    },
    surgeryDateField: {
      display: 'flex',
      alignItems: 'center'
    },
    imgStyle: {
      width: '100%',
      height: '100%',
      borderRadius: 8
    },
    caseImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 5
    },
    imgBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: '1px solid rgba(170, 170, 170, 0.2)',
      padding: 5,
      borderRadius: 5,
      marginLeft: 5,

      [theme.breakpoints.down('sm')]: {}
    },
    py0: {
      paddingTop: '0px !important',
      paddingBottom: '0px !important'
    },
    mt15: {
      marginTop: 15
    },
    mb10: {
      marginBottom: 10
    },
    mt0: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 0
      }
    },
    dateOfSurgery: {
      alignSelf: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10
      },
      paddingTop: '0px !important',
      paddingBottom: '0px !important'
    },
    hrContainer: {
      width: '100%'
    },
    noteTitle: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    py0mb: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0
      },
      paddingTop: '0px !important',
      paddingBottom: '0px !important'
    },
    mb0Mobile: {},
    mb0DateMobile: {
      [theme.breakpoints.down('sm')]: {
        margin: '10px 0px'
      }
    },
    mbGender: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: -10
      }
    },
    saveButton: {
      marginBottom: 10,
      width: '30%',
      backgroundColor: 'rgba(74, 165, 255, 0.6)',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    saveButtonEdited: {
      marginBottom: 10,
      width: '30%',
      backgroundColor: '#4aa5ff',
      color: '#ffffff',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    actionButton: {
      width: '50%',
      margin: 5
    },
    deleteButton: {
      color: '#ff3b30',
      width: '48%',
      alignContent: 'center',
      border: '1px solid #ff3b30'
    },
  })
);

export default addCaseStyle;
