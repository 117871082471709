import React, { useEffect, useState } from 'react';
import { getUser, setUser } from '../../../utils';
import {
  Paper,
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  MenuItem,
  Dialog,
  CircularProgress,
  Snackbar
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import emoji from 'emoji-dictionary';
import * as yup from 'yup';
import * as api from '../../../services/apiService';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import ReactHookFormSelect from '../../../components/select/ReactHookFormSelect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import profileImage from '../../../assets/avatar.png';
import editProfileStyle from './Styles';
import { PasswordStatus, userInfoType } from '../../../types';
import { strongPassword } from '../../../utils/validationConfig';
import useStyles from '../../../styles/globalStyles';
import CustomAccordion from '../../../components/CustomAccordion/CustomAccordion';

interface editProfileProps {
  setExistChange: (x: boolean) => void;
  setEditUserInfo: (x: boolean) => void;
  setEditUserName: (x: boolean) => void;
  setShowProfileMessage: (x: boolean) => void;
  setOpenEditProfile: (x: boolean) => void;
}

const EditProfile: React.FC<editProfileProps> = (props) => {
  const {
    setExistChange,
    setEditUserInfo,
    setShowProfileMessage,
    setOpenEditProfile,
    setEditUserName
  } = props;
  const classes = editProfileStyle();
  const globalClasses = useStyles();
  const user = getUser();
  const userId = getUser() ? getUser()?.id : null;
  const [showMessage, setShowMessage] = React.useState<boolean>(false);

  const [openEditPassword, setOpenEditPassword] = React.useState<boolean>(
    false
  );
  const [showError, setShowError] = React.useState<boolean>(false);
  const [
    showOldPasswordError,
    setShowOldPasswordError
  ] = React.useState<boolean>(false);
  const [showPhoneError, setShowPhoneError] = React.useState<boolean>(false);
  const [specialityList, setSpecialityList] = React.useState<any>([]);
  const [countries, setCountries] = React.useState<any>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [countryCode, setCountryCode] = React.useState<any>('');
  const [image, setImage] = React.useState<FileList>();
  const [values, setValues] = React.useState<PasswordStatus>({
    password: '',
    showPassword: false
  });
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [showOldPassword, setShowOldPassword] = React.useState<boolean>(false);
  const [userInfo, setUserInfo] = React.useState<userInfoType>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    userRole: {
      id: 0,
      niceName: ''
    },
    profileImage: {
      hrefSmall: ''
    },
    userProfile: {
      speciality: {
        name: ''
      }
    },
    id: 0,
    userCountryCode: {
      phoneCode: 0,
      id: 0
    },
  });

  const [countryFlag, setCountryFlag] = useState<string | undefined>(undefined)
  const [phoneCode, setPhoneCode] = useState<number | undefined>(undefined);
  const [role, setRole] = React.useState<any>([]);
  const [expanded, setExpanded] = useState<boolean>(false);


  const getSpecialityList = React.useCallback(async () => {
    const { data } = await api.getSpecialites();
    const specialityList = data.map((category) => {
      return {
        label: category.name,
        value: category?.id + ''
      };
    });
    setSpecialityList(specialityList);
  }, []);

  const getCountries = React.useCallback(async () => {
    const { data } = await api.getCountries();
    setCountries(data);
  }, []);

  const getUserRoles = async () => {
    if (role.length === 0) {
      const { data } = await api.getUserRoles();
      setRole(data);
    }
  };

  useEffect(() => {
    getSpecialityList();
    getCountries();
    getUserRoles();
  }, []);
  const validationSchema = yup.object().shape({
    first_name: yup.string().required('This field is required'),
    last_name: yup.string().required('This field is required'),
    email: yup.string().required('This field is required'),
  });

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const getUserInfo = React.useCallback(async () => {
    setOpen(true);
    const { data, statusCode } = await api.getUserInfo({
      toCamel: true,
      userId
    });

    if (data.userCountryCode) {
      setPhoneCode(data.userCountryCode.phoneCode);
      setCountryFlag(`flag-icon flag-icon-${data.userCountryCode.iso.toLowerCase()}`);
    }

    if (statusCode === api.status.SUCCESS) {
      const profileImage = {
        ...data.profileImage,
        uri: data.profileImage.hrefSmall
      };

      setUserInfo({ ...data, profileImage });
      setValue('first_name', data.firstName);
      setValue('last_name', data.lastName);
      setValue('email', data.email);
      setCountryCode({
        id: data.userCountryCode?.id,
        phone_code: data?.userCountryCode?.phoneCode,
        nice_name: data?.userCountryCode?.niceName,
        flag: data?.userCountryCode?.flag
      });
      setValue('phone', data.phone ? data?.phone : '');
      setValue('countryCode', data?.userCountryCode?.id);
      setValue('user_role_id', data?.userRole?.id);
      setValue(
        'speciality',
        data.userProfile[0]?.speciality?.id
          ? data.userProfile[0]?.speciality?.id
          : ''
      );
    }

    handleDialogClose();
  }, [setValue, userId]);

  useEffect(() => {
    if (!userInfo.id) {
      getUserInfo();
    }
  }, [getUserInfo, userInfo]);

  let passwordSchema = yup.object().shape({
    old_password: yup.string().required('Old password is required'),
    new_password: yup
      .string()
      .required('New password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase,  Lowercase, One Number and one special case Character'
      )
  });

  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    errors: passwordErrors,
    reset,
    clearErrors
  } = useForm({
    resolver: yupResolver(passwordSchema),

    reValidateMode: 'onSubmit'
  });

  const updateLocalUserInfo = async (userId: any) => {
    const { data, statusCode } = await api.getUserInfo({
      toCamel: true,
      userId
    });
    if (statusCode === api.status.SUCCESS) {
      let parsedUser: any = null;
      const user = localStorage.getItem('user');
      if (user) {
        parsedUser = JSON.parse(unescape(user));
        if (parsedUser) {
          parsedUser['user_profile'] = data.userProfile;
          localStorage.setItem('user', JSON.stringify(parsedUser));
        }
      }
    }
  }

  const onSubmit = async (values: any) => {
    setOpen(true);
    setExistChange(false);
    delete values.password;
    let isValid = true;
    let isUsed = false;

    if (isValid && !isUsed) {
      values.country_code_lookup_id = countryCode?.id || countryCode;

      if (values.speciality) {
        values.speciality_id = values.speciality;
      }

      if (values['phone'] === null || values['phone'] === '') {
        delete values.phone;
      }

      if (
        values['country_code_lookup_id'] === null ||
        values['country_code_lookup_id'].id === undefined ||
        values['countryCode'] === undefined
      ) {
        delete values.country_code_lookup_id;
        delete values.countryCode;
      }

      const { data, status_code: statusCode } = await api.setUserInfo(userId, values);

      if (statusCode === api.status.SUCCESS) {
        updateLocalUserInfo(userId);
        setEditUserInfo(true);
        setEditUserName(true);
        if (image) {
          const {
            data,
            status_code: statusCode
          } = await api.uploadProfileMedia(
            user?.id,
            image,
            api.config.onUploadProgress
          );
          if (statusCode === api.status.SUCCESS) {
            window.location.reload();
          }
        }
        setShowProfileMessage(true);
        setOpenEditProfile(false);
      }

    } else {
      setShowPhoneError(true);
      setTimeout(() => setShowPhoneError(false), 4000);
    }
    handleDialogClose();
  };

  const onPasswordSubmit = async (values: any) => {
    setOpen(true);
    const { status_code: statusCode } = await api.changePassword(
      user?.id,
      values
    );
    clearErrors('old_password');
    clearErrors('new_password');
    if (statusCode === api.status.SUCCESS) {
      setShowMessage(true);
      reset();
      setValues({ password: '', showPassword: false });
      setOpenEditPassword(false);
      setTimeout(() => setShowMessage(false), 4000);
    } else if (statusCode === api.status.USER_CHOSE_OLD_PASSWORD) {
      setShowOldPasswordError(true);
      setTimeout(() => setShowOldPasswordError(false), 4000);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 4000);
    }
    handleDialogClose();
  };
  const handleInputPress = () => {
    clearErrors('new_password');
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange = (prop: any) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setImage(files);
    }
  };

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          className={classes.passIcon}
          color={'primary'}
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {values.showPassword ? (
            <VisibilityOff className={classes.inputPassword} />
          ) : (
            <Visibility className={classes.inputPassword} />
          )}
        </IconButton>
      </InputAdornment>
    )
  };

  const inputPropsOld = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          className={classes.passIcon}
          color={'primary'}
          aria-label="toggle password visibility"
          onClick={() => setShowOldPassword(!showOldPassword)}
          onMouseDown={handleMouseDownPassword}
        >
          {showOldPassword ? (
            <VisibilityOff className={classes.inputPassword} />
          ) : (
            <Visibility className={classes.inputPassword} />
          )}
        </IconButton>
      </InputAdornment>
    )
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Dialog open={open} onClose={handleDialogClose}>
          <CircularProgress className={classes.indicator} disableShrink />
        </Dialog>
        <Snackbar open={showMessage} color="success">
          <Alert onClose={() => setShowMessage(false)} severity="success">
            Password has been changed successfully
          </Alert>
        </Snackbar>
        <Snackbar open={showError} color="error">
          <Alert onClose={() => setShowError(false)} severity="error">
            Old password is not correct!Please try again.
          </Alert>
        </Snackbar>

        <Snackbar open={showPhoneError} color="error">
          <Alert onClose={() => setShowPhoneError(false)} severity="error">
            This phone number is not valid or used!
          </Alert>
        </Snackbar>
        <Snackbar open={showOldPasswordError} color="error">
          <Alert
            onClose={() => setShowOldPasswordError(false)}
            severity="error"
          >
            Your new and old password must be different
          </Alert>
        </Snackbar>

        <Box>
          <Grid container alignItems="center">
            <Grid
              className={classes.profileHeader}
              container
              justifyContent="center"
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Box className={classes.imageBox}>
                {image ? (
                  <img
                    className={classes.profileImage}
                    src={URL.createObjectURL(image[0])}
                    alt="profileImage"
                  />
                ) : userInfo.profileImage.hrefSmall ? (
                  <img
                    className={classes.profileImage}
                    src={userInfo.profileImage.hrefSmall}
                    alt="profileImage"
                  ></img>
                ) : (
                  <img
                    className={classes.profileImage}
                    src={profileImage}
                    alt="profileImage"
                  ></img>
                )}
                <label htmlFor="raised-button-file">
                  <EditIcon
                    className={classes.editIconStyle}
                    color="primary"
                  ></EditIcon>
                </label>
                <input
                  onChange={(e) => handleCapture(e)}
                  id="raised-button-file"
                  type="file"
                  hidden
                  accept="image/*"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onChange={() => setExistChange(true)}
              >
                <Grid className={classes.py0} container spacing={3}>
                  <Grid className={classes.py0} item xs={12} sm={12} md={12}>
                    <Controller
                      as={TextField}
                      variant="outlined"
                      name="first_name"
                      defaultValue=""
                      control={control}
                      label="First name"
                      className={classes.mb0}
                      margin="normal"
                      fullWidth
                      helperText={
                        errors.first_name && errors.first_name.message
                      }
                      error={errors.first_name ? true : false}
                    />
                  </Grid>
                  <Grid className={classes.py0} item xs={12} sm={12} md={12}>
                    <Controller
                      as={TextField}
                      variant="outlined"
                      name="last_name"
                      defaultValue=""
                      control={control}
                      label="Last name"
                      className={classes.mb0}
                      margin="normal"
                      fullWidth
                      helperText={errors.last_name && errors.last_name.message}
                      error={errors.last_name ? true : false}
                    />
                  </Grid>
                  <Grid className={classes.py0} item xs={12} sm={12} md={12}>
                    <ReactHookFormSelect
                      name="user_role_id"
                      label="Role "
                      control={control}
                      defaultValue=""
                      variant="outlined"
                      setIsBlocking={() => { }}
                      className={classes.mb0}
                      margin="normal"
                      error={errors.role ? true : false}
                      helperText={errors.role && errors.role.message}
                    >
                      <MenuItem value=""></MenuItem>
                      {role.length > 0
                        ? role.map((role: any) => (
                          <MenuItem key={role?.id} value={role?.id}>
                            {role.nice_name}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>
                  <Grid className={classes.py0} item xs={12} sm={12} md={12}>
                    <Controller
                      as={TextField}
                      variant="outlined"
                      name="email"
                      defaultValue=""
                      control={control}
                      label="Email"
                      className={classes.mb0}
                      margin="normal"
                      fullWidth
                      helperText={errors.email && errors.email.message}
                      error={errors.email ? true : false}
                    />
                  </Grid>
                  <Grid className={classes.py0} item xs={12} sm={12} md={12}>
                    <ReactHookFormSelect
                      name="speciality"
                      label="Speciality "
                      control={control}
                      defaultValue={''}
                      variant="outlined"
                      setIsBlocking={() => { }}
                      className={classes.mb0}
                      margin="normal"
                      error={errors.speciality ? true : false}
                      helperText={
                        errors.speciality && errors.speciality.message
                      }
                    >
                      {specialityList.length > 0
                        ? specialityList.map((speciality: any) => (
                          <MenuItem
                            key={speciality.value}
                            value={speciality.value}
                          >
                            {speciality.label}
                          </MenuItem>
                        ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>

                  <Grid
                    className={classes.py0}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Controller
                      as={TextField}
                      variant="outlined"
                      name="password"
                      defaultValue="Change your password from here "
                      control={control}
                      label="Password"
                      className={[classes.textField, classes.mb0].join(' ')}
                      margin="normal"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        classes: { input: classes.inputPassword }
                      }}
                      onClick={() => setOpenEditPassword(true)}
                    ></Controller>
                  </Grid>
                </Grid>
                <Button
                  className={classes.profileButton}
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </form>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openEditPassword}
          classes={{ paper: classes.editPassPaper }}
        >
          <Paper className={classes.mb16}>
            <Typography className={classes.changePasHeader} variant="h1">
              Change password
            </Typography>

            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <form onSubmit={passwordHandleSubmit(onPasswordSubmit)}>
                  <TextField
                    InputProps={inputPropsOld}
                    name="old_password"
                    variant="outlined"
                    type={showOldPassword ? 'text' : 'password'}
                    inputRef={passwordRegister}
                    label="Current password"
                    margin="normal"
                    helperText={
                      passwordErrors.old_password &&
                      passwordErrors.old_password.message
                    }
                    fullWidth
                    className={classes.passwordField}
                    error={passwordErrors.old_password ? true : false}
                  />
                  <TextField
                    InputProps={inputProps}
                    name="new_password"
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    inputRef={passwordRegister}
                    label="New Password"
                    margin="normal"
                    fullWidth
                    className={classes.passwordField}
                    helperText={
                      passwordErrors.new_password &&
                      passwordErrors.new_password.message
                    }
                    error={passwordErrors.new_password ? true : false}
                    onClick={handleInputPress}
                  />
                  <Box mb={values.password ? 3 : 5}>
                    {values.password && !passwordErrors.new_password ? (
                      values.password.length < 6 ? (
                        <Box>
                          <Typography
                            variant="h4"
                            color="error"
                            className={globalClasses.weakPassword}
                          >
                            Weak password
                          </Typography>
                        </Box>
                      ) : strongPassword.test(values.password) ? (
                        <Typography
                          variant="h4"
                          className={globalClasses.strongPassword}
                        >
                          Strong password
                        </Typography>
                      ) : (
                        <Box className={globalClasses.normalPassword}>
                          Normal password
                        </Box>
                      )
                    ) : null}
                  </Box>
                  <Box className={classes.buttons}>
                    <Button
                      className={classes.passwordButton}
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpenEditPassword(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.passwordButton}
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Change
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </Dialog>
      </div>
    </>
  );
};

export default EditProfile;
