import { makeStyles, Theme, createStyles } from '@material-ui/core';

const editProfileStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '80%',
      margin: 'auto',

      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    },

    mb16: {
      height: '100%',
      marginTop: 16,
      padding: 15,
      width: '100%'
    },
    imageBox: {
      position: 'relative'
    },
    changePasHeader: {
      textAlign: 'center',
      marginBottom: 16
    },
    profileHeader: {
      display: 'flex',
      position: 'relative'
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    inputPassword: {
      color: '#aaaaaa'
    },
    passIcon: {
      paddingRight: 0
    },
    profileImage: {
      width: 102,
      height: 102,
      border: 'solid 0.7px #cccccc',
      borderRadius: '100%',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: 8
      }
    },
    passwordButton: {
      marginRight: 8,

      display: 'flex',
      marginLeft: 'auto',
      width: '50%'
    },
    profileButton: {
      marginRight: 8,
      marginBottom: 26,
      marginTop: 26,
      display: 'flex',
      width: '100%'
    },
    passwordField: {
      width: '100%',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    phoneBox: {
      alignSelf: 'center',
      marginBottom: -8,
      paddingTop: '0px !important',
      paddingBottom: '0px !important'
    },
    mt22: {
      marginTop: -22
    },
    textField: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1)
    },
    editIconStyle: {
      alignSelf: 'flex-end',
      cursor: 'pointer',
      position: 'absolute',
      left: 81,
      bottom: 10,
      borderRadius: '50%',
      width: 24,
      height: 24,
      border: '1px solid #aaaaaa',
      backgroundColor: '#ffffff',
      padding: 3
    },
    indicator: {
      margin: 15
    },
    py0: {
      paddingTop: '0px !important',
      paddingBottom: '0px !important'
    },
    mb0: {
      marginBottom: 0
    },
    editPassPaper: {
      width: 343
    },
    countryName: {
      marginLeft: 8
    },
    mt13: {
      marginTop: 12
    },
  })
);

export default editProfileStyle;
