import React, { useEffect, useRef, useState } from 'react';
import { getUser } from '../../../utils';
import {
  Paper,
  Typography,
  Box,
  Divider,
  Grid,
  Tabs,
  Tab,
  MenuItem,
  Button,
  Menu,
  Dialog,
  CircularProgress,
  DialogTitle,
  Checkbox,
  Snackbar,
  useMediaQuery,
  Popover,
  InputLabel,
  Select,
  FormControl,
  useTheme
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/Receipt'
import Alert from '@material-ui/lab/Alert';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import withWidth, {
  isWidthDown,
  isWidthUp,
  WithWidth
} from '@material-ui/core/withWidth';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import * as api from '../../../services/apiService';
import history from '../../../utils/history';
import Free from '../../../assets/PromotionalSite/Plans/FDark.svg';
import Moderate from '../../../assets/PromotionalSite/Plans/MDark.svg';
import Busy from '../../../assets/PromotionalSite/Plans/BDark.svg';
import Unlimited from '../../../assets/PromotionalSite/Plans/UDark.svg';
import profileImage from '../../../assets/avatar.png';
import profileStyle from './Styles';
import TabPanel from '../../../components/TabPanel/TabPanel';
import EducationForm from '../../../components/EducationForm/EducationForm';
import PositionForm from '../../../components/PositionForm/PositionForm';
import { EducationType, PositionType, userInfoType } from '../../../types';
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog';
import AddIcon from '@material-ui/icons/Add';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import DescriptionIcon from '@material-ui/icons/Description';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import ClearIcon from '@material-ui/icons/Clear';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import EditProfile from '../edit-profile';
import ConfirmCloseDialog from '../../../components/Dialogs/ConfirmCloseDialog';
import fi from 'date-fns/esm/locale/fi/index.js';
import { useLongPress } from 'use-long-press';
import emptyEducation from '../../../assets/emptyEducation.png';
import emptyPosition from '../../../assets/emptyPosition.png';
import emptyExportedFile from '../../../assets/emptyExportedFile.png';
import emptyEducationDark from '../../../assets/dark/educationDark.png';
import emptyPositionDark from '../../../assets/dark/positionDark.png';
import emptyExportedFileDark from '../../../assets/dark/ExportedDark.png';
import PopupState from 'material-ui-popup-state';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ImagePlaceholder from '../../../assets/ImagePlaceholder.svg';
import DoneIcon from '@material-ui/icons/Done';
import Dashboardempty from '../../../assets/Dashboardempty.svg';
import DashboardemptyDark from '../../../assets/dark/Dashboardempty.svg';
import ReimbursementForm from '../../../components/ReimbursementForm/ReimbursementForm';
import { useLocation } from 'react-router-dom';
export const months: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const Profile = (props: any) => {
  const location: any = useLocation();
  const panelIndex = location.state ? location.state.panelIndex : 0;
  const classes = profileStyle();
  const user = getUser();
  const theme = useTheme();
  const userId = getUser() ? getUser().id : null;
  const isDarkTheme = useTheme().palette.type === 'dark';
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isBottom, setIsBottom] = React.useState<boolean>(true);
  const [onRefresh, setOnRefresh] = React.useState<boolean>(false);
  const [isMouseHover, setIsMouseHover] = React.useState<boolean[]>([false]);
  const [selectedReports, setSelectedReports] = React.useState<any[]>([]);
  const [selectedReport, setSelectedReport] = React.useState<any>();
  const [filterReports, setFilteredReports] = React.useState<any[]>([]);
  const [openEditProfile, setOpenEditProfile] = React.useState(false);
  const [isBlockingEducation, setIsBlockingEducation] = React.useState(false);
  const [isBlockingPosition, setIsBlockingPosition] = React.useState(false);
  const [isBlocking, setIsBlocking] = React.useState(false);
  const [isOpenConfirmClose, setIsOpenConfirmClose] = React.useState(false);
  const [isOpenConfirmReimbursementClose, setIsOpenConfirmReimbursementClose] = React.useState(false);
  const [
    isOpenConfirmClosePosition,
    setIsOpenConfirmClosePosition
  ] = React.useState(false);
  const [optionForView, setOptionForView] = React.useState(
    props.viewAfterhandled
  );
  const [action, setAction] = React.useState('');
  const [showProfileMessage, setShowProfileMessage] = React.useState<boolean>(
    false
  );
  const [profileMessage, setProfileMessage] = React.useState<string[]>([]);
  const [
    showProfileFormMessage,
    setShowProfileFormMessage
  ] = React.useState<boolean>(false);
  const [isOpenConfirmeditClose, setIsOpenConfirmeditClose] = React.useState(
    false
  );
  const [showMultiSelectTab, setShowMultiSelectTab] = React.useState<boolean>(
    false
  );
  const [openCOllapse, setOpenCOllapse] = React.useState<boolean>(false);
  const [existChange, setExistChange] = React.useState<boolean>(false);
  const [editUserInfo, setEditUserInfo] = React.useState<boolean>(false);
  const [viewPositionForm, setViewPositionForm] = React.useState<boolean>(
    false
  );
  const [viewEducationForm, setViewEducationForm] = React.useState<boolean>(
    false
  );
  const [viewReimbursementForm, setViewReimbursementForm] = React.useState<boolean>(false);
  const [editedPosition, setEditedPosition] = React.useState<any>();
  const [editedEducation, setEditedEducation] = React.useState<any>();
  const [editedReimbursement, setEditedReimbursement] = React.useState<any>();
  const [showEndDate, setShowEndDate] = React.useState<boolean>(true);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [specialityList, setSpecialityList] = React.useState<any[]>([]);
  const [degreeList, setDegreeList] = React.useState<any[]>([]);
  const [links, setLinks] = React.useState<any[]>([]);
  const [users, setUsers] = React.useState<any[]>([]);
  const [value, setTabValue] = React.useState<number>(
    props.openDashboardTab ? 4 : panelIndex
  );
  const [userInfo, setUserInfo] = React.useState<userInfoType>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    userRole: {
      id: 0,
      niceName: ''
    },
    reports: [],
    profileImage: {
      hrefSmall: '',
      hrefOriginal: ''
    },
    userProfile: {
      speciality: {
        name: ''
      }
    },
    userExperiences: [],
    userEducations: [],
    currentPositions: [],
    id: 0,
    userCountryCode: {
      phoneCode: 0
    },
  });
  const [reports, setReports] = React.useState<any[]>([]);
  const [currentPlan, setCurrentPlan] = React.useState<any>();
  const [page, setPage] = React.useState<number>(1);
  const pageLimit: number = 25;
  const [endReports, setEndReports] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>(props.viewAfterhandled);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  React.useEffect(() => {
    setSelectedReports(reports);
  }, [reports]);

  React.useEffect(() => {
    if (editUserInfo) {
      getUserInfo();
    }
  }, [editUserInfo]);

  React.useEffect(() => {
    filterCheckedReports(selectedReports);
  }, [selectedReports]);
  React.useEffect(() => {
    if (filterReports.length === 0) {
      setIsMouseHover([false]);
    } else if (filterReports.length > 0) {
      let newIsMouseHover = new Array(reports.length).fill(true);
      setIsMouseHover(newIsMouseHover);
    }
  }, [filterReports]);

  function handleScroll() {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true);
    }
  }

  function downloadAll(urls) {
    for (let i = 0; i < urls.length; i++) {
      let link = document.createElement('a');
      link.setAttribute('id', 'link' + i);
      link.setAttribute('download', '');
      link.style.display = 'none';

      document.body.appendChild(link);

      link.setAttribute('href', urls[i]);
      setTimeout(() => {
        link.click();
      }, i * 1000);

      document.body.removeChild(link);
    }
  }

  const getSeverity = (color: any) => {
    return color;
  };

  const updateCases = React.useCallback(
    async (newReports: any) => {
      if (page === 1) {
        setReports(addCheckAtt(newReports));
      } else {
        setReports(addCheckAtt(reports.concat(newReports)));
      }
    },
    [page, reports]
  );
  const addCheckAtt = (reports) => {
    let newReports = reports.map((obj) => ({ ...obj, isChecked: false }));
    return newReports;
  };
  const getSpecialityList = React.useCallback(async () => {
    const { data } = await api.getSpecialites();
    const specialityList = data.map((category) => {
      return {
        label: category.name,
        value: category.id + ''
      };
    });
    setSpecialityList(specialityList);
  }, []);
  const removeSelect = () => {
    let newCases = reports.map((obj) => ({ ...obj, isChecked: false }));
    setSelectedReports(newCases);
  };
  const filterCheckedReports = (reports) => {
    let filterReports: any[] = [];
    let newLinks: any[] = [];
    for (let i = 0; i < reports.length; i++) {
      if (reports[i].isChecked === true) {
        filterReports.push(reports[i]);

        newLinks.push(reports[i].path);
        setLinks(newLinks);
      }
    }

    setFilteredReports(filterReports);
    filterReports.length > 0
      ? setShowMultiSelectTab(true)
      : setShowMultiSelectTab(false);
  };
  const getReports = React.useCallback(async () => {
    if (!endReports || page === 1) {
      const { data, status_code: statusCode } = await api.getReports(
        user.id,
        page,
        pageLimit
      );
      if (statusCode === api.status.SUCCESS) {
        setPage(page + 1);
        if (data < pageLimit) {
          setEndReports(true);
        }
        data.sort((a, b) => b.id - a.id);
        updateCases(data);
      }
      setIsBottom(false);
    }
  }, [endReports, page, updateCases, user.id]);

  useEffect(() => {
    let mounted = true;
    if (isBottom && mounted) {
      getReports();
    }
    return () => {
      mounted = false;
    };
  }, [isBottom]);

  useEffect(() => {
    let mounted = true;
    if (specialityList.length === 0 && mounted) {
      getSpecialityList();
    }
    return () => {
      mounted = false;
    };
  }, [getSpecialityList]);

  useEffect(() => {
    let mounted = true;
    if (!viewPositionForm && mounted) {
      setStartDate(new Date());
      setEditedPosition(undefined);
    }
    return () => {
      mounted = false;
    };
  }, [viewPositionForm]);

  useEffect(() => {
    let mounted = true;
    if (!viewEducationForm && mounted) {
      setEndDate(new Date());

      setEditedEducation(undefined);
    }
    return () => {
      mounted = false;
    };
  }, [viewEducationForm]);
  const getCurrentPlan = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getCurrentPlan();
    if (statusCode === api.status.SUCCESS) {
      setCurrentPlan(data);
    }
  }, []);

  useEffect(() => {
    getCurrentPlan();
  }, []);

  const getUserInfo = React.useCallback(async () => {
    setOpen(true);
    const { data, statusCode } = await api.getUserInfo({
      toCamel: true,
      userId
    });

    if (statusCode === api.status.SUCCESS) {
      const profileImage = {
        ...data.profileImage,
        uri: data.profileImage.hrefSmall
      };
      const userProfile = {
        ...data.userProfile[0]
      };
      setUserInfo({ ...data, profileImage, userProfile });
    }
    handleDialogClose();
  }, []);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const getDegreeList = React.useCallback(async () => {
    const { data } = await api.getDegrees();
    const newDegreeList = data.map((degree) => {
      return {
        label: degree.name,
        value: degree.id + ''
      };
    });
    setDegreeList(newDegreeList);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (!userInfo.id && mounted) {
      getUserInfo();
    } else {
      if (onRefresh) {
        getUserInfo();
        setOnRefresh(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [userInfo, onRefresh]);

  useEffect(() => {
    let mounted = true;
    if (mounted && degreeList.length === 0) {
      getDegreeList();
    }
    return () => {
      mounted = false;
    };
  }, [degreeList]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    props.setOpenDashboardTab(false);
    setViewPositionForm(false);
    setViewEducationForm(false);
    setTabValue(props.openDashboardTab ? 4 : newValue);
    setShowEndDate(true);
    setShowMultiSelectTab(false);
    removeSelect();
    setIsMouseHover([false]);
  };

  const filterChange = (filter: any) => {
    setFilter(filter);
  };

  const handleChangeView = (value) => {
    setOptionForView(value);
    if (value === 'MY_TEAMS') {
      filterChange('MY_TEAMS');
    } else if (value === 'INVITATIONS') {
      filterChange('INVITATIONS');
    }
  };

  const handleClickOpen = () => {
    setOpenCOllapse(!openCOllapse);
  };

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    setIsBlockingPosition(true);
    setIsBlocking(true);
    setIsBlockingEducation(true);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    setIsBlocking(true);
    setIsBlockingEducation(true);
    setIsBlockingPosition(true);
  };

  const onPositionEdit = (position: any) => {
    setViewPositionForm(true);
    setEditedPosition(position);
  };

  const onEducationEdit = (education: any) => {
    setViewEducationForm(true);
    setEditedEducation(education);
  };

  const onReimbursementEdit = (reimbursement: any) => {
    setViewReimbursementForm(true);
    setEditedReimbursement(reimbursement);
  }

  const bind = useLongPress(() => {
    let newSelectedCases = selectedReports.map((element) =>
      element.id === selectedReport.id
        ? { ...element, isChecked: !selectedReport.isChecked }
        : element
    );

    setSelectedReports(newSelectedCases);
  });

  const getDoctors = React.useCallback(async (userId, filter) => {
    const { data, status_code: statusCode } = await api.getDoctors(
      userId,
      filter
    );
    if (statusCode === api.status.SUCCESS) {
      setUsers(data.doctors);
    } else {
    }
  }, []);

  const handleInvitation = React.useCallback(
    async (userId, payload, status) => {
      const { data, status_code: statusCode } = await api.handleInvitation(
        userId,
        payload
      );
      if (statusCode === api.status.SUCCESS) {
        if (payload.status === 'ACCEPTED') {
          getDoctors(user?.id, 'ACCEPTED');
        } else if (payload.status === 'DECLINED') {
          getDoctors(user?.id, 'DECLINED');
        }
        setShowProfileFormMessage(true);

        if (payload.status === 'ACCEPTED') {
          setProfileMessage(['success', 'Accepted successfully']);
        } else if (payload.status === 'DECLINED') {
          if (status !== '') {
            setProfileMessage(['success', 'left successfully']);
          } else {
            setProfileMessage(['success', 'Rejected successfully']);
          }
        }
        setTimeout(() => {
          setShowProfileFormMessage(false);
          props.setOpenDashboardTab(true);
          props.setIsHandledInvitation(true);
          if (status !== '') {
            props.setViewAfterhandled('MY_TEAMS');
          } else {
            props.setViewAfterhandled('INVITATIONS');
          }
        }, 2000);
      } else {
      }
    },
    []
  );

  useEffect(() => {
    getDoctors(user?.id, filter);
  }, [filter, props.openDashboardTab]);

  const resetReimbursement = () => {
    const updatedUserInfo = { ...userInfo };
    delete updatedUserInfo.reimbursement;
    setUserInfo(updatedUserInfo);
  }

  const toTitleCase = (value) => {
    return value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()
  }

  const toCustomTitleCase = (value) => {
    switch(value){
      case "SALARY_BASED":
        return "Salary Based";
      case "RVUS_BASED":
        return "RVUs Based";
      default:
        return toTitleCase(value);
    }
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        classes={{ paper: classes.loading }}
      >
        <CircularProgress className={classes.indicator} disableShrink />
      </Dialog>
      <Paper className={classes.mb16}>
        <img
          src={
            currentPlan?.plan?.name === 'Unlimited'
              ? Unlimited
              : currentPlan?.plan?.name === 'Moderate'
                ? Moderate
                : currentPlan?.plan?.name === 'Busy'
                  ? Busy
                  : currentPlan?.plan?.name === 'Free'
                    ? Free
                    : Free
          }
          alt="planName"
          className={classes.planIcon}
        />
        <Box className={classes.infoBox}>
          <Grid container alignItems="center" item={true}>
            {isWidthUp('sm', props.width) ? null : (
              <Grid
                className={classes.editIcon}
                item={true}
                xs={12}
                sm={3}
                md={3}
              >
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button
                        {...bindTrigger(popupState)}
                        className={classes.popup}
                      >
                        <MoreVertIcon onClick={() => { }}></MoreVertIcon>
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            history.push('/MyPlan');
                            popupState.close();
                          }}
                        >
                          <Typography variant="h6" className={classes.popItem}>
                            <CardMembershipIcon
                              className={classes.popupstateIcon}
                            />
                            Current plan details
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenEditProfile(true);
                            popupState.close();
                          }}
                        >
                          {' '}
                          <Typography variant="h6" className={classes.popItem}>
                            <EditIcon className={classes.popupstateIcon} />
                            Edit personal info
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Grid>
            )}
            <Grid item={true} xs={12} sm={9} md={9} lg={9}>
              <Box className={classes.profileInfo}>
                {userInfo.profileImage.hrefOriginal ? (
                  <img
                    className={classes.profileImage}
                    src={userInfo.profileImage.hrefOriginal}
                    alt={'profileImage'}
                  ></img>
                ) : (
                  <img
                    className={classes.profileImage}
                    src={profileImage}
                    alt={'profileImage'}
                  ></img>
                )}
                <Box>
                  <Typography className={[classes.mb8].join(' ')} variant="h3">
                    {userInfo.firstName ? userInfo.firstName : 'N/A'}{' '}
                    {userInfo.lastName}
                    {userInfo?.userRole
                      ? ` (${userInfo?.userRole?.niceName})`
                      : ''}
                  </Typography>

                  <Typography className={classes.mb8} variant="h5">
                    {userInfo.userProfile.speciality
                      ? userInfo.userProfile.speciality.name
                      : 'N/A'}
                  </Typography>

                  {/* <Typography
                    className={[
                      classes.mrAuto,
                      classes.profilePhone,
                      classes.mb8
                    ].join(' ')}
                    variant="h5"
                  >
                    {userInfo.phone
                      ? '+' +
                        userInfo.userCountryCode.phoneCode +
                        userInfo.phone
                      : 'N/A'}
                  </Typography> */}
                  <Typography
                    className={[classes.mrAuto, classes.profilePhone].join(' ')}
                    variant="h5"
                  >
                    {userInfo.email ? userInfo.email : 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {isWidthUp('sm', props.width) ? (
              <Grid
                className={classes.editIcon}
                item={true}
                xs={12}
                sm={3}
                md={3}
                lg={3}
              >
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button
                        {...bindTrigger(popupState)}
                        className={classes.popup}
                      >
                        <MoreVertIcon onClick={() => { }}></MoreVertIcon>
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            history.push('/MyPlan');
                            popupState.close();
                          }}
                        >
                          <Typography variant="h6" className={classes.popItem}>
                            <CardMembershipIcon
                              className={classes.popupstateIcon}
                            />
                            Current plan details
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenEditProfile(true);
                            popupState.close();
                          }}
                        >
                          <Typography variant="h6" className={classes.popItem}>
                            <EditIcon className={classes.popupstateIcon} />
                            Edit personal info
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Paper>
      <Paper square>
        <Tabs
          variant="scrollable"
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          className={value !== 4 ? classes.tab : ''}
        >
          <Tab
            className={classes.tabStyle}
            label={
              <div>
                <BusinessCenterIcon className={classes.businessIcon} />{' '}
                {isWidthUp('lg', props.width) ? 'Current Position' : null}
              </div>
            }
          />
          <Tab
            className={classes.tabStyle}
            label={
              <div>
                <FileCopyIcon className={classes.businessIcon} />{' '}
                {isWidthUp('lg', props.width) ? ' Experience' : null}{' '}
              </div>
            }
          />
          <Tab
            className={classes.tabStyle}
            label={
              <div>
                <MenuBookIcon className={classes.businessIcon} />
                {isWidthUp('lg', props.width) ? ' Education' : null}
              </div>
            }
          />
          <Tab
            className={classes.tabStyle}
            label={
              <div>
                <ReceiptIcon className={classes.businessIcon} />
                {isWidthUp('lg', props.width) ? ' Reimbursement' : null}
              </div>
            }
          />
          <Tab
            className={classes.tabStyle}
            label={
              <div>
                <DescriptionIcon className={classes.businessIcon} />
                {isWidthUp('lg', props.width) ? ' Exported Procedures ' : null}
              </div>
            }
          />
          <Tab
            className={classes.tabStyle}
            label={
              <div>
                <DashboardIcon className={classes.businessIcon} />
                {isWidthUp('lg', props.width) ? ' Dashboards ' : null}
              </div>
            }
          />
        </Tabs>

        {viewPositionForm && value === 0 ? (
          <Dialog
            onClose={() => setViewPositionForm(false)}
            aria-labelledby="simple-dialog-title"
            open={viewPositionForm}
            classes={
              editedPosition
                ? { paper: classes.editPositionDialog }
                : { paper: classes.positionDialog }
            }
          >
            <Box className={classes.formTitle}>
              <Typography variant="subtitle1">
                {editedPosition
                  ? 'Edit current position'
                  : 'Add Current Position'}
              </Typography>
              <ClearIcon
                className={classes.clearIconDialog}
                onClick={() => {
                  if (isBlockingPosition) {
                    setIsOpenConfirmClosePosition(true);
                  } else {
                    setViewPositionForm(false);
                    setIsOpenConfirmClosePosition(false);
                  }
                }}
              />
            </Box>

            <PositionForm
              title="position"
              handleEndDateChange={handleEndDateChange}
              handleStartDateChange={handleStartDateChange}
              showEndDate={showEndDate}
              value={value}
              setIsBlocking={setIsBlockingPosition}
              setOnRefresh={setOnRefresh}
              editedPosition={editedPosition}
              setShowProfileFormMessage={setShowProfileFormMessage}
              setProfileMessage={setProfileMessage}
              specialityList={specialityList}
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setShowEndDate={setShowEndDate}
              setViewPositionForm={setViewPositionForm}
              endDate={endDate}
            ></PositionForm>
          </Dialog>
        ) : viewPositionForm && value === 1 ? (
          <Dialog
            onClose={() => setViewPositionForm(false)}
            aria-labelledby="simple-dialog-title"
            open={viewPositionForm}
            classes={{ paper: classes.editPositionDialog }}
          >
            <Box className={classes.formTitle}>
              <Typography variant="subtitle1">
                {editedPosition ? 'Edit experience' : 'Add experience'}
              </Typography>
              <ClearIcon
                className={classes.clearIconDialog}
                onClick={() => {
                  if (isBlocking) {
                    setIsOpenConfirmClosePosition(true);
                  } else {
                    setViewPositionForm(false);
                    setIsOpenConfirmClose(false);
                  }
                }}
              />
            </Box>
            <PositionForm
              title="Experience"
              handleEndDateChange={handleEndDateChange}
              handleStartDateChange={handleStartDateChange}
              showEndDate={showEndDate}
              value={value}
              setIsBlocking={setIsBlocking}
              setOnRefresh={setOnRefresh}
              setShowProfileFormMessage={setShowProfileFormMessage}
              setProfileMessage={setProfileMessage}
              editedPosition={editedPosition}
              specialityList={specialityList}
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setShowEndDate={setShowEndDate}
              setViewPositionForm={setViewPositionForm}
              endDate={endDate}
            ></PositionForm>
          </Dialog>
        ) : null}

        {viewEducationForm ? (
          <Dialog
            onClose={() => setViewEducationForm(false)}
            aria-labelledby="simple-dialog-title"
            open={viewEducationForm}
            classes={{ paper: classes.educationForm }}
          >
            <Box className={classes.formTitle}>
              <Typography variant="subtitle1">
                {editedEducation ? 'Edit education' : 'Add education'}
              </Typography>
              <ClearIcon
                className={classes.clearIconDialog}
                onClick={() => {
                  if (isBlockingEducation) {
                    setIsOpenConfirmClose(true);
                  } else {
                    setViewEducationForm(false);
                    setIsOpenConfirmClose(false);
                  }
                }}
              />
            </Box>
            <EducationForm
              handleEndDateChange={handleEndDateChange}
              handleStartDateChange={handleStartDateChange}
              showEndDate={showEndDate}
              value={value}
              setIsBlocking={setIsBlockingEducation}
              setOnRefresh={setOnRefresh}
              editedEducation={editedEducation}
              degreeList={degreeList}
              specialityList={specialityList}
              setShowProfileFormMessage={setShowProfileFormMessage}
              setProfileMessage={setProfileMessage}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              setShowEndDate={setShowEndDate}
              setViewEducationForm={setViewEducationForm}
              endDate={endDate}
            ></EducationForm>
          </Dialog>
        ) : null}

        {viewReimbursementForm ? (
          <Dialog
            onClose={() => setViewReimbursementForm(false)}
            aria-labelledby="simple-dialog-title"
            open={viewReimbursementForm}
            classes={{ paper: classes.educationForm }}
          >
            <Box className={classes.formTitle}>
              <Typography variant="subtitle1">
                {editedReimbursement ? 'Edit Reimbursement' : 'Add Reimbursement'}
              </Typography>
              <ClearIcon
                className={classes.clearIconDialog}
                onClick={() => {
                  setIsOpenConfirmReimbursementClose(true);
                }}
              />
            </Box>
            <ReimbursementForm
              userId={userId}
              editedReimbursement={editedReimbursement}
              setEditedReimbursement={setEditedReimbursement}
              reimbursement={userInfo.reimbursement}
              resetReimbursement={resetReimbursement}
              setViewReimbursementForm={setViewReimbursementForm}
              setOnRefresh={setOnRefresh}
              setProfileMessage={setProfileMessage}
              setShowProfileFormMessage={setShowProfileFormMessage}
            />
          </Dialog>
        ) : null}

        <TabPanel value={value} index={0}>
          {value === 0 ? (
            <>
              {isWidthUp('md', props.width) ? (
                <Box
                  pt={viewPositionForm ? 0 : 0.5}
                  pl={3}
                  className={classes.addPositionHeader}
                  mb={2}
                >
                  <Typography variant="h2">Current position</Typography>
                  <Button
                    onClick={() => {
                      setShowEndDate(false);
                      setViewPositionForm(true);
                    }}
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    className={classes.addButton}
                  >
                    Add Position
                  </Button>
                </Box>
              ) : (
                <Box
                  mb={2}
                  pt={viewPositionForm ? 0 : 3}
                  className={classes.formHeader}
                >
                  <Typography variant="h2">Current Position</Typography>
                  <AddIcon
                    onClick={() => {
                      setShowEndDate(false);
                      setViewPositionForm(true);
                    }}
                  />
                </Box>
              )}
            </>
          ) : null}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {value === 1 ? (
            <>
              {isWidthUp('md', props.width) ? (
                <Box
                  pt={viewPositionForm ? 0 : 0.5}
                  pl={3}
                  mb={2}
                  className={classes.addPositionHeader}
                >
                  <Typography variant="h2">Experience</Typography>
                  <Button
                    onClick={() => setViewPositionForm(true)}
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    className={classes.addButton}
                  >
                    Add Experience
                  </Button>
                </Box>
              ) : (
                <Box
                  mb={2}
                  pt={viewPositionForm ? 0 : 0.5}
                  className={classes.formHeader}
                >
                  <Typography variant="h2">Experience</Typography>
                  <AddIcon onClick={() => setViewPositionForm(true)} />
                </Box>
              )}
            </>
          ) : null}
        </TabPanel>

        <TabPanel value={value} index={2}>
          {value === 2 ? (
            <>
              {isWidthUp('md', props.width) ? (
                <Box
                  pt={viewEducationForm ? 0 : 0.5}
                  pl={3}
                  mb={2}
                  className={classes.addPositionHeader}
                >
                  <Typography variant="h2">Education</Typography>
                  <Button
                    onClick={() => {
                      setViewEducationForm(true);
                      setShowEndDate(false);
                    }}
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    className={classes.addButton}
                  >
                    Add Education
                  </Button>
                </Box>
              ) : (
                <Box
                  mb={2}
                  pt={viewPositionForm ? 0 : 0.5}
                  className={classes.formHeader}
                >
                  <Typography variant="h2">Education</Typography>
                  <AddIcon onClick={() => setViewEducationForm(true)} />
                </Box>
              )}
            </>
          ) : null}
        </TabPanel>

        <TabPanel value={value} index={3}>
          {value === 3 ? (
            <>
              {isWidthUp('md', props.width) ? (
                <Box
                  pt={viewEducationForm ? 0 : 0.5}
                  pl={3}
                  mb={2}
                  className={classes.addPositionHeader}
                >
                  <Typography variant="h2" className={userInfo.reimbursement ? classes.reimbursementTitle : classes.none}>Reimbursement</Typography>
                  {!userInfo.reimbursement ? (
                    <Button
                      onClick={() => {
                        setViewReimbursementForm(true);
                        setShowEndDate(false);
                      }}
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      className={classes.addButton}
                    >
                      Add Reimbursement
                    </Button>) : null}
                </Box>
              ) : (
                <Box
                  mb={2}
                  pt={viewPositionForm ? 0 : 0.5}
                  className={classes.formHeader}
                >
                  <Typography variant="h2">Reimbursement</Typography>
                  {!userInfo.reimbursement ? (
                    <AddIcon onClick={() => setViewReimbursementForm(true)} />) : null}
                </Box>
              )}
            </>
          ) : null}
        </TabPanel>

        <TabPanel value={value} index={4}>
          <Box
            mb={2}
            className={
              !showMultiSelectTab
                ? [classes.border, classes.exportedTitle].join(' ')
                : [classes.border, classes.exportedTitleWithSelect].join(' ')
            }
          >
            <Typography
              variant="h2"
              display="inline"
              className={
                showMultiSelectTab ? classes.tabValueP : classes.tabValue
              }
            >
              {' '}
              Exported procedures
            </Typography>

            {isWidthUp('sm', props.width) && showMultiSelectTab ? (
              <span className={classes.multiselectWeb}>
                <ClearIcon
                  className={classes.clearIcon}
                  onClick={() => {
                    setShowMultiSelectTab(false);
                    removeSelect();
                    setIsMouseHover([false]);
                  }}
                />

                <Button
                  color="primary"
                  onClick={() => {
                    let selectedAllReports;
                    filterReports.length === reports.length
                      ? (selectedAllReports = selectedReports.map((obj) => ({
                        ...obj,
                        isChecked: false
                      })))
                      : (selectedAllReports = reports.map((obj) => ({
                        ...obj,
                        isChecked: true
                      })));

                    setSelectedReports(selectedAllReports);
                    setFilteredReports(selectedAllReports);
                  }}
                >
                  {filterReports.length === selectedReports.length ? (
                    <CheckCircleIcon className={classes.icon} />
                  ) : (
                    <CircleUnchecked className={classes.icon} />
                  )}
                  Select all
                </Button>
                <Button onClick={() => downloadAll(links)} color="primary">
                  <SystemUpdateAltIcon className={classes.icon} />
                  Download
                </Button>
              </span>
            ) : null}
          </Box>
        </TabPanel>

      </Paper>
      {isWidthDown('md', props.width) && showMultiSelectTab ? (
        <Paper className={classes.multiSelect}>
          <Button color="primary">
            <ClearIcon
              className={classes.clearIcon}
              onClick={() => {
                setShowMultiSelectTab(false);
                removeSelect();
                setIsMouseHover([false]);
              }}
            />
          </Button>

          <Button
            color="primary"
            onClick={() => {
              let selectedAllReports;
              filterReports.length === reports.length
                ? (selectedAllReports = selectedReports.map((obj) => ({
                  ...obj,
                  isChecked: false
                })))
                : (selectedAllReports = reports.map((obj) => ({
                  ...obj,
                  isChecked: true
                })));

              setSelectedReports(selectedAllReports);
            }}
          >
            {filterReports.length === selectedReports.length ? (
              <CheckCircleIcon className={classes.icon} />
            ) : (
              <CircleUnchecked className={classes.icon} />
            )}
            Select all
          </Button>
          <Button onClick={() => downloadAll(links)} color="primary">
            <SystemUpdateAltIcon className={classes.icon} />
            Download
          </Button>
        </Paper>
      ) : null}

      <TabPanel value={value} index={0}>
        {userInfo.currentPositions && userInfo.currentPositions.length > 0 ? (
          userInfo.currentPositions.map((position: any, index) => (
            <Paper
              key={position.id}
              className={classes.panel}
              onClick={() => {
                setShowEndDate(false);
                onPositionEdit(position);
              }}
            >
              <Box className={classes.buttons}>
                <Typography
                  color="primary"
                  className={classes.mb5}
                  variant="h2"
                >
                  {position.speciality ? position.speciality.name : ''}
                </Typography>
              </Box>
              <Typography className={classes.mb5} variant="h3">
                {position.hospital.name}
              </Typography>
              <Box className={classes.displayFlex}>
                <Typography variant="h5" className={classes.dateInfo}>
                  {position.startDate}
                </Typography>
                <Typography variant="h5" className={classes.dateInfo}>
                  {' - '}
                </Typography>
                <Typography variant="h5" className={classes.dateInfo}>
                  {position.endDate || 'Currently'}
                </Typography>
              </Box>
            </Paper>
          ))
        ) : (
          <Box className={classes.emptyView} mx="auto">
            <Box>
              <img
                color="primary"
                src={!isDarkTheme ? emptyPosition : emptyPositionDark}
                alt="no data"
              ></img>
            </Box>
            <Typography variant="caption" className={classes.emptyText}>
              Start now by adding your current position!
            </Typography>
          </Box>
        )}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {userInfo.userExperiences && userInfo.userExperiences.length > 0 ? (
          userInfo.userExperiences.map((experience: any, index) => (
            <Paper
              key={experience.id}
              className={classes.panel}
              onClick={() => {
                setShowEndDate(false);
                onPositionEdit(experience);
              }}
            >
              <Box className={classes.buttons}>
                <Typography
                  color="primary"
                  className={classes.mb5}
                  variant="h2"
                >
                  {experience.speciality.name}
                </Typography>
              </Box>
              <Typography className={classes.mb5} variant="h3">
                {experience.hospital.name}
              </Typography>
              <Box className={classes.displayFlex}>
                <Typography variant="h5" className={classes.dateInfo}>
                  {experience.startDate}
                </Typography>
                <Typography variant="h5" className={classes.dateInfo}>
                  {' - '}
                </Typography>
                <Typography variant="h5" className={classes.dateInfo}>
                  {experience.endDate || 'Currently'}
                </Typography>
              </Box>
            </Paper>
          ))
        ) : (
          <Box className={classes.emptyView} mx="auto">
            <Box>
              <img
                src={!isDarkTheme ? emptyPosition : emptyPositionDark}
                alt="no data"
              ></img>
            </Box>
            <Typography variant="caption" className={classes.emptyText}>
              Start now by adding your Experience!
            </Typography>
          </Box>
        )}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {userInfo.userEducations && userInfo.userEducations.length > 0 ? (
          userInfo.userEducations.map((education: any, index) => (
            <Paper
              key={education.id}
              className={classes.panel}
              onClick={() => {
                setShowEndDate(false);
                onEducationEdit(education);
              }}
            >
              <Box className={classes.buttons}>
                <Typography
                  color="primary"
                  className={classes.mb5}
                  variant="h2"
                >
                  {education.institutionName}
                </Typography>
              </Box>
              <Typography className={classes.mb5} variant="h3">
                {education.degree.name}
              </Typography>
              <Box className={classes.displayFlex}>
                <Typography variant="h5" className={classes.dateInfo}>
                  {education.startDate}
                </Typography>
                <Typography variant="h5" className={classes.dateInfo}>
                  {' - '}
                </Typography>
                <Typography variant="h5" className={classes.dateInfo}>
                  {education.endDate || 'Currently'}
                </Typography>
              </Box>
            </Paper>
          ))
        ) : (
          <Box className={classes.emptyView} mx="auto">
            <Box>
              <img
                src={!isDarkTheme ? emptyEducation : emptyEducationDark}
                alt="Empty Education"
              ></img>
            </Box>
            <Typography variant="caption" className={classes.emptyText}>
              Start by adding your education!
            </Typography>
          </Box>
        )}
      </TabPanel>

      <TabPanel value={value} index={3}>
        {userInfo.reimbursement ? (
          <Paper
            className={[classes.reimbursementPaper, classes.panel].join(' ')}
            onClick={() => {
              onReimbursementEdit(userInfo.reimbursement);
            }}>
            <Box className={classes.paperRow}>
              <Typography variant="h3">
                Practice State:
              </Typography>
              <Typography variant="h5">
                &nbsp;{toTitleCase(userInfo.reimbursement.practiceState)}
              </Typography>
            </Box>
            <Box className={classes.paperRow}>
              <Typography variant="h3">
                Reimbursement Model:
              </Typography>
              <Typography variant="h5">
                &nbsp;{toCustomTitleCase(userInfo.reimbursement.reimbursementModel)}
              </Typography>
            </Box>
            {userInfo.reimbursement.reimbursementModel == "MIXED" ? (<Box className={classes.paperRow}>
              <Typography variant="h3">
                Threshold:
              </Typography>
              <Typography variant="h5">
                &nbsp;{userInfo.reimbursement.threshold}
              </Typography>
            </Box>) : null}
            {userInfo.reimbursement.reimbursementModel == "MIXED" ? (<Box className={classes.paperRow}>
              <Typography variant="h3">
                Amount:
              </Typography>
              <Typography variant="h5">
                &nbsp;{userInfo.reimbursement.amount}
              </Typography>
            </Box>) : null}
            {userInfo.reimbursement.reimbursementModel == "RVUS_BASED" ||
              userInfo.reimbursement.reimbursementModel == "MIXED" ? (
              <Box className={classes.paperRow}>
                <Typography variant="h3">
                  RVUs Multiplier:
                </Typography>
                <Typography variant="h5">
                  &nbsp;{"$" + userInfo.reimbursement.rvusMultiplier}
                </Typography>
              </Box>) : null}
          </Paper>
        ) : (
          <Box className={classes.emptyView} mx="auto">
            <Box>
              <img
                src={!isDarkTheme ? emptyEducation : emptyEducationDark}
                alt="Empty Reimbursement"
              ></img>
            </Box>
            <Typography variant="caption" className={classes.emptyText}>
              Start by adding your reimbursement!
            </Typography>
          </Box>
        )}
      </TabPanel>

      <TabPanel value={value} index={4}>
        <div>
          <Grid
            item={true}
            container
            xs={12}
            lg={12}
            md={12}
            className={classes.reportPanel}
            alignItems="center"
          >
            {selectedReports.length > 0 ? (
              selectedReports.map((report: any, index) => (
                <Grid
                  item={true}
                  key={report.id}
                  xs={6}
                  lg={2}
                  sm={3}
                  md={3}
                  className={
                    isWidthUp('lg', props.width)
                      ? (index + 1) % 6 === 0
                        ? classes.fileExportedPL
                        : (index + 1) % 6 === 1
                          ? classes.fileExportedPR
                          : classes.fileExported
                      : isWidthUp('sm', props.width)
                        ? (index + 1) % 4 === 0
                          ? classes.fileExportedPL
                          : (index + 1) % 4 === 1
                            ? classes.fileExportedPR
                            : classes.fileExported
                        : (index + 1) % 2 === 0
                          ? classes.fileExportedPL
                          : (index + 1) % 2 === 1
                            ? classes.fileExportedPR
                            : classes.fileExported
                  }
                >
                  <Paper
                    {...bind}
                    elevation={0}
                    className={classes.fileExportedPaper}
                    onMouseOver={() => {
                      const newIsMouseHover = isMouseHover.slice();
                      newIsMouseHover[index] = true;
                      setIsMouseHover(newIsMouseHover);
                      setSelectedReport(report);
                    }}
                    onMouseLeave={() => {
                      if (filterReports.length < 1) {
                        const newIsMouseHover = isMouseHover.slice();
                        newIsMouseHover[index] = false;
                        setIsMouseHover(newIsMouseHover);
                        setSelectedReport(null);
                      }
                    }}
                    onPointerDown={() => {
                      setSelectedReport(report);
                    }}
                    onPointerUpCapture={() => {
                      setSelectedReport(null);
                    }}
                  >
                    {report.file_name.includes('pdf') ? (
                      <PictureAsPdfIcon className={classes.exportedIcon} />
                    ) : (
                      <DescriptionIcon className={classes.exportedIcon} />
                    )}
                    <Divider></Divider>
                    <a className={classes.link} href={report.path}>
                      <Typography
                        className={classes.reportName}
                        variant="subtitle2"
                      >
                        {report.file_name.substring(11, 21) +
                          '.' +
                          report.file_name.split('.').pop()}
                      </Typography>
                    </a>
                    {isMouseHover[index] || report.isChecked ? (
                      <Checkbox
                        className={classes.checkbox}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CheckCircleIcon />}
                        checked={report.isChecked}
                        value={report}
                        onChange={() => {
                          let newSelectedCases = selectedReports.map(
                            (element) =>
                              element.id === report.id
                                ? { ...element, isChecked: !report.isChecked }
                                : element
                          );

                          setSelectedReports(newSelectedCases);
                        }}
                        onClick={() => setShowMultiSelectTab(true)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    ) : null}
                  </Paper>
                </Grid>
              ))
            ) : (
              <Box className={classes.emptyView} mx="auto">
                <Box>
                  <img
                    src={
                      !isDarkTheme ? emptyExportedFile : emptyExportedFileDark
                    }
                    alt="no data"
                  ></img>
                </Box>
                <Typography variant="caption" className={classes.emptyText}>
                  No exported procedures yet!
                </Typography>
              </Box>
            )}
          </Grid>
        </div>
      </TabPanel>

      <TabPanel value={value} index={5}>
        <Grid
          item={true}
          container
          xs={12}
          lg={12}
          md={12}
          className={classes.reportPanel}
        >
          <Grid item={true} xs={12} lg={12} md={12} className={classes.mb10}>
            {' '}
            <FormControl className={classes.searchBy}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                View
              </InputLabel>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={optionForView}
                onChange={(e) => handleChangeView(e.target.value)}
                defaultValue="INVITATIONS"
                className={classes.f16}
              >
                <MenuItem selected value="INVITATIONS" className={classes.f16}>
                  Invitations
                </MenuItem>

                <MenuItem value="MY_TEAMS" className={classes.f16}>
                  Joined dashboards
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item={true} xs={12} lg={12} md={12}>
            {users && users.length > 0 ? (
              users.map((currentUser, index: number) => (
                <Paper
                  key={currentUser.id}
                  className={classes.dashboardPanel}
                  elevation={0}
                >
                  <Box className={classes.userCard}>
                    <Box className={classes.userCardInfo}>
                      <img src={ImagePlaceholder} alt="ImagePlaceholder" />
                      <Typography
                        className={classes.dahsboardName}
                        variant="h3"
                      >
                        {`${currentUser.name.split(/(\s+)/)[0]}’s dashboard`}
                      </Typography>
                    </Box>
                    <Box>
                      {optionForView === 'INVITATIONS' ? (
                        <Box className={classes.userCardAction}>
                          <Typography
                            color="secondary"
                            className={classes.accept}
                            onClick={() =>
                              handleInvitation(
                                user?.id,
                                {
                                  doctor_id: currentUser?.id,
                                  status: 'ACCEPTED'
                                },
                                ''
                              )
                            }
                          >
                            {matchesMobile ? <DoneIcon /> : 'Accept'}
                          </Typography>
                          <Typography
                            className={classes.reject}
                            onClick={() =>
                              handleInvitation(
                                user?.id,
                                {
                                  doctor_id: currentUser?.id,
                                  status: 'DECLINED'
                                },
                                ''
                              )
                            }
                          >
                            {matchesMobile ? <ClearIcon /> : 'Reject'}
                          </Typography>
                        </Box>
                      ) : (
                        <Box>
                          <Typography
                            className={classes.reject}
                            onClick={() =>
                              handleInvitation(
                                user?.id,
                                {
                                  doctor_id: currentUser?.id,
                                  status: 'DECLINED'
                                },
                                'leave'
                              )
                            }
                          >
                            Leave dashboard
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Paper>
              ))
            ) : (
              <Box className={classes.emptyView} mx="auto">
                <Box>
                  <img
                    src={!isDarkTheme ? Dashboardempty : DashboardemptyDark}
                    alt="Empty Education"
                  ></img>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </TabPanel>

      <Dialog open={openEditProfile} classes={{ paper: classes.paper }}>
        <ClearIcon
          className={classes.clearIconDialog}
          onClick={() => {
            if (existChange) {
              setOpenEditProfile(true);
              setIsOpenConfirmeditClose(true);
            } else {
              setOpenEditProfile(false);
            }
          }}
        />
        <div className={classes.title}>
          <Typography variant="subtitle1">Edit personal info</Typography>
        </div>
        <EditProfile
          setExistChange={setExistChange}
          setEditUserInfo={setEditUserInfo}
          setEditUserName={props.setEditUserName}
          setShowProfileMessage={setShowProfileMessage}
          setOpenEditProfile={setOpenEditProfile}
        />
      </Dialog>

      <ConfirmCloseDialog
        isOpen={isOpenConfirmClose}
        title={'Are you sure you want to close this window?'}
        firstOption={'Stay on window'}
        secondOption={'Close'}
        handleOptionOne={() => {
          setIsBlocking(false);
          setIsOpenConfirmClose(false);
        }}
        handleOptionTwo={() => {
          setIsOpenConfirmClose(false);
          setViewEducationForm(false);
        }}
        onClose={() => setIsOpenConfirmClose(false)}
      >
        Closing this window will make you lose all unsaved information.{' '}
      </ConfirmCloseDialog>

      <ConfirmCloseDialog
        isOpen={isOpenConfirmReimbursementClose}
        title={'Are you sure you want to close this window?'}
        firstOption={'Stay on window'}
        secondOption={'Close'}
        handleOptionOne={() => {
          setIsBlocking(false);
          setIsOpenConfirmReimbursementClose(false);
        }}
        handleOptionTwo={() => {
          setIsOpenConfirmReimbursementClose(false);
          setViewReimbursementForm(false);
        }}
        onClose={() => setIsOpenConfirmReimbursementClose(false)}
      >
        Closing this window will make you lose all unsaved information.{' '}
      </ConfirmCloseDialog>

      <ConfirmCloseDialog
        isOpen={isOpenConfirmClosePosition}
        title={'Are you sure you want to close this window?'}
        firstOption={'Stay on window'}
        secondOption={'Close'}
        handleOptionOne={() => {
          setIsOpenConfirmClosePosition(false);
        }}
        handleOptionTwo={() => {
          setIsBlocking(false);
          setIsOpenConfirmClosePosition(false);
          setViewPositionForm(false);
        }}
        onClose={() => setIsOpenConfirmClosePosition(false)}
      >
        Closing this window will make you lose all unsaved information.{' '}
      </ConfirmCloseDialog>
      <ConfirmCloseDialog
        isOpen={isOpenConfirmeditClose}
        title={'Are you sure you want to exit this screen?'}
        firstOption={'Ok'}
        secondOption={'Exist'}
        handleOptionOne={() => {
          setExistChange(false);
          setIsOpenConfirmeditClose(false);
          setOpenEditProfile(false);
        }}
        handleOptionTwo={() => setIsOpenConfirmeditClose(false)}
        onClose={() => setIsOpenConfirmeditClose(false)}
      >
        Exiting this page will result in the loss of unsaved information.
      </ConfirmCloseDialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={showProfileMessage}
      >
        <Alert onClose={() => setShowProfileMessage(false)} color="success">
          Your personal info is successfully updated!
        </Alert>
      </Snackbar>
      <Snackbar open={showProfileFormMessage} color={profileMessage[0]}>
        <Alert
          onClose={() => setShowProfileFormMessage(false)}
          severity={getSeverity(profileMessage[0])}
        >
          {profileMessage[1]}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default withWidth()(Profile);
