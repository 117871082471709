import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as api from '../../services/apiService';
import ConfirmCloseDialogStyle from './Styles';
import ReactHookFormSelect from '../select/ReactHookFormSelect';
import { NavLink } from 'react-router-dom';

interface RegistryDialogPropsType {
  isOpen: boolean;
  title?: string;
  filterId: string[];
  onClose: () => void;
  setImportCaseMsg: (show: string[]) => void;
  setShowImportCaseMsg: (show: boolean) => void;
}

const RegistryDialog: React.FC<RegistryDialogPropsType> = (props) => {
  const classes = ConfirmCloseDialogStyle();

  const [name, setName] = React.useState('none');
  const [id, setId] = React.useState(0);
  const [registryName, setRegistryName] = React.useState<any[]>([]);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const {
    isOpen,
    title,
    filterId,
    onClose,
    setImportCaseMsg,
    setShowImportCaseMsg,

    children
  } = props;

  const getRegstryName = async () => {
    const { data, status_code: statusCode } = await api.registryName();
    if (statusCode === api.status.SUCCESS) {
      let RegistryInfo: any = [];
      for (let i = 0; i < data.length; i++) {
        RegistryInfo.push({
          id: data[i].id,
          name: data[i].name
        });
      }
      setRegistryName(RegistryInfo);
    }
  };

  const importFromDashboard = async () => {
    if (id !== 0) {
      const { data, status_code: statusCode } = await api.exportToRegistry(id, {
        procedure_ids: filterId
      });

      if (statusCode === api.status.SUCCESS) {
        setImportCaseMsg(['success', `Cases exported successfully!`, id + '']);
        setShowImportCaseMsg(true);
        setTimeout(() => {
          setShowImportCaseMsg(false);
          setImportCaseMsg([
            'warning',
            `Please check if there’s any missing custom fields value from the imported data!`,
            id + ''
          ]);
          setShowImportCaseMsg(true);
        }, 2000);
        setTimeout(() => {
          setShowImportCaseMsg(false);
        }, 8000);
      } else {
        if (data.message) {
          setImportCaseMsg(['error', `${data.message}`]);
        } else {
          setImportCaseMsg(['error', `${data}`]);
        }
        setShowImportCaseMsg(true);
        setTimeout(() => {
          setShowImportCaseMsg(false);
        }, 4000);
      }
    } else {
      setImportCaseMsg(['error', 'You must select Registry Name']);
      setShowImportCaseMsg(true);
      setTimeout(() => {
        setShowImportCaseMsg(false);
      }, 4000);
    }
  };

  React.useEffect(() => {
    if (registryName.length === 0 && isOpen) {
      getRegstryName();
    }
  }, [registryName.length, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.paperRegistry }}
    >
      <div className={classes.header}>
        <Typography variant="subtitle1" className={classes.registryTitle}>
          {title}
        </Typography>

        <CloseIcon className={classes.closeIcon} onClick={onClose} />
      </div>
      <Box mx={3.5} mb={3.5}>
        <Box>
          <Typography variant="h6">
            choose a registry to export cases to!
          </Typography>
        </Box>
        <Box>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Registries*
            </InputLabel>

            <Select
              fullWidth
              variant="outlined"
              labelId="demo-simple-select-outlined-label"
              label="Registries"
              placeholder="Registries Name"
              id="demo-simple-select-outlined"
              onChange={handleChange}
              name={name}
              defaultValue="none"
            >
              <MenuItem value="none" disabled>
                Registry name
              </MenuItem>
              {registryName.length > 0
                ? registryName.map((regName: any) => (
                    <MenuItem
                      key={regName.id}
                      value={regName.name}
                      onClick={() => setId(regName.id)}
                    >
                      {regName.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className={classes.footerButton}
            onClick={() => {
              importFromDashboard();
              onClose();
            }}
          >
            Export
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RegistryDialog;
