import React from 'react';
import history from '../../../utils/history';
import experienceRight from '../../../assets/img/MS-experience-right-img.png';
import streamLined from '../../../assets/img/MS-steamlined-procedure-icon.png';
import intelligent from '../../../assets/img/MS-intelligent-cpt-icon.png';
import analytic from '../../../assets/img/MS-real-time-analytic-icon.png';
import compliant from '../../../assets/img/MS-hipaa-compliant-icon.png';
import automatic from '../../../assets/img/MS-automatic-rvu-icon.png';
import customized from '../../../assets/img/MS-customized-registry-icon.png';
import dataExport from '../../../assets/img/MS-logger-data-export-icon.png';
import loginRight from '../../../assets/img/MS-Login-right-img.png';
import appleStore from '../../../assets/img/MS-mbl-site-apple-img.png';
import playStore from '../../../assets/img/MS-mbl-site-play-store-img.png';
import Footer from '../../../components/footer/Footer';

const AboutUsPage = () => {

  return (
    <div>
      <section className="breadCrums">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="crumsHeading">
                <h1>About MS Logger</h1>
                <ul className="d-flex align-items-center justify-content-center">
                  <li><a onClick={() => history.push('/')} className="breadCrumsLink home pointer">Home </a></li>
                  <li><a onClick={() => history.push('/about')} className="breadCrumsLink pointer"> &gt; About MS Logger</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="experienceFeature aboutMS">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="experienceImg">
                <img style={{width: '100%'}} src={experienceRight} alt=""></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="experienceContent">
                <h2>Experience the Future of Case Logging and Reimbursement Optimization with MS
                  Logger.</h2>
                <p>Unlock the power of AI, NLP, and advanced features to enhance your practice&#39;s efficiency, accuracy, and financial success. Join the growing community of healthcare providers who are transforming their workflows with MS Logger.</p>
                <a onClick={() => history.push('/plans')} className="bgBlue pointer">Start your own plan</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="msfeatures aboutPage">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="featuresHeading">
                <h1>Unleashing the Power of Innovation</h1>
                <p>MS Logger is designed by surgeons to provide a wide variety of HIPAA-compliant
                  features that revolutionize case logging and reimbursement optimization.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="featureContent">
                <div className="featureImg">
                  <img src={streamLined} alt=""></img>
                </div>
                <div className="featureText">
                  <h4>Streamlined Procedure Logging</h4>
                  <p>MS Logger simplifies and accelerates the process of logging medical cases. With an intuitive interface, healthcare providers can swiftly record and document procedure details, including images, ICD/CPT codes, follow-up notes and much more, saving valuable time and effort</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="featureContent">
                <div className="featureImg">
                  <img src={intelligent} alt=""></img>
                </div>
                <div className="featureText">
                  <h4>Intelligent CPT Code Suggestion</h4>
                  <p> By harnessing the power of AI and natural language processing (NLP), MS Logger goes beyond simple case logging. Our advanced algorithms analyze procedure descriptions and recommend the most suitable CPT codes, ensuring accurate coding and maximizing reimbursement potential.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="featureContent">
                <div className="featureImg">
                  <img src={analytic} alt=""></img>
                </div>
                <div className="featureText">
                  <h4>Real-Time Analytics and Insights</h4>
                  <p>Gain comprehensive analytics and actionable insights into case logs, reimbursement trends, and productivity metrics. MS Logger provides detailed reports and visualizations, empowering healthcare providers to make informed decisions and optimize their practice.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="featureContent">
                <div className="featureImg">
                  <img src={compliant} alt=""></img>
                </div>
                <div className="featureText">
                  <h4>HIPAA-Compliant Data Security</h4>
                  <p>We prioritize the security and privacy of patient information. MS Logger is built with robust security measures to ensure full compliance with HIPAA regulations, safeguarding sensitive data throughout the entire logging and storage process.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="featureContent">
                <div className="featureImg">
                  <img src={automatic} alt=""></img>
                </div>
                <div className="featureText">
                  <h4>Automatic RVU and Reimbursement Estimations</h4>
                  <p>With MS Logger, healthcare providers gain instant insights into the Relative Value Units (RVUs) and reimbursement estimations for logged cases. This invaluable feature enables efficient financial planning and helps optimize revenue generation.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="featureContent">
                <div className="featureImg">
                  <img src={customized} alt=""></img>
                </div>
                <div className="featureText">
                  <h4>Customized Registry Builder</h4>
                  <p>MS Logger offers the ability to build customizable registries, which are databases that can be shared with your team to add to (eg. Tumor Bank) or to collaborate with physicians from other institutions safely and efficiently.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="featureContent">
                <div className="featureImg">
                  <img src={dataExport} alt=""></img>
                </div>
                <div className="featureText">
                  <h4>Data Export</h4>
                  <p>MS Logger seamlessly allows for easy data import and export. Whether it&#39;s for billing, research, or archiving purposes, the platform offers flexible options to export logged case data in standardized formats.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

      <section className="mblSite">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mblSiteImg">
                <img style={{width: '100%'}} src={loginRight} alt=""></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mblSiteContent">
                <h2>Mobile Site and Application</h2>
                <p>Our mobile site and application allow you to add and check your patients and procedures anytime and anywhere. You can also use it to search, add patient data and images, follow-up information, export cases and much more.</p>
                <h4>Get MS Logger now and start building your cases database.</h4>
                <div className="mblAppImg d-flex">
                  <div className="appleImg">
                    <a className="pointer" onClick={() => window.open('https://apps.apple.com/us/app/ms-logger/id1484770342', '_blank')}>
                      <img style={{ width: '100%' }} src={appleStore} alt=""></img>
                    </a>
                  </div>
                  <div className="playStoreImg">
                    <a className="pointer" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.medsolace.caselog', '_blank')}>
                      <img style={{ width: '100%' }} src={playStore} alt=""></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div >
  )
};

export default AboutUsPage;
