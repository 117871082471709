import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Popover,
  Select,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React, { useEffect } from 'react';
import registriesStyle from './Style';
import history from '../../../utils/history';
import * as api from '../../../services/apiService';
import SortIcon from '@material-ui/icons/Sort';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Dialog from '@material-ui/core/Dialog';
import { format } from '../../../utils/date';
import GroupIcon from '@material-ui/icons/Group';
import registriesLight from '../../../assets/registriesLight.png';
import registriesDark from '../../../assets/dark/Registries.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import registryicon from '../../../assets/registryicon.png';
import registryiconDark from '../../../assets/dark/RegistryiconDark.png';
import { Alert } from '@material-ui/lab';

const Registries = (props) => {
  const {
    showAddRegistryMessage,
    registryMessage,
    setShowAddRegistryMessage,
    setRegistryMessage
  } = props;

  const [registries, setRegistries] = React.useState<any[]>([]);

  const [optionForView, setOptionForView] = React.useState('All registries');
  const [endRegistries, setEndRegistries] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const [group, setGroup] = React.useState<string>('ALL');
  const [sortBy, setSortBy] = React.useState<string>('ASC');
  const [orderBy, setOrderBy] = React.useState<string>('name');
  const [isOpenSortList, setIsOpenSortList] = React.useState<boolean>(false);
  const [openLoader, setOpenLoader] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = registriesStyle();
  const theme = useTheme();
  const matchSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const isDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');

  const groupChange = (filter: any) => {
    setGroup(filter);
    setPage(0);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChange = (event) => {
    setOptionForView(event.target.value);
    if (event.target.value === 'All registries') {
      groupChange('ALL');
    } else if (event.target.value === 'My registries') {
      groupChange('MINE');
    } else if (event.target.value === 'Shared registries') {
      groupChange('SHARED');
    }
  };
  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
    }
  };
  const handleLoaderClose = () => {
    setOpenLoader(false);
  };
  const getRegistries = React.useCallback(
    async (group, sort_by, order_by, page, size) => {
      if (!endRegistries || page === 0) {
        setOpenLoader(true);
        if (page === 0) {
          setRegistries([]);
        }

        const { data, status_code: statusCode } = await api.getRegistries(
          group,
          sort_by,
          order_by,
          page,
          size
        );
        if (statusCode === api.status.SUCCESS) {
          if (data.length < size) {
            setEndRegistries(true);
          } else {
            setEndRegistries(false);
          }

          if (page > 0) {
            setRegistries([...registries, ...data]);
          } else {
            setRegistries([]);

            setRegistries(data);
          }

          setCount(data.length);
        }

        handleLoaderClose();
      }
    },
    [endRegistries, registries]
  );

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (window.innerWidth > 600) {
        getRegistries(group, sortBy, orderBy, page, 55);
      } else {
        getRegistries(group, sortBy, orderBy, page, 10);
      }
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, sortBy, orderBy, page, window.innerWidth]);

  return (
    <div className={classes.root}>
      <Snackbar open={showAddRegistryMessage}>
        <Alert
          onClose={() => setShowAddRegistryMessage(false)}
          color={registryMessage[0]}
          severity={registryMessage[0]}
        >
          {registryMessage[1]}
        </Alert>
      </Snackbar>{' '}
      <Dialog open={openLoader} onClose={handleLoaderClose}>
        <CircularProgress className={classes.indicator} />
      </Dialog>
      <Grid container item={true} xs={12} className={classes.headerContainer}>
        <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControl className={classes.view}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              View
            </InputLabel>
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={optionForView}
              onChange={handleChange}
              defaultValue="All registries"
            >
              <MenuItem selected value="All registries">
                All registries
              </MenuItem>
              <MenuItem value="My registries">My registries</MenuItem>
              <MenuItem value="Shared registries">Shared registries</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.buttonHeader}
        >
          <Button
            aria-describedby={id}
            variant="contained"
            color="primary"
            className={classes.sortButton}
            onClick={handleClick}
            startIcon={<SortIcon />}
            endIcon={<ArrowDropDownIcon className={classes.endIcon} />}
          >
            Sort
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <List>
              <ListItem
                onClick={() => {
                  setSortBy('DESC');
                  setPage(0);
                  setOrderBy('updated');
                  handleClose();
                }}
                className={classes.cursor}
              >
                <Typography variant="h6">Most recent</Typography>
              </ListItem>
              <ListItem
                onClick={() => {
                  setSortBy('ASC');
                  setPage(0);
                  setOrderBy('updated');
                  handleClose();
                }}
                className={classes.cursor}
              >
                <Typography variant="h6">Oldest</Typography>
              </ListItem>
              <ListItem
                onClick={() => {
                  setSortBy('ASC');
                  setPage(0);
                  setOrderBy('name');
                  handleClose();
                }}
                className={classes.cursor}
              >
                <Typography variant="h6">From A-Z</Typography>
              </ListItem>
              <ListItem
                onClick={() => {
                  setSortBy('DESC');
                  setPage(0);
                  setOrderBy('name');
                  handleClose();
                }}
                className={classes.cursor}
              >
                <Typography variant="h6">From Z-A</Typography>
              </ListItem>
            </List>
          </Popover>
          <Button
            variant="contained"
            color="secondary"
            className={classes.createButton}
            startIcon={<CreateNewFolderIcon />}
            onClick={() => history.push('/new-registry')}
          >
            Create registry{' '}
          </Button>
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className={classes.mobileHeader}
        >
          <Typography variant="subtitle2">Registries</Typography>
        </Grid>
      </Grid>
      <InfiniteScroll
        dataLength={count}
        next={() => {
          setPage(page + 1);
        }}
        hasMore={true}
        loader={<h4></h4>}
      >
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} item={true}>
          {registries.length > 0 ? (
            registries.map((registrey: any, index) => (
              <Grid
                key={registrey.id}
                item={true}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={4}
              >
                <Box className={classes.registeryBox}>
                  <Paper
                    onClick={() => {
                      history.push('/registry/' + registrey.id);
                    }}
                    className={
                      matchesLargeScreen
                        ? index % 3 === 0
                          ? classes.paperLeft
                          : index % 3 === 2
                          ? classes.paperRight
                          : classes.paper
                        : index % 2 === 0
                        ? classes.paperLeft
                        : index % 2 === 1
                        ? classes.paperRight
                        : classes.paper
                    }
                  >
                    <div>
                      <img
                        src={isDarkTheme ? registryiconDark : registryicon}
                        alt="registry_icon"
                      />
                    </div>
                    <div className={classes.registeryCard}>
                      <Typography
                        className={classes.noWrapWidth}
                        variant="h3"
                        noWrap
                      >
                        {registrey.name}
                      </Typography>
                      <Typography
                        className={[
                          classes.noWrapWidth,
                          classes.alignLeft
                        ].join(' ')}
                        variant="h5"
                        noWrap
                      ></Typography>
                      <Typography
                        className={classes.noWrapWidth}
                        variant="caption"
                        noWrap
                      >
                        {registrey.owner.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        noWrap
                        className={[
                          classes.noWrapWidth,
                          classes.alignLeft
                        ].join(' ')}
                      >
                        {format(registrey.created, 'MMM DD YYYY')}
                      </Typography>
                    </div>
                    <div>
                      {registrey.count_shared > 0 ? (
                        <Tooltip
                          title={`shared with ${registrey.count_shared} user`}
                          placement="bottom-end"
                        >
                          <GroupIcon className={classes.groupIcon} />
                        </Tooltip>
                      ) : null}
                    </div>
                  </Paper>
                </Box>
              </Grid>
            ))
          ) : (
            <Box className={classes.imageBox}>
              <Box>
                <img
                  src={isDarkTheme ? registriesDark : registriesLight}
                  alt="emptyView"
                ></img>
              </Box>
              <Box className={classes.emptyRegistries}>
                {' '}
                <Typography variant="caption">
                  Start now by creating a registry!
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

export default Registries;
