import React, { useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import {
    withWidth,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

} from '@material-ui/core';
import { selectModifiersStyle } from './Styles';


const SelectModifier = (props) => {
    const classes = selectModifiersStyle();
    const [selectedModifier, setSelectedModifier] = useState(null);

    const handleSelectModifier = (modifier) => {
        props.setOpenModifierDialog(false);
        props.setCPTModifier(modifier);
    }
    return (
        <div className={classes.container}>
            <ClearIcon className={classes.icon} onClick={() => props.setOpenModifierDialog(false)} />
            <Typography className={classes.header} variant="h1">
                CPT Modifiers
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Modifier</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Percentage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.cptModifiers.map((row, index) => (
                        <TableRow
                            key={index}
                            hover
                            selected={index === selectedModifier}
                            className={classes.row}
                            onClick={() => handleSelectModifier(row)}
                        >
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{`${parseFloat(row.percentage) % 1 == 0 ? parseFloat(row.percentage).toFixed(0) : parseFloat(row.percentage).toFixed(1)}% ${row.additional_info}`}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </div>
    )
};
export default withWidth()(SelectModifier);
