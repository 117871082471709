import React, { Fragment, useEffect } from 'react';
import {
  Typography,
  Paper,
  Box,
  Button,
  TextField,
  Grid,
  MenuItem,
  Dialog,
  Divider,
  DialogTitle,
  Snackbar
} from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import history from '../../../utils/history';
import { getUser } from '../../../utils';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import * as api from '../../../services/apiService';
import AddIcon from '@material-ui/icons/Add';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import 'date-fns';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReactHookFormSelect from '../../../components/select/ReactHookFormSelect';
import SelectCodes from '../../../components/selectCodes/selectCodes';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import addCaseStyle from './Styles';
import { NavLink, Prompt } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  genderListType,
  hospitalDataType,
  rolesDataType
} from '../../../types';
import EditIcon from '@material-ui/icons/Edit';
import PositionForm from '../../../components/PositionForm/PositionForm';
import ConfirmCloseDialog from '../../../components/Dialogs/ConfirmCloseDialog';
import { Alert } from '@material-ui/lab';
import {
  caseParams,
  registryParams,
  months,
  requiredField
} from '../../../types/index';
import { uploadRegistryProcedureMedia } from '../../../services/apiService';
import { format } from '../../../utils/date';
import UpgradeDialog from '../../../components/Dialogs/UpgradeDialog';

const AddCaseRegistry = (props) => {
  const classes = addCaseStyle();
  const { caseId } = useParams<caseParams>();
  const { registryId } = useParams<registryParams>();
  const user = getUser();
  const [images, setImages] = React.useState<any[]>([]);
  const [imagesObj, setImagesObj] = React.useState<any[]>([]);
  const [imageName, setImageName] = React.useState<string>('');
  const [notes, setNotes] = React.useState<string[]>([]);
  const [editNoteField, setEditNoteField] = React.useState<string>('');
  const [profileMessage, setProfileMessage] = React.useState(['', '']);
  const [registryName, setRegistryName] = React.useState<string>('');
  const [customizedField, setCustomizedField] = React.useState<string[]>([]);
  const [registryFields, setRegistryFields] = React.useState<any[]>([]);
  const [
    showProfileFormMessage,
    setShowProfileFormMessage
  ] = React.useState<boolean>(false);
  const [showAddNoteText, setShowAddNoteText] = React.useState<boolean>(false);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [editedNotes, setEditedNotes] = React.useState<any[]>([]);
  const [imageErrorMsg, setImageErrorMsg] = React.useState<any[]>([]);
  const [imageErrorAlert, setImageErrorAlert] = React.useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [selectedCustomDate, setSelectedCustomDate] = React.useState<any[]>([]);
  const [surgeryDate, setSurgeryDate] = React.useState<Date>(new Date());
  const [genderList, setGenderList] = React.useState<genderListType[]>([]);
  const [roleList, setRoleList] = React.useState<rolesDataType[]>([]);
  const [selectedCptCodes, setSelectedCptCodes] = React.useState<any[]>([]);
  const [diagnosis, setDiagnosis] = React.useState<any[]>([]);
  const [mySelectedCptCodes, setMySelectedCptCodes] = React.useState<any[]>([]);
  const [myDiagnosis, setMyDiagnosis] = React.useState<any[]>([]);

  const [hospitalList, setHospitalList] = React.useState<hospitalDataType[]>(
    []
  );
  const [showAddCaseMessageError, setShowAddCaseMessageError] = React.useState(
    false
  );
  const [addCaseMessage, setAddCaseMessage] = React.useState<any[]>([]);
  const [isBlocking, setIsBlocking] = React.useState<boolean>(false);
  const [isOpenConfirmClose, setIsOpenConfirmClose] = React.useState(false);
  const [specialityList, setSpecialityList] = React.useState<any[]>([]);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [onRefresh, setOnRefresh] = React.useState<boolean>(false);
  const [uploadImageStauts, setUploadImageStauts] = React.useState<string>(
    'upload'
  );
  const [openSelectDialog, setOpenSelectDialog] = React.useState<boolean>(
    false
  );
  const [openSelectDialognew, setOpenSelectDialognew] = React.useState<
    boolean[]
  >([false]);
  const [dateTitle, setDateTitle] = React.useState<string>('');
  const [searchPath, setSearchPath] = React.useState<string>('');
  const [media, setMedia] = React.useState<any[]>([]);
  const [showEndDate, setShowEndDate] = React.useState<boolean>(true);
  const [diagnosisCount, setDiagnosisCount] = React.useState<number>(0);
  const [cptCount, setCptCount] = React.useState<number>(0);
  const [title, setTitle] = React.useState<string>('');
  const [inputIndex, setInputIndex] = React.useState<number>(0);
  const [dateIndex, setDateIndex] = React.useState<number>(0);
  const [viewPositionForm, setViewPositionForm] = React.useState<boolean>(
    false
  );
  const [messageError, setMessageError] = React.useState<string>('');
  const [currentPlan, setCurrentPlan] = React.useState<any>();
  const [tokenError, setTokenError] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [userToken, setUserToken] = React.useState<string>('');
  const [newPlan, setNewPlan] = React.useState<boolean>(false);
  const [isOpen, setisOpen] = React.useState<boolean>(false);
  let adminFieldsArray = new Array();
  useEffect(() => {}, [
    hospitalList,
    registryFields,
    selectedCustomDate,
    notes,
    uploadImageStauts,
    customizedField,
    imageName,
    myDiagnosis,
    mySelectedCptCodes,
    editedNotes,
    isBlocking,
    showAddNoteText
  ]);
  useEffect(() => {
    mySelectedCptCodes.forEach((item, index) => {
      if (item.name === title) {
        setInputIndex(index);
      }
    });
    selectedCustomDate.forEach((item, index) => {
      if (item.name === dateTitle) {
        setDateIndex(index);
      }
    });
    myDiagnosis.forEach((item, index) => {
      if (item.name === title) {
        setInputIndex(index);
      }
    });
  }, [title]);

  useEffect(() => {
    if (!props.showAddCaseRegistryMessage) {
      getRegistryName();
    }
  }, []);
  useEffect(() => {
    if (hospitalList.length === 1) {
      setValue('institute', hospitalList[0].value);
    }
  }, [hospitalList]);

  const procedureInitDataSample = {
    patient_first_name: '',
    patient_last_name: '',
    gender: '',
    type: '',
    mrn: '',
    insurance: undefined,
    institute: '',
    role: '',
    findings: undefined,
    vendors: undefined,
    complications: undefined,
    outcome: undefined,
    diagnosisCodes: undefined,
    cptCodes: undefined,
    notes: [],
    date_of_birth: new Date(),
    date: new Date(),
    images: [],
    newNote: undefined
  };
  const [procedureInitData, setProcedureInitData] = React.useState(
    procedureInitDataSample
  );
  const [procedureEditData, setProcedureEditData] = React.useState({
    patient_first_name: '',
    patient_last_name: '',
    gender: '',
    type: '',
    mrn: '',
    insurance: undefined,
    institute: '',
    role: '',
    findings: undefined,
    vendors: undefined,
    complications: undefined,
    outcome: undefined,
    note: [],
    images: []
  });

  const validationSchema = yup.object().shape({
    patient_first_name: yup.string().trim().required('This field is required'),
    patient_last_name: yup.string().trim().required('This field is required'),
    type: yup.string().trim().required('This field is required'),
    gender: yup.string().required('This field is required'),
    mrn: yup.string().nullable(true),
    insurance: yup.string().nullable(true),
    institute: yup.string().required('This field is required'),
    role: yup.string().nullable(true),
    findings: yup.string().nullable(true),
    vendors: yup.string().nullable(true),
    complications: yup.string().nullable(true),
    outcome: yup.string().nullable(true),
    note: yup.array().of(
      yup.object().shape({
        note: yup.string().nullable(false)
      })
    )
  });

  const checkCustomizedField = (data) => {
    const procedureDatakeys = Object.keys(procedureInitDataSample);
    let customizedField = data.filter(
      (x) => procedureDatakeys.indexOf(x.name) === -1
    );
    setCustomizedField(customizedField);
    let countOfDiagnosis = 0;
    let countOfDate = 0;
    let countOfCPT = 0;
    let newSelectedCPTitle: any[] = [];
    let newSelectedDTitle: any[] = [];
    let newSelectedDateTitle: any[] = [];

    if (customizedField) {
      for (let i = 0; i < customizedField.length; i++) {
        if (customizedField[i]['type'] === 'CPT') {
          countOfCPT++;
          const title1 = customizedField[i]['name'];
          newSelectedCPTitle.push(title1);
        } else if (customizedField[i]['type'] === 'Diagnosis') {
          countOfDiagnosis++;
          const title2 = customizedField[i]['name'];
          newSelectedDTitle.push(title2);
        } else if (customizedField[i]['type'] === 'Date picker') {
          countOfDate++;
          const title2 = customizedField[i]['name'];
          newSelectedDateTitle.push(title2);
        }
      }
      if (countOfCPT > 0) {
        let array1 = new Array();
        for (let i = 0; i < countOfCPT; i++) {
          const name = newSelectedCPTitle[i];
          let arr = new Array();
          array1[i] = { name, arr };
        }

        setMySelectedCptCodes(array1);
      }
      if (countOfDiagnosis > 0) {
        let array2 = new Array();

        for (let i = 0; i < countOfDiagnosis; i++) {
          const name = newSelectedDTitle[i];
          let arr = new Array();
          array2[i] = { name, arr };
        }

        setMyDiagnosis(array2);
      }
      if (countOfDate > 0) {
        let array3 = new Array();

        for (let i = 0; i < countOfDate; i++) {
          const name = newSelectedDateTitle[i];
          let date = new Date();
          array3[i] = { name, date };
        }

        setSelectedCustomDate(array3);
      }
    }
  };
  const location = useLocation().pathname;
  const {
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
    register,
    watch
  } = useForm({
    defaultValues: procedureInitData,
    resolver: yupResolver(validationSchema),
    mode: 'all'
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'note'
    }
  );

  const handleCloseDialog = () => {
    setisOpen(false);
  };

  const getFieldsInput = (field) => {
    if (field.type === 'Free text') {
      const title = field.name;
      if (field.visible || isAdmin) {
        return (
          <Grid
            key={field.name}
            className={[classes.py0, classes.mb0Mobile].join(' ')}
            item={true}
            xs={12}
            sm={4}
            md={6}
            lg={6}
          >
            <Controller
              className={classes.mt0Custom}
              as={TextField}
              required={field.required}
              defaultValue=""
              InputLabelProps={{
                classes: {
                  asterisk: classes.asterisk
                }
              }}
              label={field.name}
              control={control}
              name={field.name}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Grid>
        );
      } else if (!field.visible && !isAdmin) {
        const name = field.name;
        let value = 'required';

        adminFieldsArray.push({ name, value });
      }
    } else if (field.type === 'Drop down list') {
      let listValue = field.value.split(',');

      if (listValue[listValue.length - 1].indexOf('.') !== -1) {
        if (listValue[listValue.length - 1].length > 1) {
          listValue[listValue.length - 1] = listValue[
            listValue.length - 1
          ].slice(0, -1);
        }
      }
      let Options = listValue.filter((el) => {
        return el != null && el !== '' && el !== '.';
      });
      if (field.visible || isAdmin) {
        return (
          <Grid
            className={[classes.py0mb, classes.mbGender].join(' ')}
            item={true}
            xs={12}
            sm={4}
            md={6}
            lg={6}
          >
            <ReactHookFormSelect
              required={field.required}
              name={field.name}
              label={field.name}
              control={control}
              className={classes.mt0Custom}
              defaultValue=""
              variant="outlined"
              margin="normal"
              setIsBlocking={setIsBlocking}
              helperText={null}
            >
              {Options.length > 0
                ? Options.map((option: any, index) => (
                    <MenuItem key={option + '' + index} value={option}>
                      {option}
                    </MenuItem>
                  ))
                : null}
            </ReactHookFormSelect>
          </Grid>
        );
      } else if (!field.visible && !isAdmin) {
        const name = field.name;
        let value = 'required';
        adminFieldsArray.push({ name, value });
      }
    } else if (field.type === 'Date picker') {
      let i;
      selectedCustomDate.forEach((item, index) => {
        if (item.name === field.name) {
          i = index;
        }
      });
      if (field.visible || isAdmin) {
        return (
          <Grid
            className={[
              classes.surgeryDateField,
              classes.py0,
              classes.mb0DateCustomMobile
            ].join(' ')}
            item={true}
            xs={12}
            sm={4}
            md={6}
            lg={6}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                required
                className={classes.mt0Custom}
                variant="inline"
                autoOk={true}
                inputVariant="outlined"
                value={
                  selectedCustomDate[i] && selectedCustomDate[i].date
                    ? selectedCustomDate[i].date
                    : new Date()
                }
                onClick={() => setDateTitle(field.name)}
                onChange={(date) => {
                  handleCustomDateChange(date, field.name);
                }}
                label={field.name}
                name={field.name}
                format="MMM dd, yyyy"
                margin="normal"
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </Grid>
        );
      } else if (!field.visible && !isAdmin) {
        const name = field.name;
        let value = new Date();
        adminFieldsArray.push({ name, value });
      }
    }
  };

  const getSelectOption = (field) => {
    if (field.type === 'CPT') {
      let titleCpt = field.name;
      let i;
      mySelectedCptCodes.forEach((item, index) => {
        if (item.name === titleCpt) {
          i = index;
        }
      });
      if (field.visible || isAdmin) {
        return (
          <Box mb={2}>
            <Paper>
              <Box>
                <Box className={classes.title}>
                  <Typography variant="h2" className={classes.inline}>
                    {titleCpt}
                  </Typography>
                  <AddIcon
                    onClick={() => {
                      const openDialog = openSelectDialognew.slice();
                      openDialog[i] = true;

                      setSearchPath('cpt_lookups');
                      setOpenSelectDialognew(openDialog);
                      setTitle(titleCpt);
                    }}
                    color="secondary"
                    className={classes.cursor}
                  ></AddIcon>
                </Box>
                <Box mt={1} mb={2}>
                  {mySelectedCptCodes &&
                  mySelectedCptCodes[i] &&
                  mySelectedCptCodes[i].arr.length > 0 ? (
                    <Divider></Divider>
                  ) : null}
                </Box>
                <Grid container item={true} xs={12} spacing={1}>
                  {mySelectedCptCodes.length > 0
                    ? mySelectedCptCodes[i].arr.map((item: any, index) => (
                        <Fragment key={item.id + index}>
                          <Grid
                            className={
                              index % 2 === 0
                                ? classes.withpadding
                                : classes.withOutpadding
                            }
                            item={true}
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                          >
                            <Box
                              className={
                                index % 2 === 0
                                  ? classes.cptBoxL
                                  : classes.cptBoxR
                              }
                            >
                              <Typography variant="h6">
                                <span className={classes.codeBox}>
                                  {item.code}
                                </span>
                                <LinesEllipsis
                                  text={item.value}
                                  maxLine="3"
                                  ellipsis="..."
                                  trimRight
                                  basedOn="words"
                                />
                              </Typography>
                              <DeleteOutlineIcon
                                onClick={() => spliceCptCodeCustom(item, i)}
                                className={classes.cursor}
                                color="error"
                              ></DeleteOutlineIcon>
                            </Box>
                          </Grid>
                          {index < mySelectedCptCodes.length - 1 ? (
                            <Grid
                              item={true}
                              xs={12}
                              className={classes.dividerMobile}
                            >
                              <Divider></Divider>
                            </Grid>
                          ) : null}
                        </Fragment>
                      ))
                    : null}
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      } else if (!field.visible && !isAdmin) {
        const name = field.name;
        let value = [];
        adminFieldsArray.push({ name, value });
      }
    } else if (field.type === 'Diagnosis') {
      let titleDiagnosis = field.name;
      let i;
      myDiagnosis.forEach((item, index) => {
        if (item.name === titleDiagnosis) {
          i = index;
        }
      });
      if (field.visible || isAdmin) {
        return (
          <Box mb={2}>
            <Paper>
              <Box>
                <Box className={classes.title}>
                  <Typography variant="h2" className={classes.inline}>
                    {titleDiagnosis}
                  </Typography>

                  <AddIcon
                    onClick={() => {
                      const openDialog = openSelectDialognew.slice();
                      openDialog[i] = true;
                      setSearchPath('icd_lookup');
                      setOpenSelectDialognew(openDialog);
                      setTitle(titleDiagnosis);
                    }}
                    color="secondary"
                    className={classes.cursor}
                  ></AddIcon>
                </Box>

                <Box mt={1} mb={2}>
                  {myDiagnosis &&
                  myDiagnosis[i] &&
                  myDiagnosis[i].arr.length > 0 ? (
                    <Divider></Divider>
                  ) : null}
                </Box>

                <Grid container item={true} xs={12} spacing={1}>
                  {myDiagnosis && myDiagnosis.length > 0
                    ? myDiagnosis[i].arr.map((item: any, index) => (
                        <Fragment key={item.id + index}>
                          <Grid
                            className={
                              index % 2 === 0
                                ? classes.withpadding
                                : classes.withOutpadding
                            }
                            item={true}
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                          >
                            <Box
                              className={
                                index % 2 === 0
                                  ? classes.cptBoxL
                                  : classes.cptBoxR
                              }
                            >
                              <Typography variant="h6">
                                <span className={classes.codeBox}>
                                  {item.code}
                                </span>
                                <LinesEllipsis
                                  text={item.value}
                                  maxLine="3"
                                  ellipsis="..."
                                  trimRight
                                  basedOn="words"
                                />
                              </Typography>
                              <DeleteOutlineIcon
                                onClick={() => spliceDiagnoseCustom(item, i)}
                                className={classes.cursor}
                                color="error"
                              ></DeleteOutlineIcon>
                            </Box>
                          </Grid>
                          {index < myDiagnosis.length - 1 ? (
                            <Grid
                              item={true}
                              xs={12}
                              className={classes.dividerMobile}
                            >
                              <Divider></Divider>
                            </Grid>
                          ) : null}
                        </Fragment>
                      ))
                    : null}
                </Grid>
              </Box>
            </Paper>
          </Box>
        );
      } else if (!field.visible && !isAdmin) {
        const name = field.name;
        let value = [];
        adminFieldsArray.push({ name, value });
      }
    }
  };

  const getRegistryProcedureData = React.useCallback(async () => {
    if (caseId) {
      const {
        data,
        status_code: statusCode
      } = await api.getRegistryProcedureById(registryId, caseId);
      if (statusCode === api.status.SUCCESS) {
        const {
          institute,
          cptCodes,
          diagnosisCodes,
          ...otherValues
        } = data.data;

        setSelectedCptCodes(cptCodes);

        setDiagnosis(diagnosisCodes);

        const newValues = {
          institute: institute.id + '',

          ...otherValues
        };
        const procedureDatakeys = Object.keys(procedureInitDataSample);
        const customprocedureDatakeys = Object.keys(newValues);
        let customizedFieldEdit = customprocedureDatakeys.filter(
          (x) => procedureDatakeys.indexOf(x) === -1
        );
        let array2 = new Array();
        let array1 = new Array();

        for (let i = 0; i < customizedFieldEdit.length; i++) {
          let name = customizedFieldEdit[i];
          if (typeof newValues[name] === 'object') {
            let arr = newValues[name];
            array2[i] = { name, arr };
          }
          if (Date.parse(newValues[name]) > 0) {
            let date = newValues[name];
            array1[i] = { name, date };
          }

          setValue(name, newValues[name]);
        }
        let cleanCptCodes = array2.filter(function () {
          return true;
        });
        let cleanDiagnosis = array2.filter(function () {
          return true;
        });
        setSelectedCustomDate(array1);
        setMyDiagnosis(cleanDiagnosis);
        setMySelectedCptCodes(cleanCptCodes);
        if (newValues.images) {
          setMedia(newValues.images);
        }
        setNotes(newValues.notes);
        setProcedureInitData(newValues);
        setValue('note', newValues.notes);
        setValue('patient_first_name', newValues.patient_first_name);
        setValue('patient_last_name', newValues.patient_last_name);
        setValue('gender', newValues.gender.toUpperCase());
        setValue('mrn', newValues.mrn);
        setValue('insurance', newValues.insurance);
        setValue('institute', newValues.institute);
        setValue('type', newValues.type);
        setValue('role', newValues.role ? newValues.role : '');
        setValue('findings', newValues.findings);
        setValue('vendors', newValues.vendors);
        setValue('complications', newValues.complications);
        setValue('outcome', newValues.outcome);
        setSelectedDate(newValues.date_of_birth);
        setSurgeryDate(newValues.date);
      }
    }
  }, [caseId, setValue, registryId]);

  const getSpecialityList = React.useCallback(async () => {
    const { data } = await api.getSpecialites();
    const specialityList = data.map((category) => {
      return {
        label: category.name,
        value: category.id + ''
      };
    });
    setSpecialityList(specialityList);
  }, []);

  const getNameOfFields = (fields) => {
    var names: any[] = [];
    for (var i = 0; i < fields.length; ++i) {
      names.push(fields[i]['name']);
    }
    setRegistryFields(names);
  };

  const getRegistryName = async () => {
    const { data, status_code: statusCode } = await api.getRegistryById(
      registryId
    );

    if (statusCode === api.status.SUCCESS) {
      setRegistryName(data.name);
      getNameOfFields(data.fields);
      checkCustomizedField(data.fields);
      if (data.owner.id === user.id) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  };

  const getHospitals = async () => {
    const { data, status_code: statusCode } = await api.getUserHospitals(
      user.id
    );

    if (statusCode === api.status.SUCCESS) {
      const hospitalList = data.map(({ name, id }) => ({
        id,
        value: id + '',
        label: name
      }));
      setHospitalList(hospitalList);
    }
  };

  const getGenders = async () => {
    const { data, status_code: statusCode } = await api.getGenders();
    if (statusCode === api.status.SUCCESS) {
      const genderList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setGenderList(genderList);
    }
  };

  const getRoles = async () => {
    const { data, status_code: statusCode } = await api.getRoles();
    if (statusCode === api.status.SUCCESS) {
      const roleList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setRoleList(roleList);
    }
  };

  useEffect(() => {
    if (!props.showAddCaseRegistryMessage) {
      getSpecialityList();
    }
  }, []);

  useEffect(() => {
    if (
      (viewPositionForm || hospitalList.length === 0) &&
      !props.showAddCaseRegistryMessage
    ) {
      getHospitals();
    }
  }, [viewPositionForm, hospitalList.length]);

  useEffect(() => {
    if (!props.showAddCaseRegistryMessage) {
      getRegistryProcedureData();
    }
  }, []);

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (genderList.length === 0 && !props.showAddCaseRegistryMessage) {
      getGenders();
    }
  }, [genderList.length]);

  useEffect(() => {
    if (roleList.length === 0 && !props.showAddCaseRegistryMessage) {
      getRoles();
    }
  }, [roleList.length]);

  const uploadImages = async (registryId, files, onUploadProgress) => {
    const {
      status_code: statusCode,
      data
    } = await api.uploadRegistryProcedureMedia(
      registryId,
      files,
      onUploadProgress
    );
    if (statusCode === api.status.SUCCESS) {
      let newImages = [...imagesObj, data];

      setImagesObj(newImages);
      setUploadImageStauts('done');
    } else {
      setUploadImageStauts('error');
      setImageErrorMsg(['error', `${files.name} image was not added.`]);
    }
  };

  const onSubmit = async (values: any) => {
    let newValues = { ...values };
    if (!values.hasOwnProperty('notes')) {
      newValues = { ...values, notes: [] };
    }
    const noteLength = newValues.notes.length;

    const address = hospitalList.filter((x) => x.value === values.institute);
    const institute = address[0];
    const newNote = newValues['notes'].map((a) => a.note);
    const year = selectedDate.getFullYear();
    const monthName = months[selectedDate.getMonth()];
    const dateBirth = selectedDate.getDate();
    let date_of_birth = `${monthName} ${dateBirth}, ${year}`;

    const yearSurgery = surgeryDate.getFullYear();
    const monthNameSurgery = surgeryDate.getMonth() + 1;
    const dateSurgery = surgeryDate.getDate();
    let date = `${yearSurgery}-${monthNameSurgery}-${dateSurgery}`;

    const cptCodes: any[] = [];
    const diagnosisCodes: any[] = [];
    for (let index = 0; index < selectedCptCodes.length; index++) {
      cptCodes.push(selectedCptCodes[index]);
    }
    for (let index = 0; index < diagnosis.length; index++) {
      diagnosisCodes.push(diagnosis[index]);
    }

    const images = [...imagesObj];
    newValues = {
      ...values,
      images,
      institute,
      date_of_birth,
      date,
      diagnosisCodes,
      cptCodes,
      newNote
    };
    newValues['notes'] = [];
    for (let i = 0; i < noteLength; i++) {
      const noteObj = {
        id: i,
        note: values.notes[i].note,
        date: format(new Date(), 'MM DD YYYY')
      };
      newValues['notes'].push(noteObj);
    }
    if (mySelectedCptCodes.length > 0) {
      for (let i = 0; i < mySelectedCptCodes.length; i++) {
        const t = mySelectedCptCodes[i].name;
        const selectArr = mySelectedCptCodes[i].arr;
        newValues[`${t}`] = selectArr;
      }
    }
    if (myDiagnosis.length > 0) {
      for (let i = 0; i < myDiagnosis.length; i++) {
        const t = myDiagnosis[i].name;
        const selectArr = myDiagnosis[i].arr;
        newValues[`${t}`] = selectArr;
      }
    }
    if (adminFieldsArray.length > 0) {
      for (let i = 0; i < adminFieldsArray.length; i++) {
        const t = adminFieldsArray[i].name;
        const selectArr = adminFieldsArray[i].value;
        newValues[`${t}`] = selectArr;
      }
    }
    let CustomYear;
    let CustomMonth;
    let CutomDay;
    let CustomDate;
    if (selectedCustomDate.length > 0) {
      for (let i = 0; i < selectedCustomDate.length; i++) {
        const t = selectedCustomDate[i].name;
        const date = selectedCustomDate[i].date;
        CustomYear = date.getFullYear();
        CustomMonth = months[date.getMonth()];
        CutomDay = date.getDate();
        CustomDate = `${CustomMonth} ${CutomDay}, ${CustomYear}`;
        newValues[`${t}`] = CustomDate;
      }
    }

    const finalData = {
      ...newValues
    };
    if (imageErrorMsg.length !== 0) {
      setImageErrorAlert(true);
      setTimeout(() => setImageErrorAlert(false), 3000);
    }
    const {
      status_code: statusCode,
      data
    } = await api.createRegistryProcedure(registryId, { data: finalData });
    if (statusCode === api.status.SUCCESS) {
      props.setShowAddCaseRegistryMessage(true);
      let messageSuccess = ['success', 'Case addded successfully'];
      props.setAddCaseRegistryMessage(messageSuccess);
      setTimeout(() => props.setShowAddCaseRegistryMessage(false), 3000);
      history.push('/registry/' + registryId);
    } else {
      let messageError;
      setShowAddCaseMessageError(true);
      if (data.message) {
        messageError = ['error', `${data.message}`];
      } else {
        messageError = ['error', `${data}`];
      }
      setAddCaseMessage(messageError);
      setTimeout(() => setShowAddCaseMessageError(false), 3000);
    }
  };

  const EmptyNote = (notes) => {
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].length === 0) {
        notes.splice(i, 1);
      }
    }
    return notes;
  };

  const onEditSubmit = async (values: any) => {
    setIsBlocking(false);
    const images = [...media, ...imagesObj];
    let newValues = { ...values, images };
    if (!values.hasOwnProperty('notes')) {
      newValues = { ...values, notes: [], images };
    }
    const noteLength = newValues.notes.length;
    if (typeof selectedDate !== 'string') {
      const year = selectedDate.getFullYear();
      const monthName = months[selectedDate.getMonth()];
      const date = selectedDate.getDate();
      const formattedBirthDate = `${monthName} ${date}, ${year}`;
      newValues['date_of_birth'] = formattedBirthDate;
    } else {
      newValues['date_of_birth'] = selectedDate;
    }

    if (typeof surgeryDate !== 'string') {
      const yearSurgery = surgeryDate.getFullYear();
      const monthNameSurgery = months[surgeryDate.getMonth()];
      const dateSurgery = surgeryDate.getDate();
      const formattedSurgeryDate = `${monthNameSurgery} ${dateSurgery}, ${yearSurgery}`;
      newValues['date'] = formattedSurgeryDate;
    } else {
      newValues['date'] = surgeryDate;
    }

    const cptCodes: any[] = [];
    const diagnosisCodes: any[] = [];

    for (let index = 0; index < selectedCptCodes.length; index++) {
      cptCodes.push(selectedCptCodes[index]);
    }
    for (let index = 0; index < diagnosis.length; index++) {
      diagnosisCodes.push(diagnosis[index]);
    }

    newValues['cptCodes'] = cptCodes;
    newValues['diagnosisCodes'] = diagnosisCodes;
    const address = hospitalList.filter((x) => x.value === values.institute);
    newValues['institute'] = address[0];
    if (newValues['mrn'] === null) {
      newValues['mrn'] = '';
    }
    if (newValues['role'] === null) {
      newValues['role'] = '';
    }
    const newEditeNote = newValues['notes'].map((a) => a.note);
    const filterNote = EmptyNote(newEditeNote);
    newValues['notes'] = filterNote;
    let cleanSelectedCptCodes = mySelectedCptCodes.filter(function () {
      return true;
    });
    let cleanDiagnosis = myDiagnosis.filter(function () {
      return true;
    });
    let cleanCustomDate = selectedCustomDate.filter(function () {
      return true;
    });
    if (cleanSelectedCptCodes.length > 0) {
      for (let i = 0; i < cleanSelectedCptCodes.length; i++) {
        const t = cleanSelectedCptCodes[i].name;
        const selectArr = cleanSelectedCptCodes[i].arr;
        newValues[`${t}`] = selectArr;
      }
    }
    if (cleanDiagnosis.length > 0) {
      for (let i = 0; i < cleanDiagnosis.length; i++) {
        const t = cleanDiagnosis[i].name;
        const selectArr = cleanDiagnosis[i].arr;
        newValues[`${t}`] = selectArr;
      }
    }
    if (adminFieldsArray.length > 0) {
      for (let i = 0; i < adminFieldsArray.length; i++) {
        const t = adminFieldsArray[i].name;
        const selectArr = adminFieldsArray[i].value;
        newValues[`${t}`] = selectArr;
      }
    }
    let CustomYear;
    let CustomMonth;
    let CutomDay;
    let CustomDate;
    if (cleanCustomDate.length > 0) {
      for (let i = 0; i < cleanCustomDate.length; i++) {
        const t = cleanCustomDate[i].name;
        const date = new Date(cleanCustomDate[i].date);
        CustomYear = date.getFullYear();
        CustomMonth = months[date.getMonth()];
        CutomDay = date.getDate();
        CustomDate = `${CustomMonth} ${CutomDay}, ${CustomYear}`;
        newValues[`${t}`] = CustomDate;
      }
    }
    newValues['notes'] = [];
    for (let i = 0; i < noteLength; i++) {
      const noteObj = {
        id: i,
        note: values.notes[i].note,
        date: format(new Date(), 'mm DD YYYY')
      };
      newValues['notes'].push(noteObj);
    }
    const finalData = {
      ...newValues
    };
    if (imageErrorMsg.length !== 0) {
      setImageErrorAlert(true);
      setTimeout(() => setImageErrorAlert(false), 1000);
    }
    const {
      status_code: statusCode,
      data
    } = await api.editRegistryProcedureById(registryId, caseId, {
      data: finalData
    });
    if (statusCode === api.status.SUCCESS) {
      props.setShowAddCaseRegistryMessage(true);
      props.setAddCaseRegistryMessage([
        'success',
        'Case info is successfully updated!'
      ]);
      setTimeout(() => {
        props.setShowAddCaseRegistryMessage(false);
      }, 3000);
      history.push('/registrycase/' + registryId + '/' + caseId);
    } else {
      props.setShowAddCaseRegistryMessage(true);
      props.setAddCaseRegistryMessage(['error', { data }]);
      setTimeout(() => props.setShowAddCaseRegistryMessage(false), 3000);
    }
  };
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setIsBlocking(true);
  };

  const handleCustomDateChange = (date: any, title) => {
    setValue(title, date);
    const newDate = selectedCustomDate.slice();
    selectedCustomDate.forEach((item, index) => {
      if (item.name === title) {
        newDate[index].date = date;
      }
    });

    setSelectedCustomDate(newDate);
    setIsBlocking(true);
  };

  const handleSurgeryDateChange = (date: any) => {
    setSurgeryDate(date);
    setIsBlocking(true);
  };

  const handleDialogClose = () => {
    setOpenSelectDialog(false);
  };
  const handleNewDialogClose = () => {
    const openDialog = openSelectDialognew.slice();
    openDialog[inputIndex] = false;
    setOpenSelectDialognew(openDialog);
  };
  const spliceCptCode = (code: any, index: number) => {
    setSelectedCptCodes(selectedCptCodes.filter((item) => item !== code));
  };

  const spliceCptCodeCustom = (code: any, index: number) => {
    const mySelectedCptCodesCopy = mySelectedCptCodes.slice();
    let newData = mySelectedCptCodes[index].arr.filter((item) => item !== code);
    mySelectedCptCodesCopy[index].arr = newData;
    setMySelectedCptCodes(mySelectedCptCodesCopy);
  };

  const spliceImage = async (index: any, id) => {
    setImages((images) => images.filter((img, i) => i !== index));
    setImagesObj((images) => images.filter((img, i) => i !== index));
    const deletedImage = imagesObj.filter((img, i) => i === index);
  };

  const deleteImage = async (index: any, id: any) => {
    const { status_code: statusCode } = await api.deleteImage(
      user.id,
      caseId,
      id
    );
    if (statusCode === api.status.SUCCESS || registryId) {
      setMedia((media) => media.filter((img, i) => i !== index));
    }
  };

  const spliceDiagnose = (code: any) => {
    setDiagnosis(diagnosis.filter((item) => item !== code));
  };

  const spliceDiagnoseCustom = (code: any, index) => {
    const mySelectedCptCodesCopy = myDiagnosis.slice();
    let newData = myDiagnosis[index].arr.filter((item) => item !== code);
    mySelectedCptCodesCopy[index].arr = newData;
    setMyDiagnosis(mySelectedCptCodesCopy);
  };

  const deleteNote = async (index: any, id: any) => {
    const { status_code: statusCode } = await api.deleteNote(
      user.id,
      caseId,
      id
    );
    if (statusCode === api.status.SUCCESS) {
      setEditedNotes((editedNotes) =>
        editedNotes.filter((note, i) => i !== index)
      );
    }
  };

  const handleCapture = async ({ target }: any) => {
    setIsBlocking(true);
    if (target.files.length > 0) {
      let newImages = [...images, target.files[0]];
      setImages(newImages);
      setImageName(target.files[0].name);

      setUploadImageStauts('upload');

      uploadImages(registryId, target.files[0], api.config.onUploadProgress);
    }
  };

  const getCurrentPlan = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getCurrentPlan();
    if (statusCode === api.status.SUCCESS) {
      setCurrentPlan(data);
    }
  }, []);

  const getUserToken = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getUserToken();
    setNewPlan(false);
    if (statusCode === api.status.SUCCESS) {
      setUserToken(data.token);
    } else if (statusCode === api.status.ERROR_UPDATE) {
      setTokenError(true);
      setMessageError(data);
    }
  }, []);

  React.useEffect(() => {
    getUserToken();
    if (isOpen) {
      getCurrentPlan();
    }
  }, [isOpen]);

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth={false}
        open={openSelectDialog}
        onClose={handleDialogClose}
        classes={{ paper: classes.selectPaper }}
      >
        <div className={classes.dialog}>
          <SelectCodes
            diagnosis={diagnosis}
            selectedCptCodes={selectedCptCodes}
            index={-1}
            selectedCodeObjCptCodes={selectedCptCodes}
            selectedCodeObjDiagnosis={diagnosis}
            setSelectedCptCodes={setSelectedCptCodes}
            setDiagnosis={setDiagnosis}
            searchPath={searchPath}
            closeDialog={setOpenSelectDialog}
          />
        </div>
      </Dialog>
      <Dialog
        maxWidth={false}
        open={openSelectDialognew[inputIndex]}
        onClose={handleNewDialogClose}
        classes={{ paper: classes.selectPaper }}
      >
        <div className={classes.dialog}>
          <SelectCodes
            diagnosis={myDiagnosis}
            selectedCptCodes={mySelectedCptCodes}
            index={inputIndex}
            selectedCodeObjCptCodes={mySelectedCptCodes[inputIndex]}
            selectedCodeObjDiagnosis={myDiagnosis[inputIndex]}
            setSelectedCptCodes={setMySelectedCptCodes}
            setDiagnosis={setMyDiagnosis}
            searchPath={searchPath}
            closeDialog={handleNewDialogClose}
          />
        </div>
      </Dialog>
      <form
        onKeyPress={onKeyPress}
        onChange={() => setIsBlocking(true)}
        onSubmit={handleSubmit(caseId ? onEditSubmit : onSubmit)}
      >
        <Prompt when={caseId ? isBlocking : false} message={() => false} />
        <Box className={classes.addCaseHeader}>
          {caseId ? (
            <>
              <Typography
                onClick={() => {
                  setIsOpenConfirmClose(true);
                  history.push('/registry/' + registryId);
                }}
                variant="h1"
                className={classes.link}
              >
                {'Registries >  '}
              </Typography>
              <Typography
                onClick={() => {
                  setIsOpenConfirmClose(true);
                  history.push('/registrycase/' + registryId + '/' + caseId);
                }}
                variant="h1"
                className={classes.link}
              >
                {procedureInitData.patient_first_name +
                  ' ' +
                  procedureInitData.patient_last_name +
                  ' > '}
              </Typography>
              <Typography color="primary" className={classes.pageName}>
                Edit case
              </Typography>
            </>
          ) : (
            <>
              <Typography
                onClick={() => {
                  history.push('/registries');
                }}
                variant="h1"
                className={classes.link}
              >
                {'Registries > '}
              </Typography>
              <Typography
                onClick={() => {
                  history.push('/registry/' + registryId);
                }}
                variant="h1"
                className={classes.link}
              >
                {`${registryName} > `}
              </Typography>
              <Typography color="primary" className={classes.pageName}>
                Add Case
              </Typography>
            </>
          )}
        </Box>
        <Box my={2}>
          <Paper>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h2" className={classes.header}>
                Patient info
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Box className={classes.InputContainerSecond}>
              <Grid className={classes.py0} item={true} container spacing={2}>
                <Grid
                  className={[classes.py0, classes.mb0Mobile].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    className={classes.mt0}
                    as={TextField}
                    required
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk
                      }
                    }}
                    label="Patient first name "
                    control={control}
                    name="patient_first_name"
                    error={errors.patient_first_name ? true : false}
                    helperText={
                      errors.patient_first_name &&
                      errors.patient_first_name.message
                    }
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid
                  className={[classes.py0, classes.mb0Mobile].join(' ')}
                  item
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    as={TextField}
                    className={classes.mt0}
                    required
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk
                      }
                    }}
                    variant="outlined"
                    name="patient_last_name"
                    control={control}
                    label="Patient last name "
                    margin="normal"
                    helperText={
                      errors.patient_last_name &&
                      errors.patient_last_name.message
                    }
                    fullWidth
                    error={errors.patient_last_name ? true : false}
                  />
                </Grid>
                <Grid
                  className={[classes.py0mb, classes.mbGender].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <ReactHookFormSelect
                    required
                    name="gender"
                    label="Gender "
                    control={control}
                    className={classes.mt0}
                    defaultValue=""
                    variant="outlined"
                    margin="normal"
                    setIsBlocking={setIsBlocking}
                    error={errors.gender ? true : false}
                    helperText={errors.gender && errors.gender.message}
                  >
                    {genderList.length > 0
                      ? genderList.map((gender: any, index) => (
                          <MenuItem
                            key={gender.value + index}
                            value={gender.value}
                          >
                            {gender.label}
                          </MenuItem>
                        ))
                      : null}
                  </ReactHookFormSelect>
                </Grid>

                <Grid
                  className={[
                    classes.surgeryDateField,
                    classes.py0,
                    classes.mb0DateMobile
                  ].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      className={classes.mt0}
                      variant="inline"
                      autoOk={true}
                      inputVariant="outlined"
                      value={selectedDate}
                      onChange={handleDateChange}
                      label="Date of birth "
                      name="date_of_birth"
                      format="MMM dd, yyyy"
                      fullWidth
                      maxDate={new Date()}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {registryFields.indexOf('mrn') !== -1 ? (
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      variant="outlined"
                      name="mrn"
                      defaultValue=""
                      control={control}
                      label="Medical record number"
                      margin="normal"
                      fullWidth
                      error={errors.mrn ? true : false}
                    />
                  </Grid>
                ) : null}
                {registryFields.indexOf('insurance') !== -1 ? (
                  <Grid
                    className={classes.py0}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      variant="outlined"
                      name="insurance"
                      defaultValue=""
                      control={control}
                      label="Insurance"
                      margin="normal"
                      fullWidth
                      error={errors.insurance ? true : false}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Paper>
        </Box>
        {registryFields.indexOf('cptCodes') !== -1 ? (
          <Box mb={2}>
            <Paper>
              <Box>
                <Box className={classes.title}>
                  <Typography variant="h2" className={classes.inline}>
                    Diagnosis (ICD-9/10)
                  </Typography>

                  <AddIcon
                    onClick={() => {
                      setSearchPath('icd_lookup');
                      setOpenSelectDialog(true);
                    }}
                    color="secondary"
                    className={classes.cursor}
                  ></AddIcon>
                </Box>

                <Box mt={1} mb={2}>
                  {diagnosis.length > 0 ? <Divider></Divider> : null}
                </Box>

                <Grid container item={true} xs={12} spacing={1}>
                  {diagnosis.length > 0
                    ? diagnosis.map((item: any, index) => (
                        <Fragment key={item.id + index}>
                          <Grid
                            className={
                              index % 2 === 0
                                ? classes.withpadding
                                : classes.withOutpadding
                            }
                            item={true}
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                          >
                            <Box
                              className={
                                index % 2 === 0
                                  ? classes.cptBoxL
                                  : classes.cptBoxR
                              }
                            >
                              <Typography variant="h6">
                                <span className={classes.codeBox}>
                                  {item.code}
                                </span>
                                <LinesEllipsis
                                  text={item.value}
                                  maxLine="3"
                                  ellipsis="..."
                                  trimRight
                                  basedOn="words"
                                />
                              </Typography>
                              <DeleteOutlineIcon
                                onClick={() => spliceDiagnose(item)}
                                className={classes.cursor}
                                color="error"
                              ></DeleteOutlineIcon>
                            </Box>
                          </Grid>
                          {index < diagnosis.length - 1 ? (
                            <Grid
                              item={true}
                              xs={12}
                              className={classes.dividerMobile}
                            >
                              <Divider></Divider>
                            </Grid>
                          ) : null}
                        </Fragment>
                      ))
                    : null}
                </Grid>
              </Box>
            </Paper>
          </Box>
        ) : null}
        {registryFields.indexOf('cptCodes') !== -1 ? (
          <Box mb={2}>
            <Paper>
              <Box>
                <Box className={classes.title}>
                  <Typography variant="h2" className={classes.inline}>
                    CPT Code
                  </Typography>
                  <AddIcon
                    onClick={() => {
                      setSearchPath('cpt_lookups');
                      setOpenSelectDialog(true);
                    }}
                    color="secondary"
                    className={classes.cursor}
                  ></AddIcon>
                </Box>
                <Box mt={1} mb={2}>
                  {selectedCptCodes.length > 0 ? <Divider></Divider> : null}
                </Box>
                <Grid container item={true} xs={12} spacing={1}>
                  {selectedCptCodes.length > 0
                    ? selectedCptCodes.map((item: any, index) => (
                        <Fragment key={item.id + index}>
                          <Grid
                            className={
                              index % 2 === 0
                                ? classes.withpadding
                                : classes.withOutpadding
                            }
                            item={true}
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                          >
                            <Box
                              className={
                                index % 2 === 0
                                  ? classes.cptBoxL
                                  : classes.cptBoxR
                              }
                            >
                              <Typography variant="h6">
                                <span className={classes.codeBox}>
                                  {item.code}
                                </span>
                                <LinesEllipsis
                                  text={item.value}
                                  maxLine="3"
                                  ellipsis="..."
                                  trimRight
                                  basedOn="words"
                                />
                              </Typography>
                              <DeleteOutlineIcon
                                onClick={() => spliceCptCode(item, index)}
                                className={classes.cursor}
                                color="error"
                              ></DeleteOutlineIcon>
                            </Box>
                          </Grid>
                          {index < selectedCptCodes.length - 1 ? (
                            <Grid
                              item={true}
                              xs={12}
                              className={classes.dividerMobile}
                            >
                              <Divider></Divider>
                            </Grid>
                          ) : null}
                        </Fragment>
                      ))
                    : null}
                </Grid>
              </Box>
            </Paper>
          </Box>
        ) : null}
        <Box mb={2}>
          <Paper>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h2" className={classes.header}>
                Procedure details
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Box className={classes.InputContainerSecond}>
              <Grid className={classes.py0} container item={true} spacing={2}>
                <Grid
                  className={classes.dateOfSurgery}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      variant="inline"
                      inputVariant="outlined"
                      className={classes.mt0}
                      autoOk={true}
                      value={surgeryDate}
                      onChange={handleSurgeryDateChange}
                      label="Date of procedure "
                      name="date"
                      format="MMM dd, yyyy"
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  className={[classes.py0, classes.mb0Mobile].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <ReactHookFormSelect
                    required
                    name="institute"
                    label="Institution "
                    control={control}
                    className={classes.mt0}
                    defaultValue=""
                    variant="outlined"
                    margin="normal"
                    setIsBlocking={setIsBlocking}
                    error={errors.institute ? true : false}
                    helperText={errors.institute && errors.institute.message}
                  >
                    {hospitalList.length > 0 ? (
                      hospitalList.map((hospital: any, index) => (
                        <MenuItem
                          key={hospital.value + index}
                          value={hospital.value}
                        >
                          {hospital.label}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        key="add new position"
                        value=""
                        onClick={() => {
                          setShowEndDate(false);
                          setViewPositionForm(true);
                        }}
                      >
                        Add position
                      </MenuItem>
                    )}
                  </ReactHookFormSelect>
                </Grid>
                <Grid
                  className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                  item={true}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={6}
                >
                  <Controller
                    as={TextField}
                    className={classes.mt0}
                    required
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk
                      }
                    }}
                    variant="outlined"
                    name="type"
                    control={control}
                    label="Procedure type "
                    margin="normal"
                    helperText={errors.type && errors.type.message}
                    fullWidth
                    error={errors.type ? true : false}
                  />
                </Grid>

                {registryFields.indexOf('role') !== -1 ? (
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <ReactHookFormSelect
                      name="role"
                      label="Role "
                      className={classes.mt0}
                      control={control}
                      defaultValue=""
                      variant="outlined"
                      margin="normal"
                      setIsBlocking={setIsBlocking}
                      error={errors.role ? true : false}
                      helperText={errors.role && errors.role.message}
                    >
                      {roleList.length > 0
                        ? roleList.map((role: any, index) => (
                            <MenuItem
                              key={role.value + index}
                              value={role.value}
                            >
                              {role.label}
                            </MenuItem>
                          ))
                        : null}
                    </ReactHookFormSelect>
                  </Grid>
                ) : null}
                {registryFields.indexOf('vendors') !== -1 ? (
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      name="vendors"
                      variant="outlined"
                      control={control}
                      label="Vendors"
                      margin="normal"
                      fullWidth
                      error={errors.vendors ? true : false}
                    />
                  </Grid>
                ) : null}
                {registryFields.indexOf('findings') !== -1 ? (
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      defaultValue=""
                      className={classes.mt0}
                      name="findings"
                      variant="outlined"
                      control={control}
                      label="Findings"
                      margin="normal"
                      fullWidth
                      error={errors.findings ? true : false}
                    />
                  </Grid>
                ) : null}
                {registryFields.indexOf('complications') !== -1 ? (
                  <Grid
                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      as={TextField}
                      className={classes.mt0}
                      defaultValue=""
                      variant="outlined"
                      name="complications"
                      control={control}
                      label="Complications"
                      margin="normal"
                      fullWidth
                      error={errors.complications ? true : false}
                    />
                  </Grid>
                ) : null}

                {registryFields.indexOf('outcome') !== -1 ? (
                  <Grid
                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                    item={true}
                    xs={12}
                    sm={4}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      defaultValue=""
                      as={TextField}
                      className={classes.mt0}
                      variant="outlined"
                      name="outcome"
                      control={control}
                      label="Outcome"
                      margin="normal"
                      fullWidth
                      error={errors.outcome ? true : false}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Paper>
        </Box>

        {customizedField && customizedField.length > 0 ? (
          <>
            <Box my={3}>
              <Paper>
                <Grid container item={true} spacing={0}>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h2" className={classes.header}>
                      Custom fields
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Box className={classes.CustomInputContainer}>
                    <Grid
                      className={classes.py0}
                      container
                      item={true}
                      spacing={2}
                    >
                      {customizedField.map((field: any) =>
                        getFieldsInput(field)
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Paper>
            </Box>
            <>
              {customizedField.map((field: any) =>
                field.type === 'CPT' || field.type === 'Diagnosis'
                  ? getSelectOption(field)
                  : null
              )}
            </>
          </>
        ) : null}
        {registryFields.indexOf('notes') !== -1 ? (
          <Box mb={2}>
            <Paper>
              <Box p={3} className={classes.notes}>
                <Box className={classes.noteTitle}>
                  <Typography variant="h2" className={classes.inline}>
                    Notes
                  </Typography>

                  <Box ml={'20px'}>
                    <AddIcon
                      onClick={() => setShowAddNoteText(true)}
                      color="secondary"
                      className={classes.cursor}
                    ></AddIcon>
                  </Box>
                </Box>
                {fields && fields.length > 0 ? (
                  <Box mt={1} mb={2}>
                    <Divider></Divider>
                  </Box>
                ) : null}
                <Grid
                  container
                  item={true}
                  xs={12}
                  className={classes.notesContainer}
                >
                  {fields.map((item, index) => {
                    return (
                      <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        key={item.note + index}
                        className={classes.noteFieldEdited}
                      >
                        <TextField
                          name={`notes[${index}].note`}
                          defaultValue={`${item.note}`}
                          label="Note"
                          inputRef={register()}
                          variant="outlined"
                          fullWidth
                          maxRows={3}
                          multiline
                        />

                        <DeleteOutlineIcon
                          onClick={() => {
                            remove(index);
                          }}
                          className={classes.cursor}
                          color="error"
                        ></DeleteOutlineIcon>
                      </Grid>
                    );
                  })}
                  {showAddNoteText ? (
                    <Grid
                      item={true}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.noteFieldEdited}
                    >
                      <TextField
                        variant="outlined"
                        name="note"
                        value={editNoteField}
                        onChange={(e) => {
                          setEditNoteField(e.target.value);
                        }}
                        label="Note"
                        margin="normal"
                        fullWidth
                        maxRows={3}
                        multiline
                        minRows={3}
                      />
                      <AddIcon
                        onClick={() => {
                          if (editNoteField.length && registryId) {
                            if (registryId) {
                              append({
                                date: new Date(),
                                id: 0,
                                note: editNoteField
                              });
                            } else {
                              append({ note: editNoteField, id: 0 });
                            }
                          }
                          setEditNoteField('');
                          setShowAddNoteText(false);
                        }}
                        color="secondary"
                        className={classes.cursor}
                      ></AddIcon>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            </Paper>
          </Box>
        ) : null}
        {registryFields.indexOf('images') !== -1 &&
        currentPlan?.plan?.name !== 'Free' ? (
          <Box mb={2}>
            <Paper>
              <Box>
                <Box className={classes.titleImage}>
                  <div>Images</div>
                  <label htmlFor="raised-button-file">
                    <AddIcon
                      color="secondary"
                      className={classes.cursor}
                    ></AddIcon>
                  </label>
                  <input
                    onChange={handleCapture}
                    id="raised-button-file"
                    type="file"
                    hidden
                    onClick={(event) => (event.currentTarget.value = '')}
                    accept="image/*"
                  />
                </Box>
                {images && images.length > 0 ? (
                  <Box className={classes.hrContainer}>
                    <Divider />
                  </Box>
                ) : null}
                <Grid
                  item={true}
                  className={classes.imageContainer}
                  xs={12}
                  sm={12}
                  md={12}
                  container
                >
                  {images !== undefined && images.length > 0
                    ? images.map((image: any, index) => (
                        <Grid
                          item={true}
                          xs={6}
                          sm={4}
                          md={3}
                          lg={4}
                          xl={2}
                          key={Image.name + '' + index}
                        >
                          <Box className={classes.imgBox} mx={2} my={0.5}>
                            <Box className={classes.image}>
                              <img
                                className={classes.caseImage}
                                src={URL.createObjectURL(image)}
                                alt={URL.createObjectURL(image)}
                              />
                            </Box>
                            <Box className={classes.imageFooter}>
                              <Divider className={classes.divider} />
                              <Box className={classes.imageName}>
                                <Typography
                                  noWrap
                                  variant="overline"
                                  align="left"
                                >
                                  {image?.name}
                                </Typography>
                                {uploadImageStauts === 'done' ||
                                imageName !== image?.name ? (
                                  <DeleteOutlineIcon
                                    onClick={() => spliceImage(index, image)}
                                    className={classes.cursor}
                                    color="error"
                                  ></DeleteOutlineIcon>
                                ) : uploadImageStauts === 'error' &&
                                  imageName === image?.name ? (
                                  <ErrorOutlineIcon
                                    color="error"
                                    className={classes.cursor}
                                  />
                                ) : uploadImageStauts === 'upload' &&
                                  imageName === image?.name ? (
                                  <HourglassEmptyIcon color="primary" />
                                ) : null}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                    : null}
                  {media.length > 0
                    ? media.map((image: any, index) => (
                        <Grid
                          item={true}
                          xs={6}
                          sm={3}
                          md={3}
                          lg={4}
                          xl={2}
                          key={
                            registryId
                              ? image.url.href_small + index
                              : image.urls.href_small + index
                          }
                        >
                          <Box className={classes.imgBox} mx={2} my={0.5}>
                            <Box className={classes.image}>
                              <img
                                className={classes.caseImage}
                                src={
                                  registryId
                                    ? image.url.href_small
                                    : image.urls.href_small
                                }
                                alt="caselogImage"
                              />
                            </Box>
                            <Box className={classes.hrContainer}>
                              <Divider className={classes.divider} />
                            </Box>
                            <Box className={classes.imageFooter}>
                              <Box className={classes.imageName}>
                                <Typography
                                  noWrap
                                  variant="overline"
                                  align="left"
                                >
                                  {registryId ? image?.name : image?.image_name}
                                </Typography>

                                <DeleteOutlineIcon
                                  onClick={() => deleteImage(index, image.id)}
                                  className={classes.cursor}
                                  color="error"
                                ></DeleteOutlineIcon>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                    : null}
                </Grid>
              </Box>
            </Paper>
          </Box>
        ) : null}
        <Box className={classes.buttonsContainer}>
          {caseId ? (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={
                isBlocking ? classes.saveButtonEdited : classes.saveButton
              }
            >
              Save
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={
                isBlocking ? classes.addCaseButtonEdited : classes.addCaseButton
              }
            >
              Add
            </Button>
          )}
        </Box>
      </form>
      <Dialog
        onClose={() => setViewPositionForm(false)}
        aria-labelledby="simple-dialog-title"
        open={viewPositionForm}
      >
        {' '}
        <Box className={classes.formTitle}>
          <Typography variant="subtitle1">Add Current Position</Typography>
          <ClearIcon
            className={classes.clearIconDialog}
            onClick={() => setViewPositionForm(false)}
          />
        </Box>
        <PositionForm
          title="position"
          handleEndDateChange={handleEndDateChange}
          handleStartDateChange={handleStartDateChange}
          showEndDate={showEndDate}
          value={0}
          setIsBlocking={setIsBlocking}
          setOnRefresh={setOnRefresh}
          specialityList={specialityList}
          startDate={startDate}
          setShowProfileFormMessage={setShowProfileFormMessage}
          setProfileMessage={setProfileMessage}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setShowEndDate={setShowEndDate}
          setViewPositionForm={setViewPositionForm}
          endDate={endDate}
        ></PositionForm>
      </Dialog>
      <ConfirmCloseDialog
        isOpen={isOpenConfirmClose}
        title={'Are you sure you want to exit this screen?'}
        firstOption={'Stay on page'}
        secondOption={'Exit'}
        handleOptionTwo={() => {
          setIsBlocking(false);
          setIsOpenConfirmClose(false);
        }}
        handleOptionOne={() => setIsOpenConfirmClose(false)}
        onClose={() => setIsOpenConfirmClose(false)}
      >
        Exiting this page will result in loss of unsaved information
      </ConfirmCloseDialog>
      <Snackbar open={showProfileFormMessage}>
        <Alert onClose={() => setShowProfileFormMessage(false)} color="success">
          Your position is successfully added!
        </Alert>
      </Snackbar>
      <UpgradeDialog
        isOpen={isOpen && !tokenError}
        onClose={() => setisOpen(false)}
        handleChange={() => {}}
        handleCloseDialog={handleCloseDialog}
        userToken={userToken}
        setNewPlan={setNewPlan}
        subscriptionId={currentPlan?.subscr_id}
      />
      <Snackbar open={showError} color="warning">
        <Alert onClose={() => setShowError(false)} severity="warning">
          You can't manage your plan since you have cancelled subscription
        </Alert>
      </Snackbar>
      <Snackbar open={showAddCaseMessageError}>
        <Alert
          action={
            addCaseMessage[0] === 'error' &&
            addCaseMessage[1] === 'You need to upgrade your plan' ? (
              <Button
                onClick={() => {
                  if (!tokenError) {
                    setisOpen(true);
                    setShowAddCaseMessageError(false);
                  } else {
                    setShowError(true);
                    setShowAddCaseMessageError(false);
                  }
                }}
              >
                Manage plans
              </Button>
            ) : null
          }
          onClose={() => setShowAddCaseMessageError(false)}
          color={addCaseMessage[0]}
          severity={addCaseMessage[0]}
        >
          {addCaseMessage[1]}
        </Alert>
      </Snackbar>{' '}
      <Snackbar open={imageErrorAlert}>
        <Alert
          onClose={() => setImageErrorAlert(false)}
          color={imageErrorMsg[0]}
          severity={imageErrorMsg[0]}
        >
          {imageErrorMsg[1]}
        </Alert>
      </Snackbar>{' '}
    </div>
  );
};
export default AddCaseRegistry;
