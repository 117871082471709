import React, { useEffect } from 'react';
import { Box, Grid, MenuItem, TextField, InputAdornment, Button } from '@material-ui/core';
import useStyles from './Styles';
import ReactHookFormSelect from '../select/ReactHookFormSelect';
import { Controller, useForm } from 'react-hook-form';
import * as api from '../../services/apiService';
import DeleteDialog from '../Dialogs/DeleteDialog';
import { ReimbursementType } from '../../types';

function ReimbursementForm(props) {
    const classes = useStyles();
    const [statesList, setStatesList] = React.useState<any[]>([]);
    const [reimbursementModelList, setReimbursementModelList] = React.useState<any[]>([]);
    const [thresholdList, setThresholdList] = React.useState<any[]>([]);
    const [reimbursementInit, setReimbursementInit] = React.useState<ReimbursementType>({
        practiceState: "",
        reimbursementModel: "",
        threshold: "",
        amount: 0,
        rvusMultiplier: 0
    });
    const [reimbursement, setReimbursement] = React.useState<ReimbursementType>(props.reimbursement ? props.reimbursement : { ...reimbursementInit });
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const {
        errors,
        control,
        setValue,
        getValues,
        handleSubmit
    } = useForm({
        defaultValues: reimbursement,
        mode: 'all'
    });

    const onSubmit = async () => {
        await api.updateReimbursement(props.userId, filterReimbursement());
        props.setOnRefresh(true);
        props.setShowProfileFormMessage(true);
        props.setProfileMessage(['success', `Reimbursement ${props.editedReimbursement ? 'edited' : 'added'} successfully`]);
        setTimeout(() => props.setShowProfileFormMessage(false), 1000);
        props.setViewReimbursementForm(false);
    }

    const filterReimbursement = () => {
        return {
            practice_state: reimbursement.practiceState,
            reimbursement_model: reimbursement.reimbursementModel,
            threshold: reimbursement.reimbursementModel == 'MIXED' ? reimbursement.threshold : "",
            amount: reimbursement.reimbursementModel == 'MIXED' ? reimbursement.amount : 0,
            rvus_multiplier: reimbursement.reimbursementModel == 'SALARY_BASED' ? 0 : reimbursement.rvusMultiplier
        }
    }

    const getStatesList = React.useCallback(async () => {
        const { data } = await api.getStatesList();
        const states = Object.keys(data).map((key) => ({
            value: key,
            label: data[key]
        }));
        setStatesList(states);
    }, []);

    useEffect(() => {
        if (statesList.length === 0) {
            getStatesList();
        }
    }, []);

    const getReimbursementModelList = React.useCallback(async () => {
        const { data } = await api.getReimbursementModelList();
        const reimbursementModels = Object.keys(data).map((key) => ({
            value: key,
            label: data[key]
        }));
        setReimbursementModelList(reimbursementModels);
    }, []);

    useEffect(() => {
        if (reimbursementModelList.length === 0) {
            getReimbursementModelList();
        }
    }, []);

    const getThresholdList = React.useCallback(async () => {
        const { data } = await api.getThresholdList();
        const threshold = Object.keys(data).map((key) => ({
            value: key,
            label: data[key]
        }));
        setThresholdList(threshold);
    }, []);

    useEffect(() => {
        if (reimbursementModelList.length === 0) {
            getThresholdList();
        }
    }, []);

    const handleValueChange = (e) => {
        const updatedReimbursement = { ...reimbursement };
        updatedReimbursement[e.target.name] = e.target.value;
        setReimbursement(updatedReimbursement);
    }

    const onDelete = async () => {
        const { data } = await api.deleteReimbursement(props.userId);
        setReimbursement({ ...reimbursementInit });
        setOpenDelete(false);
        props.resetReimbursement();
        props.setViewReimbursementForm(false);
        props.setEditedReimbursement(false);
        props.setOnRefresh(true);
        props.setShowProfileFormMessage(true);
        props.setProfileMessage(['success', `Reimbursement deleted successfully`]);
        setTimeout(() => props.setShowProfileFormMessage(false), 1000);

    }

    const isRVUsBased = () => {
        return reimbursement.reimbursementModel === "RVUS_BASED";
    }

    const isMixed = () => {
        return reimbursement.reimbursementModel == "MIXED";
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
        >
            <Box className={classes.accordionContainer} onChange={handleValueChange}>
                <DeleteDialog
                    isOpen={openDelete}
                    title={'Delete reimbursement'}
                    firstOption={'Delete anyway'}
                    secondOption={'Cancel'}
                    handleOptionOne={onDelete}
                    handleOptionTwo={() => setOpenDelete(false)}
                    onClose={() => { }}
                >
                    {'Note: Deleting reimbursement is permanent and cannot be undone.'}
                </DeleteDialog>
                <Grid className={classes.py0} item xs={12} sm={12} md={12}>
                    <ReactHookFormSelect
                        required
                        name="practiceState"
                        label="Practice State "
                        control={control}
                        defaultValue={''}
                        variant="outlined"
                        setIsBlocking={() => { }}
                        className={classes.mb1}
                        margin="normal"
                        error={errors.practiceState ? true : false}
                        helperText={
                            errors.practiceState && errors.practiceState.message
                        }
                        onClick={handleValueChange}
                    >
                        {statesList.length > 0
                            ? statesList.map((state: any) => (
                                <MenuItem
                                    key={state.value}
                                    value={state.value}
                                >
                                    {state.label}
                                </MenuItem>
                            ))
                            : null}
                    </ReactHookFormSelect>
                </Grid>
                <Grid className={classes.py0} item xs={12} sm={12} md={12}>
                    <ReactHookFormSelect
                        required
                        name="reimbursementModel"
                        label="Reimbursement model "
                        control={control}
                        defaultValue={''}
                        variant="outlined"
                        setIsBlocking={() => { }}
                        className={classes.mb1}
                        margin="normal"
                        error={errors.reimbursementModel ? true : false}
                        helperText={
                            errors.reimbursementModel && errors.reimbursementModel.message
                        }
                        onClick={handleValueChange}
                    >
                        {reimbursementModelList.length > 0
                            ? reimbursementModelList.map((model: any) => (
                                <MenuItem
                                    key={model.value}
                                    value={model.value}
                                >
                                    {model.label}
                                </MenuItem>
                            ))
                            : null}
                    </ReactHookFormSelect>
                </Grid>

                {isMixed() ? (<Grid className={classes.py0} item xs={12} sm={12} md={12}>
                    <ReactHookFormSelect
                        required
                        name="threshold"
                        label="Threshold "
                        control={control}
                        defaultValue={''}
                        variant="outlined"
                        setIsBlocking={() => { }}
                        className={classes.mb1}
                        margin="normal"
                        error={errors.threshold ? true : false}
                        helperText={
                            errors.threshold && errors.threshold.message
                        }
                        onClick={handleValueChange}
                    >
                        {thresholdList.length > 0
                            ? thresholdList.map((threshold: any) => (
                                <MenuItem
                                    key={threshold.value}
                                    value={threshold.value}
                                >
                                    {threshold.label}
                                </MenuItem>
                            ))
                            : null}
                    </ReactHookFormSelect>
                </Grid>) : null}

                {isMixed() ? (<Grid
                    className={[classes.py0].join(' ')}
                    item xs={12} sm={12} md={12}
                >
                    <Controller
                        required
                        type="number"
                        as={TextField}
                        className={classes.mb1}
                        defaultValue=""
                        name="amount"
                        variant="outlined"
                        control={control}
                        label="Amount"
                        margin="normal"
                        fullWidth
                        error={errors.amount ? true : false}
                        InputProps={{
                            inputProps: {
                                inputMode: "numeric",
                                min: 0,
                                step: 0.01,
                            },
                        }}
                    />

                </Grid>) : null}

                {isRVUsBased() || isMixed() ? (<Grid
                    className={[classes.py0].join(' ')}
                    item xs={12} sm={12} md={12}
                >
                    <Controller
                        required
                        type="number"
                        as={TextField}
                        className={classes.mb1}
                        defaultValue=""
                        name="rvusMultiplier"
                        variant="outlined"
                        control={control}
                        label="RVUs Multiplier (Reimbursement per RVU)"
                        margin="normal"
                        fullWidth
                        error={errors.rvusMultiplier ? true : false}
                        InputProps={{
                            endAdornment: <InputAdornment position="end" style={{ paddingRight: '7px' }}>$</InputAdornment>,
                            inputProps: {
                                inputMode: "numeric",
                                pattern: "[0-9]+([,\\.][0-9]+)?",
                                min: 0,
                                step: 0.01,
                            },
                        }}
                    />

                </Grid>) : null}

                {props.editedReimbursement ? (
                    <Box className={classes.buttons}>
                        <Button
                            className={classes.deleteButton}
                            variant="outlined"
                            onClick={() => setOpenDelete(true)}
                        >
                            Delete
                        </Button>
                        <Button
                            className={classes.saveButton}
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >
                            Save
                        </Button>
                    </Box>
                ) : (
                    <Button
                        className={classes.addButton}
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >
                        Add
                    </Button>
                )}
            </Box>
        </form>
    );
}

export default ReimbursementForm;
