import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const headerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '78%',
      margin: 'auto',
      padding: 4,
      [theme.breakpoints.down('lg')]: {
        width: '95%',
        paddingLeft: 0
      }
    },
    menuList: {
      width: 238,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    },
    logout: {
      marginTop: 'auto',
      borderTop: '1px solid #aaaaaa',
      padding: '20px 17px',
      color: '#ff3b30',
      fontSize: 16,
      cursor: 'pointer'
    },
    mb: {
      marginBottom: 'auto'
    },
    activeItem: {
      color: '#4aa5ff',
      fontSize: 18,
      alignContent: 'left',
      height: 62,
      textDecoration: 'none'
    },

    editLink: {
      textDecoration: 'none'
    },
    disabledItem: {
      color: '#aaaaaa',
      fontSize: 18,
      alignContent: 'left',
      height: 62
    },
    ml5: {
      marginLeft: 5,
    },
    disabled: {
      color: 'rgba(255, 255, 255, 0.5)',
      fontSize: 18,
      textDecoration: 'none'
    },
    logo: {
      width: 120,
      cursor: 'pointer'
    },
    cursor: {
      cursor: 'pointer'
    },
    selected: {
      color: '#f7b500',
      fontSize: 18,
      paddingRight: 16,
      textDecoration: 'none'
    },
    link: {
      paddingRight: 16,
      textDecoration: 'none',
      margin: '7px 0px',
      cursor: 'pointer',
      display: 'flex',
      width: 'max-content',
      alignItems: 'center'
    },

    profileMenuIcon: {
      marginRight: 5
    },
    divider: {
      borderLeft: '1px solid white',
      paddingLeft: 15,
      marginLeft: 15
    },
    user: {
      display: 'flex',
      flexDirection: 'row'
    },
    userName: {
      color: '#ffffff',
      fontSize: 18,
      textDecoration: 'none'
    },
    avatorBox: {
      position: 'relative'
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      cursor: 'pointer',
    },
    userList: {
      display: 'flex',
      flexDirection: 'column'
    },
    poper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      right: 10,
      zIndex: 11111,
      top: 33
    },
    mobileView: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    signInMobile: {
      textDecoration: 'none',
      border: '1px solid #ffffff',
      color: '#ffffff',
      padding: '5px 17px',
      borderRadius: 5
    },
    webView: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    checkIcon: {
      margin: '5px 15px'
    },
    linkTopBorder: {
      borderTop: '1px solid rgba(170, 170, 170, 0.4)',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingRight: 16,
      textDecoration: 'none',
      paddingTop: 4,
      cursor: 'pointer',
      fontWeight: 200
    },

    linkBottomBorder: {
      borderBottom: '1px solid rgba(170, 170, 170, 0.4)',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingRight: 16,
      textDecoration: 'none',
      paddingBottom: 4,
      cursor: 'pointer',
      fontWeight: 200
    },
    linkDoctorList: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingRight: 16,
      textDecoration: 'none',
      fontWeight: 200,
      cursor: 'pointer'
    },
    linkDoctorListMobileActive: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: 18,
      paddingRight: 16,
      textDecoration: 'none',
      marginBottom: 20,
      cursor: 'pointer',
      color: '#4aa5ff'
    },
    linkDoctorListMobileActiveMb0: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: 18,
      paddingRight: 16,
      textDecoration: 'none',
      marginBottom: 0,
      cursor: 'pointer',
      color: '#4aa5ff'
    },
    linkDoctorListMobile: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: 18,
      paddingRight: 16,
      textDecoration: 'none',
      marginBottom: 20,
      cursor: 'pointer'
    },
    linkDoctorListMobileMb0: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: 18,
      paddingRight: 16,
      textDecoration: 'none',
      marginBottom: 0,
      cursor: 'pointer'
    },
    hiddenIcon: {
      visibility: 'hidden',
      margin: '5px 15px'
    },
    profileVisit: {
      fontSize: 12,
      marginTop: 3
    },
    userInfo: {
      marginLeft: 5
    },
    collapsedIcon: {
      marginLeft: 35
    },

    listItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    fw200: {
      fontWeight: 200
    }
  })
);
