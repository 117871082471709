import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import EditRegistryStyle from './Styles';
import FolderIcon from '@material-ui/icons/Folder';
import history from '../../../utils/history';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import * as api from '../../../services/apiService';
import { yupResolver } from '@hookform/resolvers';
import { RegistryDataType } from '../../../types';
import { NavLink, useParams } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

type registryParams = {
  registryId: string;
};

const EditRegistry = () => {
  const classes = EditRegistryStyle();
  const { registryId } = useParams<registryParams>();
  const [registryIndicator, setRegistryIndicator] =
    React.useState<boolean>(false);
  const [registryData, setRegistryData] = React.useState<RegistryDataType>({
    name: '',
    description: ''
  });

  const [registryField, setRegistryField] = React.useState<any[]>([]);
  const [registryMessageEdit, setRegistryMessageEdit] = React.useState<any[]>(
    []
  );
  const [age, setAge] = React.useState('none');
  const [showRegistryMessageEdit, setShowRegistryMessageEdit] =
    React.useState<boolean>(false);
  const validationSchema = yup.object().shape({
    name: yup.string().required('This field is required')
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch
  } = useForm({
    defaultValues: registryData,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const getRegistryById = React.useCallback(async () => {
    setRegistryIndicator(true);

    if (registryId) {
      const { data, status_code: statusCode } = await api.getRegistryById(
        registryId
      );
      if (statusCode === api.status.SUCCESS) {
        setValue('name', data?.name);
        setValue('description', data?.description);
        setRegistryField(data?.fields);
      }
      setRegistryIndicator(false);
    }
  }, [registryId]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const UpdateRegistry = React.useCallback(async (registryId, payload) => {
    setRegistryIndicator(true);
    const { data, status_code: statusCode } = await api.UpdateRegistry(
      registryId,
      payload
    );
    if (statusCode === api.status.SUCCESS) {
      let message = ['success', data.message];
      setRegistryMessageEdit(message);
      setShowRegistryMessageEdit(true);
      setTimeout(() => {
        setShowRegistryMessageEdit(false);
        history.push('/registry/' + registryId);
      }, 1000);
    } else {
      let message = ['error', data.message];
      setRegistryMessageEdit(message);
      setShowRegistryMessageEdit(true);
      setTimeout(() => setShowRegistryMessageEdit(false), 1000);
    }
    setRegistryIndicator(false);
  }, []);

  const handleUpdate = () => {
    UpdateRegistry(registryId, {
      name: getValues('name'),
      description: getValues('description')
    });
  };

  useEffect(() => {
    getRegistryById();
  }, [getRegistryById]);

  return (
    <div className={classes.root}>
      <Snackbar open={showRegistryMessageEdit}>
        <Alert
          onClose={() => setShowRegistryMessageEdit(false)}
          color={registryMessageEdit[0]}
          severity={registryMessageEdit[0]}
        >
          {registryMessageEdit[1]}
        </Alert>
      </Snackbar>{' '}
      <Grid
        item={true}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.headerContainer}
      >
        <>
          <Grid
            item={true}
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            className={classes.registeryName}
          >
            <NavLink className={classes.link} to="/registries">
              <Typography variant="h1" className={classes.inline}>
                {'Registries > '}
              </Typography>
            </NavLink>
            <Typography
              color="primary"
              variant="body2"
              className={classes.inline}
            >
              Edit registry
            </Typography>
          </Grid>
        </>
      </Grid>
      <Paper className={classes.formPaper}>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.header}
        >
          <FolderIcon color="secondary" className={classes.headerIcon} />
          <Typography variant="h2" className={classes.inline}>
            Registry details
          </Typography>
        </Grid>
        <Grid item={true} container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.header}
          >
            {' '}
            <Controller
              as={TextField}
              required
              variant="outlined"
              name="name"
              defaultValue=""
              control={control}
              label="Registry name"
              margin="normal"
              fullWidth
              helperText={errors.name && errors.name.message}
              error={errors.name ? true : false}
            />
          </Grid>
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={[classes.header, classes.pr].join(' ')}
          >
            {' '}
            <Controller
              as={TextField}
              variant="outlined"
              name="description"
              defaultValue=""
              control={control}
              label="Description"
              margin="normal"
              fullWidth
              helperText={errors.description && errors.description.message}
              error={errors.description ? true : false}
            />
          </Grid>
        </Grid>
        <Paper className={classes.sharedPaper}>
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.secHeader}
          >
            <Typography variant="h2">Custom fields</Typography>
          </Grid>
          <Grid item={true}>
            <Divider />
          </Grid>
          <Grid
            item={true}
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.selectHeader}
          >
            {registryField && registryField.length > 0
              ? registryField.map((field: any) => (
                  <Grid
                    item={true}
                    key={field.name}
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid
                      item={true}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className={classes.field}
                    >
                      <TextField
                        name="title"
                        label="Field Title*"
                        disabled
                        value={field.property || field.name}
                        fullWidth
                        variant="outlined"
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid
                      item={true}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className={classes.field}
                    >
                      {' '}
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Type*
                        </InputLabel>

                        <Select
                          fullWidth
                          variant="outlined"
                          labelId="demo-simple-select-outlined-label"
                          label="Type"
                          id="demo-simple-select-outlined"
                          onChange={handleChange}
                          disabled
                          name={age}
                          defaultValue="none"
                          inputProps={{ readOnly: true }}
                        >
                          <MenuItem value="none" disabled>
                            {field.type}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))
              : null}
          </Grid>
        </Paper>
        <div className={classes.saveButtonContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.saveButton}
            onClick={handleUpdate}
          >
            Save
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default EditRegistry;
