import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { isFileExcluded } from 'tslint/lib/configuration';

const inviteDialogStyle = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      marginRight: 10,
      color: '#aaaaaa',
      cursor: 'pointer'
    },
    title: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 500,
      marginTop: 13,
      marginBottom: 15,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    dialogHeader: {
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 22
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #aaa',
      margin: '5px 12px 5px 15px'
    },
    rightBox: {
      display: 'flex',
      alignItems: 'center'
    },
    inviteButton: {
      width: '100%',
      margin: '20px 2px',
      fontWeight: 100
    },
    inviteButtonContainer: {
      width: '100%',
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative'
    },
    removeIcon: {
      color: '#ff3b30',
      position: 'absolute',
      left: '96%',
      [theme.breakpoints.down('xl')]: {
        left: '96.2%'
      },
      [theme.breakpoints.down('sm')]: {
        left: '93%'
      }
    },
    errormsg: {
      color: '#ff3b30',
      fontSize: 10,
      margin: '0px 2px'
    },
    mt3: {
      marginTop: 3
    },
    form: {
      margin: '5px 15px',
      width: '100%'
    },
    mr3: {
      marginRight: 3
    },
    mr10: {
      marginRight: 10
    },
    f14: {
      fontSize: 14
    },
    dataBox: {
      paddingLeft: 27
    },
    headerIcon: {
      marginRight: 3
    },
    boxHeader: {
      display: 'flex',
      alignItems: 'center',
      margin: '7px 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '5px 0px'
      }
    },
    userInfoHeader: {
      marginTop: 10,

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 22
    },
    infoContainer: {
      padding: '0px 12px 12px'
    },
    popupIcon: {
      display: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        top: '23%',
        left: '93%'
      }
    },
    collapsed: {
      paddingLeft: 20
    },
    popup: {
      cursor: 'pointer'
    },
    icon: {
      marginRight: 4
    },
    collapsedIcon: {
      marginLeft: 'auto'
    },
    listIcon: {
      marginRight: 5
    },
    listItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

export default inviteDialogStyle;
