import { makeStyles, Theme, createStyles } from '@material-ui/core';

const educationFormStyle = makeStyles((theme: Theme) =>
  createStyles({
    mb0: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    deleteButton: {
      color: '#ff3b30',
      width: '48%',
      alignContent: 'center',
      border: '1px solid #ff3b30'
    },
    dateField: {
      display: 'flex',
      alignItems: 'center'
    },
    root: {
      '&$checked': {
        color: '#ffad2c'
      }
    },
    checked: {},
    saveButton: {
      width: '48%'
    },
    addButton: {
      width: '100%',
      alignContent: 'center',
      marginTop: 30
    },
    checkBoxColor: {
      color: '#ffad2c'
    },
    endDateBox: {
      margin: '12px 0px 8px'
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 25
    },
    startDateField: {
      marginTop: 4,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    startDateFieldError: {
      marginTop: -12,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    indicator: {
      margin: 15
    },
    mt16: {
      marginTop: 4
    },
    checkBox: {
      border: 'solid 1px rgba(170, 170, 170, 0.6)',
      padding: '6px 15px',
      margin: '8px 0px'
    },
    date: {
      color: '#aaaaaa'
    }
  })
);

export default educationFormStyle;
