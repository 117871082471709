import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });

function getTheme(theme: any) {
  return createTheme({
    breakpoints: {
      ...customBreakpointValues
    },
    palette: {
      type: theme.paletteType,
      primary: {
        light: '#6ca9f8',
        main: '#6ca9f8',
        dark: '#6ca9f8',
        contrastText: '#ffff'
      },
      secondary: {
        light: '#4aa5ff',
        main: '#4aa5ff',
        dark: '#4aa5ff',
        contrastText: '#ffff'
      },
      error: {
        light: '#ff453a',
        main: '#ff453a',
        dark: '#ff453a'
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      text: {
        primary: '#f4f4f4',
        secondary: '#aaaaaa',
        disabled: 'rgba(248, 248, 248, 0.1)',
        hint: '#aaaaaa'
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      background: {
        paper: '#2e2e2e',
        default: '#2e2e2e'
      },
      action: {
        active: '#f4f4f4',

        selected: 'rgba(0, 0, 0, 0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255, 255, 255, 0.5)',
        disabledBackground: 'rgba(74, 165, 255, 0.7)',
        disabledOpacity: 0.38,
        focus: 'rgba(0, 0, 0, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12
      }
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      h1: {
        fontSize: 18,
        color: '#aaaaaa',
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif'
      },
      h3: {
        fontSize: 14,
        color: '#f4f4f4',
        fontFamily: 'Roboto, sans-serif'
      },
      h2: {
        fontSize: 16,
        color: '#4aa5ff',
        fontWeight: 500
      },
      h4: {
        fontSize: 12,
        color: '#4aa5ff'
      },
      h5: {
        fontSize: 12,
        color: '#aaaaaa'
      },
      h6: {
        fontSize: 16,
        color: '#aaaaaa'
      },
      body1: {
        fontSize: 14,
        color: '#aaaaaa'
      },
      caption: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        color: '#aaaaaa'
      },
      body2: {
        fontSize: 18,
        color: '#aaaaaa'
      },
      subtitle1: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 18,
        color: '#f4f4f4'
      },
      subtitle2: {
        fontSize: 16,
        color: '#aaaaaa'
      },
      overline: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 12,
        color: '#6ca9f8',

        textTransform: 'none'
      }
    },
    overrides: {
      MuiInput: {
        input: {
          '&::placeholder': {
            color: '#aaaaaa'
          }
        }
      },
      MuiAppBar: {
        root: {
          zIndex: 'unset'
        },
        colorPrimary: {
          backgroundColor: '#fffff'
        },
        colorDefault: {
          backgroundColor: '#2e2e2e'
        }
      },
      MuiTablePagination: {
        root: {
          overflow: 'none'
        },
        toolbar: {
          paddingLeft: 4,
          paddingRight: 0
        }
      },
      MuiTouchRipple: {
        root: {
          color: '#aaaaaa'
        }
      },
      MuiLink: {
        root: {
          color: '#ffad2c'
        }
      },

      MuiFormLabel: {
        root: {
          color: '#aaaaaa'
        },
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      },
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': 'thin'
          },
          '*::-webkit-scrollbar': {
            width: '9px',
            height: '11px'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(128, 128, 128, 0.5)',
            borderRadius: 20
          },
          '*::-webkit-scrollbar-track': {
            borderRadius: '11px'
          }
        }
      },
      MuiTable: {
        root: {
          borderCollapse: 'revert'
        }
      },
      MuiAvatar: {
        colorDefault: {
          backgroundColor: '#fff'
        }
      },
      MuiGrid: {
        'spacing-xs-4': {
          backgroundColor: '#434343'
        }
      },
      MuiTab: {
        textColorPrimary: {
          color: '#aaa'
        }
      },
      MuiTabs: {
        root: {
          backgroundColor: '#434343',
          color: '#aaaaaa'
        }
      },
      MuiButton: {
        root: {
          fontFamily: 'Roboto, sans-serif',
          textTransform: 'none',
          fontWeight: 200
        },
        textSecondary: {
          color: '#aaa'
        }
      },
      MuiStepLabel: {
        root: {
          '&completed': {
            color: '#4aa5ff'
          },

          '&active': {
            color: '#4aa5ff'
          },
          '&disabled': {
            color: 'gray'
          }
        }
      },

      MuiButtonBase: {
        root: {
          '&disabled': {
            color: '#aaa'
          },
          '&focusVisible': {
            color: '#f4f4f4'
          }
        }
      },
      MuiNativeSelect: {
        icon: {
          color: '#f4f4f4'
        }
      },

      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s'
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: '#434343'
        }
      },
      MuiMobileStepper: {
        root: {
          backgroundColor: 'transparent'
        }
      },
      MuiDialogContentText: {
        root: {
          color: '#aaaaaa',
          backgroundColor: '#2d2d2d'
        }
      },
      MuiDialogContent: {
        root: {
          backgroundColor: '#2d2d2d'
        }
      },
      MuiDialogActions: {
        root: {
          backgroundColor: '#2d2d2d'
        }
      },
      MuiTextField: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(154, 154, 154, 0.5)'
            }
          }
        }
      }
    }
  });
}

export const darkTheme = getTheme({
  paletteType: 'dark'
});
