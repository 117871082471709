import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    accordionContainer: {
        marginTop: 8,
        padding: 25,
    },
    root: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 'none',
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 0,
        },
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            transition: 'border 0.2s ease-out',
        },
    },
    expanded: {},
    summary: {
        paddingLeft: theme.spacing(1.6),
        paddingRight: theme.spacing(2.3),
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },

    },
    accordionFontSize: {
        fontSize: 14,
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0,
        },
    },
    customSummary: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    customContent: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    py0: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
    },
    mb1: {
        marginTop: 0,
        marginBottom: 12
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 25
    },
    deleteButton: {
        color: '#ff3b30',
        width: '48%',
        alignContent: 'center',
        border: '1px solid #ff3b30'
    },
    saveButton: {
        width: '48%'
    },
    addButton: {
        width: '100%',
        alignContent: 'center',
        marginTop: 30
    },
}));

export default useStyles;
