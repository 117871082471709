import {
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import UserInfoBox from '../../../components/userInfoBox/UserInfoBox';
import SearchIcon from '@material-ui/icons/Search';
import UsersStyle from './Styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InviteUser from '../../../components/Dialogs/InviteUser';
import { Alert } from '@material-ui/lab';
import * as api from '../../../services/apiService';
import { getUser } from '../../../utils';
import Usersempty from '../../../assets/Usersempty.svg';
import UsersemptyDark from '../../../assets/dark/Usersempty.svg';

const Users = (props) => {
  const classes = UsersStyle();
  const user = getUser();
  const isDarkTheme = useTheme().palette.type === 'dark';
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [openUserInvite, setOpenUserInvite] = React.useState<boolean>(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMsg, setAlertMsg] = React.useState<string>('');
  const [users, setUsers] = useState<any[]>([]);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearchChange = (event: any) => {
    setSearchKeyword(event.target.value);
  };

  const editPermission = (newPer: string, id: number) => {
    updatePermission(user?.id, { end_user_id: id, permission: newPer });
  };

  const revoke = (id: number) => {
    const payload = {
      end_user_id: id
    };
    declineInvitation(user?.id, payload);
  };

  const searchUser = async (userId) => {
    const { data, status_code: statusCode } = await api.searchUserSection(
      userId,
      searchKeyword
    );
    if (statusCode === api.status.SUCCESS) {
      setUsers(data.user_staff.filter((user) => {
        return user.status == 'ACCEPTED';
      }));
    }
  };

  const getMyUser = async (userId) => {
    const { data, status_code: statusCode } = await api.getMyUser(userId);
    if (statusCode === api.status.SUCCESS) {
      setUsers(data.user_staff.filter((user) => {
        return user.status == 'ACCEPTED';
      }));
    }
  };

  const declineInvitation = async (userId, payload) => {
    const { data, status_code: statusCode } = await api.declineInvitation(
      userId,
      payload
    );
    if (statusCode === api.status.SUCCESS) {
      setShowAlert(true);
      setAlertMsg('Access revoked successfully');
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  const updatePermission = async (userId, payload) => {
    const { data, status_code: statusCode } = await api.updatePermission(
      userId,
      payload
    );
    if (statusCode === api.status.SUCCESS) {
      setShowAlert(true);
      setAlertMsg('Permissions have been updated successfully');
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (searchKeyword.length > 0) {
      searchUser(user?.id);
    } else if (searchKeyword.length === 0) {
      getMyUser(user?.id);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (showAlert) {
      getMyUser(user?.id);
    }
  }, [showAlert]);

  return (
    <Grid container item={true} className={classes.root}>
      <Snackbar open={showAlert} color="success">
        <Alert onClose={() => setShowAlert(false)} severity="success">
          {alertMsg}
        </Alert>
      </Snackbar>
      <InviteUser
        isOpen={openUserInvite}
        onClose={() => setOpenUserInvite(false)}
        setAlertMsg={setAlertMsg}
        setShowAlert={setShowAlert}
      ></InviteUser>
      <Grid className={classes.header} item={true}>
        {matchesMobile ? (
          <Grid item={true} xs={12}>
            <Typography color="primary" className={classes.f16}>
              Users
            </Typography>
          </Grid>
        ) : null}
        <Grid item={true} xs={12} sm={12} md={6} lg={3} xl={3}>
          <Input
            placeholder={'Search '}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            }
            className={classes.input}
            value={searchKeyword}
            onChange={(event) => handleSearchChange(event)}
          />
        </Grid>{' '}
        <Grid
          className={classes.mr24}
          item={true}
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<PersonAddIcon />}
            className={classes.inviteButton}
            onClick={() => setOpenUserInvite(true)}
          >
            Invite users
          </Button>
        </Grid>
      </Grid>
      <Grid container item={true} xs={12} md={12} lg={12}>
        {users.length > 0 ? (
          users.map((user: any, index) => (
            <UserInfoBox
              userInfo={user}
              index={index}
              editPermission={editPermission}
              revoke={revoke}
            />
          ))
        ) : (
          <Box className={classes.imageBox} mx="auto">
            <Box>
              <img
                src={!isDarkTheme ? Usersempty : UsersemptyDark}
                alt="no data"
              ></img>
            </Box>
            <Box className={classes.emptyUsers}>
              {' '}
              <Typography variant="caption">
                Start inviting users to edit/view your case log
              </Typography>
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Users;
