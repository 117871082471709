import {
  Box,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React from 'react';
import ourTeamStyle from './Styles';
import 'flag-icon-css/css/flag-icon.min.css';
import nimerImg from '../../../assets/team/Nimer.png';
import richardImg from '../../../assets/team/Richard.png';
import christImg from '../../../assets/team/Christ.png';
import muhanadImg from '../../../assets/team/Muhanad.png';
import anthonyImg from '../../../assets/team/Anthony.png';
import johnImg from '../../../assets/team/John.png';
import linkedInIcon from '../../../assets/icons/linkedInIcon.png';
import emailIcon from '../../../assets/icons/emailIcon.png';
import appleStore from '../../../assets/img/MS-mbl-site-apple-img.png';
import playStore from '../../../assets/img/MS-mbl-site-play-store-img.png';
import Footer from '../../../components/footer/Footer';
import history from '../../../utils/history';
import loginRight from '../../../assets/img/MS-Login-right-img.png';

const OurTeam = () => {
  const classes = ourTeamStyle();
  const theme = useTheme();
  const matchesWeb = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');

  const openLink = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div>
      <section className="breadCrums">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="crumsHeading">
                <h1>Our Team</h1>
                <ul className="d-flex align-items-center justify-content-center">
                  <li><a onClick={() => history.push('/')} className="breadCrumsLink home pointer">Home </a></li>
                  <li><a onClick={() => history.push('/ourteam')} className="breadCrumsLink pointer"> &gt; Our team</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="msfeatures aboutPage">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="featuresHeading">
                <h1>Meet Our Professionals</h1>
                <p>Our team is composed of very well known and highly respected professionals, who together bring a top-notch technology and service that will help you build a great practice.</p>
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-lg-6 col-md-12">
              <div className="teamContent">
                <div className="teamImg">
                  <img src={nimerImg} alt="" width="100" height="100" ></img>
                </div>
                <div className="teamText">
                  <h4>Nimer Adeeb, MD</h4>
                  <h2>Co-Founder and Chief Executive Officer</h2>
                  <p>Nimer is a Neurosurgeon, serial entrepreneur, and Harvard alumni with long experience in Big Data, research, and device development. He owns several patents in the surgical field and has led MS Logger to become the largest and most popular case log solution.</p>
                  <div className='contact-icons'>
                    <div className='contact-icons-box'>
                      <div onClick={() => openLink('mailto:nimer@mslogger.com')} className='contactImg pointer'>
                        <img src={emailIcon} alt="" />
                      </div>
                      <div onClick={() => openLink('https://www.linkedin.com/in/nimer-adeeb-md-67a666145/')} className='contactImg pointer'>
                        <img src={linkedInIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="teamContent">
                <div className="teamImg">
                  <img src={richardImg} alt="" width="100" height="100" ></img>
                </div>
                <div className="teamText">
                  <h4>Richard Menger, MD, MPA</h4>
                  <h2>Chief Medical Officer</h2>
                  <p>Dr. Menger is a Neurosurgeon and Chief of Complex Spine Surgery at the University of South Alabama. He received a Master of Public Administration (MPA) from the Harvard Kennedy School of Government, and has a long experience and several publications and books in business and medicine. Dr. Menger manages our user interface and ensures the most up-to-date features that will help achieve the surgeons goals and needs.</p>
                  <div className='contact-icons'>
                    <div className='contact-icons-box'>
                      <div onClick={() => openLink('mailto:rich@mslogger.com')} className='contactImg pointer'>
                        <img src={emailIcon} alt="" />
                      </div>
                      <div onClick={() => openLink('https://www.linkedin.com/in/richard-menger-9633aa70/')} className='contactImg pointer'>
                        <img src={linkedInIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="teamContent">
                <div className="teamImg">
                  <img src={christImg} alt="" width="100" height="100" ></img>
                </div>
                <div className="teamText">
                  <h4>Christopher Storey, MD, PhD</h4>
                  <h2>Chief Information Officer</h2>
                  <p>Dr. Storey is a Cerebrovascular Neurosurgeon in Nashville, TN. He also has a PhD degree in Computer Science and Biomedical Engineering. Given his background and experience in surgery and information technology, Dr. Storey manages our data division to ensure security and efficiency in data entry and sharing.</p>
                  <div className='contact-icons'>
                    <div className='contact-icons-box'>
                      <div onClick={() => openLink('mailto:chris@mslogger.com')} className='contactImg pointer'>
                        <img src={emailIcon} alt="" />
                      </div>
                      <div onClick={() => openLink('https://www.linkedin.com/in/christopher-storey-49488712b/')} className='contactImg pointer'>
                        <img src={linkedInIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="teamContent">
                <div className="teamImg">
                  <img src={muhanadImg} alt="" width="100" height="100" ></img>
                </div>
                <div className="teamText">
                  <h4>Muhanad Jadallah</h4>
                  <h2>Co-Founder and Business Advisor</h2>
                  <p>Muhanad is a successful businessman with more than 25 years of experience in healthcare business building multiple successful companies spanning the USA, Canada, and the Middle East. He serves as the Chairman of ASTEMED, a multinational medical company, and Starkey Hearing based in Dubai. Through his long experience, Muahanad oversees our business strategy and plan.</p>
                  <div className='contact-icons'>
                    <div className='contact-icons-box'>
                      <div onClick={() => openLink('mailto:muhanad@mslogger.com')} className='contactImg pointer'>
                        <img src={emailIcon} alt="" />
                      </div>
                      <div onClick={() => openLink('https://www.linkedin.com/in/muhanad-jadallah-12788511/')} className='contactImg pointer'>
                        <img src={linkedInIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="teamContent">
                <div className="teamImg">
                  <img src={anthonyImg} alt="" width="100" height="100" ></img>
                </div>
                <div className="teamText">
                  <h4>Anthony Sin, MD, FAANS</h4>
                  <h2>Co-Founder and Surgical Advisor</h2>
                  <p>Dr. Sin is a Professor of Neurosurgery and Chief of Complex Spine Surgery at LSU Health. He is a world-renowned neurosurgeon and a serial investor and entrepreneur who is focused on improving digital healthcare.</p>
                  <div className='contact-icons'>
                    <div className='contact-icons-box'>
                      <div onClick={() => openLink('mailto:anthony@mslogger.com')} className='contactImg pointer'>
                        <img src={emailIcon} alt="" />
                      </div>
                      <div className='contactImg'>
                        <img src={linkedInIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="teamContent">
                <div className="teamImg">
                  <img src={johnImg} alt="" width="100" height="100" ></img>
                </div>
                <div className="teamText">
                  <h4>John Robson</h4>
                  <h2>Director, Sales and Marketing</h2>
                  <p>John brings many years of experience in Sales and Marketing in the medical field. He oversees our online and in-person marketing with a mission to achieve ultimate user outreach and satisfaction.</p>
                  <div className='contact-icons'>
                    <div className='contact-icons-box'>
                      <div onClick={() => openLink('mailto:john@mslogger.com')} className='contactImg pointer'>
                        <img src={emailIcon} alt="" />
                      </div>
                      <div onClick={() => openLink('https://www.linkedin.com/in/john-robson-6a374331/')} className='contactImg pointer'>
                        <img src={linkedInIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section >

      <section className="mblSite">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mblSiteImg">
                <img style={{ width: '100%' }} src={loginRight} alt=""></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mblSiteContent">
                <h2>Mobile Site and Application</h2>
                <p>Our mobile site and application allow you to add and check your patients and procedures anytime and anywhere. You can also use it to search, add patient data and images, follow-up information, export cases and much more.</p>
                <h4>Get MS Logger now and start building your cases database.</h4>
                <div className="mblAppImg d-flex">
                  <div className="appleImg">
                    <a className="pointer" onClick={() => window.open('https://apps.apple.com/us/app/ms-logger/id1484770342', '_blank')}>
                      <img style={{ width: '100%' }} src={appleStore} alt=""></img>
                    </a>
                  </div>
                  <div className="playStoreImg">
                    <a className="pointer" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.medsolace.caselog', '_blank')}>
                      <img style={{ width: '100%' }} src={playStore} alt=""></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div >
  );
};

export default OurTeam;
