import { makeStyles, Theme, createStyles } from '@material-ui/core';

const dashboardStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '80%',
      margin: 'auto',
      paddingTop: 40,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingTop: 18
      },
      [theme.breakpoints.only('md')]: {
        width: '95%',
        paddingTop: 18
      },
      display: 'flex',
      flexDirection: 'column'
    },
    inline: {
      display: 'inline'
    },

    registryRoot: {
      position: 'relative',

      margin: 'auto',
      paddingTop: 20,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 18
      },
      [theme.breakpoints.only('md')]: {
        paddingTop: 18
      },
      display: 'flex',
      flexDirection: 'column'
    },
    title: {
      paddingLeft: 5,
      paddingBottom: 5
    },

    fieldBox: {
      boxShadow: 'none',
      border: 'solid',
      borderWidth: 1,
      padding: '0px 0px 0px 4px',
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(0)
      }
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 16
    },
    paperOfNum: {
      height: '100%'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      display: 'flex',
      width: '100%'
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    searchIcon: {
      color: '#aaaaaa'
    },
    cursor: {
      cursor: 'pointer'
    },
    cursorA: {
      cursor: 'cursor'
    },
    MsgLink: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 40
      },
      textDecoration: 'none'
    },
    registrySnackbar: {
      [theme.breakpoints.up('sm')]: {
        width: 500
      }
    },
    input: {
      fontSize: 16,
      marginTop: 3,
      marginLeft: theme.spacing(1),
      flex: 1,
      flexGrow: 1,
      height: 45,
      width: '90%',
      [theme.breakpoints.down('xs')]: {
        height: 30
      },
      [theme.breakpoints.only('lg')]: {
        width: '95%'
      },
      [theme.breakpoints.only('xs')]: {
        width: '95%'
      }
    },
    searchButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      width: 200,
      [theme.breakpoints.down('xs')]: {
        width: 100
      }
    },
    doSearchButton: {
      display: 'flex',
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(0)
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        paddingLeft: theme.spacing(0)
      }
    },
    searchBox: {
      marginLeft: 'auto',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginTop: theme.spacing(3)
      }
    },
    searchBoxButton: {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginTop: theme.spacing(3)
      }
    },
    search: {
      fontWeight: 'normal',
      fontSize: 16,
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    poper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      left: 8,
      top: 41,
      width: 204,
      zIndex: 100,
      [theme.breakpoints.down('xs')]: {
        width: 204,
        left: -16,
        top: 31
      }
    },
    poperFilter: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      right: 70,
      width: 204,
      zIndex: 100,
      [theme.breakpoints.up('lg')]: {
        right: 70,
        top: 100
      },
      [theme.breakpoints.only('md')]: {
        right: 70,
        top: 80
      }
    },
    poperFilterRegitry: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      right: 70,
      width: 204,
      zIndex: 100,
      [theme.breakpoints.up('lg')]: {
        right: 60
      },
      [theme.breakpoints.only('md')]: {
        right: 70,
        top: 60
      }
    },
    exportPoper: {
      display: 'flex',
      justifyContent: 'space-evenly',
      cursor: 'pointer',
      fontSize: 16,
      padding: 12
    },
    filterTab: {
      fontWeight: 'normal',
      fontSize: 16,
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      }
    },
    icon: {
      marginRight: 4
    },
    clearIcon: {
      margin: '6px 0px',
      alignSelf: 'center'
    },
    number: {
      color: 'black',
      fontSize: 30,
      fontWeight: 500,
      [theme.breakpoints.down('lg')]: {
        fontSize: 27
      }
    },
    text: {
      color: 'black',
      fontSize: 18,
      fontWeight: 500,
      [theme.breakpoints.down('lg')]: {
        fontSize: 15
      },
      marginBottom: 10,
    },
    item: {
      height: 120,
      padding: '5px'
    },
    link: {
      textDecoration: 'none',
      color: '#1877f2'
    },
    boxNumber: {
      color: 'black',
      fontSize: 24,
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 24
      }
    },
    boxText: {
      padding: 0,
      margin: 0,
      marginTop: -3,
    },
    article1: {
      padding: '0px 12px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 0px 0px 12px',
        marginBottom: 10
      }
    },
    calculate1: {
      paddingLeft: 12
    },
    numberContainer: {
      marginBottom: 20
    },
    description: {
      padding: '0px 12px 12px',
      fontWeight: 400,
      [theme.breakpoints.down('md')]: {
        textAlign: 'start'
      }
    },
    upgrade: {
      color: '#1877f2',
      cursor: 'pointer'
    },
    upgradeBox: {
      fontSize: 13,
      color: 'black',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        alignItems: 'center'
      }
    },
    imgContainer: {
      padding: 12,
    },
    avatarHighlight: {
      width: 40,
      height: 40,
      padding: '7px 7px',
      borderRadius: '50%',
      backgroundColor: ' rgba(247, 181, 0, 0.1)',
      fontSize: 12,
      color: '#ffff',
      textAlign: 'center',
      border: '1px solid #ffad2c'
    },
    avatarPrimary: {
      width: 40,
      height: 40,
      padding: '7px 7px',
      borderRadius: '50%',
      backgroundColor: 'rgba(74, 165, 255, 0.15)',
      fontSize: 12,
      color: '#ffff',
      textAlign: 'center',
      border: '1px solid #60a4f8'
    },
    mb4: {
      marginBottom: 4
    },
    buttonText: {
      padding: 2,
      fontSize: 12
    },

    indicator: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: '50%'
    },
    buttonHeight: {
      height: 38
    },
    searchInput: {
      width: 500,
      height: 28
    },
    searchBy: {
      width: '80%',
      marginBottom: 15,
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    AppBarDiv: {
      display: 'flex'
    },
    headerButton: {
      margin: 10,
      height: 34,
      color: '#ffffff',
      fontWeight: 300,
      [theme.breakpoints.down('lg')]: {
        '&:nth-child(1)': {
          margin: '15px 0px 0px '
        },
        width: '100%',
        fontSize: 14,
        margin: '15px 0px 0px 9px'
      },
      [theme.breakpoints.up('xl')]: {
        margin: 8,
        width: '17%',
        padding: 3
      }
    },
    headerButtonDisabled: {
      margin: 10,
      height: 34,
      color: 'rgba(255, 255, 255, 0.5)',
      fontWeight: 300,
      [theme.breakpoints.down('lg')]: {
        '&:nth-child(1)': {
          margin: '15px 0px 0px '
        },
        width: '100%',
        fontSize: 14,
        margin: '15px 0px 0px 9px'
      },
      [theme.breakpoints.up('xl')]: {
        margin: 8,
        width: '17%',
        padding: 3
      }
    },
    multiSelect: {
      [theme.breakpoints.down('xl')]: {
        width: '100%',
        display: 'flex',
        padding: '5px 0px',
        borderTop: '1px solid #aaaaaa',
        borderBottom: '1px solid #aaaaaa'
      }
    },
    AppBar: {
      marginTop: 20
    },
    Pagination: {
      backgroundColor: theme.palette.background.paper,
      marginLeft: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '70%'
      }
    },
    patientInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10
    },
    overlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      height: '100%',
      opacity: 0.4,
      zIndex: 100
    },
    mobileIcon: {
      backgroundColor: '#4aa5ff',
      color: '#ffffff',
      width: 30,
      height: 30,
      marginLeft: 15,
      borderRadius: 4,
      [theme.breakpoints.up('xl')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 6
      },
      [theme.breakpoints.only('lg')]: {
        marginTop: 8
      }
    },
    mobileIconFilter: {
      backgroundColor: '#4aa5ff',
      color: '#ffffff',
      width: 30,
      height: 30,
      marginLeft: 15,
      borderRadius: 4,
      [theme.breakpoints.up('xl')]: {
        display: 'none'
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 6
      },
      [theme.breakpoints.only('lg')]: {
        marginTop: 8
      },
      position: 'relative'
    },
    mobileView: {
      [theme.breakpoints.up('xl')]: {
        display: 'none'
      }
    },
    searchHeader: {
      [theme.breakpoints.up('xl')]: {
        display: 'none'
      },
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.only('md')]: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
      }
    },
    mobileHeader: {
      display: 'flex',
      flexDirection: 'row'
    },
    mobileViewMultiSelect: {
      [theme.breakpoints.up('xl')]: {
        display: 'none'
      }
    },
    webView: {
      [theme.breakpoints.down('lg')]: {
        display: 'none'
      }
    },
    searchMobile: {
      marginTop: 9,
      [theme.breakpoints.down('lg')]: {
        display: 'none'
      }
    },
    mobilePagination: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    mobileFooter: {
      marginTop: 20,

      backgroundColor: '#ffffff'
    },
    casesContainer: {
      marginBottom: 50
    },
    loadingBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '65%',
    }
  })
);

export default dashboardStyle;
