import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React from 'react';
import personTemplate from '../../assets/personTemplate.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import userInfoBoxStyle from './Styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import UserInfo from '../Dialogs/UserInfo';
import Person from '../../assets/dark/Person.svg';

const UserInfoBox = (props) => {
  const { userInfo, index, editPermission, revoke } = props;
  const classes = userInfoBoxStyle();
  const isDarkTheme = useTheme().palette.type === 'dark';
  const [openCOllapse, setOpenCOllapse] = React.useState<boolean>(false);
  const [openInfo, setOpenInfo] = React.useState<boolean>(false);
  const [userInfoObj, setUserInfoObj] = React.useState<any>();
  const handleClickOpen = () => {
    setOpenCOllapse(!openCOllapse);
  };

  return (
    <>
      <UserInfo
        isOpen={openInfo}
        userInfo={userInfoObj}
        onClose={() => setOpenInfo(false)}
        width={502}
        editPermission={editPermission}
        revoke={revoke}
      ></UserInfo>

      <Grid
        container
        item={true}
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={3}
        className={
          isWidthUp('lg', props.width)
            ? index % 4 === 0
              ? classes.fileExportedPR
              : index % 4 === 1
              ? classes.fileExportedPR
              : index % 2 === 0
              ? classes.fileExportedPR
              : index % 2 === 1
              ? classes.fileExportedPR
              : classes.fileExported
            : isWidthUp('sm', props.width)
            ? classes.fileExported
            : classes.fileExported
        }
      >
        <Paper className={classes.borderPaper}>
          <Grid container xs={12} className={classes.border}>
            <Grid
              item={true}
              xs={3}
              md={12}
              lg={12}
              className={classes.imgBox}
              onClick={() => {
                setOpenInfo(true);
                setUserInfoObj(userInfo);
              }}
            >
              <img
                className={
                  userInfo?.profile_image?.href_small
                    ? classes.w100
                    : classes.br6
                }
                src={
                  userInfo?.profile_image?.href_small
                    ? userInfo?.profile_image?.href_small
                    : !isDarkTheme
                    ? personTemplate
                    : Person
                }
                alt="personTemplate"
              />
            </Grid>
            <Grid item={true} xs={9} md={12} lg={12} className={classes.pb12}>
              <Grid className={classes.nameBox}>
                <Grid
                  onClick={() => {
                    setOpenInfo(true);
                    setUserInfoObj(userInfo);
                  }}
                >
                  <Typography color="primary" className={classes.f16} noWrap>
                    {userInfo?.end_user?.first_name
                      ? `${userInfo?.end_user?.first_name} ${userInfo?.end_user?.last_name}`
                      : userInfo?.end_user?.email.substring(
                          0,
                          userInfo?.end_user?.email.indexOf('@')
                        )}
                  </Typography>
                </Grid>
                <Grid>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <>
                        <MoreVertIcon
                          {...bindTrigger(popupState)}
                          className={classes.popup}
                          color="primary"
                        ></MoreVertIcon>

                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={(e) => {
                              handleClickOpen();
                              e.stopPropagation();
                            }}
                          >
                            <Typography
                              variant="h6"
                              className={classes.listItem}
                            >
                              {' '}
                              <SettingsApplicationsIcon
                                className={classes.listIcon}
                              />
                              Change permission
                            </Typography>

                            {openCOllapse ? (
                              <ExpandLess className={classes.collapsedIcon} />
                            ) : (
                              <ExpandMore className={classes.collapsedIcon} />
                            )}
                          </MenuItem>
                          <Collapse
                            in={openCOllapse}
                            timeout="auto"
                            unmountOnExit
                            className={classes.collapsed}
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                onClick={() => {
                                  editPermission(
                                    'EDITOR',
                                    userInfo?.end_user?.id
                                  );
                                  popupState.close();
                                }}
                              >
                                {' '}
                                <Typography
                                  variant="h6"
                                  className={classes.listItem}
                                >
                                  {' '}
                                  <EditIcon className={classes.listIcon} />
                                  Editor
                                </Typography>
                              </ListItem>
                              <ListItem
                                button
                                onClick={() => {
                                  editPermission(
                                    'VIEWER',
                                    userInfo?.end_user?.id
                                  );
                                  popupState.close();
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  className={classes.listItem}
                                >
                                  {' '}
                                  <VisibilityIcon
                                    className={classes.listIcon}
                                  />
                                  Viewer
                                </Typography>
                              </ListItem>
                            </List>
                          </Collapse>
                          <MenuItem
                            onClick={() => {
                              revoke(userInfo?.end_user?.id);
                              popupState.close();
                            }}
                          >
                            <Typography
                              variant="h6"
                              className={classes.listItem}
                            >
                              {' '}
                              <RemoveCircleIcon className={classes.listIcon} />
                              Revoke
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </PopupState>
                </Grid>
              </Grid>
              <Grid
                item={true}
                xs={4}
                sm={4}
                md={12}
                lg={12}
                className={classes.w200Box}
                onClick={() => {
                  setOpenInfo(true);
                  setUserInfoObj(userInfo);
                }}
              >
                <Box className={classes.p12}>
                  <Typography variant="caption">
                    {userInfo?.permission === 'EDITOR' ? 'Editor' : 'Viewer'}
                  </Typography>
                </Box>

                <Typography
                  variant="h5"
                  className={[classes.pt0, classes.w200].join(' ')}
                  noWrap
                >
                  {userInfo?.end_user?.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default withWidth()(UserInfoBox);
