import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Dialog
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import useStyles from '../../../styles/globalStyles';
import Visibility from '@material-ui/icons/Visibility';
import history from '../../../utils/history';
import { setCookie } from '../../../utils/sessionUtils';
import { setUser } from '../../../utils/index';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as api from '../../../services/apiService';
import Alert from '@material-ui/lab/Alert';
import { PasswordStatus } from '../../../types';
import loginTop from '../../../assets/img/MS-login-top-img.png';
import loginRight from '../../../assets/img/MS-Login-right-img.png';

const Signin: React.FC = (props: any) => {
  const classes = useStyles();
  const [values, setValues] = useState<PasswordStatus>({
    password: '',
    showPassword: false
  });
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleInputPress = () => {
    clearErrors('password');
  };
  const handleInputChange = (e) => {
    clearErrors('password');
    setValues({ ...values, password: e.target.value });
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          className={classes.showPassword}
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {values.showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    )
  };

  let loginSchema = yup.object().shape({
    username: yup
      .string()
      .trim()
      .email('Must use valid email address')
      .required('This field is required!'),
    password: yup.string().required('This field is required!')
  });

  const { register, handleSubmit, errors, clearErrors } = useForm({
    resolver: yupResolver(loginSchema)
  });

  const onSubmit = React.useCallback(async (values: any) => {
    setShowError(false);
    setErrorMessage('');
    setOpen(true);
    const { status_code: statusCode, data } = await api.login(values);

    if (
      statusCode === api.status.SUCCESS ||
      statusCode === api.status.USER_NOT_VERIFIED
    ) {
      if (statusCode === api.status.SUCCESS) {
        data['verified'] = true;
        setCookie('user', JSON.stringify(data));
        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
        props.setEditUserName(true);
        props.setIsHandledInvitation(true);
        history.push('/dashboard');
        if (props.setUser) {
          props.setUser(data);
        } else {
          window.location.reload();
        }
      } else {
        data['verified'] = false;
        setCookie('user', JSON.stringify(data));
        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
        history.push('/not-verified');
      }
    } else if (statusCode === api.status.INVALID_CREDENTIALS) {
      setShowError(true);
      setErrorMessage('Invalid Credentials');
    }
    handleDialogClose();
  }, []);

  const [formValues, setFormValues] = useState<any>({
    username: '',
    password: '',
  });

  const handleUsernameChange = (event) => {
    setFormValues({
      ...formValues,
      username: event.target.value,
    });
  };

  const handlePasswordChange = (event) => {
    setFormValues({
      ...formValues,
      password: event.target.value,
    });
  };

  const onSubmitHandler = handleSubmit(onSubmit);

  return (
    <section className="logIn">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="loginImages">
              <div className="loginLogoimg">
                <img src={loginTop} alt=""></img>
              </div>
              <div className="loginRightimg">
                <img style={{ width: '100%' }} src={loginRight} alt=""></img>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <form className="contactForm" onSubmit={onSubmitHandler}>
              <h1>Hey, Hello!</h1>
              <div className="form-group">
                <label htmlFor="emailAddress">Email</label>
                <input
                  name="username"
                  value={formValues.username}
                  onChange={handleUsernameChange}
                  type="email"
                  ref={register({ required: 'Username is required' })}
                  id="emailAddress"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  name="password"
                  value={formValues.password}
                  onChange={handlePasswordChange}
                  ref={register({ required: 'Password is required' })}
                  type="password"
                  id="password"
                  className="form-control"
                  required
                />
              </div>
              <div className="forget-row">
                <div className="remember">
                  <input type="checkbox" id="rememberMe"></input>
                  <label htmlFor="rememberMe">Remember Me</label>
                </div>
                <div className="forget">
                  <a className='pointer' onClick={() => history.push('/forget-password')}>Forgot Password?</a>
                </div>
              </div>
              {showError ? (<div style={{ color: 'red', textAlign: 'center' }}>
                {errorMessage}
              </div>) : null}
              <div className="formSubmission">
                <div className="form-group">
                  <button type="submit" className="form-control bgBlue login-btn pointer">
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signin;
