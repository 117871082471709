import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, Grid, Typography } from '@material-ui/core';
import useStyles from '../../../styles/globalStyles';
import * as api from '../../../services/apiService';
import { getUser } from '../../../utils';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import notVerifiedStyle from './Styles';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const NotVerified: React.FC = () => {
  const classes = useStyles();
  const [sent, setSent] = React.useState<boolean>(false);
  const allClasses = notVerifiedStyle();

  const resendVerifyLink = React.useCallback(async () => {
    const { status_code: statusCode } = await api.resendVerificationCode(
      getUser()?.id
    );
    if (statusCode === api.status.SUCCESS) {
      setSent(true);
    }
  }, []);

  return (
    <Box>
    <div>
      <Box>
        <Grid
         className={classes.stepperBox}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Paper elevation={0} className={classes.paperVerified}>
              <Box pr={2} className={allClasses.infoHeader}>
                <VerifiedUserIcon /> <Box pl={1}>Verify</Box>
              </Box>
              {sent ? (
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setSent(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Verification email sent successfully!
                </Alert>
              ) : null}
              <Box>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Typography
                    className={allClasses.emailMessage}
                    variant="body1"
                  >
                    In order to continue to MS Logger, you must verify
                    your account.
                    <br />
                    <br /> An email has been sent to{' '}
                    <span className={classes.email}>
                      {getUser() ? getUser().email : null}
                    </span>{' '}
                    with a link to verify your account.
                    <br /> If you have not received the verification
                    email after a few minutes, Please check your spam
                    folder.
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Typography
                    variant="body1"
                    className={classes.resendLink}
                    onClick={() => resendVerifyLink()}
                  >
                    Re-send the verification email
                  </Typography>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  </Box>
  );
};

export default NotVerified;
