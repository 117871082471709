import { makeStyles, Theme, createStyles } from '@material-ui/core';

const RegistryDetailsStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '15px 168px',
      minWidth: 500,
      [theme.breakpoints.down('md')]: {
        padding: 16,
        minWidth: 0
      },
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        width: '78%',
        margin: 'auto'
      },
      [theme.breakpoints.only('md')]: {
        padding: '15px 50px',
        minWidth: 0
      }
    },
    indicator: {
      margin: 15
    },
    link: {
      textDecoration: 'none'
    },
    inline: {
      display: 'inline'
    },
    description: {
      border: `1px solid rgba(170, 170, 170, 0.4)`,
      padding: '12px 18px',
      marginTop: 12
    },
    registeryName: {
      paddingLeft: 12,
      paddingBottom: 12,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    popItem: {
      display: 'flex'
    },
    popIcon: {
      color: '#ffffff'
    },
    headerContainer: {
      display: 'flex',

      marginTop: 24,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        marginTop: 0
      }
    },
    headerButton: {
      display: 'contents'
    },
    popupContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end'
      }
    },

    invitationButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: 16
      }
    },
    editCotainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    mr: {
      marginRight: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 34,
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    },
    popupstateIcon: {
      marginRight: 10
    }
  })
);

export default RegistryDetailsStyle;
