import registryFormStyle from './Styles';
import React, { Fragment } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  MobileStepper,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import FolderIcon from '@material-ui/icons/Folder';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Permission, FieldsType } from '../../../types';
import SearchIcon from '@material-ui/icons/Search';
import {
  InputSharp,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import * as api from '../../../services/apiService';
import history from '../../../utils/history';
import DoctorPlaceholder from '../../../assets/DoctorPlaceholder.png';
import emptySaerch from '../../../assets/emptySearch.png';
import text from '../../../assets/text.png';
import emptySaerchDark from '../../../assets/dark/searchDark.png';
import Customizeform from '../../../assets/Customizeform.png';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import ReactHookFormSelect from '../../../components/select/ReactHookFormSelect';
import { yupResolver } from '@hookform/resolvers';
import {
  registrFormStepOneValuesType,
  registrFormStepTwoValuesType,
  requiredField
} from '../../../types';
import { getUser } from '../../../utils';
import { Alert } from '@material-ui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import UpgradeDialog from '../../../components/Dialogs/UpgradeDialog';

const RegistryForm = (props) => {
  const {
    showAddRegistryMessage,
    registryMessage,
    setShowAddRegistryMessage,
    setRegistryMessage
  } = props;
  const isDarkTheme = useTheme().palette.type === 'dark';
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [messageError, setMessageError] = React.useState<string>('');
  const [currentPlan, setCurrentPlan] = React.useState<any>();
  const [tokenError, setTokenError] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [userToken, setUserToken] = React.useState<string>('');
  const [newPlan, setNewPlan] = React.useState<boolean>(false);
  const [viewOption, setViewOption] = React.useState<string>('Own cases');
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [doctors, setDoctors] = React.useState<any[]>([]);
  const [users, setUsers] = React.useState<any[]>([]);
  const [errorsField, seterrorsField] = React.useState<any[][]>([]);
  const [resetForm, setResetForm] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [
    showAddRegistryMessageError,
    setShowAddRegistryMessageError
  ] = React.useState(false);
  const [registryMessageError, setRegistryMessageError] = React.useState<any[]>(
    []
  );
  const [orignalField, setOrignalField] = React.useState<any[]>([
    {
      property: 'Patient first name',
      type: FieldsType.Free_Text,
      name: 'patient_first_name',
      visible: true,
      required: true
    },
    {
      property: 'Patient last name ',
      type: FieldsType.Free_Text,
      name: 'patient_last_name',
      visible: true,
      required: true
    },
    {
      property: 'Gender ',
      type: FieldsType.Drop_down_list,
      name: 'gender',
      visible: true,
      required: true
    },
    {
      property: 'Procedure type ',
      type: FieldsType.Free_Text,
      name: 'type',
      visible: true,
      required: true
    },
    {
      property: 'Date of surgery ',
      type: FieldsType.Date,
      name: 'date',
      visible: true,
      required: true
    },
    {
      property: 'Institute',
      type: FieldsType.Free_Text,
      name: 'institute',
      visible: true,
      required: false
    },
    {
      property: 'Date of birth ',
      type: FieldsType.Date,
      name: 'date_of_birth',
      visible: true,
      required: false
    },
    {
      name: 'mrn',
      type: FieldsType.Free_Text,
      required: false,
      visible: true,
      property: 'Medical record number'
    },
    {
      name: 'insurance',
      type: FieldsType.Free_Text,
      required: false,
      visible: true,
      property: 'Insurance'
    },
    {
      name: 'diagnosisCodes',
      type: FieldsType.Diagnosis,
      required: false,
      visible: true,
      property: 'Diagnosis (ICD-9/10)'
    },
    {
      name: 'cptCodes',
      type: FieldsType.Cpt,
      required: false,
      visible: true,
      property: 'CPT Code'
    },
    {
      name: 'role',
      type: FieldsType.Drop_down_list,
      required: false,
      visible: true,
      property: 'Role'
    },
    {
      name: 'vendors',
      type: FieldsType.Free_Text,
      required: false,
      visible: true,
      property: 'Vendors'
    },
    {
      name: 'findings',
      type: FieldsType.Free_Text,
      required: false,
      visible: true,
      property: 'Findings'
    },
    {
      name: 'complications',
      type: FieldsType.Free_Text,
      required: false,
      visible: true,
      property: 'Complications'
    },
    {
      name: 'outcome',
      type: FieldsType.Free_Text,
      required: false,
      visible: true,
      property: 'Outcome'
    },

    {
      name: 'notes',
      type: FieldsType.Array,
      required: false,
      visible: true,
      property: 'Notes'
    },
    {
      name: 'images',
      type: FieldsType.Image,
      required: false,
      visible: true,
      property: 'Images'
    }
  ]);
  const [
    formValues,
    setFormValues
  ] = React.useState<registrFormStepOneValuesType>({
    name: '',
    description: ''
  });
  const [formStepTwoValues, setFormStepTwoValues] = React.useState<
    registrFormStepTwoValuesType[]
  >([]);
  const [isBlocking, setIsBlocking] = React.useState<boolean>(false);
  const [searchResult, setSearchResult] = React.useState<any[]>([]);
  const [filterDoctors, setFilterDoctors] = React.useState<any[]>([]);
  const [isOpen, setisOpen] = React.useState<boolean>(false);
  const [selectedDoctors, setSelectedDoctors] = React.useState<any[]>([]);
  const [performSearch, setPerformSearch] = React.useState<boolean>(false);
  const userId = getUser() ? getUser().id : null;
  const theme = useTheme();
  const matchesWeb = useMediaQuery(theme.breakpoints.up('md'));
  const classes = registryFormStyle();

  const clearSearch = () => {
    if (searchValue !== '') {
      setSearchValue('');
      setSearchResult([]);
    }
  };

  const typeList = [
    'Free text',
    'Drop down list',
    'Date picker',
    'CPT',
    'Diagnosis'
  ];
  const optionList = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  const handleSearchChange = (event: any) => {
    setPerformSearch(true);
    setSearchValue(event.target.value);
  };

  const filterCheckedDoctors = (searchResult) => {
    if (searchResult) {
      let filterDoctor: any[] = [];
      for (let i = 0; i < searchResult.length; i++) {
        if (searchResult[i].isChecked === true) {
          filterDoctor.push(searchResult[i]);
        }
      }
      let selectedUsers: any[] = [];
      for (var i = 0; i < selectedDoctors.length; ++i) {
        selectedUsers.push({
          user_id: selectedDoctors[i]['id'],
          permission: selectedDoctors[i]['permission']
        });
      }
      setUsers(selectedUsers);
      setFilterDoctors(filterDoctor);
      let newSelected: any[] = [];
      for (let i = 0; i < filterDoctor.length; i++) {
        if (selectedDoctors.indexOf(filterDoctor[i]) === -1) {
          newSelected.push(filterDoctor[i]);
        }
      }
    }
  };

  React.useEffect(() => {
    filterCheckedDoctors(searchResult);
  }, [searchResult]);

  const addCheckAtt = (data) => {
    let newData =
      data &&
      data.map((obj) => ({
        ...obj,
        isChecked: false,
        permission: Permission.MINE
      }));

    return newData;
  };

  const getAvailableDoctor = async (searchValue) => {
    const { data, status_code: statusCode } = await api.getAvailableUser(
      searchValue
    );
    if (statusCode === api.status.SUCCESS) {
      setDoctors(addCheckAtt(data));
      setSearchResult(addCheckAtt(data));
    }
  };

  const onSubmit = async (isError) => {
    const mainField = [...orignalField];

    const values: any[] = getValues().inputs;
    let isDuplicate;
    if (values !== undefined && values.length > 0) {
      let valueArr = values.map(function (item) {
        return item.name;
      });
      isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx;
      });

      for (let i = 0; i < values.length; i++) {
        for (const [key, value] of Object.entries(values[i])) {
          if (value === '') {
            setErrorMsg(`${key} is required`);
            setIsError(true);
          }
        }
      }
    }
    if (isDuplicate) {
      setIsError(true);
      setErrorMsg('Please enter different title for fields ');
    }
    if (!isError && !isDuplicate) {
      let registryData;
      if (values !== undefined && values.length > 0) {
        registryData = {
          user_id: userId,
          name: formValues.name,
          description: formValues.description,
          fields: [...mainField, ...values],
          users: users
        };
      } else {
        registryData = {
          user_id: userId,
          name: formValues.name,
          description: formValues.description,
          fields: [...mainField],
          users: users
        };
      }
      const { data, status_code: statusCode } = await api.addRegistry(
        registryData
      );
      if (statusCode === api.status.SUCCESS) {
        let message = ['success', `${formValues.name} successfully created!`];
        setRegistryMessage(message);
        setShowAddRegistryMessage(true);
        setTimeout(() => setShowAddRegistryMessage(false), 1000);
        history.push('/registries');
      } else {
        let message = ['error', `${data}`];
        setRegistryMessageError(message);
        setShowAddRegistryMessageError(true);
        setTimeout(() => setShowAddRegistryMessageError(false), 1000);
      }
    }
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required('This field is required')
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch
  } = useForm({
    defaultValues: {
      inputs: formStepTwoValues,
      name: '',
      description: ''
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });
  const { fields, append, remove } = useFieldArray({
    control,

    name: 'inputs'
  });
  const watched = watch('inputs');
  const handleNext = () => {
    setValue('inputs', formStepTwoValues);
    setResetForm(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCloseDialog = () => {
    setisOpen(false);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setIsError(false);
      const values: any[] = getValues().inputs;
      if (values !== undefined && values.length > 0) {
        setFormStepTwoValues(values);
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setResetForm(true);
  };

  const submitStepOne = () => {
    setFormValues({
      name: getValues('name'),
      description: getValues('description')
    });
    handleNext();
  };

  const doSearch = React.useCallback(async () => {
    const result = doctors.filter((doctor) => {
      const doctorName = doctor.name.toLowerCase();
      return doctorName.includes(searchValue.toLowerCase());
    });
    setSearchResult(result);
  }, [searchValue, doctors]);

  React.useEffect(() => {
    if (searchValue === '') {
      clearSearch();
    } else {
      doSearch();
    }
  }, [searchValue]);

  React.useEffect(() => {
    if (searchValue.length >= 3) {
      getAvailableDoctor(searchValue);
    }
  }, [searchValue]);

  React.useEffect(() => {
    setValue('name', formValues.name);
    setValue('description', formValues.description);
  }, [formValues.name, formValues.description, resetForm, setValue]);

  const handleChange = (e) => {
    setViewOption(e.target.name);
  };

  const getCurrentPlan = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getCurrentPlan();
    if (statusCode === api.status.SUCCESS) {
      setCurrentPlan(data);
    }
  }, []);

  const getUserToken = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getUserToken();
    setNewPlan(false);
    if (statusCode === api.status.SUCCESS) {
      setUserToken(data.token);
    } else if (statusCode === api.status.ERROR_UPDATE) {
      setTokenError(true);
      setMessageError(data);
    }
  }, []);

  React.useEffect(() => {
    getUserToken();
    if (isOpen) {
      getCurrentPlan();
    }
  }, [isOpen]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Grid
              item={true}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.header}
            >
              <FolderIcon color="secondary" className={classes.headerIcon} />
              <Typography variant="h2" className={classes.inline}>
                Registry details
              </Typography>
            </Grid>
            <Grid container item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item={true}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={classes.header}
              >
                {' '}
                <Controller
                  as={TextField}
                  required
                  variant="outlined"
                  name="name"
                  defaultValue=""
                  control={control}
                  label="Registry name"
                  margin="normal"
                  fullWidth
                  helperText={errors.name && errors.name.message}
                  error={errors.name ? true : false}
                />
              </Grid>
              <Grid
                item={true}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                className={[classes.header, classes.pr].join(' ')}
              >
                {' '}
                <Controller
                  as={TextField}
                  variant="outlined"
                  name="description"
                  defaultValue=""
                  control={control}
                  label="Description"
                  margin="normal"
                  fullWidth
                  helperText={errors.description && errors.description.message}
                  error={errors.description ? true : false}
                />
              </Grid>
            </Grid>
            <Paper className={classes.sharedPaper}>
              <Grid
                item={true}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.secHeader}
              >
                <Typography variant="h2">Share registry</Typography>
                <Typography variant="caption">
                  Share registry with your colleagues!
                </Typography>
                <Typography variant="h5">
                  Invited users will receive emails with their invitations.{' '}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Divider />
              </Grid>
              <Grid
                item={true}
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.selectHeader}
              >
                <Grid
                  item={true}
                  xs={6}
                  sm={6}
                  md={8}
                  lg={8}
                  xl={8}
                  className={classes.selectSection}
                >
                  {selectedDoctors.length > 0 || searchResult.length > 0 ? (
                    <>
                      {selectedDoctors.length === doctors.length ? (
                        <CheckBoxIcon
                          className={classes.icon}
                          onClick={() => {
                            let selectedAllDoctors;
                            selectedDoctors.length === doctors.length
                              ? (selectedAllDoctors = doctors.map((obj) => ({
                                  ...obj,
                                  isChecked: false
                                })))
                              : (selectedAllDoctors = searchResult.map(
                                  (obj) => ({
                                    ...obj,
                                    isChecked: true
                                  })
                                ));
                            if (selectedDoctors.length === doctors.length) {
                              setSelectedDoctors([]);
                              setSearchResult(selectedAllDoctors);
                            } else {
                              let newSelected = [
                                ...selectedDoctors,
                                ...selectedAllDoctors
                              ];

                              setSelectedDoctors(newSelected);
                              setSearchResult([]);
                            }

                            setFilterDoctors(selectedAllDoctors);
                          }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          className={classes.icon}
                          onClick={() => {
                            let selectedAllDoctors;
                            selectedDoctors.length === doctors.length
                              ? (selectedAllDoctors = doctors.map((obj) => ({
                                  ...obj,
                                  isChecked: false
                                })))
                              : (selectedAllDoctors = searchResult.map(
                                  (obj) => ({
                                    ...obj,
                                    isChecked: true
                                  })
                                ));
                            if (selectedDoctors.length === doctors.length) {
                              setSelectedDoctors([]);
                              setSearchResult(selectedAllDoctors);
                            } else {
                              let newSelected = [
                                ...selectedDoctors,
                                ...selectedAllDoctors
                              ];

                              setSelectedDoctors(newSelected);
                              setSearchResult([]);
                            }

                            setFilterDoctors(selectedAllDoctors);
                          }}
                        />
                      )}

                      <Typography variant="h3" className={classes.inline}>
                        Select all
                      </Typography>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  item={true}
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  className={classes.search}
                >
                  <TextField
                    InputProps={{
                      endAdornment:
                        searchValue.length > 0 ? (
                          <InputAdornment position="start">
                            <ClearIcon
                              onClick={clearSearch}
                              className={classes.cursor}
                            ></ClearIcon>
                          </InputAdornment>
                        ) : null,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search"
                    margin="normal"
                    fullWidth
                    value={searchValue}
                    onChange={(event) => handleSearchChange(event)}
                  />
                </Grid>
              </Grid>
              <Grid item={true}>
                <Divider />
              </Grid>
              <Box
                className={
                  selectedDoctors.length > 0 || searchResult.length > 0
                    ? classes.list
                    : ''
                }
              >
                {selectedDoctors && selectedDoctors.length > 0
                  ? selectedDoctors.map((doctor: any, index) => (
                      <Fragment key={doctor.id}>
                        <Grid
                          item={true}
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className={classes.doctorInfo}
                        >
                          <Grid
                            item={true}
                            xs={8}
                            sm={8}
                            md={9}
                            lg={10}
                            xl={10}
                            className={classes.leftSection}
                          >
                            <Checkbox
                              classes={{
                                root: classes.checkRoot,
                                checked: classes.checked
                              }}
                              checked={doctor.isChecked}
                              onChange={() => {
                                let newSelectedDoctors = selectedDoctors.filter(
                                  (selectedDoctor) =>
                                    selectedDoctor.id !== doctor.id
                                );
                                let unCheckedDoctor = selectedDoctors.filter(
                                  (selectedDoctor) =>
                                    selectedDoctor.id === doctor.id
                                );

                                if (searchValue !== '') {
                                  searchResult.unshift({
                                    ...unCheckedDoctor[0],
                                    isChecked: false
                                  });
                                }

                                setSelectedDoctors(newSelectedDoctors);
                              }}
                            />
                            <img
                              className={classes.doctorImage}
                              src={
                                doctor.user_media
                                  ? doctor.user_media.image.href_small
                                  : DoctorPlaceholder
                              }
                              alt="Doctor Placeholder"
                            />
                            <Box className={classes.doctorBox}>
                              <Typography
                                variant="h3"
                                noWrap
                                className={classes.noWrapWidth}
                              >
                                {`${doctor.name}`}
                                {doctor.user_role
                                  ? ` (${doctor.user_role?.nice_name})`
                                  : ''}
                              </Typography>
                              <Typography
                                variant="caption"
                                noWrap
                                className={classes.noWrapWidth}
                              >
                                {doctor.user_profile &&
                                  doctor.user_profile.speciality &&
                                  doctor.user_profile.speciality.name}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            item={true}
                            xs={4}
                            sm={4}
                            md={3}
                            lg={2}
                            xl={2}
                            className={classes.search}
                          >
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                fullWidth
                                variant="outlined"
                                id="demo-simple-select-outlined"
                                onChange={handleChange}
                                value={
                                  doctor.permission === 'ALL'
                                    ? 'All cases'
                                    : 'Own cases'
                                }
                              >
                                <MenuItem value="" selected>
                                  This user can view:
                                </MenuItem>
                                <MenuItem
                                  selected={
                                    doctor.permission === 'ALL' ? true : false
                                  }
                                  value="All cases"
                                  onClick={() => {
                                    setViewOption('All cases');
                                    let newSelectedDoctors = searchResult.map(
                                      (element) =>
                                        element.id === doctor.id
                                          ? {
                                              ...element,

                                              permission: Permission.ALL
                                            }
                                          : element
                                    );
                                    let newSelectedDoctorsPerm = selectedDoctors.map(
                                      (element) =>
                                        element.id === doctor.id
                                          ? {
                                              ...element,

                                              permission: Permission.ALL
                                            }
                                          : element
                                    );
                                    setSearchResult(newSelectedDoctors);
                                    setSelectedDoctors(newSelectedDoctorsPerm);
                                  }}
                                >
                                  All cases
                                </MenuItem>
                                <MenuItem
                                  selected={
                                    doctor.permission === 'MINE' ? true : false
                                  }
                                  value="Own cases"
                                  onClick={() => {
                                    setViewOption('Own cases');
                                    let newSelectedDoctors = searchResult.map(
                                      (element) =>
                                        element.id === doctor.id
                                          ? {
                                              ...element,

                                              permission: Permission.MINE
                                            }
                                          : element
                                    );
                                    let newSelectedDoctorsPerm = selectedDoctors.map(
                                      (element) =>
                                        element.id === doctor.id
                                          ? {
                                              ...element,

                                              permission: Permission.MINE
                                            }
                                          : element
                                    );
                                    setSearchResult(newSelectedDoctors);
                                    setSelectedDoctors(newSelectedDoctorsPerm);
                                  }}
                                >
                                  Own cases
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item={true}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <Divider />
                        </Grid>
                      </Fragment>
                    ))
                  : null}
                {searchResult && searchResult.length > 0 ? (
                  searchResult.map((doctor: any, index) => (
                    <Fragment key={doctor.id}>
                      <Grid
                        item={true}
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.doctorInfo}
                      >
                        <Grid
                          item={true}
                          xs={8}
                          sm={8}
                          md={9}
                          lg={10}
                          xl={10}
                          className={classes.leftSection}
                        >
                          <Checkbox
                            classes={{
                              root: classes.checkRoot,
                              checked: classes.checked
                            }}
                            checked={doctor.isChecked}
                            onChange={() => {
                              let newSelectedDoctor = searchResult.map(
                                (element) =>
                                  element.id === doctor.id
                                    ? {
                                        ...element,
                                        isChecked: !doctor.isChecked
                                      }
                                    : element
                              );

                              let newSelectedDoctors = newSelectedDoctor.filter(
                                (selectedDoctor) =>
                                  selectedDoctor.id !== doctor.id
                              );
                              let selectedDoctor = newSelectedDoctor.filter(
                                (selectedDoctor) =>
                                  selectedDoctor.id === doctor.id
                              );
                              setSearchResult(newSelectedDoctors);
                              let newSelected = selectedDoctors.concat(
                                selectedDoctor
                              );
                              setSelectedDoctors(newSelected);
                            }}
                          />
                          <img
                            className={classes.doctorImage}
                            src={
                              doctor.user_media
                                ? doctor.user_media.image.href_small
                                : DoctorPlaceholder
                            }
                            alt="Doctor Placeholder"
                          />
                          <Box className={classes.doctorBox}>
                            <Typography
                              variant="h3"
                              noWrap
                              className={classes.noWrapWidth}
                            >
                              {`${doctor.name}`}
                              {doctor.user_role
                                ? ` (${doctor.user_role?.nice_name})`
                                : ''}
                            </Typography>
                            <Typography
                              variant="caption"
                              noWrap
                              className={classes.noWrapWidth}
                            >
                              {doctor.user_profile &&
                                doctor.user_profile.speciality &&
                                doctor.user_profile.speciality.name}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid
                          item={true}
                          xs={4}
                          sm={4}
                          md={3}
                          lg={2}
                          xl={2}
                          className={classes.search}
                        >
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              fullWidth
                              variant="outlined"
                              id="demo-simple-select-outlined"
                              onChange={handleChange}
                              name={viewOption}
                              defaultValue="Own cases"
                            >
                              <MenuItem value="">This user can view:</MenuItem>
                              <MenuItem
                                value="All cases"
                                onClick={() => {
                                  setViewOption('All cases');
                                  let newSelectedDoctors = searchResult.map(
                                    (element) =>
                                      element.id === doctor.id
                                        ? {
                                            ...element,

                                            permission: Permission.ALL
                                          }
                                        : element
                                  );
                                  setSearchResult(newSelectedDoctors);
                                }}
                              >
                                All cases
                              </MenuItem>
                              <MenuItem
                                value="Own cases"
                                onClick={() => {
                                  setViewOption('Own cases');
                                  let newSelectedDoctors = searchResult.map(
                                    (element) =>
                                      element.id === doctor.id
                                        ? {
                                            ...element,

                                            permission: Permission.MINE
                                          }
                                        : element
                                  );
                                  setSearchResult(newSelectedDoctors);
                                }}
                              >
                                Own cases
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ))
                ) : selectedDoctors.length === 0 ? (
                  <Box className={classes.imageBox}>
                    <Box>
                      <img
                        src={!isDarkTheme ? emptySaerch : emptySaerchDark}
                        alt="emptyView"
                      ></img>
                    </Box>
                    <Box className={classes.emptySearch}>
                      {' '}
                      <Typography variant="caption">
                        Start typing to get results!
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Paper>
          </>
        );
      case 1:
        return (
          <>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box className={classes.header}>
                <img
                  className={classes.headerIcon}
                  src={text}
                  alt="textField"
                />
                <Typography variant="h2" className={classes.inline}>
                  Customized fields
                </Typography>
              </Box>
              <Typography variant="caption" className={classes.pl}>
                Customize case fields to suit the registry topic
              </Typography>
            </Grid>
            <Paper className={classes.fieldPaper}>
              <Grid
                item={true}
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid item={true}>
                  <Divider />
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={matchesWeb ? classes.fieldPaperHeader : ''}
                >
                  <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h2" className={classes.pb}>
                      Original case fields
                    </Typography>
                  </Grid>

                  <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider />
                  </Grid>
                  <Box className={classes.originalfieldList}>
                    {orignalField && orignalField.length > 0 && matchesWeb ? (
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {' '}
                                <Typography
                                  variant="h3"
                                  className={classes.firstfieldTitle}
                                >
                                  Field name
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="h3">Type</Typography>
                              </TableCell>

                              <TableCell align="left">
                                <Typography variant="h3">
                                  Visible to others
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="h3">Required</Typography>
                              </TableCell>
                            </TableRow>
                            {orignalField.map((field, index) => (
                              <TableRow key={field.name}>
                                <TableCell align="left">
                                  <span className={classes.fieldName}>
                                    <Typography
                                      variant="h3"
                                      className={classes.firstfieldContent}
                                    >
                                      {field.property}
                                    </Typography>
                                  </span>
                                </TableCell>
                                <TableCell align="left">
                                  {' '}
                                  <Typography variant="h3">
                                    {field.type}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h3">
                                    {field.visible ? 'Yes' : 'No'}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <span className={classes.deleteCell}>
                                    <Typography
                                      variant="h3"
                                      className={classes.inline}
                                    >
                                      {field.required ? 'Yes' : 'No'}
                                    </Typography>
                                    {index > 4 ? (
                                      <DeleteOutlineIcon
                                        onClick={() => {
                                          setOrignalField(
                                            orignalField.filter(
                                              (_field, i) => i !== index
                                            )
                                          );
                                        }}
                                        className={classes.deleteIcon}
                                      />
                                    ) : null}
                                  </span>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      orignalField.map((field: any, index) => (
                        <Grid
                          item={true}
                          container
                          key={field.name}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className={classes.mtContainer}
                        >
                          <Grid
                            item={true}
                            xs={1}
                            sm={1}
                            md={1}
                            lg={1}
                            xl={1}
                          ></Grid>
                          <Grid
                            item={true}
                            container
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            xl={10}
                          >
                            <Grid
                              item={true}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.fieldContentMobile}
                            >
                              <Typography
                                variant="h3"
                                className={classes.fieldContent}
                              >
                                {field.property}
                              </Typography>
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.fieldContentMobile}
                            >
                              <Typography
                                variant="h3"
                                className={classes.fieldContent}
                              >
                                {field.type}
                              </Typography>
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.fieldContentMobile}
                            >
                              <Typography variant="h3">{field.name}</Typography>
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className={classes.fieldContentMobile}
                            >
                              <Typography variant="h3">
                                {field.optional ? 'Optional' : 'Required'}
                              </Typography>
                            </Grid>
                            <Grid
                              item={true}
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Typography
                                variant="h3"
                                className={classes.lastfieldContent}
                              >
                                {field.visible ? 'Visible' : 'Hidden'}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item={true} xs={1} sm={1} md={1} lg={1} xl={1}>
                            {index > 4 ? (
                              <DeleteOutlineIcon
                                onClick={() => {
                                  setOrignalField(
                                    orignalField.filter(
                                      (_field, i) => i !== index
                                    )
                                  );
                                }}
                                className={classes.deleteIcon}
                              />
                            ) : null}
                          </Grid>
                          <Grid
                            item={true}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <Divider />
                          </Grid>
                        </Grid>
                      ))
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.fieldPaper}>
              <Grid
                item={true}
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid
                  item={true}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.fieldPaperHeader}
                >
                  <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className={classes.customFieldHeader}>
                      <Typography variant="h2">Custom fields</Typography>
                      <AddIcon color="secondary" onClick={() => append({})} />
                    </Box>
                    <Typography variant="caption">
                      Customized fields on add case page will be arranged
                      according to your order, the first entered fields will
                      appear first!
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider />
                  </Grid>
                  <Box className={fields.length > 0 ? classes.fieldList : ''}>
                    <form>
                      {fields && fields.length > 0 ? (
                        fields.map((item, index) => {
                          return (
                            <>
                              <Grid
                                item={true}
                                key={item.name}
                                container
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                spacing={1}
                                alignItems="center"
                              >
                                <Grid item={true} xs={12} sm={12} md={3} lg={3}>
                                  {!matchesWeb ? (
                                    <Box className={classes.mobilefieldHeader}>
                                      <Typography variant="h3">
                                        Customized Field {index + 1}{' '}
                                      </Typography>
                                      <DeleteOutlineIcon
                                        onClick={() => {
                                          remove(index);
                                        }}
                                        className={[
                                          classes.cursor,
                                          classes.deleteIcon
                                        ].join(' ')}
                                      ></DeleteOutlineIcon>
                                    </Box>
                                  ) : null}
                                  <TextField
                                    className={classes.mt}
                                    variant="outlined"
                                    name={`inputs[${index}].name`}
                                    defaultValue={
                                      item.name ? `${item.name}` : ''
                                    }
                                    label="Field title*"
                                    margin="normal"
                                    fullWidth
                                    inputRef={register()}
                                  />
                                </Grid>
                                <Grid item={true} xs={12} sm={12} md={3} lg={3}>
                                  <ReactHookFormSelect
                                    required
                                    name={`inputs[${index}].type`}
                                    label="Type "
                                    defaultValue={
                                      item.type ? `${item.type}` : ''
                                    }
                                    control={control}
                                    variant="outlined"
                                    margin="normal"
                                    setIsBlocking={setIsBlocking}
                                    error={errors.inputs ? true : false}
                                    helperText={errors.inputs && errors.inputs}
                                  >
                                    {typeList.length > 0
                                      ? typeList.map((type: any) => (
                                          <MenuItem key={type} value={type}>
                                            {type}
                                          </MenuItem>
                                        ))
                                      : null}
                                  </ReactHookFormSelect>
                                </Grid>

                                <Grid item={true} xs={6} sm={6} md={3} lg={3}>
                                  <ReactHookFormSelect
                                    name={`inputs[${index}].visible`}
                                    label="Visible*"
                                    control={control}
                                    defaultValue={
                                      item.visible ? `${item.visible}` : ''
                                    }
                                    variant="outlined"
                                    margin="normal"
                                    setIsBlocking={setIsBlocking}
                                    error={errors.inputs ? true : false}
                                    helperText={errors.inputs && errors.inputs}
                                  >
                                    {optionList.length > 0
                                      ? optionList.map((option: any) => (
                                          <MenuItem
                                            key={option.label}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        ))
                                      : null}
                                  </ReactHookFormSelect>
                                </Grid>
                                <Grid
                                  item={true}
                                  xs={6}
                                  sm={6}
                                  md={3}
                                  lg={3}
                                  className={classes.optional}
                                >
                                  <ReactHookFormSelect
                                    name={`inputs[${index}].required`}
                                    label="Required*"
                                    control={control}
                                    defaultValue={
                                      item.required ? `${item.required}` : ''
                                    }
                                    variant="outlined"
                                    margin="normal"
                                    setIsBlocking={setIsBlocking}
                                    error={errors.inputs ? true : false}
                                    helperText={errors.inputs && errors.inputs}
                                  >
                                    {optionList.length > 0
                                      ? optionList.map((option: any) => (
                                          <MenuItem
                                            key={option.label}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        ))
                                      : null}
                                  </ReactHookFormSelect>

                                  {matchesWeb ? (
                                    <DeleteOutlineIcon
                                      onClick={() => {
                                        remove(index);
                                      }}
                                      className={[
                                        classes.cursor,
                                        classes.deleteIcon
                                      ].join(' ')}
                                    ></DeleteOutlineIcon>
                                  ) : null}
                                </Grid>
                              </Grid>
                              {watched[0]['type'] !== '' &&
                              watched[index] !== undefined &&
                              watched[index]['type'] === 'Drop down list' ? (
                                <Grid
                                  item={true}
                                  container
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                >
                                  <Grid
                                    item={true}
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                  >
                                    <TextField
                                      required
                                      className={classes.mt}
                                      variant="outlined"
                                      name={`inputs[${index}].value`}
                                      defaultValue={
                                        item.value ? `${item.value}` : ''
                                      }
                                      label="Drop down list items"
                                      margin="normal"
                                      placeholder="Option 1, Option 2, Option 3, Option 4."
                                      fullWidth
                                      inputRef={register()}
                                    />
                                  </Grid>
                                  <Grid
                                    item={true}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                  >
                                    <Typography
                                      variant="h5"
                                      className={classes.hint}
                                    >
                                      Add items one by one and use comma to
                                      separate them.
                                    </Typography>
                                  </Grid>{' '}
                                </Grid>
                              ) : null}
                              {isError ? (
                                <p
                                  className={[
                                    classes.errorMsg,
                                    classes.hint
                                  ].join(' ')}
                                >
                                  {errorMsg}
                                </p>
                              ) : null}
                              <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                                <Divider />
                              </Grid>
                            </>
                          );
                        })
                      ) : (
                        <Box className={classes.customizedimageBox}>
                          <Box>
                            <img
                              src={!isDarkTheme ? Customizeform : Customizeform}
                              alt="emptyView"
                            ></img>
                          </Box>

                          <Box className={classes.emptyField}>
                            {' '}
                            <Typography variant="h5">
                              Start by customizing your case form!{' '}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <div className={classes.root}>
      <UpgradeDialog
        isOpen={isOpen && !tokenError}
        onClose={() => setisOpen(false)}
        handleChange={handleChange}
        handleCloseDialog={handleCloseDialog}
        userToken={userToken}
        setNewPlan={setNewPlan}
        subscriptionId={currentPlan?.subscr_id}
      />
      <Snackbar open={showAddRegistryMessageError}>
        <Alert
          action={
            registryMessageError[0] === 'error' &&
            registryMessageError[1] === 'You need to upgrade your plan' ? (
              <Button
                onClick={() => {
                  if (!tokenError) {
                    setisOpen(true);
                    setShowAddRegistryMessage(false);
                  } else {
                    setShowError(true);
                    setShowAddRegistryMessage(false);
                  }
                }}
              >
                Manage plans
              </Button>
            ) : null
          }
          onClose={() => setShowAddRegistryMessageError(false)}
          color={registryMessageError[0]}
          severity={registryMessageError[0]}
        >
          {registryMessageError[1]}
        </Alert>
      </Snackbar>{' '}
      <Snackbar open={showError} color="warning">
        <Alert onClose={() => setShowError(false)} severity="warning">
          You can't manage your plan since you have cancelled subscription
        </Alert>
      </Snackbar>
      <Grid
        item={true}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.headerContainer}
      >
        <>
          <Grid
            item={true}
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            className={classes.registeryName}
          >
            <NavLink className={classes.link} to="/registries">
              <Typography variant="h1" className={classes.inline}>
                {'Registries > '}
              </Typography>
            </NavLink>
            <Typography
              color="primary"
              variant="body2"
              className={classes.inline}
            >
              Create registry
            </Typography>
          </Grid>
        </>
      </Grid>
      <Paper className={classes.formPaper}>
        {getStepContent(activeStep)}
        <MobileStepper
          steps={2}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={
            activeStep === 1 ? (
              <Button
                size="medium"
                onClick={() => {
                  setIsError(false);
                  onSubmit(false);
                }}
                disabled={activeStep === 3 - 1}
              >
                Done
                <KeyboardArrowRight />
              </Button>
            ) : (
              <Button
                size="medium"
                onClick={handleSubmit(submitStepOne)}
                disabled={activeStep === 3 - 1}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            )
          }
          backButton={
            <Button
              size="large"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Paper>
    </div>
  );
};

export default RegistryForm;
