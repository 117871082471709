import { makeStyles, Theme, createStyles } from '@material-ui/core';

const CurrentPlanStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '15px 168px',
      minWidth: 500,
      [theme.breakpoints.down('md')]: {
        padding: 16,
        minWidth: 0
      },
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        width: '78%',
        margin: 'auto'
      },
      [theme.breakpoints.only('md')]: {
        padding: '15px 50px',
        minWidth: 0
      }
    },
    headerContainer: {
      display: 'flex',

      marginTop: 24,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: 0
      }
    },

    planName: {
      fontWeight: 500
    },
    newPlanName: {
      color: '#ffad2c',
      fontSize: 12
    },
    newPlan: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'baseline'
      }
    },
    planInfoCel: {
      padding: '16px 24px',
      border: '1px solid rgba(170, 170, 170, 0.5)',

      [theme.breakpoints.up('md')]: {
        borderRight: 'none',
        borderTop: 'none'
      }
    },
    planInfoLastCel: {
      padding: '16px 24px',
      border: '1px solid rgba(170, 170, 170, 0.5)',
      [theme.breakpoints.up('md')]: {
        borderRight: 'none',
        borderTop: 'none',
        borderBottomLeftRadius: 5
      },
      [theme.breakpoints.down('sm')]: {
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5
      }
    },

    planImage: {
      position: 'absolute',
      top: 0,
      right: 20
    },

    planInfoFirstCel: {
      padding: '16px 24px',
      border: '1px solid rgba(170, 170, 170, 0.5)',
      [theme.breakpoints.up('md')]: {
        borderRight: 'none',
        borderTopLeftRadius: 5
      },
      [theme.breakpoints.down('sm')]: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
      }
    },
    planUsedFeaturesCel: {
      padding: '16px 24px',
      border: '1px solid rgba(170, 170, 170, 0.5)',
      [theme.breakpoints.up('md')]: {
        borderTop: 'none'
      }
    },
    planUsedFeaturesLastCel: {
      padding: '16px 24px',
      border: '1px solid rgba(170, 170, 170, 0.5)',
      [theme.breakpoints.up('md')]: {
        borderBottomRightRadius: 5,
        borderTop: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5
      }
    },
    planUsedFeaturesFirstCel: {
      padding: '16px 24px',
      border: '1px solid rgba(170, 170, 170, 0.5)',
      [theme.breakpoints.up('md')]: {
        borderTopRightRadius: 5
      },
      [theme.breakpoints.down('sm')]: {
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5
      }
    },
    planFeaturesContainer: {
      [theme.breakpoints.down('md')]: {
        marginBottom: 8
      }
    },
    indicator: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: '50%'
    },
    shortcut: {
      paddingLeft: 6
    },
    planHeaderContainer: {
      position: 'relative',
      marginTop: 27,
      marginBottom: 16
    },
    planHeader: {
      padding: '16px 25px'
    },
    currentPlan: {
      paddingLeft: 12,
      paddingBottom: 12,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    link: {
      textDecoration: 'none'
    },
    inline: {
      display: 'inline'
    },
    headerButton: {
      display: 'contents'
    }
  })
);

export default CurrentPlanStyle;
