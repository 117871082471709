import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
};

const breakpoints = createBreakpoints({ ...customBreakpointValues });

const common = {
  black: '#000',
  white: '#fff'
};

function getTheme(theme: any) {
  return createTheme({
    breakpoints: {
      ...customBreakpointValues
    },
    overrides: {
      MuiDialogContent: {
        root: {
          backgroundColor: '#f8f8f8',
          fontFamily: 'Roboto, sans-serif'
        }
      },
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': 'thin'
          },
          '*::-webkit-scrollbar': {
            width: '8px'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(128, 128, 128, 0.5)',
            borderRadius: 20
          },
          '*::-webkit-scrollbar-track': {
            borderRadius: '11px'
          }
        }
      },
      MuiPopover: {
        paper: {
          maxWidth: 300
        }
      },
      MuiTable: {
        root: {
          borderCollapse: 'revert'
        }
      },
      MuiFormLabel: {
        root: {
          color: '#aaaaaa'
        },
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      },

      MuiInputBase: {
        root: {
          fontFamily: 'Roboto, sans-serif'
        },
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s'
          }
        }
      },
      MuiInput: {
        input: {
          '&::placeholder': {
            color: '#aaaaaa'
          }
        }
      },
      MuiOutlinedInput: {
        input: {
          color: '#2f2f2f'
        },
        adornedEnd: {
          paddingRight: 8
        }
      },
      MuiCheckbox: {
        root: {
          '&$checked': {
            color: '#3D70B2'
          }
        },
        checked: {}
      },

      MuiIconButton: {
        root: {
          padding: 0
        }
      },
      MuiDialogActions: {
        root: {
          fontFamily: 'Roboto, sans-serif',
          backgroundColor: '#f8f8f8'
        }
      },
      MuiButton: {
        containedSecondary: {
          '&:hover': {
            backgroundColor: '#4AA5FF'
          }
        },
        root: {
          fontFamily: 'Roboto, sans-serif',
          textTransform: 'none',
          fontWeight: 200
        }
      },
      MuiAppBar: {
        root: {
          zIndex: 'unset'
        },
        colorDefault: {
          backgroundColor: '#ffffff'
        }
      },
      MuiTypography: {
        colorInherit: {
          color: '#aaaaaa'
        }
      },

      MuiButtonBase: {},
      MuiTablePagination: {
        root: {
          overflow: 'none'
        },
        toolbar: {
          paddingLeft: 4,
          paddingRight: 0
        }
      },
      MuiRadio: {
        colorPrimary: {
          color: '#ffad2c'
        }
      },
      MuiLinearProgress: {
        barColorPrimary: {
          color: '#4aa5ff',
          backgroundColor: '#4aa5ff'
        }
      },

      MuiMobileStepper: {
        root: {
          fontFamily: 'Roboto, sans-serif',
          backgroundColor: 'none',
          background: 'none'
        }
      }
    },
    palette: {
      primary: {
        light: '#073B70',
        main: '#073B70',
        dark: '#073B70'
      },
      secondary: {
        light: '#4AA5FF',
        main: '#4AA5FF',
        dark: '#ffffff',
        contrastText: '#ffffff'
      },
      error: {
        light: '#ff3b30',
        main: '#ff3b30',
        dark: '#ff3b30'
      },
      success: {
        main: '#073B70'
      },
      type: 'light',
      contrastThreshold: 3,
      tonalOffset: 0.2,
      text: {
        primary: '#073B70',
        secondary: '#aaaaaa',
        hint: '#4AA5FF'
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      background: {
        paper: common.white,
        default: '#f8f8f8'
      },
      action: {
        active: '#073B70',

        selected: 'rgba(0, 0, 0, 0.08)',
        selectedOpacity: 0.08,

        disabledBackground: 'rgba(74, 165, 255, 0.7)',
        disabledOpacity: 0.38,
        focus: 'rgba(0, 0, 0, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12
      }
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      h1: {
        fontSize: 18,
        color: '#aaaaaa',
        fontWeight: 400,
        fontFamily: 'Roboto, sans-serif'
      },
      h2: {
        fontSize: 16,
        color: '#4aa5ff',
        fontWeight: 500,
        lineHeight: 1.65,
        fontFamily: 'Roboto, sans-serif'
      },
      h3: {
        fontSize: 14,
        color: '#2f2f2f',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 500
      },
      h4: {
        fontSize: 12,
        color: '#4aa5ff',
        fontFamily: 'Roboto, sans-serif'
      },
      h5: {
        fontSize: 12,
        color: '#aaaaaa',
        fontFamily: 'Roboto, sans-serif'
      },
      h6: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,

        color: '#2f2f2f',

        fontSize: 16
      },
      body1: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        color: '#757575'
      },
      caption: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
        color: '#aaaaaa'
      },
      body2: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 18,
        color: '#073b70',
        textAlign: 'left'
      },
      subtitle1: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 18,
        color: '#2f2f2f'
      },
      subtitle2: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        color: '#073b70',
        textAlign: 'left'
      },
      overline: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 12,
        color: '#073b70',

        textTransform: 'none'
      }
    }
  });
}

export const lightTheme = getTheme({
  paletteType: 'light'
});
