import React, { useEffect, useState } from "react";
import history from '../../utils/history';
import { Link, NavLink, useParams } from 'react-router-dom';
import logo from '../../assets/img/MS-logo-img.png';
import { useLocation } from 'react-router-dom';

const NewHeader = () => {

  const location = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const openNav = () => {
    setIsOpen(true);
  };

  const closeNav = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    function handleScroll() {
      setIsActive(window.scrollY > 100);
    }

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openLink = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div>
      <section className="topBar">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="callUs">
                <a href="tel:(800) 575-8585"><i className="fa-solid fa-phone"></i> Call Us: (800) 575-8585</a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="followUs">
                <p>Follow us:</p>
                <ul>
                  <li><a onClick={() => openLink('https://twitter.com/MS_Logger')}><i style={{ alignItems: 'center' }} className="fab fa-twitter pointer"></i></a></li>
                  <li><a onClick={() => openLink('https://www.instagram.com/ms_logger/')}><i className="fab fa-instagram pointer"></i></a></li>
                  <li><a onClick={() => openLink('https://www.facebook.com/mslogger')}><i className="fab fa-facebook pointer"></i></a></li>
                  <li><a onClick={() => openLink('https://www.linkedin.com/company/72060014')}><i className="fa-brands fa-linkedin-in pointer"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <header className={`header ${isActive ? 'active' : ''}`} style={{ backgroundColor: location === '/' && !isActive ? 'transparent' : 'white' }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg">
                <div className="logo">
                  <a className="navbar-brand pointer" onClick={() => history.push('/')}>
                    <img style={{ width: '204px' }} src={logo} alt=""></img>
                  </a>
                </div>
                <div className="d-lg-none">
                  <button className="openbtn" onClick={() => {
                    openNav();
                  }}>☰</button>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Link to="/" className={location === '/' ? 'nav-link active' : 'nav-link'}>Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about" className={location === '/about' ? 'nav-link active' : 'nav-link'}>About MS Logger</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/plans" className={location === '/plans' ? 'nav-link active' : 'nav-link'}>Plan & Pricing</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/ourteam" className={location === '/ourteam' ? 'nav-link active' : 'nav-link'}>Our Team</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact" className={location === '/contact' ? 'nav-link active' : 'nav-link'}>Contact</Link>
                    </li>
                  </ul>
                  <div className="signInBtn d-flex">
                    <div className="sigUp">
                      <a onClick={() => history.push('/signin')} className="bgWhite pointer">Sign In</a>
                    </div>
                    <div className="sigIn">
                      <a onClick={() => history.push('/plans')} className="bgBlue pointer">Sign Up</a>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <section className="d-lg-none">
        <div style={{ width: isOpen ? 250 : 0 }} id="mySidepanel" className="sidepanel">
          <a href="javascript:void(0)" className="closebtn" onClick={() => closeNav()}>×</a>
          <a onClick={() => {
            history.push('/');
            closeNav();
          }} className="pointer">Home</a>
          <a onClick={() => {
            history.push('/about');
            closeNav();
          }} className="pointer">About MS Logger</a>
          <a onClick={() => {
            history.push('/plans');
            closeNav();
          }} className="pointer">Plan & Pricing</a>
          <a onClick={() => {
            history.push('/ourteam');
            closeNav();
          }} className="pointer">Our Team</a>
          <a onClick={() => {
            history.push('/contact');
            closeNav();
          }} className="pointer">Contact</a>
          <div className="signInBtn">
            <div className="sigUp pointer">
              <a onClick={() => {
                history.push('/signin');
                closeNav();
              }} className="bgWhite">Sign In</a>
            </div>
            <div className="sigIn pointer">
              <a onClick={() => {
                history.push('/plans');
                closeNav();
              }} className="bgBlue">Sign Up</a>
            </div>
          </div>
        </div>

      </section>

    </div>
  )
}

export default NewHeader;
