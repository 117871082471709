import { makeStyles, Theme, createStyles } from '@material-ui/core';

const UsersStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '80%',
      margin: 'auto',
      paddingTop: 40,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingTop: 18
      },
      [theme.breakpoints.only('md')]: {
        width: '95%',
        paddingTop: 18
      },
      display: 'flex',
      flexDirection: 'column'
    },
    searchIcon: {
      color: '#aaaaaa'
    },
    input: {
      fontSize: 16,
      marginTop: 3,
      marginLeft: theme.spacing(1),
      flex: 1,
      flexGrow: 1,
      height: 45,
      width: '90%',
      marginBottom: 16,
      [theme.breakpoints.down('xs')]: {
        height: 30
      },
      [theme.breakpoints.only('lg')]: {
        width: '95%'
      },
      [theme.breakpoints.only('xs')]: {
        width: '95%'
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 0
      }
    },
    header: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      [theme.breakpoints.only('lg')]: {
        margin: '0px 11px'
      }
    },
    mr24: {
      marginRight: 24,
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        marginRight: 9
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        padding: '0px 6px 16px'
      },

      [theme.breakpoints.only('lg')]: {
        marginRight: 0
      }
    },
    inviteButton: {
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    f16: {
      fontSize: 16,
      fontWeight: 500,
      marginLeft: 10
    },
    imageBox: {
      textAlign: "center",
      margin: '15% auto 0px',
      [theme.breakpoints.down('md')]: {
        margin: '35% auto 0px'
      }
    },
    emptyUsers: {
      margin: '5px auto 0px',
      textAlign: 'center'
    },
  })
);

export default UsersStyle;
