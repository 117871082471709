import { makeStyles, Theme, createStyles } from '@material-ui/core';

const plansStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '78%',

      margin: 'auto',
      marginTop: 50,
      [theme.breakpoints.between('md', 'lg')]: {
        width: '95%'
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 30
      }
    },
    filterTab: {
      fontWeight: 'normal',
      fontSize: 16,
      textTransform: 'none'
    },
    header: {
      fontWeight: 500,
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 48
    },
    appBar: {
      marginBottom: 50
    },
    planPaper: {
      position: 'relative'
    },
    planImg: {
      position: 'absolute',
      top: 0,
      left: 16
    },
    planHeader: {
      paddingTop: 24,
      textAlign: 'center',
      fontWeight: 500
    },
    planPrice: {
      paddingTop: 24,
      paddingBottom: 32,
      textAlign: 'center',
      fontWeight: 500,
      fontSize: 24
    },
    button: {
      width: '100%'
    },
    buttonPopular: {
      width: '100%',
      backgroundColor: '#ffad2c'
    },
    listItem: {
      marginBottom: 16,
      textAlign: 'center',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '78%',
      margin: 'auto',

      padding: 4,
      [theme.breakpoints.up('xl')]: {
        position: 'fixed',
        bottom: 0
      },

      [theme.breakpoints.down('lg')]: {
        width: '95%',
        paddingLeft: 0,
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    sectionOne: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column'
      }
    },
    mr: {
      marginRight: 50,
      [theme.breakpoints.down('lg')]: {
        margin: '20px 0px 0px'
      }
    },
    sectionTwo: {
      [theme.breakpoints.down('lg')]: {
        margin: '20px 0px 0px'
      }
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      listStyleType: 'none'
    },
    popular: {
      textAlign: 'center',
      fontSize: 14,
      paddingBottom: 10
    },
    div: {
      position: 'absolute',

      width: '100%',
      height: '95%',
      marginTop: 150
    },
    plansContainer: {
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
      }
    },
    mainRoot: {
      position: 'relative'
    },
    planPopularPrice: {
      padding: 12,
      textAlign: 'center',
      fontWeight: 500,
      fontSize: 24
    },
    buttonContainer: {
      textAlign: 'center',
      padding: 20
    },
    cursor: {
      cursor: 'pointer',
      marginRight: 50,
      fontSize: 16,
      [theme.breakpoints.down('lg')]: {
        margin: '20px 0px 0px'
      }
    }
  })
);

export default plansStyle;
