import { makeStyles, Theme, createStyles } from '@material-ui/core';

const profileStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '80%',
      margin: 'auto',
      paddingTop: 40,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingTop: 8
      },
      [theme.breakpoints.only('md')]: {
        width: '95%',
        paddingTop: 18
      }
    },
    businessIcon: {
      verticalAlign: 'middle'
    },
    emptyView: {
      width: '100%',
      textAlign: 'center',
    },
    emptyText: {
      textAlign: 'center',
      margin: '16px auto 0px'
    },
    panel: {
      padding: '10px 20px',
      marginTop: 12,
      cursor: 'pointer'
    },
    dashboardPanel: {
      padding: '10px 20px',

      marginBottom: 2
    },
    popupstateIcon: {
      marginRight: 10
    },
    popItem: {
      display: 'flex'
    },
    popup: {
      position: 'absolute',
      right: 5
    },
    reportPanel: {
      margin: 0
    },
    reportName: {},
    alert: {
      position: 'fixed',
      bottom: 74,
      left: 396,
      [theme.breakpoints.down('sm')]: {
        left: 0
      }
    },
    title: {
      padding: '26px 0px 0px',

      fontWeight: 400,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 16
      }
    },
    icon: {
      marginRight: 4
    },
    clearIcon: {
      margin: '6px 0px'
    },
    multiselectWeb: {
      display: 'flex'
    },
    tabValueP: {
      fontSize: 16,
      fontWeight: 500,
      paddingRight: 10,
      paddingTop: 5
    },
    tabValue: {
      fontSize: 16,
      fontWeight: 500,
      paddingRight: 10
    },
    clearIconDialog: {
      position: 'absolute',
      top: 10,
      right: 10,
      color: '#aaaaaa'
    },
    userName: {
      color: '#2f2f2f',
      fontSize: 14,
      fontWeight: 500
    },
    checkbox: {
      position: 'absolute',
      top: 2,
      right: 1
    },
    profileImage: {
      width: 102,
      height: 102,
      border: 'solid 0.7px #cccccc',
      borderRadius: '100%',
      marginRight: 24,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: 8
      }
    },
    positionDialog: {
      width: 600
    },
    editPositionDialog: {
      [theme.breakpoints.up('md')]: {
        width: 600,
        overflow: 'revert'
      }
    },
    loading: {
      overflow: 'revert'
    },
    educationForm: {
      width: 600,
      overflow: 'revert'
    },
    exportedTitle: {
      padding: '15px 20px',
      color: '#4aa5ff',
      [theme.breakpoints.down('xs')]: {
        padding: '9px 10px'
      },
      fontSize: 16,
      fontWeight: 500
    },
    exportedTitleWithSelect: {
      display: 'flex',
      padding: '10px 20px',
      color: '#4aa5ff',
      [theme.breakpoints.down('xs')]: {
        padding: '9px 10px'
      },
      fontSize: 16,
      fontWeight: 500
    },
    profileInfo: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 3,
      marginLeft: 15,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        textAlign: 'center',
        border: 'none',
        justifyContent: 'center'
      }
    },
    profilePhone: {
      alignItems: 'baseline'
    },
    mr16: {
      marginRight: 16,
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    mrAuto: {
      [theme.breakpoints.down('xs')]: {
        marginRight: 'auto'
      }
    },
    mb0: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    mt16: {
      marginTop: 16
    },
    editIcon: {
      display: 'flex',
      alignSelf: 'start',
      justifyContent: 'flex-end'
    },
    mb8: {
      marginBottom: 8
    },
    mb5: {
      marginBottom: 5
    },
    addressField: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    editIconPos: {
      alignSelf: 'center',
      marginRight: 7
    },
    mb16: {
      marginBottom: 16,
      position: 'relative'
    },
    cursor: {
      cursor: 'pointer'
    },
    multiSelect: {
      display: 'flex',
      borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
      borderTop: '1px solid rgba(0, 0, 0, 0.16)',
      marginBottom: 17
    },
    dateField: {
      display: 'flex',
      alignItems: 'center'
    },
    displayFlex: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 8,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
    },
    dateInfo: {
      margin: 0,
      padding: 0
    },
    link: {
      display: 'flex',
      textDecoration: 'none',

      justifyContent: 'space-between',
      alignItems: 'flex-end'
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    startDateField: {
      marginTop: 4,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    startDateFieldError: {
      marginTop: -12,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    indicator: {
      margin: 15
    },
    mobileView: {
      [theme.breakpoints.up('xs')]: {
        display: 'none'
      }
    },
    webView: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    userContactInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    },
    formHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 5,
      [theme.breakpoints.down('xs')]: {
        padding: '8px 12px',
        marginBottom: 8,
        fontSize: 16
      }
    },
    border: {
      border: '1px solid rgba(0, 0, 0, 0.1) '
    },
    tab: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    },
    none: {},
    reimbursementTitle: {
      padding: '10px 0px',
    },
    paperTitle: {
      fontWeight: 'bold',
      fontSize: 15,
      color: 'primary',
      padding: 0,
      margin: 0,
    },
    paperInfo: {
      padding: 0,
      margin: 0,
      color: 'white',
    },
    paperRow: {
      display: 'flex',
      padding: '3px 10px',
      alignItems: 'center',
    },
    reimbursementPaper: {
      padding: '10px',
    },
    formTitle: {
      textAlign: 'center',
      marginTop: 10,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        alignItems: 'baseline'
      }
    },
    addPositionHeader: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      padding: '9px 10px 3px 20px',
      [theme.breakpoints.up('xs')]: {
        justifyContent: 'space-between',
        alignItems: 'baseline'
      }
    },
    addButton: {
      marginRight: 10,
      marginBottom: 10
    },
    exportedIcon: {
      [theme.breakpoints.up('xs')]: {
        height: 93
      },
      width: '50%',
      height: '50%',
      marginLeft: '20%',
      color: 'rgba(170, 170, 170, 0.3)'
    },
    planIcon: {
      position: 'absolute',
      left: 10
    },
    paper: { width: 600 },
    fileExported: {
      borderRadius: 6,
      marginBottom: 4,

      paddingLeft: 10,
      position: 'relative'
    },
    fileExportedPR: {
      borderRadius: 6,
      marginBottom: 4,

      position: 'relative'
    },
    fileExportedPL: {
      borderRadius: 6,
      marginBottom: 4,
      paddingLeft: 10,
      position: 'relative'
    },
    fileExportedPaper: {
      border: '1px solid rgba(170, 170, 170, 0.4)',
      padding: 3
    },

    tabStyle: {
      fontSize: 18,
      fontWeight: 'normal',
      textTransform: 'none'
    },
    infoBox: {
      padding: '30px 16px 30px 40px',
      [theme.breakpoints.down('sm')]: {
        padding: '12px 14px'
      }
    },
    searchBy: {
      width: '25%',
      marginBottom: 15,
      marginTop: 15,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 0
      },
      [theme.breakpoints.down('sm')]: {
        width: '95%',
        marginLeft: 10
      }
    },
    userCard: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    dahsboardName: {
      marginBottom: 5,
      marginLeft: 10
    },
    reject: {
      color: '#ff3b30',
      fontSize: 12,
      margin: 5,
      display: 'inline',
      cursor: 'pointer'
    },
    accept: {
      fontSize: 12,
      margin: 5,
      display: 'inline',
      cursor: 'pointer'
    },
    userCardInfo: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    userCardAction: {
      display: 'flex'
    },
    f16: {
      fontSize: 16
    },
    mb10: {
      marginBottom: 10
    }
  })
);

export default profileStyle;
