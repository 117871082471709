import React from 'react';
import DropIn from 'braintree-web-drop-in-react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Snackbar, Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = (theme) => ({
  subscribeButton: {
    width: '100%',


  },
  buttonContainer: {
    display: 'flex'
  },
});

class Payment extends React.Component {

  isBuying = false;
  instance;
  state = {
    showAlert: false,
    alertMessage: '',
    messageType: '',
  };

  constructor(props) {
    super(props);
  }

  async buy() {
    try {
      this.isBuying = true;
      const { nonce } = await this.instance?.requestPaymentMethod();
      this.props.setPaymentMethod(nonce);
      this.isBuying = false;
    } catch (err) {
      this.isBuying = false;
      if (err.message === 'No payment method is available.') {
        this.setState({ alertMessage: err.message });
        this.setState({ showAlert: true })
        this.setState({ messageType: "error" })
        setTimeout(() => {
          this.setState({ showAlert: false })
        }, 2000);
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>

        <Snackbar open={this.state.showAlert} color={this.state.messageType}>
          <Alert onClose={() => this.setState({ showAlert: false })} severity={this.state.messageType}>
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
        <Typography variant="h3">Choose your method of payment</Typography>
        <DropIn
          options={{
            authorization: this.props.clientToken, paypal: {
              flow: 'vault', buttonStyle: {
                color: 'blue',
                shape: 'rect',
                size: 'medium'
              }
            }, card: {
              vault: { vaultCard: true, allowVaultCardOverride: true },
              overrides: {
                styles: {
                  backgroundColor: 'grey',
                  input: {
                    'font-size': '18px'
                  },
                  ':focus': {
                    color: 'red'
                  }
                }
              }
            }, translations: {
              payingWith: '',
              chooseAnotherWayToPay: 'Choose your method of payment',

            }, dataCollector: true
          }}
          onInstance={(instance) => {
            this.instance = instance
            instance.clearSelectedPaymentMethod()
          }}
        />

        <Box mt={2} justifyContent="center" className={classes.buttonContainer}>
          {!this.isBuying ? (<Button
            onClick={this.buy.bind(this)}
            variant="contained" color="primary"
            className={classes.subscribeButton}
          >
            Subscribe
          </Button>) :
            (<Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>)}
        </Box>
      </div>
    );
  }
}

export default withStyles(useStyles)(Payment)