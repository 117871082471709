import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Control, Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { FormHelperText, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import useStyles from '../../styles/globalStyles';

const MultipleSelect = ({
    name,
    label,
    control,
    defaultValue,
    children,
    helperText,
    setIsBlocking,
    renderValue = '',
    flag = '',
    setFormValue,
    selectedValues,
    ...props
}) => {
    const labelId = `${name}-label`;

    const handleChange = (event) => {
        setFormValue(name, event.target.value);
    };

    return (
        <FormControl fullWidth {...props}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                multiple
                value={selectedValues}
                onChange={handleChange}
                fullWidth
                labelId={labelId}
                label={label}
                renderValue={(selected: any) => selected.join(', ')}

            >
                {children ? children.map((child) => (
                    <MenuItem key={child.props.value} value={child.props.value}>
                        <Checkbox checked={selectedValues.indexOf(child.props.value) > -1} />
                        <ListItemText primary={child.props.children} />
                    </MenuItem>
                )) : null}
            </Select>
        </FormControl>
    );
};
export default MultipleSelect;
