import React from 'react';
import { Redirect, Route, RouteProps, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getCookie } from '../../utils/sessionUtils';
import { getUser } from '../../utils';
import { codeType, keyType } from '../../types';

interface ProtectedRoutePropsType extends RouteProps {
  component: React.FC;
}

const ProtectedRoute: React.FC<ProtectedRoutePropsType> = (props) => {
  const { component: Component, ...rest } = props;
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        const cookieUser = localStorage.getItem('user');
        const user = getUser();
        if (cookieUser) {
          if (user.verified) {
            return <Component {...rest} {...props} />;
          } else {
            history.push('/');
          }
        } else {
          return <Redirect to="/signin" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
