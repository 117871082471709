import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { format } from '../../utils/date';
import { getAge } from '../../utils';
import caseStyle from './Styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useLongPress } from 'use-long-press';
import emptyCaseDark from '../../assets/dark/emptyCaseDark.png';
import emptyCase from '../../assets/emptyCase.png';
import { getUser } from '../../utils'

const user = getUser();

const Case = (props) => {
  const {
    cases,
    openCaseDetails,
    performSearch,
    addCase,
    title,
    setShowMultiSelectTab,
    filterCases,
    setfilterCases
  } = props;
  const isDarkTheme = useTheme().palette.type === 'dark';
  const [isMouseHover, setIsMouseHover] = React.useState<boolean[]>([false]);
  const [selectedCases, setSelectedCases] = React.useState<any[]>(cases);
  const [selectedCase, setSelectedCase] = React.useState<any>();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const classes = caseStyle();

  React.useEffect(() => {
    if (filterCases.length === 0) {
      setIsMouseHover([false]);
    } else if (filterCases.length > 0) {
      let newIsMouseHover = new Array(cases.length).fill(true);
      setIsMouseHover(newIsMouseHover);
    }
  }, [filterCases]);

  React.useEffect(() => { }, [isMouseHover]);
  React.useEffect(() => {
    const result = cases.map((el) =>
      el.hospital != null
        ? {
          ...el,
          institute: {
            id: el.hospital.id,
            label: el.hospital.label
          }
        }
        : el
    );
    setSelectedCases(result);
  }, [cases]);

  React.useEffect(() => {
    filterCheckedCases(selectedCases);
  }, [selectedCases]);

  const filterCheckedCases = (cases) => {
    if (cases) {
      let filterCases: any[] = [];
      for (let i = 0; i < cases.length; i++) {
        if (cases[i].isChecked === true) {
          filterCases.push(cases[i]);
        }
      }
      setfilterCases(filterCases);
      filterCases.length > 0
        ? setShowMultiSelectTab(true)
        : setShowMultiSelectTab(false);
    }
  };
  const bind = useLongPress(() => {
    let newSelectedCases = selectedCases.map((element) =>
      element.id === selectedCase.id
        ? { ...element, isChecked: !selectedCase.isChecked }
        : element
    );

    setSelectedCases(newSelectedCases);
  });

  const isNeurosurgery = () => {
    return user.user_profile[0].speciality?.name === 'Neurological Surgery';
  }

  const isInterventional = () => {
    return user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology';
  }

  const sort = (data: any) => {
    return data.sort((a: any, b: any) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
  }

  const getMaxDate = (currentCase) => {
    const dates = currentCase.case_procedures;
    dates.push({ date: currentCase.date });
    const sortedDates = sort(dates);
    const [year, month, day] = sortedDates[0].date.split('-');
    return `${months[Number(month - 1)]} ${day}`;
  }

  return (
    <>
      <Grid container item xs={12}>
        {selectedCases && selectedCases.length > 0 ? (
          selectedCases.map((caseItem: any, index) => (
            <Grid key={caseItem.id} item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Box className={classes.casesBox}>
                <Paper
                  {...bind}
                  onMouseOver={() => {
                    const newIsMouseHover = isMouseHover.slice();
                    newIsMouseHover[index] = true;
                    setIsMouseHover(newIsMouseHover);
                    setSelectedCase(caseItem);
                  }}
                  onMouseLeave={() => {
                    if (filterCases.length < 1) {
                      const newIsMouseHover = isMouseHover.slice();
                      newIsMouseHover[index] = false;
                      setIsMouseHover(newIsMouseHover);
                      setSelectedCase(null);
                    }
                  }}
                  onPointerDown={() => {
                    setSelectedCase(caseItem);
                  }}
                  onPointerUpCapture={() => {
                    setSelectedCase(null);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (filterCases.length === 0) {
                      openCaseDetails(caseItem.id, index);
                    }
                  }}
                  className={
                    isWidthUp('xl', props.width)
                      ? index % 3 === 0
                        ? classes.paperLeft
                        : index % 3 === 2
                          ? classes.paperRight
                          : classes.paper
                      : index % 2 === 0
                        ? classes.paperLeft
                        : index % 2 === 1
                          ? classes.paperRight
                          : classes.paper
                  }
                >
                  <div className={[classes.avatar, classes.mb4].join(' ')}>
                    {format(getMaxDate(caseItem), 'MMM DD')}
                  </div>
                  <div className={classes.caseCard}>
                    <Typography
                      className={classes.noWrapWidth}
                      variant="h2"
                      noWrap
                    >
                      {caseItem &&
                        caseItem.patient_last_name +
                        ', ' +
                        caseItem.patient_first_name}
                    </Typography>
                    <Typography
                      className={[
                        classes.mb4,
                        classes.noWrapWidth,
                        classes.alignLeft
                      ].join(' ')}
                      variant="caption"
                      noWrap
                    >
                      {caseItem.date_of_birth ? getAge(caseItem.date_of_birth) : caseItem.age && caseItem.age == 1 ? caseItem.age + ' Year old' : caseItem.age && caseItem.age > 1 ? caseItem.age + ' Years old' : null}
                      {' ' + caseItem.gender.charAt(0).toUpperCase() + caseItem.gender.substring(1).toLowerCase()}
                    </Typography>
                    <Typography
                      className={[
                        classes.mb4,
                        classes.hospitalName,
                        classes.noWrapWidth
                      ].join(' ')}
                      variant="h3"
                      noWrap
                    >
                      {caseItem.institute ? caseItem.institute.label : 'N/A'}
                    </Typography>
                    <Typography
                      variant="caption"
                      noWrap
                      className={[classes.noWrapWidth, classes.alignLeft].join(
                        ' '
                      )}
                    >
                      {caseItem.procedure_name && caseItem.procedure_name != 'undefined' ? caseItem.procedure_name : caseItem.type}
                    </Typography>
                  </div>
                  <div>
                    {(isMouseHover[index] || caseItem.isChecked) &&
                      title !== 'sharedDashboard' ? (
                      <Checkbox
                        className={classes.checkbox}
                        icon={<CircleUnchecked />}
                        checkedIcon={<CheckCircleIcon />}
                        checked={caseItem.isChecked}
                        value={caseItem}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => {
                          let newSelectedCases = selectedCases.map((element) =>
                            element.id === caseItem.id
                              ? { ...element, isChecked: !caseItem.isChecked }
                              : element
                          );
                          setSelectedCases(newSelectedCases);
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    ) : null}
                  </div>
                </Paper>
              </Box>
            </Grid>
          ))
        ) : (
          <Box mx="auto" my={2}>
            {performSearch ? (
              <Typography variant="h3">
                No results for this search criteria!
              </Typography>
            ) : (
              <Box className={classes.imageBox}>
                <Box>
                  <img
                    src={!isDarkTheme ? emptyCase : emptyCaseDark}
                    alt="no data"
                  ></img>
                </Box>
                <Box className={classes.emptyCases}>
                  {' '}
                  <Typography variant="h5">No cases yet,</Typography>
                </Box>
                <Box className={classes.emptyCasesHint}>
                  <Typography variant="h5">Click “+” to add case!</Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

export default withWidth()(Case);
