import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { codeType, doctorParams, keyType } from '../../../types';
import * as api from '../../../services/apiService';
import history from '../../../utils/history';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import VerifyInvitationStyle from './Styles';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import * as yup from 'yup';
import ReactHookFormSelect from '../../../components/select/ReactHookFormSelect';
import useStyles from '../../../styles/globalStyles';
import { strongPassword } from '../../../utils/validationConfig';
import { Alert } from '@material-ui/lab';
import { getUser, setUser } from '../../../utils';
import { setCookie } from '../../../utils/sessionUtils';

const VerifyInvitation = (props) => {
  const { code } = useParams<codeType>();
  const { key } = useParams<keyType>();
  const user = getUser();
  const { doctorId } = useParams<doctorParams>();
  const classes = VerifyInvitationStyle();
  const globalStyle = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [role, setRole] = React.useState<any>([]);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMsg, setAlertMsg] = React.useState<string>('');
  const [userId, setUserId] = React.useState<number>(0);

  const validationSchema = yup.object().shape({
    first_name: yup.string().required('This field is required'),
    last_name: yup.string().required('This field is required'),
    email: yup.string().required('This field is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!.%*+=(){}?&,:;'-])[A-Za-z\d@$#!.%+=(){}*?&,:;'-]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Number and one special case Character'
      )
  });

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    watch
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit'
  });
  const watchFields = watch('password');
  const getUserRoles = async () => {
    if (role.length === 0) {
      const { data } = await api.getUserRoles();
      setRole(data);
    }
  };

  const verify = async () => {
    const {
      status_code: statusCode,
      data: verifyData
    } = await api.verifyInvitation(doctorId, key, code);
    if (statusCode === api.status.SUCCESS) {
      const User = {
        ...verifyData?.user,
        verified: true
      };
      localStorage.setItem('user', JSON.stringify(User));
      setUser(User);
      if (verifyData.old_user) {
        const userId = getUser()?.id;
        props.setEditUserName(true);
        if (userId === verifyData?.user_id) {
          history.push('/profile');

          props.setOpenDashboardTab(true);
        } else {
          history.push('/users');
        }
      } else {
        setIsOpen(true);
        setValue('email', verifyData?.email);
        setUserId(verifyData?.user_id);
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          className={globalStyle.showPassword}
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    )
  };
  const updateUserInfo = async (values) => {
    const payload = {
      ...values,
      accepted_privacy_policy: true,
      accepted_terms: true,
      registered_from: 'WEB',
      verified: true
    };
    const { status_code: statusCode, data } = await api.updateUserInfo(
      userId,
      payload
    );
    if (statusCode === api.status.SUCCESS) {
      const User = {
        ...data?.result,
        verified: true
      };
      localStorage.setItem('user', JSON.stringify(User));
      setUser(User);
      setIsOpen(false);
      history.push('/profile');
      props.setOpenDashboardTab(true);
      props.setEditUserName(true);
    } else {
      setAlertMsg(data.message);
      setShowAlert(true);
    }
  };
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      verify();
      getUserRoles();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Dialog open={isOpen} className={classes.verifyDialog}>
      <form onSubmit={handleSubmit(updateUserInfo)}>
        <Grid className={classes.container} container spacing={3}>
          <Grid className={classes.py0} item={true} xs={12} sm={12} md={12}>
            <Typography variant="subtitle1" className={classes.dialogTitle}>
              Create an account
            </Typography>
          </Grid>
          <Grid className={classes.py0} item={true} xs={12} sm={12} md={12}>
            <Controller
              as={TextField}
              variant="outlined"
              name="first_name"
              defaultValue=""
              required
              control={control}
              label="First name"
              className={classes.mb0}
              margin="normal"
              fullWidth
              helperText={errors.first_name && errors.first_name.message}
              error={errors.first_name ? true : false}
            />
          </Grid>
          <Grid className={classes.py0} item={true} xs={12} sm={12} md={12}>
            <Controller
              as={TextField}
              variant="outlined"
              name="last_name"
              defaultValue=""
              required
              control={control}
              label="Last name"
              className={classes.mb0}
              margin="normal"
              fullWidth
              helperText={errors.last_name && errors.last_name.message}
              error={errors.last_name ? true : false}
            />
          </Grid>
          <Grid className={classes.py0} item={true} xs={12} sm={12} md={12}>
            <ReactHookFormSelect
              name="user_role_id"
              label="Role "
              control={control}
              defaultValue=""
              variant="outlined"
              setIsBlocking={() => {}}
              className={classes.mb0}
              margin="normal"
              error={errors.role ? true : false}
              helperText={errors.role && errors.role.message}
            >
              <MenuItem value=""></MenuItem>
              {role.length > 0
                ? role.map((role: any) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.nice_name}
                    </MenuItem>
                  ))
                : null}
            </ReactHookFormSelect>
          </Grid>
          <Grid className={classes.py0} item={true} xs={12} sm={12} md={12}>
            <Controller
              as={TextField}
              variant="outlined"
              name="email"
              defaultValue=""
              required
              InputProps={{
                readOnly: true
              }}
              control={control}
              label="Email"
              className={classes.mb0}
              margin="normal"
              fullWidth
              helperText={errors.email && errors.email.message}
              error={errors.email ? true : false}
            />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12} lg={12}>
            <TextField
              required
              type={showPassword ? 'text' : 'password'}
              InputProps={inputProps}
              name="password"
              inputRef={register}
              placeholder="Password Must Contain 8 Characters, One Uppercase, One Number and one special case Character"
              label="Password "
              className={globalStyle.textField}
              margin="normal"
              fullWidth
              variant="outlined"
              helperText={errors.password && errors.password.message}
              error={errors.password ? true : false}
              onKeyDown={handleEnter}
            />
            {watchFields && !errors.password ? (
              getValues('password').length < 6 ? (
                <Box>
                  <Typography
                    variant="h4"
                    color="error"
                    className={globalStyle.weakPassword}
                  >
                    Weak password
                  </Typography>
                </Box>
              ) : strongPassword.test(getValues('password')) ? (
                <Typography variant="h4" className={globalStyle.strongPassword}>
                  Strong password
                </Typography>
              ) : (
                <Box className={globalStyle.normalPassword}>
                  Normal password
                </Box>
              )
            ) : null}
          </Grid>
          <Grid item={true} xs={12} className={classes.signUpButtonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.signUpButton}
            >
              Sign up
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={showAlert} color="error">
        <Alert onClose={() => setShowAlert(false)} severity="error">
          {alertMsg}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default VerifyInvitation;
