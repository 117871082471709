import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Directions } from '@material-ui/icons';

const contactUsStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '78%',

      margin: 'auto',
      marginTop: 30,
      [theme.breakpoints.down('lg')]: {
        width: '90%'
      }
    },
    fS24: {
      fontSize: 24,
      fontWeight: 500,
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        padding: '24px 12px 0px'
      }
    },
    mt8: {
      marginTop: 8,
      [theme.breakpoints.up('xl')]: {
        marginTop: 0,
        marginBottom: -11
      }
    },
    mtMessage: {
      marginTop: 8,
      [theme.breakpoints.up('xl')]: {
        marginTop: -22,
        marginBottom: -11
      }
    },
    mb4: {
      marginBottom: 4,
      [theme.breakpoints.up('xl')]: {
        marginBottom: -30
      }
    },
    mt22: {
      marginTop: -22
    },
    fullWidth: {
      width: '100%'
    },
    countryName: {
      marginLeft: 8
    },
    phoneBox: {
      alignSelf: 'baseline'
    },
    selectImage: {
      width: 10,
      height: 10
    },
    EmailImg: {
      padding: '121px 0px',
      [theme.breakpoints.down('lg')]: {
        padding: '16px 16px 0px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    Container: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column'
      }
    },
    submitButton: {
      fontSize: 18,
      [theme.breakpoints.down('lg')]: {
        margin: '24px  0px'
      }
    },
    formSection: {
      paddingTop: 40,
      paddingLeft: 60,
      [theme.breakpoints.down('lg')]: {
        padding: '10px 16px 4px'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '10px 4px 4px'
      }
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '78%',
      margin: 'auto',

      padding: 4,
      [theme.breakpoints.up('xl')]: {
        position: 'fixed',
        bottom: 10
      },

      [theme.breakpoints.down('lg')]: {
        width: '95%',
        paddingLeft: 0,
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    sectionOne: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column'
      }
    },
    mr: {
      fontSize: 16,
      marginRight: 50,
      [theme.breakpoints.down('lg')]: {
        margin: '20px 0px 0px'
      }
    },
    sectionTwo: {
      [theme.breakpoints.down('lg')]: {
        margin: '20px 0px 0px'
      }
    },
    div: {
      position: 'absolute',

      width: '100%',
      height: '85%',

      '&:before': {
        position: 'absolute',
        zIndex: -1,
        top: '70%',
        left: 0,
        width: '100%',

        content: '""'
      },
      background: 'linear-gradient(transparent 40%,rgba(74, 165, 255, 0.1) 00)'
    },
    cursor: {
      cursor: 'pointer',
      marginRight: 50,
      fontSize: 16,
      [theme.breakpoints.down('lg')]: {
        margin: '20px 0px 0px'
      }
    }
  })
);

export default contactUsStyle;
