import { makeStyles, Theme, createStyles } from '@material-ui/core';

const positionFormStyle = makeStyles((theme: Theme) =>
  createStyles({
    mb0: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    dateField: {
      display: 'flex',
      alignItems: 'center'
    },
    positionBox: {
      padding: 24,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0
      }
    },
    saveButton: {
      width: '48%',
      alignContent: 'center'
    },
    addButton: {
      width: '100%',
      alignContent: 'center',
      marginTop: 30
    },
    endDateBOX: {
      margin: '10px 0px'
    },
    checkboxIcon: {
      color: '#ffad2c'
    },
    highlightpart: {
      fontWeight: 700
    },
    root: {
      '&$checked': {
        color: '#ffad2c'
      }
    },
    checked: {},
    part: {
      fontWeight: 400
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 26
    },
    mt16: {
      marginTop: 10
    },
    addressField: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    deleteButton: {
      color: '#ff3b30',
      width: '48%',
      alignContent: 'center',
      border: '1px solid #ff3b30'
    },
    checkbox: {
      border: '1px solid rgba(170, 170, 170, 0.6)',
      marginTop: 8,
      marginBottom: 10,
      padding: '8px 8px',
      color: '#ffad2c'
    }
  })
);

export default positionFormStyle;
