import React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  Box,
  Grid,
  Typography,
  TextField,
  Dialog,
  CircularProgress
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import useStyles from '../../../styles/globalStyles';
import history from '../../../utils/history';
import * as api from '../../../services/apiService';
import Alert from '@material-ui/lab/Alert';

const ForgetPassword: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);

  let forgetSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email('Must use valid email address')
      .required('Email is required')
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(forgetSchema)
  });

  const onSubmit = async (values: any) => {
    setOpen(true);
    const { status_code: statusCode } = await api.forgetPassword(values);
    if (statusCode === api.status.SUCCESS) {
      setShowMessage(true);
      history.push('/reset-password/' + values['email']);
    } else {
    }
    handleDialogClose();
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleDialogClose}>
        <CircularProgress className={classes.indicator} disableShrink />
      </Dialog>
      <Box>
        {showMessage ? (
          <Box mb={2}>
            <Alert severity="success">Email has been sent.</Alert>
          </Box>
        ) : null}
        <Grid
          className={classes.boxSignIn}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={5} className={classes.bigBox}>
            <Paper variant="outlined" className={classes.paperSignIn}>
              <Box mb={3}>
                <Typography variant="subtitle1">Forgot password</Typography>
              </Box>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.container}
              >
                <Typography
                  variant="body1"
                  align="left"
                  className={classes.forgetPasswordHeader}
                >
                  We need your registered e-mail address to send you a reset
                  password code.
                </Typography>
                <TextField
                  name="email"
                  inputRef={register}
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  helperText={errors.email && errors.email.message}
                  fullWidth
                  error={errors.email ? true : false}
                />
                <Button
                  className={classes.highlightButton}
                  type="submit"
                  variant="contained"
                  aria-label="delete"
                >
                  Submit
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ForgetPassword;
