import React, { useEffect, useState } from 'react';
import {
    Typography,
    Paper,
    Box,
    Button,
    TextField,
    Grid,
    MenuItem,
    Dialog,
    Divider,
    Snackbar,
    DialogTitle,
    DialogActions,
    DialogContent
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import history from '../../utils/history';
import { getUser } from '../../utils';
import * as api from '../../services/apiService';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import 'date-fns';
import ReactHookFormSelect from '../../components/select/ReactHookFormSelect';
import {
    caseProcedureType,
    doctorParams,
    genderListType,
    hospitalDataType,
    rolesDataType
} from '../../types';
import { caseParams } from '../../types/index';
import addCaseStyle from './Styles';
import { title } from 'process';
import DeleteDialog from '../Dialogs/DeleteDialog';
import MultipleSelect from '../select/MultipleSelect';

const CaseProcedure = (props) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const classes = addCaseStyle();
    const { caseId } = useParams<caseParams>();
    const { doctorId } = useParams<doctorParams>();
    const user = getUser();
    const location = useLocation().pathname;
    const [images, setImages] = React.useState<any[]>([]);
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
    const [surgeryDate, setSurgeryDate] = React.useState<Date>(props.procedure.date);
    const [procedureDate, setProcedureDate] = React.useState<Date>(props.procedure.date);
    const [ticiGradeList, setTICIGradeList] = React.useState<any[]>([]);
    const [neurosurgeryProcedureTypeList, setNeurosurgeryProcedureTypeList] = React.useState<any[]>([]);
    const [bedsideProcedureTypeList, setBedsideProcedureTypeList] = React.useState<any[]>([]);
    const [interventionalProcedureTypeList, setInterventionalProcedureTypeList] = React.useState<any[]>([]);
    const [radiologyProcedureTypeList, setRadiologyProcedureTypeList] = React.useState<any[]>([]);
    const [cranialApproachList, setCranialApproachList] = React.useState<any[]>([]);
    const [spineApproachList, setSpineApproachList] = React.useState<any[]>([]);
    const [cranialIndicationList, setCranialIndicationList] = React.useState<any[]>([]);
    const [spineIndicationList, setSpineIndicationList] = React.useState<any[]>([]);
    const [functionalIndicationList, setFunctionalIndicationList] = React.useState<any[]>([]);
    const [nervesIndicationList, setNervesIndicationList] = React.useState<any[]>([]);
    const [interIndicationList, setInterIndicationList] = React.useState<any[]>([]);
    const [interventionalIndicationList, setInterventionalIndicationList] = React.useState<any[]>([]);
    const [radiologyIndicationList, setRadiologyIndicationList] = React.useState<any[]>([]);
    const [locationList, setLocationList] = React.useState<any[]>([]);
    const [sidesList, setSidesList] = React.useState<any[]>([]);

    const [tumorLocationList, setTumorLocationList] = React.useState<any[]>([]);
    const [aneurysmLocationList, setAneurysmLocationList] = React.useState<any[]>([]);
    const [aneurysmLocationInterList, setAneurysmLocationInterList] = React.useState<any[]>([]);
    const [hematomaLocationList, setHematomaLocationList] = React.useState<any[]>([]);
    const [fractureLocationList, setFractureLocationList] = React.useState<any[]>([]);
    const [hydrocephalusLocationList, setHydrocephalusLocationList] = React.useState<any[]>([]);
    const [avmLocationList, setAVMLocationList] = React.useState<any[]>([]);
    const [bleedingLocationList, setBleedingLocationList] = React.useState<any[]>([]);
    const [dissectionLocationList, setDissectionLocationList] = React.useState<any[]>([]);

    const [osteotomyList, setosteotomyList] = React.useState<any[]>([]);
    const [yesNoList, setYesNoList] = React.useState<any[]>([]);
    const [procedureType, setProcedureType] = React.useState<String>(props.procedure.type);
    const [indicationType, setIndicationType] = React.useState<String>(props.isMultipleSelection ? props.procedure['indication'] : '');
    const [locationType, setLocationType] = React.useState<String>(props.procedure['location']);
    const [approachType, setApproachType] = React.useState<String>(props.procedure['approach']);
    const [_procedureType, set_procedureType] = React.useState<String>(props.procedure['_procedure']);
    const [neuroProcedure, setNeuroProcedure] = React.useState<string>('');
    const [isBlocking, setIsBlocking] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const {
        handleSubmit,
        errors,
        getValues,
        control,
        setValue,
        register,
        watch
    } = useForm({
        defaultValues: props.procedure,
        mode: 'all'
    });

    const handleValueChange = (e) => {
        props.procedure[e.target.name] = e.target.value;
        props.handleFormChange();
    }

    const getTICIGradeList = async () => {
        const { data, status_code: statusCode } = await api.getTICIGradeList();
        if (statusCode === api.status.SUCCESS) {
            const ticiList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setTICIGradeList(ticiList);
        }
    }

    useEffect(() => {
        if (ticiGradeList.length === 0 && !props.showEditCaseMessage) {
            getTICIGradeList();
        }
    }, [ticiGradeList.length]);

    const getNeurosurgeryProcedureTypeList = async () => {
        const { data, status_code: statusCode } = await api.getNeurosurgeryProcedureTypeList();
        if (statusCode === api.status.SUCCESS) {
            const procedureList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setNeurosurgeryProcedureTypeList(procedureList);
        }
    }

    const getBedsideProcedureTypeList = async () => {
        const { data, status_code: statusCode } = await api.getBedsideProcedureTypeList();
        if (statusCode === api.status.SUCCESS) {
            const procedureList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setBedsideProcedureTypeList(procedureList);
        }
    }

    const getInterventionalProcedureTypeList = async () => {
        const { data, status_code: statusCode } = await api.getInterventionalProcedureTypeList();
        if (statusCode === api.status.SUCCESS) {
            const procedureList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setInterventionalProcedureTypeList(procedureList);
        }
    }

    const getRadiologyProcedureTypeList = async () => {
        const { data, status_code: statusCode } = await api.getRadiologyProcedureTypeList();
        if (statusCode === api.status.SUCCESS) {
            const procedureList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setRadiologyProcedureTypeList(procedureList);
        }
    }

    useEffect(() => {
        if (neurosurgeryProcedureTypeList.length === 0) {
            getNeurosurgeryProcedureTypeList();
        }
        if (interventionalProcedureTypeList.length === 0) {
            getInterventionalProcedureTypeList();
        }
        if (bedsideProcedureTypeList.length === 0) {
            getBedsideProcedureTypeList();
        }
        if (radiologyProcedureTypeList.length === 0) {
            getRadiologyProcedureTypeList();
        }
    }, [neurosurgeryProcedureTypeList.length, interventionalProcedureTypeList.length, bedsideProcedureTypeList.length, radiologyProcedureTypeList.length]);

    const getCranialApproachList = async () => {
        const { data, status_code: statusCode } = await api.getCranialApproachList();
        if (statusCode === api.status.SUCCESS) {
            const approachList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setCranialApproachList(approachList);
        }
    }

    const getSpineApproachList = async () => {
        const { data, status_code: statusCode } = await api.getSpineApproachList();
        if (statusCode === api.status.SUCCESS) {
            const approachList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setSpineApproachList(approachList);
        }
    }

    useEffect(() => {
        if (cranialApproachList.length === 0) {
            getCranialApproachList();
        }
        if (spineApproachList.length === 0) {
            getSpineApproachList();
        }
    }, [cranialApproachList.length, spineApproachList.length]);

    const getCranialIndicationList = async () => {
        const { data, status_code: statusCode } = await api.getCranialIndicationList();
        if (statusCode === api.status.SUCCESS) {
            const indicationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setCranialIndicationList(indicationList);
        }
    }

    const getSpineIndicationList = async () => {
        const { data, status_code: statusCode } = await api.getSpineIndicationList();
        if (statusCode === api.status.SUCCESS) {
            const indicationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setSpineIndicationList(indicationList);
        }
    }

    const getFunctionalIndicationList = async () => {
        const { data, status_code: statusCode } = await api.getFunctionalIndicationList();
        if (statusCode === api.status.SUCCESS) {
            const indicationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setFunctionalIndicationList(indicationList);
        }
    }

    const getNervesIndicationList = async () => {
        const { data, status_code: statusCode } = await api.getNervesIndicationList();
        if (statusCode === api.status.SUCCESS) {
            const indicationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setNervesIndicationList(indicationList);
        }
    }

    const getInterventionalIndicationList = async () => {
        const { data, status_code: statusCode } = await api.getInterventionalIndicationList();
        if (statusCode === api.status.SUCCESS) {
            const indicationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setInterventionalIndicationList(indicationList);
        }
    }

    const getInterIndicationList = async () => {
        const { data, status_code: statusCode } = await api.getInterIndicationList();
        if (statusCode === api.status.SUCCESS) {
            const indicationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setInterIndicationList(indicationList);
        }
    }

    const getRadiologyIndicationList = async () => {
        const { data, status_code: statusCode } = await api.getRadiologyIndicationList();
        if (statusCode === api.status.SUCCESS) {
            const indicationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setRadiologyIndicationList(indicationList);
        }
    }

    const getSidesList = async () => {
        const { data, status_code: statusCode } = await api.getSidesList();
        if (statusCode === api.status.SUCCESS) {
            const sides = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setSidesList(sides);
        }
    }

    useEffect(() => {
        if (sidesList.length === 0 && !props.showEditCaseMessage) {
            getSidesList();
        }
    }, [sidesList.length]);

    const getSpineLocationList = async () => {
        const { data, status_code: statusCode } = await api.getSpineLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setLocationList(locationList);
        }
    }

    const getTumorLocationList = async () => {
        const { data, status_code: statusCode } = await api.getTumorLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setTumorLocationList(locationList);
        }
    }

    const getAneurysmLocationList = async () => {
        const { data, status_code: statusCode } = await api.getAneurysmLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setAneurysmLocationList(locationList);
        }
    }

    const getAneurysmLocationInterList = async () => {
        const { data, status_code: statusCode } = await api.getAneurysmLocationInterList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setAneurysmLocationInterList(locationList);
        }
    }

    const getHematomaLocationList = async () => {
        const { data, status_code: statusCode } = await api.getHematomaLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setHematomaLocationList(locationList);
        }
    }

    const getFractureLocationList = async () => {
        const { data, status_code: statusCode } = await api.getFractureLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setFractureLocationList(locationList);
        }
    }

    const getHydrocephalusLocationList = async () => {
        const { data, status_code: statusCode } = await api.getHydrocephalusLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setHydrocephalusLocationList(locationList);
        }
    }

    const getAVMLocationList = async () => {
        const { data, status_code: statusCode } = await api.getAVMLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setAVMLocationList(locationList);
        }
    }

    const getBleedingLocationList = async () => {
        const { data, status_code: statusCode } = await api.getBleedingLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setBleedingLocationList(locationList);
        }
    }

    const getDissectionLocationList = async () => {
        const { data, status_code: statusCode } = await api.getDissectionLocationList();
        if (statusCode === api.status.SUCCESS) {
            const locationList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setDissectionLocationList(locationList);
        }
    }

    const getOsteotomyList = async () => {
        const { data, status_code: statusCode } = await api.getOsteotomyList();
        if (statusCode === api.status.SUCCESS) {
            const osteotomyList = Object.keys(data).map((key) => ({
                value: key,
                label: data[key]
            }));
            setosteotomyList(osteotomyList);
        }
    }

    useEffect(() => {
        if (cranialIndicationList.length === 0) {
            getCranialIndicationList();
        }
        if (spineIndicationList.length === 0) {
            getSpineIndicationList();
        }
        if (functionalIndicationList.length === 0) {
            getFunctionalIndicationList();
        }
        if (nervesIndicationList.length === 0) {
            getNervesIndicationList();
        }
        if (interventionalIndicationList.length === 0) {
            getInterventionalIndicationList();
        }
        if (interIndicationList.length === 0) {
            getInterIndicationList();
        }
        if (radiologyIndicationList.length === 0) {
            getRadiologyIndicationList();
        }

    }, [cranialIndicationList.length, spineIndicationList.length, functionalIndicationList.length, nervesIndicationList.length, interventionalIndicationList.length, interIndicationList.length, radiologyIndicationList.length]);

    useEffect(() => {
        if (yesNoList.length === 0 && !props.showEditCaseMessage) {
            setYesNoList([
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' }
            ]);
        }
    }, [yesNoList.length])

    useEffect(() => {
        if (locationList.length === 0) {
            getSpineLocationList();
        }
        if (tumorLocationList.length === 0) {
            getTumorLocationList();
        }
        if (aneurysmLocationList.length === 0) {
            getAneurysmLocationList();
        }
        if (hematomaLocationList.length === 0) {
            getHematomaLocationList();
        }
        if (fractureLocationList.length === 0) {
            getFractureLocationList();
        }
        if (hydrocephalusLocationList.length === 0) {
            getHydrocephalusLocationList();
        }
        if (avmLocationList.length === 0) {
            getAVMLocationList();
        }
        if (bleedingLocationList.length === 0) {
            getBleedingLocationList();
        }
        if (dissectionLocationList.length === 0) {
            getDissectionLocationList();
        }
        if (aneurysmLocationInterList.length === 0) {
            getAneurysmLocationInterList();
        }
    }, [locationList.length, tumorLocationList.length, aneurysmLocationList.length,
    hematomaLocationList.length, fractureLocationList.length, hydrocephalusLocationList.length,
    avmLocationList.length, bleedingLocationList.length, dissectionLocationList.length, aneurysmLocationInterList.length]);

    useEffect(() => {
        if (osteotomyList.length === 0 && !props.showEditCaseMessage) {
            getOsteotomyList();
        }
    }, [osteotomyList.length]);

    const isEmptyProcedure = () => {
        return procedureType === '';
    }

    const isEmptyIndication = () => {
        const indication = indicationType === null ? '' : indicationType;
        return indication === '' || indication?.includes('');
    }

    const isOtherIndication = () => {
        const indication = indicationType === null ? '' : indicationType;
        return indication === 'Other' || indication?.includes('Other');
    }

    const isOtherProcedure = () => {
        return procedureType === 'Other';
    }

    const isNeurosurgery = () => {
        return user.user_profile[0].speciality?.name === 'Neurological Surgery'
    }

    const isInterventional = () => {
        return user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology';
    }

    const isRadiology = () => {
        return user.user_profile[0].speciality?.name == 'Interventional Radiology';
    }

    const isTumor = () => {
        return indicationType === 'Tumor' || indicationType?.includes('Tumor');
    }

    const isPain = () => {
        return indicationType === 'Pain' || indicationType?.includes('Pain');
    }

    const isCarotid = () => {
        return indicationType === 'Carotid stenosis' || indicationType?.includes('Carotid stenosis');
    }
    const isAVF = () => {
        return indicationType === 'AVF' || indicationType?.includes('AVF');
    }

    const isAVM = () => {
        return indicationType === 'AVM' || indicationType?.includes('AVM');
    }

    const isAbscess = () => {
        return indicationType === 'Abscess' || indicationType?.includes('Abscess');
    }

    const isAneurysm = () => {
        return indicationType === 'Aneurysm' || indicationType?.includes('Aneurysm');
    }

    const isHematoma = () => {
        return indicationType === 'Hematoma' || indicationType?.includes('Hematoma');
    }

    const isFracture = () => {
        return indicationType === 'Fracture' || indicationType?.includes('Fracture');
    }

    const isHydrocephalus = () => {
        return indicationType === 'Hydrocephalus' || indicationType?.includes('Hydrocephalus');
    }

    const isBleeding = () => {
        return indicationType === 'Bleeding' || indicationType?.includes('Bleeding');
    }

    const isDissection = () => {
        return indicationType === 'Dissection' || indicationType?.includes('Dissection');
    }

    const isVasospasm = () => {
        return indicationType === 'Vasospasm' || indicationType?.includes('Vasospasm');
    }

    const isStroke = () => {
        return indicationType === 'Stroke' || indicationType?.includes('Stroke');
    }

    const isCranial = () => {
        return procedureType === 'Cranial';
    }

    const isSpine = () => {
        return procedureType === 'Spine';
    }

    const isFunctional = () => {
        return procedureType === 'Functional';
    }

    const isPeripheral = () => {
        return procedureType === 'Peripheral nerves';
    }

    const isInter = () => {
        return procedureType === 'Neurointerventional';
    }

    const isBedside = () => {
        return procedureType === 'Bedside';
    }

    const isThrombectomy = () => {
        return neuroProcedure === 'Thrombectomy' || _procedureType?.includes('Thrombectomy');
    }

    const isNewProcedure = () => {
        return typeof (props.procedure.id) === 'string';
    }

    const onDelete = () => {
        props.onDelete(props.procedure.id);
        if (!isNewProcedure()) {
            deleteCaseProcedureById();
        }
    }

    const deleteCaseProcedureById = async () => {
        const { status_code: statusCode, data } = await api.deleteCaseProcedureById(
            user?.id,
            props.caseId === undefined ? caseId : props.caseId,
            props.procedure.id
        );
    }

    const handleDateChange = (date: any) => {
        setSurgeryDate(date);
        setProcedureDate(date);
        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}-${date.getDate()}`
        props.procedure['date_of_procedure'] = props.procedure['date'] = formattedDate;
    };

    const setFormValue = (name, value) => {
        switch (name) {
            case 'indication':
                setIndicationType(value);
                break;
            case 'location':
                setLocationType(value);
                break;
            case 'approach':
                setApproachType(value);
                break;
            case '_procedure':
                set_procedureType(value);
                break;
        }
    }

    const isMultipleSelection = (): boolean => {
        return isNeurosurgery() || isInterventional();
    }

    const collectList = (list) => {
        if (Array.isArray(list)) {
            return list.join(', ');
        }
        return list;
    }

    return (
        <Box>
            <DeleteDialog
                isOpen={openDelete}
                title={!isNewProcedure() ? 'Delete Procedure' : 'Remove Procedure'}
                firstOption={!isNewProcedure() ? 'Delete anyway' : 'Remove'}
                secondOption={'Cancel'}
                handleOptionOne={onDelete}
                handleOptionTwo={() => setOpenDelete(false)}
                onClose={() => { }}
            >
                {isNewProcedure() ? `Are you sure you want to remove this procedure?` : 'Note: Deleting this procedure is permanent and cannot be undone.'}
            </DeleteDialog>
            <Box mb={2}>
                <Paper>
                    <Box className={classes.title}>
                        <Box className={classes.procedureTitle}>
                            <Typography variant="h2" className={[classes.inline].join(' ')}>
                                Procedure {props.index + 2}
                            </Typography>
                            <p className={classes.new}>{isNewProcedure() && props.caseId ? 'New' : ''}</p>
                        </Box>
                        <DeleteOutlineIcon
                            onClick={() => {
                                setOpenDelete(true);
                            }}
                            color="secondary"
                            className={classes.cursor}
                        ></DeleteOutlineIcon>
                    </Box>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Divider />
                    </Grid>
                    <Box className={classes.InputContainer} onChange={handleValueChange}>
                        <Grid className={classes.py0} container item={true} spacing={2}>
                            {isNeurosurgery() || isInterventional() || isRadiology() ?
                                (<Grid
                                    className={classes.dateOfSurgery}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            required
                                            variant="inline"
                                            inputVariant="outlined"
                                            className={classes.mt0}
                                            autoOk={true}
                                            value={procedureDate}
                                            onChange={handleDateChange}
                                            label="Date of procedure "
                                            name="date_of_procedure"
                                            format="MM/dd/yyyy"
                                            fullWidth
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>) : null}

                            {isNeurosurgery() || isInterventional() || isRadiology() ? (
                                <Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        required
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        name="procedure_name"
                                        variant="outlined"
                                        control={control}
                                        label="Procedure name"
                                        margin="normal"
                                        fullWidth
                                        error={errors.procedure_name ? true : false}
                                    />
                                </Grid>) : null}

                            <Grid
                                className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                item={true}
                                xs={12}
                                sm={4}
                                md={6}
                                lg={6}
                            >
                                {isNeurosurgery() || isInterventional() || isRadiology() ? (
                                    <ReactHookFormSelect
                                        required
                                        name="type"
                                        label="Procedure type "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.type ? true : false}
                                        helperText={errors.type}
                                        onClick={(e) => {
                                            if (e.target.value != 0 && e.target.value != undefined && !isRadiology()) {
                                                if (e.target.value == props.procedure.type) {
                                                    // getProcedureData();
                                                } else if (e.target.value == 'Other') {
                                                    setProcedureType('');
                                                } else {
                                                    handleValueChange(e);
                                                    setProcedureType(e.target.value);
                                                    setIndicationType('');
                                                    props.procedure._procedure = '';
                                                    props.procedure.approach = '';
                                                    props.procedure.indication = '';
                                                    props.procedure.location = '';
                                                }
                                                if (isInterventional()) {
                                                    if (e.target.value == 'Other') {
                                                        setNeuroProcedure('');
                                                        props.procedure.tici_grade = '';
                                                    } else {
                                                        setNeuroProcedure(e.target.value);
                                                        props.procedure.tici_grade = '';
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        {isNeurosurgery() && neurosurgeryProcedureTypeList.length > 0
                                            ? neurosurgeryProcedureTypeList.map((type: any) => (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.label}
                                                </MenuItem>
                                            ))
                                            : isInterventional() && interventionalProcedureTypeList.length > 0
                                                ? interventionalProcedureTypeList.map((type: any) => (
                                                    <MenuItem key={type.value} value={type.value}>
                                                        {type.label}
                                                    </MenuItem>
                                                ))
                                                : isRadiology() && radiologyProcedureTypeList.length > 0
                                                    ? radiologyProcedureTypeList.map((type: any) => (
                                                        <MenuItem key={type.value} value={type.value}>
                                                            {type.label}
                                                        </MenuItem>
                                                    )) : null}
                                    </ReactHookFormSelect>
                                ) : (
                                    <Controller
                                        required
                                        as={TextField}
                                        className={classes.mt0}
                                        InputLabelProps={{
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                        variant="outlined"
                                        name="type"
                                        control={control}
                                        label="Procedure type "
                                        margin="normal"
                                        helperText={errors.type}
                                        fullWidth
                                        error={errors.type ? true : false}
                                    />)}
                            </Grid>

                            {isNeurosurgery() && procedureType == 'Cranial' ||
                                isNeurosurgery() && procedureType == 'Spine' ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    {isMultipleSelection() ? (<MultipleSelect
                                        name="approach"
                                        label="Approach "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.approach ? true : false}
                                        helperText={errors.approach}
                                        setFormValue={setFormValue}
                                        selectedValues={approachType ? approachType : []}
                                        onClick={(e) => {
                                            e.target.name = 'approach';
                                            if (e.target.value != undefined) {
                                                handleValueChange(e);
                                            }
                                        }}
                                    >
                                        {procedureType === "Cranial" && cranialApproachList.length > 0
                                            ? cranialApproachList.map((approach: any) => (
                                                <MenuItem key={approach.value} value={approach.value}>
                                                    {approach.label}
                                                </MenuItem>
                                            ))
                                            : procedureType === "Spine" && spineApproachList.length > 0
                                                ? spineApproachList.map((approach: any) => (
                                                    <MenuItem key={approach.value} value={approach.value}>
                                                        {approach.label}
                                                    </MenuItem>
                                                )) : null}
                                    </MultipleSelect>) : (
                                        <ReactHookFormSelect
                                            name="approach"
                                            label="Approach "
                                            control={control}
                                            className={classes.mt0}
                                            defaultValue=""
                                            variant="outlined"
                                            margin="normal"
                                            setIsBlocking={setIsBlocking}
                                            error={errors.approach ? true : false}
                                            helperText={errors.approach}
                                            onClick={handleValueChange}
                                        >
                                            {procedureType === "Cranial" && cranialApproachList.length > 0
                                                ? cranialApproachList.map((approach: any) => (
                                                    <MenuItem key={approach.value} value={approach.value}>
                                                        {approach.label}
                                                    </MenuItem>
                                                ))
                                                : procedureType === "Spine" && spineApproachList.length > 0
                                                    ? spineApproachList.map((approach: any) => (
                                                        <MenuItem key={approach.value} value={approach.value}>
                                                            {approach.label}
                                                        </MenuItem>
                                                    )) : null}
                                        </ReactHookFormSelect>
                                    )}
                                </Grid>) : null}

                            {isNeurosurgery() && isSpine() ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="minimally_invasive"
                                        label="Minimally invasive "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.minimally_invasive ? true : false}
                                        helperText={errors.minimally_invasive}
                                        onClick={handleValueChange}
                                    >
                                        {yesNoList.length > 0
                                            ? yesNoList.map((mi: any) => (
                                                <MenuItem key={mi.value} value={mi.value}>
                                                    {mi.label}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isNeurosurgery() && isSpine() ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="navigation"
                                        label="Navigation "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.navigation ? true : false}
                                        helperText={errors.navigation}
                                        onClick={handleValueChange}
                                    >
                                        {yesNoList.length > 0
                                            ? yesNoList.map((navigation: any) => (
                                                <MenuItem key={navigation.value} value={navigation.value}>
                                                    {navigation.label}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isNeurosurgery() && isSpine() ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="robotic"
                                        label="Robotic "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.robotic ? true : false}
                                        helperText={errors.robotic}
                                        onClick={handleValueChange}
                                    >
                                        {yesNoList.length > 0
                                            ? yesNoList.map((robotic: any) => (
                                                <MenuItem key={robotic.value} value={robotic.value}>
                                                    {robotic.label}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isNeurosurgery() && isBedside() ?
                                (<Grid
                                    className={classes.py0}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        variant="outlined"
                                        name="indication"
                                        defaultValue=""
                                        control={control}
                                        label="Indication"
                                        margin="normal"
                                        fullWidth
                                        error={errors.indication ? true : false}
                                        helperText={errors.indication && errors.indication.message}
                                    />
                                </Grid>) : isNeurosurgery() && !isOtherProcedure() && !isEmptyProcedure() ||
                                    isInterventional() || isRadiology() ? (
                                    <Grid
                                        className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                        item={true}
                                        xs={12}
                                        sm={4}
                                        md={6}
                                        lg={6}
                                    >
                                        {isMultipleSelection() ? (<MultipleSelect
                                            name="indication"
                                            label="Indication "
                                            control={control}
                                            className={classes.mt0}
                                            defaultValue=""
                                            variant="outlined"
                                            margin="normal"
                                            setIsBlocking={setIsBlocking}
                                            error={errors.indication ? true : false}
                                            helperText={errors.indication}
                                            setFormValue={setFormValue}
                                            selectedValues={indicationType ? indicationType : []}
                                            onClick={(e) => {
                                                e.target.name = 'indication';
                                                if (e.target.value != 0 && e.target.value != undefined && !isRadiology()) {
                                                    if (e.target.value == 'Other') {
                                                        setIndicationType('Other');
                                                        props.procedure.location = '';

                                                    } else {
                                                        handleValueChange(e);
                                                        setIndicationType(e.target.value);
                                                        props.procedure.location = '';
                                                    }
                                                }
                                            }}
                                        >
                                            {isInterventional() && interIndicationList.length > 0
                                                ? interIndicationList.map((indication: any) => (
                                                    <MenuItem key={indication.value} value={indication.value}>
                                                        {indication.label}
                                                    </MenuItem>
                                                ))
                                                : isRadiology() && radiologyIndicationList.length > 0
                                                    ? radiologyIndicationList.map((indication: any) => (
                                                        <MenuItem key={indication.value} value={indication.value}>
                                                            {indication.label}
                                                        </MenuItem>
                                                    ))
                                                    : procedureType === "Cranial" && cranialIndicationList.length > 0
                                                        ? cranialIndicationList.map((indication: any) => (
                                                            <MenuItem key={indication.value} value={indication.value}>
                                                                {indication.label}
                                                            </MenuItem>
                                                        ))
                                                        : procedureType === "Spine" && spineIndicationList.length > 0
                                                            ? spineIndicationList.map((indication: any) => (
                                                                <MenuItem key={indication.value} value={indication.value}>
                                                                    {indication.label}
                                                                </MenuItem>
                                                            ))
                                                            : procedureType === "Functional" && functionalIndicationList.length > 0
                                                                ? functionalIndicationList.map((indication: any) => (
                                                                    <MenuItem key={indication.value} value={indication.value}>
                                                                        {indication.label}
                                                                    </MenuItem>
                                                                ))
                                                                : procedureType === "Peripheral nerves" && nervesIndicationList.length > 0
                                                                    ? nervesIndicationList.map((indication: any) => (
                                                                        <MenuItem key={indication.value} value={indication.value}>
                                                                            {indication.label}
                                                                        </MenuItem>
                                                                    ))
                                                                    : interIndicationList.length > 0
                                                                        ? interIndicationList.map((indication: any) => (
                                                                            <MenuItem key={indication.value} value={indication.value}>
                                                                                {indication.label}
                                                                            </MenuItem>
                                                                        )) : null}
                                        </MultipleSelect>) : (
                                            <ReactHookFormSelect
                                                name="indication"
                                                label="Indication "
                                                control={control}
                                                className={classes.mt0}
                                                defaultValue=""
                                                variant="outlined"
                                                margin="normal"
                                                setIsBlocking={setIsBlocking}
                                                error={errors.indication ? true : false}
                                                helperText={errors.indication}
                                                onClick={(e) => {
                                                    if (e.target.value != 0 && e.target.value != undefined && !isRadiology()) {
                                                        if (e.target.value == 'Other') {
                                                            setIndicationType('Other');
                                                            props.procedure.location = '';

                                                        } else {
                                                            handleValueChange(e);
                                                            setIndicationType(e.target.value);
                                                            props.procedure.location = '';
                                                        }
                                                    }
                                                }}
                                            >
                                                {isInterventional() && interIndicationList.length > 0
                                                    ? interIndicationList.map((indication: any) => (
                                                        <MenuItem key={indication.value} value={indication.value}>
                                                            {indication.label}
                                                        </MenuItem>
                                                    ))
                                                    : isRadiology() && radiologyIndicationList.length > 0
                                                        ? radiologyIndicationList.map((indication: any) => (
                                                            <MenuItem key={indication.value} value={indication.value}>
                                                                {indication.label}
                                                            </MenuItem>
                                                        ))
                                                        : procedureType === "Cranial" && cranialIndicationList.length > 0
                                                            ? cranialIndicationList.map((indication: any) => (
                                                                <MenuItem key={indication.value} value={indication.value}>
                                                                    {indication.label}
                                                                </MenuItem>
                                                            ))
                                                            : procedureType === "Spine" && spineIndicationList.length > 0
                                                                ? spineIndicationList.map((indication: any) => (
                                                                    <MenuItem key={indication.value} value={indication.value}>
                                                                        {indication.label}
                                                                    </MenuItem>
                                                                ))
                                                                : procedureType === "Functional" && functionalIndicationList.length > 0
                                                                    ? functionalIndicationList.map((indication: any) => (
                                                                        <MenuItem key={indication.value} value={indication.value}>
                                                                            {indication.label}
                                                                        </MenuItem>
                                                                    ))
                                                                    : procedureType === "Peripheral nerves" && nervesIndicationList.length > 0
                                                                        ? nervesIndicationList.map((indication: any) => (
                                                                            <MenuItem key={indication.value} value={indication.value}>
                                                                                {indication.label}
                                                                            </MenuItem>
                                                                        ))
                                                                        : interIndicationList.length > 0
                                                                            ? interIndicationList.map((indication: any) => (
                                                                                <MenuItem key={indication.value} value={indication.value}>
                                                                                    {indication.label}
                                                                                </MenuItem>
                                                                            )) : null}
                                            </ReactHookFormSelect>
                                        )}
                                    </Grid>) : null}

                            {isNeurosurgery() || isInterventional() ? (<Grid
                                className={classes.py0}
                                item={true}
                                xs={12}
                                sm={4}
                                md={6}
                                lg={6}
                            >
                                <ReactHookFormSelect
                                    name="side"
                                    label="Side "
                                    control={control}
                                    className={classes.mt0}
                                    defaultValue=""
                                    variant="outlined"
                                    margin="normal"
                                    setIsBlocking={setIsBlocking}
                                    error={errors.side ? true : false}
                                    helperText={errors.side}
                                    onClick={handleValueChange}
                                >
                                    {sidesList.length > 0
                                        ? sidesList.map((side: any) => (
                                            <MenuItem key={side.value} value={side.value}>
                                                {side.label}
                                            </MenuItem>
                                        ))
                                        : null}
                                </ReactHookFormSelect>
                            </Grid>) : null}

                            {isNeurosurgery() && isInter() && !isEmptyProcedure() ||
                                isNeurosurgery() && isBedside() && !isEmptyProcedure() ?
                                (<Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    {isMultipleSelection() ? (<MultipleSelect
                                        name="_procedure"
                                        label="Procedure "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors._procedure ? true : false}
                                        helperText={errors._procedure}
                                        setFormValue={setFormValue}
                                        selectedValues={_procedureType ? _procedureType : []}
                                        onClick={(e) => {
                                            e.target.name = '_procedure';
                                            if (e.target.value != 0 && e.target.value != undefined) {
                                                if (e.target.value == 'Other') {
                                                    setNeuroProcedure('');
                                                    props.procedure.tici_grade = '';

                                                } else {
                                                    handleValueChange(e);
                                                    setNeuroProcedure(e.target.value);
                                                    props.procedure.tici_grade = '';
                                                }
                                            }
                                        }}
                                    >
                                        {interventionalProcedureTypeList.length > 0 && (isInterventional() ||
                                            isNeurosurgery() && isInter())
                                            ? interventionalProcedureTypeList.map((type: any) => (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.label}
                                                </MenuItem>
                                            ))
                                            : isNeurosurgery() && isBedside() && bedsideProcedureTypeList.length > 0
                                                ? bedsideProcedureTypeList.map((type: any) => (
                                                    <MenuItem key={type.value} value={type.value}>
                                                        {type.label}
                                                    </MenuItem>
                                                )) : null}
                                    </MultipleSelect>) : (
                                        <ReactHookFormSelect
                                            name="_procedure"
                                            label="Procedure "
                                            control={control}
                                            className={classes.mt0}
                                            defaultValue=""
                                            variant="outlined"
                                            margin="normal"
                                            setIsBlocking={setIsBlocking}
                                            error={errors._procedure ? true : false}
                                            helperText={errors._procedure}
                                            onClick={(e) => {
                                                if (e.target.value != 0 && e.target.value != undefined) {
                                                    if (e.target.value == 'Other') {
                                                        setNeuroProcedure('');
                                                        props.procedure.tici_grade = '';

                                                    } else {
                                                        handleValueChange(e);
                                                        setNeuroProcedure(e.target.value);
                                                        props.procedure.tici_grade = '';
                                                    }
                                                }
                                            }}
                                        >
                                            {interventionalProcedureTypeList.length > 0 && (isInterventional() ||
                                                isNeurosurgery() && isInter())
                                                ? interventionalProcedureTypeList.map((type: any) => (
                                                    <MenuItem key={type.value} value={type.value}>
                                                        {type.label}
                                                    </MenuItem>
                                                ))
                                                : isNeurosurgery() && isBedside() && bedsideProcedureTypeList.length > 0
                                                    ? bedsideProcedureTypeList.map((type: any) => (
                                                        <MenuItem key={type.value} value={type.value}>
                                                            {type.label}
                                                        </MenuItem>
                                                    )) : null}
                                        </ReactHookFormSelect>
                                    )}
                                </Grid>) : null}

                            {(isNeurosurgery() && isInter()) && (isCarotid() || isPain() || isOtherIndication()) ||
                                (isInterventional() && (isCarotid() || isPain() || isOtherIndication())) || isRadiology() ?
                                (<Grid
                                    className={classes.py0}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        variant="outlined"
                                        name="location"
                                        defaultValue=""
                                        control={control}
                                        label="Location"
                                        margin="normal"
                                        fullWidth
                                        error={errors.location ? true : false}
                                        helperText={errors.location && errors.location.message}
                                    />
                                </Grid>) : (isNeurosurgery() && !(isFunctional() || isPeripheral())) &&
                                    !(isEmptyIndication() || isOtherIndication()) ||
                                    isNeurosurgery() && isSpine() ?
                                    (<Grid
                                        className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                        item={true}
                                        xs={12}
                                        sm={4}
                                        md={6}
                                        lg={6}
                                    >
                                        {isMultipleSelection() ? (<MultipleSelect
                                            name="location"
                                            label="Location "
                                            control={control}
                                            className={classes.mt0}
                                            defaultValue=""
                                            variant="outlined"
                                            margin="normal"
                                            setIsBlocking={setIsBlocking}
                                            error={errors.location ? true : false}
                                            helperText={errors.location}
                                            setFormValue={setFormValue}
                                            selectedValues={locationType ? locationType : []}
                                            onClick={(e) => {
                                                e.target.name = 'location';
                                                if (e.target.value != undefined) {
                                                    handleValueChange(e);
                                                }
                                            }}                                        >
                                            {isCranial() && (isTumor() || isAVM() || isAVF() || isAbscess()) && tumorLocationList.length > 0
                                                ? tumorLocationList.map((location: any) => (
                                                    <MenuItem key={location.value} value={location.value}>
                                                        {location.label}
                                                    </MenuItem>
                                                ))
                                                : isCranial() && isAneurysm() && aneurysmLocationList.length > 0
                                                    ? aneurysmLocationList.map((location: any) => (
                                                        <MenuItem key={location.value} value={location.value}>
                                                            {location.label}
                                                        </MenuItem>
                                                    ))
                                                    : isCranial() && isHematoma() && hematomaLocationList.length > 0
                                                        ? hematomaLocationList.map((location: any) => (
                                                            <MenuItem key={location.value} value={location.value}>
                                                                {location.label}
                                                            </MenuItem>
                                                        ))
                                                        : isCranial() && isFracture() && fractureLocationList.length > 0
                                                            ? fractureLocationList.map((location: any) => (
                                                                <MenuItem key={location.value} value={location.value}>
                                                                    {location.label}
                                                                </MenuItem>
                                                            ))
                                                            : isCranial() && isHydrocephalus() && hydrocephalusLocationList.length > 0
                                                                ? hydrocephalusLocationList.map((location: any) => (
                                                                    <MenuItem key={location.value} value={location.value}>
                                                                        {location.label}
                                                                    </MenuItem>
                                                                ))
                                                                : isSpine() && locationList.length > 0
                                                                    ? locationList.map((location: any) => (
                                                                        <MenuItem key={location.value} value={location.value}>
                                                                            {location.label}
                                                                        </MenuItem>
                                                                    ))
                                                                    : isInter() && (isAVM() || isAVF()) && avmLocationList.length > 0
                                                                        ? avmLocationList.map((location: any) => (
                                                                            <MenuItem key={location.value} value={location.value}>
                                                                                {location.label}
                                                                            </MenuItem>
                                                                        ))
                                                                        : isInter() && isBleeding() && bleedingLocationList.length > 0
                                                                            ? bleedingLocationList.map((location: any) => (
                                                                                <MenuItem key={location.value} value={location.value}>
                                                                                    {location.label}
                                                                                </MenuItem>
                                                                            ))
                                                                            : isInter() && isAneurysm() && aneurysmLocationInterList.length > 0
                                                                                ? aneurysmLocationInterList.map((location: any) => (
                                                                                    <MenuItem key={location.value} value={location.value}>
                                                                                        {location.label}
                                                                                    </MenuItem>
                                                                                ))
                                                                                : isInter() && (isDissection() || isVasospasm() || isStroke()) && dissectionLocationList.length > 0
                                                                                    ? dissectionLocationList.map((location: any) => (
                                                                                        <MenuItem key={location.value} value={location.value}>
                                                                                            {location.label}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                    : null}
                                        </MultipleSelect>) : (
                                            <ReactHookFormSelect
                                                name="location"
                                                label="Location "
                                                control={control}
                                                className={classes.mt0}
                                                defaultValue=""
                                                variant="outlined"
                                                margin="normal"
                                                setIsBlocking={setIsBlocking}
                                                error={errors.location ? true : false}
                                                helperText={errors.location}
                                                onClick={handleValueChange}
                                            >
                                                {isCranial() && (isTumor() || isAVM() || isAVF() || isAbscess()) && tumorLocationList.length > 0
                                                    ? tumorLocationList.map((location: any) => (
                                                        <MenuItem key={location.value} value={location.value}>
                                                            {location.label}
                                                        </MenuItem>
                                                    ))
                                                    : isCranial() && isAneurysm() && aneurysmLocationList.length > 0
                                                        ? aneurysmLocationList.map((location: any) => (
                                                            <MenuItem key={location.value} value={location.value}>
                                                                {location.label}
                                                            </MenuItem>
                                                        ))
                                                        : isCranial() && isHematoma() && hematomaLocationList.length > 0
                                                            ? hematomaLocationList.map((location: any) => (
                                                                <MenuItem key={location.value} value={location.value}>
                                                                    {location.label}
                                                                </MenuItem>
                                                            ))
                                                            : isCranial() && isFracture() && fractureLocationList.length > 0
                                                                ? fractureLocationList.map((location: any) => (
                                                                    <MenuItem key={location.value} value={location.value}>
                                                                        {location.label}
                                                                    </MenuItem>
                                                                ))
                                                                : isCranial() && isHydrocephalus() && hydrocephalusLocationList.length > 0
                                                                    ? hydrocephalusLocationList.map((location: any) => (
                                                                        <MenuItem key={location.value} value={location.value}>
                                                                            {location.label}
                                                                        </MenuItem>
                                                                    ))
                                                                    : isSpine() && locationList.length > 0
                                                                        ? locationList.map((location: any) => (
                                                                            <MenuItem key={location.value} value={location.value}>
                                                                                {location.label}
                                                                            </MenuItem>
                                                                        ))
                                                                        : isInter() && (isAVM() || isAVF()) && avmLocationList.length > 0
                                                                            ? avmLocationList.map((location: any) => (
                                                                                <MenuItem key={location.value} value={location.value}>
                                                                                    {location.label}
                                                                                </MenuItem>
                                                                            ))
                                                                            : isInter() && isBleeding() && bleedingLocationList.length > 0
                                                                                ? bleedingLocationList.map((location: any) => (
                                                                                    <MenuItem key={location.value} value={location.value}>
                                                                                        {location.label}
                                                                                    </MenuItem>
                                                                                ))
                                                                                : isInter() && isAneurysm() && aneurysmLocationInterList.length > 0
                                                                                    ? aneurysmLocationInterList.map((location: any) => (
                                                                                        <MenuItem key={location.value} value={location.value}>
                                                                                            {location.label}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                    : isInter() && (isDissection() || isVasospasm() || isStroke()) && dissectionLocationList.length > 0
                                                                                        ? dissectionLocationList.map((location: any) => (
                                                                                            <MenuItem key={location.value} value={location.value}>
                                                                                                {location.label}
                                                                                            </MenuItem>
                                                                                        ))
                                                                                        : null}
                                            </ReactHookFormSelect>
                                        )}
                                    </Grid>) : isInterventional() && !isEmptyIndication() ? (
                                        <Grid
                                            className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                            item={true}
                                            xs={12}
                                            sm={4}
                                            md={6}
                                            lg={6}
                                        >
                                            <ReactHookFormSelect
                                                name="location"
                                                label="Location "
                                                control={control}
                                                className={classes.mt0}
                                                defaultValue=""
                                                variant="outlined"
                                                margin="normal"
                                                setIsBlocking={setIsBlocking}
                                                error={errors.location ? true : false}
                                                helperText={errors.location}
                                                onClick={handleValueChange}
                                            >
                                                {(isAVM() || isAVF()) && avmLocationList.length > 0
                                                    ? avmLocationList.map((location: any) => (
                                                        <MenuItem key={location.value} value={location.value}>
                                                            {location.label}
                                                        </MenuItem>
                                                    ))
                                                    : isBleeding() && bleedingLocationList.length > 0
                                                        ? bleedingLocationList.map((location: any) => (
                                                            <MenuItem key={location.value} value={location.value}>
                                                                {location.label}
                                                            </MenuItem>
                                                        )) : isAneurysm() && aneurysmLocationInterList.length > 0
                                                            ? aneurysmLocationInterList.map((location: any) => (
                                                                <MenuItem key={location.value} value={location.value}>
                                                                    {location.label}
                                                                </MenuItem>
                                                            )) : (isDissection() || isVasospasm() || isStroke()) && dissectionLocationList.length > 0
                                                                ? dissectionLocationList.map((location: any) => (
                                                                    <MenuItem key={location.value} value={location.value}>
                                                                        {location.label}
                                                                    </MenuItem>
                                                                )) : null}
                                            </ReactHookFormSelect>
                                        </Grid>) : null}

                            {isNeurosurgery() && isInter() && isThrombectomy() ||
                                isInterventional() && isThrombectomy() ?
                                (<Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="tici_grade"
                                        label="TICI grade "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.tici_grade ? true : false}
                                        helperText={errors.tici_grade}
                                        onClick={handleValueChange}
                                    >
                                        {ticiGradeList.length > 0
                                            ? ticiGradeList.map((grade: any) => (
                                                <MenuItem key={grade.value} value={grade.value}>
                                                    {grade.label}
                                                </MenuItem>
                                            )) : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isNeurosurgery() &&
                                procedureType === 'Spine' && procedureType !== '' ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="osteotomy"
                                        label="Osteotomy "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.osteotomy ? true : false}
                                        helperText={errors.osteotomy}
                                        onClick={handleValueChange}
                                    >
                                        {osteotomyList.length > 0
                                            ? osteotomyList.map((osteotomy: any) => (
                                                <MenuItem key={osteotomy.value} value={osteotomy.value}>
                                                    {osteotomy.label}
                                                </MenuItem>
                                            )) : null}

                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isNeurosurgery() &&
                                procedureType === 'Spine' && procedureType !== '' ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="_fusion"
                                        label="Fusion "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors._fusion ? true : false}
                                        helperText={errors._fusion}
                                        onClick={handleValueChange}
                                    >
                                        {yesNoList.length > 0
                                            ? yesNoList.map((fusion: any) => (
                                                <MenuItem key={fusion.value} value={fusion.value}>
                                                    {fusion.label}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isNeurosurgery() &&
                                procedureType === 'Spine' && procedureType !== '' ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="interbody_fusion"
                                        label="Interbody fusion "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.interbody_fusion ? true : false}
                                        helperText={errors.interbody_fusion}
                                        onClick={handleValueChange}
                                    >
                                        {yesNoList.length > 0
                                            ? yesNoList.map((interbody_fusion: any) => (
                                                <MenuItem key={interbody_fusion.value} value={interbody_fusion.value}>
                                                    {interbody_fusion.label}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isNeurosurgery() &&
                                procedureType === 'Spine' && procedureType !== '' ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="extension_to_pelvis"
                                        label="Extension to pelvis "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.extension_to_pelvis ? true : false}
                                        helperText={errors.extension_to_pelvis}
                                        onClick={handleValueChange}
                                    >
                                        {yesNoList.length > 0
                                            ? yesNoList.map((extension_to_pelvis: any) => (
                                                <MenuItem key={extension_to_pelvis.value} value={extension_to_pelvis.value}>
                                                    {extension_to_pelvis.label}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isNeurosurgery() && isSpine() ? (
                                <Grid
                                    className={classes.py0}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        variant="outlined"
                                        name="fusion_levels"
                                        defaultValue=""
                                        control={control}
                                        label="Fusion levels"
                                        margin="normal"
                                        fullWidth
                                        error={errors.fusion_levels ? true : false}
                                        helperText={errors.fusion_levels && errors.fusion_levels.message}
                                    />
                                </Grid>) : null}

                            {isNeurosurgery() && isSpine() ? (
                                <Grid
                                    className={classes.py0}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        variant="outlined"
                                        name="decompression_levels"
                                        defaultValue=""
                                        control={control}
                                        label="Decompression levels"
                                        margin="normal"
                                        fullWidth
                                        error={errors.fusion_levels ? true : false}
                                        helperText={errors.fusion_levels && errors.fusion_levels.message}
                                    />
                                </Grid>) : null}

                            {isNeurosurgery() &&
                                procedureType === 'Spine' && procedureType !== '' ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="morphogenic_protein"
                                        label="Morphogenic protein "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.morphogenic_protein ? true : false}
                                        helperText={errors.morphogenic_protein}
                                        onClick={handleValueChange}
                                    >
                                        {yesNoList.length > 0
                                            ? yesNoList.map((morphogenic_protein: any) => (
                                                <MenuItem key={morphogenic_protein.value} value={morphogenic_protein.value}>
                                                    {morphogenic_protein.label}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isInterventional() || isRadiology() ||
                                isNeurosurgery() && !isSpine() && !isEmptyProcedure() && !isOtherProcedure() ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <ReactHookFormSelect
                                        name="implant"
                                        label="Implant "
                                        control={control}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        margin="normal"
                                        setIsBlocking={setIsBlocking}
                                        error={errors.implant ? true : false}
                                        helperText={errors.implant}
                                        onClick={handleValueChange}
                                    >
                                        {yesNoList.length > 0
                                            ? yesNoList.map((implant: any) => (
                                                <MenuItem key={implant.value} value={implant.value}>
                                                    {implant.label}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </ReactHookFormSelect>
                                </Grid>) : null}

                            {isInterventional() || isRadiology() ||
                                isNeurosurgery() && !isSpine() && !isEmptyProcedure() && !isOtherProcedure() ?
                                <Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        className={classes.mt0}
                                        as={TextField}
                                        InputLabelProps={{
                                            classes: {
                                                asterisk: classes.asterisk
                                            }
                                        }}
                                        label="Implant type "
                                        control={control}
                                        name="implant_type"
                                        error={errors.implant ? true : false}
                                        helperText={
                                            errors.implant &&
                                            errors.implant.message
                                        }
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid> : null}

                            {isNeurosurgery() && procedureType !== '' ||
                                isInterventional() && procedureType !== '' ||
                                !user.user_profile[0].speciality || user.user_profile[0].speciality.name ?
                                (<Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        name="vendors"
                                        variant="outlined"
                                        control={control}
                                        label="Vendors"
                                        margin="normal"
                                        fullWidth
                                        error={errors.vendors ? true : false}
                                    />
                                </Grid>) : null}

                            {isInterventional() || isRadiology() ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        name="access"
                                        control={control}
                                        label="Access"
                                        margin="normal"
                                        fullWidth
                                        error={errors.access ? true : false}
                                    />
                                </Grid>
                            ) : null}

                            {isInterventional() || isRadiology() ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        name="vascular_closure_device"
                                        control={control}
                                        label="Vascular closure device (if used)"
                                        margin="normal"
                                        fullWidth
                                        error={errors.vascular_closure_device ? true : false}
                                    />
                                </Grid>
                            ) : null}

                            {isNeurosurgery() && !isEmptyProcedure() && isInter() ||
                                isInterventional() || isRadiology() ?
                                (<Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        name="duration_of_radiation"
                                        variant="outlined"
                                        control={control}
                                        label="Duration of radiation"
                                        margin="normal"
                                        fullWidth
                                        error={errors.duration_of_radiation ? true : false}
                                    />
                                </Grid>) : null}

                            {isNeurosurgery() ||
                                isInterventional() ?
                                (<Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        name="duration_of_procedure"
                                        variant="outlined"
                                        control={control}
                                        label="Duration of procedure"
                                        margin="normal"
                                        fullWidth
                                        error={errors.duration_of_procedure ? true : false}
                                    />
                                </Grid>) : null}

                            {isNeurosurgery() &&
                                procedureType === 'Functional' && procedureType !== '' ?
                                (<Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        name="target"
                                        variant="outlined"
                                        control={control}
                                        label="Target "
                                        margin="normal"
                                        fullWidth
                                        error={errors.target ? true : false}
                                    />
                                </Grid>) : null}

                            {isNeurosurgery() &&
                                procedureType === 'Peripheral nerves' && procedureType !== '' ?
                                (<Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        name="nerve"
                                        variant="outlined"
                                        control={control}
                                        label="Nerve "
                                        margin="normal"
                                        fullWidth
                                        error={errors.nerve ? true : false}
                                    />
                                </Grid>) : null}

                            {!isNeurosurgery() && !isInterventional() && !isRadiology() ?
                                (<Grid
                                    className={classes.dateOfSurgery}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            required
                                            variant="inline"
                                            inputVariant="outlined"
                                            className={classes.mt0}
                                            autoOk={true}
                                            value={surgeryDate}
                                            onChange={handleDateChange}
                                            label="Date of procedure "
                                            name="date"
                                            format="MM/dd/yyyy"
                                            fullWidth
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>) : null}

                            {!isRadiology() ? (
                                <Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        defaultValue=""
                                        className={classes.mt0}
                                        name="findings"
                                        variant="outlined"
                                        control={control}
                                        label="Findings"
                                        margin="normal"
                                        fullWidth
                                        error={errors.findings ? true : false}
                                    />
                                </Grid>) : null}

                            {!isRadiology() ? (
                                <Grid
                                    className={[classes.mb0Mobile, classes.py0mb].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        as={TextField}
                                        className={classes.mt0}
                                        defaultValue=""
                                        variant="outlined"
                                        name="complications"
                                        control={control}
                                        label="Complications"
                                        margin="normal"
                                        fullWidth
                                        error={errors.complications ? true : false}
                                    />
                                </Grid>) : null}

                            {!isRadiology() ? (
                                <Grid
                                    className={[classes.py0, classes.mb0Mobile].join(' ')}
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                >
                                    <Controller
                                        defaultValue=""
                                        as={TextField}
                                        className={classes.mt0}
                                        variant="outlined"
                                        name="outcome"
                                        control={control}
                                        label="Outcome"
                                        margin="normal"
                                        fullWidth
                                        error={errors.outcome ? true : false}
                                    />
                                </Grid>) : null}
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Box >
    )
}
export default CaseProcedure;
