import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { isFileExcluded } from 'tslint/lib/configuration';

const UserInfoBoxStyle = makeStyles((theme: Theme) =>
  createStyles({
    popupIcon: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    collapsed: {
      paddingLeft: 20
    },
    popup: {
      cursor: 'pointer'
    },
    icon: {
      marginRight: 4
    },
    collapsedIcon: {
      marginLeft: 'auto'
    },
    listIcon: {
      marginRight: 5
    },
    listItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    userBox: {
      border: '1px solid rgba(0, 0, 0, 0.15)',
      color: theme.palette.text.secondary
    },

    fileExportedPR: {
      borderRadius: 6,
      marginBottom: 30,
      color: theme.palette.text.secondary,
      position: 'relative',
      paddingRight: 20,
      [theme.breakpoints.down('lg')]: {
        marginBottom: 15
      },
      [theme.breakpoints.only('lg')]: {
        paddingRight: 0
      }
    },
    fileExportedPL: {
      borderRadius: 6,
      marginBottom: 30,
      paddingLeft: 10,
      position: 'relative',
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('lg')]: {
        marginBottom: 15
      }
    },
    fileExportedPaper: {
      padding: 3,
      color: theme.palette.text.secondary
    },
    fileExported: {
      marginBottom: 30,

      color: theme.palette.text.secondary,

      position: 'relative',
      [theme.breakpoints.down('lg')]: {
        marginBottom: 15
      }
    },
    border: {
      display: 'flex',
      alignItems: 'center'
    },
    borderPaper: {
      borderRadius: 6,
      cursor: 'pointer',
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 0,
        margin: '0px 6px'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 5,
        margin: '0px 6px'
      }
    },
    imgBox: {
      backgroundColor: 'rgba(170, 170, 170, 0.2)',
      display: 'flex',
      justifyContent: 'center',
      height: 180,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      [theme.breakpoints.down('sm')]: {
        borderRadius: 6,
        border: 'rgba(170, 170, 170, 0.2)',
        width: 77,
        height: 77
      }
    },
    nameBox: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '12px 12px 0px'
    },
    p12: {
      padding: '0px 12px'
    },
    pt0: {
      padding: '0px 12px'
    },
    pb12: {
      paddingBottom: 8
    },
    f16: {
      fontSize: 16,
      fontWeight: 500
    },
    w200: {
      width: 200,
      fontSize: 14
    },
    w200Box: {
      width: 200,
      marginTop: -11
    },
    w100: {
      width: '100%',
      borderRadius: 6,
      objectFit: 'cover'
    },
    br6: {
      borderRadius: 6,
      objectFit: 'cover'
    }
  })
);

export default UserInfoBoxStyle;
