import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { isFileExcluded } from 'tslint/lib/configuration';

const ConfirmCloseDialogStyle = makeStyles((theme: Theme) =>
  createStyles({
    actionFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 18,
      marginRight: 20,
      marginBottom: 25
    },
    actionButton: {
      width: '50%',
      margin: 5
    },
    header: {
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 22
    },
    closeIcon: {
      marginRight: 10,
      color: '#aaaaaa'
    },
    actionDeleteButton: {
      color: '#ff3b30',
      width: '50%',
      alignContent: 'center',
      border: '1px solid #ff3b30',
      margin: 5
    },
    dialogActionDelete: {
      marginBottom: 30,
      marginLeft: 17,
      marginRight: 17
    },
    DialogBody: {
      paddingLeft: 24,
      marginBottom: 34
    },
    paperDelete: {
      height: 198,
      width: 507
    },
    paperRegistry: {
      width: 448,
      height: 'auto',
      margin: 16,
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        width: 343
      }
    },
    dialogContainer: {
      width: 507,
      [theme.breakpoints.down('sm')]: {
        width: 343
      },
      padding: 5
    },
    title: {
      fontWeight: 500
    },
    registryTitle: {
      textAlign: 'center',

      fontSize: 20,
      fontWeight: 500,
      marginTop: 13,
      marginBottom: 15,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    formControl: {
      width: '100%',
      marginTop: 24,
      marginBottom: 12
    },
    loseInfoMessage: {
      marginLeft: 24,
      marginTop: 16,
      marginBottom: 34
    },
    footerButton: {
      width: '100%'
    }
  })
);

export default ConfirmCloseDialogStyle;
