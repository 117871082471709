import {
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

const HomePageStyle = makeStyles((theme: Theme) =>
  createStyles({})
);

export default HomePageStyle;
