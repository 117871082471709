import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { MessageRounded } from '@material-ui/icons';

const caseDetailsStyle = makeStyles((theme: Theme) =>
  createStyles({
    seeMoreBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
    },
    seeMoreBtn: {
      padding: 0,
      margin: 0,
      color: '#4aa5ff',
    },
    boxPadding: {
      padding: '15px 168px',
      minWidth: 500,
      [theme.breakpoints.down('md')]: {
        padding: 0,
        minWidth: 0
      },

      [theme.breakpoints.up('lg')]: {
        padding: 0,
        width: '80%',
        margin: 'auto'
      },
      [theme.breakpoints.only('md')]: {
        padding: '15px 50px',
        minWidth: 0
      }
    },
    registrySnackbar: {
      [theme.breakpoints.up('sm')]: {
        width: 500
      }
    },
    inline: {
      display: 'inline'
    },
    hrContainer: {
      width: '1005'
    },

    icdBox: {
      padding: '3px 0px'
    },
    collapsed: {
      paddingLeft: 20
    },
    popup: {
      position: 'absolute',
      right: 5
    },
    imageDivider: {
      marginRight: '15px',
      marginLeft: '15px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
        marginLeft: '0px',
        marginBottom: '5px'
      }
    },
    addIcon: {
      marginRight: -8,
      display: 'flex',
      alignItems: 'center', 
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      }
    },
    titleImage: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#4aa5ff',
      fontSize: 16,
      padding: '24px 32px',
      paddingBottom: 0,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        padding: '12px 8px 0px 14px',
        paddingBottom: '0px',
        display: 'flex', 
        alignItems: 'center',
      }
    },
    imageName: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    openedImage: {
      width: '100%',
      height: 'auto',
    },
    rvuContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0px 0px 10px',
      borderBottom: '1px solid rgba(170, 170, 170, 0.4)',
      paddingBottom: 5,
      flexDirection: 'column',
    },
    collapsedIcon: {
      marginLeft: 'auto'
    },
    exportPoper: {
      display: 'flex',
      fontSize: 16,
      padding: 12
    },
    collapsedItem: {
      marginRight: 4
    },
    caseTab: {
      fontWeight: 500,
      position: 'absolute',
      top: 10,
      left: 20,
      fontSize: 16
    },
    header: {
      fontWeight: 500,
      marginBottom: 4,
      fontSize: 16
    },
    nullData: {
      padding: '5px 18px',
      [theme.breakpoints.up('md')]: {
        padding: '5px 34px 0px'
      }
    },
    icon: {
      marginRight: 4
    },
    Padding: {
      padding: '4px 16px'
    },
    cptCodeLeft: {
      marginLeft: 13.9,
      marginBottom: 18,
      border: '1px solid rgba(170, 170, 170, 0.8)',
      padding: 8,
      [theme.breakpoints.up('md')]: {
        height: 110
      },
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: '1px solid rgba(170, 170, 170, 0.8)',
        paddingLeft: 16,
        paddingTop: 6,
        width: '100%'
      }
    },
    cptCodeRight: {
      marginRight: 13.9,
      marginBottom: 18,
      border: '1px solid rgba(170, 170, 170, 0.8)',
      width: '100%',
      padding: 8,
      [theme.breakpoints.up('md')]: {
        height: 110
      },
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        paddingLeft: 16,
        paddingTop: 6,
        width: '100%'
      }
    },
    notePaper: {
      marginTop: 22,
      padding: 15,
      [theme.breakpoints.down('sm')]: {
        padding: 0
      }
    },
    noteBoxLeft: {
      padding: 2,
      [theme.breakpoints.down('sm')]: {
        padding: '4px 13px',
        paddingRight: 12
      }
    },
    noteBoxRight: {
      paddingLeft: 12,
      padding: 2,
      [theme.breakpoints.down('sm')]: {
        padding: '4px 13px'
      },
      [theme.breakpoints.only('md')]: {
        paddingLeft: 0
      }
    },

    imgBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: '1px solid rgba(170, 170, 170, 0.2)',
      padding: 5,
      borderRadius: 5,
      marginLeft: 5
    },
    image: {
      width: 160,
      height: 140,
      [theme.breakpoints.down('md')]: {
        width: 135,
        height: 140
      }
    },
    imageFooter: {
      width: '100%',
      textAlign: 'center',
      fontSize: 12
    },
    imageContainer: {
      display: 'flex',
      padding: '10px 8px',
      [theme.breakpoints.down('sm')]: {
        padding: '4px 8px',
      }
    },
    notes: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 9
      },
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 31,
      paddingRight: 38,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 10,
        paddingRight: 10
      },
    },
    noteData: {
      paddingLeft: 5,
      marginBottom: 8
    },
    note: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 3
    },
    cptCodeBox: {
      display: 'flex',
      margin: '15px 5px 5px',

      [theme.breakpoints.down('sm')]: {
        margin: 0,

        flexDirection: 'column'
      }
    },
    cptCodeBoxWOutmargin: {
      display: 'flex',

      [theme.breakpoints.down('sm')]: {
        margin: 0,

        flexDirection: 'column'
      }
    },

    procedureDataFieldLast: {
      marginBottom: 8
    },
    dialogHeader: {
      backgroundColor: theme.palette.background.paper,
      marginBottom: 16,
      border: '1px solid rgba(170, 170, 170, 0.4)',
      marginRight: 0,
      position: 'relative',
      height: 43
    },
    link: {
      textDecoration: 'none'
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16
    },

    label: {
      marginBottom: 4,
      fontSize: 14
    },

    selectlabel: {
      marginBottom: 4,
      fontSize: 16,
      fontWeight: 500
    },
    divider: {
      marginTop: 4,
      marginBottom: 4,
      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    },
    code: {
      marginBottom: 4
    },
    caseImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 5
    },
    prevwiedImage: {
      minWidth: 400,
      padding: 16,
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    },
    cursor: {
      cursor: 'pointer'
    },
    paper: {
      padding: 16,
      [theme.breakpoints.down('sm')]: {
        padding: 0
      }
    },
    noteMobilePadding: {
      padding: '11px 15px 8px'
    },
    dividerMobile: {
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    previewImage: {
      width: '100%'
    },
    indicator: {
      margin: 15
    },
    mediaContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: 18
    },
    mediaText: {
      alignSelf: 'center',
      flex: 1,
      flexWrap: 'wrap'
    },
    fontSize: {
      fontSize: 16
    },
    nullImageData: {
      padding: '5px 18px',
      [theme.breakpoints.up('md')]: {
        padding: '12px 34px 0px'
      }
    },
    noteTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: 10,
    },
    notesContainer: {
      display: 'flex',
      alignItems: 'baseline'
    },
    noteFieldEdited: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        margin: '5px 5px 8px'
      }
    },
    saveBtnBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingRight: 20,
    },
    saveButton: {
      marginBottom: 10,
      width: '30%',
      backgroundColor: 'rgba(74, 165, 255, 0.6)',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    saveButtonEdited: {
      marginBottom: 10,
      width: '30%',
      backgroundColor: '#4aa5ff',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    rvuRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })
);

export default caseDetailsStyle;
