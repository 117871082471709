import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { getUser } from '../../utils';
import ReactHookFormSelect from '../select/ReactHookFormSelect';
import educationFormStyle from './Styles';
import * as yup from 'yup';
import * as api from '../../services/apiService';
import { months } from '../../scenes/app/profile/profile';
import { Alert } from '@material-ui/lab';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import TodayIcon from '@material-ui/icons/Today';

interface EducationFormPropsType {
  showEndDate: boolean;
  value: number;
  endDate: Date;
  startDate: Date;
  editedEducation: any;
  degreeList: any[];
  specialityList: any[];
  setIsBlocking: (isBlock: boolean) => void;
  setOnRefresh: (refresh: boolean) => void;
  handleEndDateChange: (date: any) => void;
  handleStartDateChange: (date: any) => void;
  setStartDate: (startDate: Date) => void;
  setViewEducationForm: (view: boolean) => void;
  setShowEndDate: (view: boolean) => void;
  setEndDate: (endDate: Date) => void;
  setShowProfileFormMessage: (view: boolean) => void;
  setProfileMessage: (message: [string, string]) => void;
}

const EducationForm: React.FC<EducationFormPropsType> = (props) => {
  const classes = educationFormStyle();
  const userId = getUser() ? getUser().id : null;
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [endDate, setEndDate] = React.useState<boolean>(props.showEndDate);
  const [showEndDate, setShowEndDate] = React.useState<boolean>(
    props.showEndDate
  );
  const [isOpenEducationConfirm, setIsOpenEducationConfirm] =
    React.useState<boolean>(false);
  const user = getUser();
  const validationSchema = yup.object().shape({
    speciality: yup.string().required('This field is required'),
    degree: yup.string().required('This field is required'),
    institution: yup.string().required('This field is required')
  });
  const inputDateProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton className={classes.date} aria-label="date">
          <TodayIcon />
        </IconButton>
      </InputAdornment>
    )
  };
  const { handleSubmit, errors, control, setValue, formState } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });
  const handleEducationConfirmClose = () => {
    setIsOpenEducationConfirm(false);
    props.setViewEducationForm(false);
  };
  const deleteEducation = async (educationData: any) => {
    const { status_code, data } = await api.deleteEducation(
      userId,
      educationData.id
    );
    if (status_code === api.status.SUCCESS) {
      props.setOnRefresh(true);
      props.setShowProfileFormMessage(true);
      props.setProfileMessage(['success', 'Education  deleted  successfully']);
      setTimeout(() => props.setShowProfileFormMessage(false), 1000);
    } else {
      props.setShowProfileFormMessage(true);
      props.setProfileMessage(['error', `${data}`]);
      setTimeout(() => props.setShowProfileFormMessage(false), 1000);
    }
  };

  const handleDeleteEducation = () => {
    deleteEducation(props.editedEducation);
    handleEducationConfirmClose();
    props.setViewEducationForm(false);
  };

  useEffect(() => {
    if (props.editedEducation) {
      setValue('speciality', props.editedEducation.speciality?.id);
      setValue('degree', props.editedEducation.degree?.id);
      setValue('institution', props.editedEducation.institutionName);
      props.setStartDate(props.editedEducation.startDate);
      props.setEndDate(props.editedEducation.endDate);
      if (props.editedEducation.endDate) {
        props.setShowEndDate(true);
        setShowEndDate(true);
      }
    }
  }, [props.editedEducation]);

  const handleEducationConfirmOpen = () => {
    setIsOpenEducationConfirm(true);
  };
  const onSubmit = async (values: any) => {
    if (typeof props.startDate !== 'string') {
      const year = props.startDate.getFullYear();
      const monthName = months[props.startDate.getMonth()];
      const date = props.startDate.getDate();
      const formattedBirthDate = `${monthName} ${date}, ${year}`;
      values['start_date'] = formattedBirthDate;
    } else {
      values['start_date'] = props.startDate;
    }
    values['speciality_id'] = values.speciality;
    if (showEndDate) {
      if (typeof props.endDate !== 'string') {
        const yearEnd = props.endDate.getFullYear();
        const monthNameEnd = months[props.endDate.getMonth()];
        const dateEnd = props.endDate.getDate();
        const formattedSurgeryEnd = `${monthNameEnd} ${dateEnd}, ${yearEnd}`;
        values['end_date'] = formattedSurgeryEnd;
      } else {
        values['end_date'] = props.endDate;
      }
    }
    values['institution_name'] = values.institution;
    values['degree_id'] = values.degree;
    if (!showEndDate) {
      if (props.editedEducation) {
        const { status_code: statusCode, data } = await api.editEducation(
          user.id,
          props.editedEducation.id,
          values
        );
        if (statusCode === api.status.SUCCESS) {
          props.setViewEducationForm(false);
          props.setOnRefresh(true);
          props.setShowEndDate(false);
          props.setShowProfileFormMessage(true);
          props.setProfileMessage([
            'success',
            'Education  updated  successfully'
          ]);
          setTimeout(() => props.setShowProfileFormMessage(false), 700);
        } else {
          props.setShowProfileFormMessage(true);
          props.setProfileMessage(['error', `${data}`]);
          setTimeout(() => props.setShowProfileFormMessage(false), 700);
        }
      } else {
        const { status_code: statusCode, data } = await api.addEducation(
          user.id,
          values
        );
        if (statusCode === api.status.SUCCESS) {
          props.setViewEducationForm(false);
          props.setOnRefresh(true);
          props.setShowEndDate(false);
          props.setShowProfileFormMessage(true);
          props.setProfileMessage([
            'success',
            'Education  added  successfully'
          ]);
          setTimeout(() => props.setShowProfileFormMessage(false), 1000);
        } else {
          props.setShowProfileFormMessage(true);
          props.setProfileMessage(['error', `${data}`]);
          setTimeout(() => props.setShowProfileFormMessage(false), 1000);
        }
      }
    } else if (props.endDate <= props.startDate && showEndDate) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 1000);
    } else if (!(props.endDate <= props.startDate) && showEndDate) {
      if (props.editedEducation) {
        const { status_code: statusCode, data } = await api.editEducation(
          user.id,
          props.editedEducation.id,
          values
        );
        if (statusCode === api.status.SUCCESS) {
          props.setViewEducationForm(false);
          props.setOnRefresh(true);
          props.setShowEndDate(false);
          props.setShowProfileFormMessage(true);
          props.setProfileMessage([
            'success',
            'Education  updated  successfully'
          ]);
          setTimeout(() => props.setShowProfileFormMessage(false), 700);
        } else {
          props.setShowProfileFormMessage(true);
          props.setProfileMessage(['error', `${data}`]);
          setTimeout(() => props.setShowProfileFormMessage(false), 700);
        }
      } else {
        const { status_code: statusCode, data } = await api.addEducation(
          user.id,
          values
        );
        if (statusCode === api.status.SUCCESS) {
          props.setViewEducationForm(false);
          props.setOnRefresh(true);
          props.setShowEndDate(false);
          props.setShowProfileFormMessage(true);
          props.setProfileMessage([
            'success',
            'Education  added  successfully'
          ]);
          setTimeout(() => props.setShowProfileFormMessage(false), 1000);
        } else {
          props.setShowProfileFormMessage(true);
          props.setProfileMessage(['error', `${data}`]);
          setTimeout(() => props.setShowProfileFormMessage(false), 1000);
        }
      }
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 1000);
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => props.setIsBlocking(true)}
      >
        <Snackbar open={showAlert} color="error">
          <Alert onClose={() => setShowAlert(false)} severity="error">
            End date must be greater than start date{' '}
          </Alert>
        </Snackbar>

        <Box p={3}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Controller
                required
                className={classes.mb0}
                as={TextField}
                variant="outlined"
                name="institution"
                defaultValue=""
                control={control}
                label="Institution"
                margin="normal"
                helperText={errors.institution && errors.institution.message}
                fullWidth
                error={errors.institution ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ReactHookFormSelect
                className={[classes.mb0, classes.mt16].join(' ')}
                name="degree"
                label="Degree "
                control={control}
                defaultValue={''}
                variant="outlined"
                margin="normal"
                setIsBlocking={props.setIsBlocking}
                error={errors.degree ? true : false}
                helperText={errors.degree && errors.degree.message}
              >
                {props.degreeList.length > 0
                  ? props.degreeList.map((degree: any) => (
                      <MenuItem key={degree.value} value={degree.value}>
                        {degree.label}
                      </MenuItem>
                    ))
                  : null}
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ReactHookFormSelect
                className={classes.mt16}
                name="speciality"
                label="Speciality "
                control={control}
                defaultValue={''}
                variant="outlined"
                setIsBlocking={props.setIsBlocking}
                margin="normal"
                error={errors.speciality ? true : false}
                helperText={errors.speciality && errors.speciality.message}
              >
                {props.specialityList.length > 0
                  ? props.specialityList.map((speciality: any) => (
                      <MenuItem key={speciality.value} value={speciality.value}>
                        {speciality.label}
                      </MenuItem>
                    ))
                  : null}
              </ReactHookFormSelect>
            </Grid>
            <Grid
              className={classes.dateField}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  required
                  variant="inline"
                  inputVariant="outlined"
                  className={
                    errors.degree || errors.institution
                      ? classes.startDateFieldError
                      : classes.startDateField
                  }
                  value={props.startDate}
                  onChange={props.handleStartDateChange}
                  label="Start date "
                  name="start_date"
                  format="MMM dd, yyyy"
                  fullWidth
                  maxDate={new Date()}
                  autoOk={true}
                  keyboardIcon={<TodayIcon />}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.checkBox}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{
                      root: classes.root,
                      checked: classes.checked
                    }}
                    className={classes.checkBoxColor}
                    checked={!showEndDate}
                    onClick={() => {
                      setEndDate(!endDate);
                      props.setShowEndDate(!props.showEndDate);
                      setShowEndDate(!showEndDate);
                    }}
                  />
                }
                label="I currently study"
              />
            </Grid>

            {showEndDate ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.endDateBox}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    variant="inline"
                    className={classes.mb0}
                    inputVariant="outlined"
                    value={props.endDate}
                    onChange={props.handleEndDateChange}
                    label="End date "
                    name="end_date"
                    format="MMM dd, yyyy"
                    fullWidth
                    maxDate={new Date()}
                    autoOk={true}
                    keyboardIcon={<TodayIcon />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}
          </Grid>
          {props.editedEducation ? (
            <Box className={classes.buttons}>
              <Button
                className={classes.deleteButton}
                variant="outlined"
                onClick={handleEducationConfirmOpen}
              >
                Delete
              </Button>
              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
            </Box>
          ) : (
            <Button
              className={classes.addButton}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Add
            </Button>
          )}
        </Box>
      </form>
      <DeleteDialog
        isOpen={isOpenEducationConfirm}
        title={'Delete Education'}
        firstOption={'Delete'}
        secondOption={'Cancel'}
        handleOptionOne={handleDeleteEducation}
        handleOptionTwo={handleEducationConfirmClose}
        onClose={handleEducationConfirmClose}
        height={198}
        width={507}
      >
        Are you sure you want to delete this Education?
      </DeleteDialog>
    </div>
  );
};

export default EducationForm;
