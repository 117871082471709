import { makeStyles, Theme, createStyles } from '@material-ui/core';

const registryFormStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '15px 168px',
      minWidth: 500,
      [theme.breakpoints.down('md')]: {
        padding: 16,
        minWidth: 0
      },
      [theme.breakpoints.up('lg')]: {
        padding: 0,
        width: '78%',
        margin: 'auto'
      },
      [theme.breakpoints.only('md')]: {
        padding: '15px 50px',
        minWidth: 0
      }
    },
    inline: {
      display: 'inline'
    },
    link: {
      textDecoration: 'none'
    },
    registeryName: {
      paddingLeft: 12,
      paddingBottom: 14,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    headerContainer: {
      display: 'flex',

      marginTop: 24,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: 0
      }
    },
    noWrapWidth: {
      width: 200,
      [theme.breakpoints.down('md')]: {
        width: 90
      }
    },
    formPaper: {
      border: `1px solid rgba(170, 170, 170, 0.4)`,
      [theme.breakpoints.down('md')]: {
        padding: '23px 20px 10px'
      },
      padding: '23px 35px 10px'
    },
    header: {
      display: 'flex',
      alignItems: 'center'
    },
    headerIcon: {
      marginRight: 8
    },
    pr: {
      paddingLeft: 15,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    sharedPaper: {
      border: `1px solid rgba(0, 0, 0, 0.25)`
    },
    fieldPaper: {
      border: `1px solid rgba(0, 0, 0, 0.25)`,
      marginTop: 15
    },
    secHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: '9px 12px'
    },

    icon: {
      color: '#ffad2c',
      margin: '0px 9px'
    },
    checkRoot: {
      '&$checked': {
        color: '#ffad2c'
      }
    },
    checked: {},
    selectHeader: {
      display: 'flex',
      alignItems: 'center'
    },
    cursor: {
      cursor: 'pointer'
    },
    selectSection: {
      display: 'flex',
      alignItems: 'center'
    },
    search: {
      paddingRight: 17,
      [theme.breakpoints.down('md')]: {
        paddingRight: 5
      }
    },
    doctorInfo: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 0px'
    },
    leftSection: {
      display: 'flex'
    },
    doctorBox: {
      marginLeft: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around'
    },
    doctorImage: {
      width: 46,
      height: 46
    },
    imageBox: {
      marginTop: '7%',
      marginBottom: 40,
      textAlign: 'center'
    },
    customizedimageBox: {
      marginTop: '4%',

      textAlign: 'center'
    },
    fieldName: {
      display: 'flex'
    },
    deleteCell: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    emptySearch: {
      margin: '16px auto 0px',
      textAlign: 'center'
    },
    emptyField: {
      textAlign: 'center',
      marginBottom: 9
    },
    table: {
      minWidth: 650,
      height: 200,
      overflowY: 'scroll'
    },
    list: {
      height: 333,
      overflowY: 'scroll'
    },
    fieldList: {
      height: 200,
      overflowY: 'scroll'
    },

    customFieldHeader: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    originalfieldList: {
      height: 180,
      overflowY: 'scroll',
      [theme.breakpoints.down('md')]: {
        height: 163
      }
    },
    pl: {
      paddingLeft: 32,
      display: 'inline-block'
    },

    fieldPaperHeader: {
      flexDirection: 'column',
      padding: '9px 14px'
    },
    fieldTitle: {
      padding: '15px 0px',
      fontWeight: 500
    },
    firstfieldTitle: {
      padding: '15px 15px',
      fontWeight: 500
    },
    fieldContent: {
      fontWeight: 500
    },
    fieldTypeTitle: {
      padding: '15px 15px',
      fontWeight: 500
    },
    lastfieldContent: {
      padding: '5px 10px 16px'
    },
    fieldContentMobile: {
      padding: '5px 10px 5px'
    },
    mtContainer: {
      marginTop: 15
    },
    firstfieldContent: {
      padding: '15px 14px',
      fontWeight: 500
    },
    pb: {
      paddingBottom: 9,
      [theme.breakpoints.down('md')]: {
        padding: 9
      }
    },
    deleteIcon: {
      marginRight: 12,
      color: '#ff3b30',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      },
      [theme.breakpoints.only('md')]: {
        margin: 0
      }
    },
    firstField: {
      display: 'flex',

      alignItems: 'center'
    },
    field: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    optional: {
      display: 'flex',
      alignItems: 'center'
    },
    mobilefieldHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '13px 7px 0px'
    },
    mt: {
      marginTop: 13
    },
    errorMsg: {
      fontSize: 15,
      color: '#ff3b30',
      margin: 0
    },
    hint: {
      paddingLeft: 13,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 5
      }
    }
  })
);

export default registryFormStyle;
