import { makeStyles, Theme, createStyles } from '@material-ui/core';

const notVerifiedStyle = makeStyles((theme: Theme) =>
  createStyles({
    emailMessage: {
      textAlign: 'left',
      paddingTop: 20,
      paddingBottom: 32
    },
    phoneBox: {
      alignSelf: 'center',
      marginBottom: -8
    },

    infoHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'baseline',

      padding: 10
    }
  })
);

export default notVerifiedStyle;
