import React, { useCallback } from 'react';
import { removeCookie } from '../utils/sessionUtils';
import * as api from '../services/apiService';
import { useHistory } from 'react-router-dom';

export const authService = {};
const useAuth = () => {
  const history = useHistory();
  const logout = useCallback(async (isUnAuthenticated) => {
    history.push('/');
    await removeCookie('user');
    localStorage.clear();
    if (!isUnAuthenticated) {
      await api.logout();
    }
    document.location.href="/";
  });

  const _authService = React.useMemo(
    () => ({
      logout
    }),
    [logout]
  );
  Object.assign(authService, _authService);
  return _authService;
};
export default useAuth;
