import * as api from '../services/apiService';

export const strongPassword = new RegExp(
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
);

export const isUsedEmail = async (email) => {
  if (email !== '') {
    const { status_code: statusCode, data } = await api.emailValidation({
      email
    });
    if (statusCode === api.status.SUCCESS) {
      return !data.email_used;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const isValidPhone = async (country_code_lookup_id, phone) => {
  if (phone !== '' && country_code_lookup_id) {
    const { status_code: statusCode, data } = await api.phoneValidation({
      phone,
      country_code_lookup_id: country_code_lookup_id
    });
    if (statusCode === api.status.SUCCESS) {
      if (!data.phone_valid) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const isUsedPhone = async (country_code_lookup_id, phone) => {
  if (phone !== '' && country_code_lookup_id) {
    const { status_code: statusCode, data } = await api.phoneValidation({
      phone,
      country_code_lookup_id: country_code_lookup_id
    });
    if (statusCode === api.status.SUCCESS) {
      if (data.phone_used) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};
