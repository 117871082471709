import classes from '*.module.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import React from 'react';
import ConfirmCloseDialogStyle from './Styles';

interface ConfirmDialogPropsType {
  isOpen: boolean;
  title?: string;
  firstOption: string;
  secondOption: string;
  onClose: () => void;
  handleOptionOne: () => void;
  handleOptionTwo: () => void;
}

const ConfirmCloseDialog: React.FC<ConfirmDialogPropsType> = (props) => {
  const {
    isOpen,
    title,
    firstOption,
    secondOption,
    handleOptionOne,
    handleOptionTwo,
    onClose,
    children
  } = props;

  const classes = ConfirmCloseDialogStyle();
  const handelClose = () => {
    handleOptionTwo();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.dialogContainer }}
    >
      <DialogTitle className={classes.title}>
        <Typography variant="subtitle1">{title}</Typography>
      </DialogTitle>
      <Box className={classes.loseInfoMessage}>
        <Typography>{children}</Typography>
      </Box>
      <Box className={classes.actionFooter}>
        <Button
          className={classes.actionButton}
          onClick={handleOptionTwo}
          color="secondary"
          autoFocus
          variant="outlined"
        >
          {secondOption}
        </Button>
        <Button
          onClick={handleOptionOne}
          color="secondary"
          variant="contained"
          className={classes.actionButton}
        >
          {firstOption}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ConfirmCloseDialog;
