import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import * as api from '../../../services/apiService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DoctorPlaceholder from '../../../assets/DoctorPlaceholder.png';
import emptySaerchDark from '../../../assets/dark/searchDark.png';
import emptySaerch from '../../../assets/emptySearch.png';
import { RegistryDataCountType, registryParams } from '../../../types';
import { Permission } from '../../../types';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import history from '../../../utils/history';
import InvitationStyle from './Styles';
import { Alert } from '@material-ui/lab';

const Invitation = () => {
  const isDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');
  const [registryData, setRegistryData] = React.useState<RegistryDataCountType>(
    {
      name: '',
      countShared: 0
    }
  );
  const [registryIndicator, setRegistryIndicator] = React.useState<boolean>(
    false
  );
  const [isDeleted, setIsDeleted] = React.useState<boolean>(false);
  const [searchDoctorValue, setSearchDoctorValue] = React.useState<string>('');
  const [searchInvitedValue, setSearchInvitedValue] = React.useState<string>(
    ''
  );
  const [searchShareValue, setSearchShareValue] = React.useState<string>('');
  const [selectedSharedDoctors, setSelectedSharedDoctors] = React.useState<
    any[]
  >([]);
  const [isClickSearchMobile, setIsClickSearchMobile] = React.useState<boolean>(
    false
  );
  const [
    isClickSearchMobileDoctors,
    setIsClickSearchMobileDoctors
  ] = React.useState<boolean>(false);
  const [
    isClickSearchMobileInvited,
    setIsClickSearchMobileInvited
  ] = React.useState<boolean>(false);
  const [selectedInvitedDoctors, setSelectedInvitedDoctors] = React.useState<
    any[]
  >([]);
  const [selectedDoctors, setSelectedDoctors] = React.useState<any[]>([]);
  const [viewOptionDoctors, setViewOptionDoctors] = React.useState<string>(
    'Own cases'
  );
  const [viewOption, setViewOption] = React.useState<string>('Own cases');
  const [alerMessage, setAlerMessage] = React.useState<any[]>([]);
  const [showAlerMessage, setShowAlerMessage] = React.useState<boolean>(false);
  const [themeName, setThemeName] = React.useState(
    localStorage.getItem('Theme')
  );

  const [
    searchResultforSharedUser,
    setSearchResultforSharedUser
  ] = React.useState<any[]>([]);
  const [sharedUser, setsharedUser] = React.useState<any[]>([]);
  const [searchResultforDoctors, setSearchResultforDoctors] = React.useState<
    any[]
  >([]);
  const [doctors, setDoctors] = React.useState<any[]>([]);
  const [
    searchResultforInvitedDoctors,
    setSearchResultforInvitedDoctors
  ] = React.useState<any[]>([]);
  const [invitedDictors, setInvitedDoctors] = React.useState<any[]>([]);
  const [expandDoctors, setExpandDoctors] = React.useState(false);
  const [expandInvitedDoctors, setExpandedInvitedDoctors] = React.useState(
    false
  );
  const [expandShareRegistry, setExpandShareRegistry] = React.useState(true);
  const classes = InvitationStyle();
  const { registryId } = useParams<registryParams>();
  const theme = useTheme();
  const matchesWeb = useMediaQuery(theme.breakpoints.up('md'));

  const addCheckAtt = (data) => {
    let newData =
      data &&
      data.map((obj) => ({
        ...obj,
        isChecked: false,
        permission: Permission.MINE
      }));

    return newData;
  };

  const getRegistryById = React.useCallback(async () => {
    setRegistryIndicator(true);
    if (registryId) {
      const { data, status_code: statusCode } = await api.getRegistryById(
        registryId
      );
      if (statusCode === api.status.SUCCESS) {
        setRegistryData({
          name: data.name,
          countShared: data.count_shared
        });
      }
      setRegistryIndicator(false);
    }
  }, [registryId]);

  const getShareUser = async (registryId, searchValue) => {
    const { data, status_code: statusCode } = await api.getShareUser(
      registryId,
      searchValue
    );

    if (statusCode === api.status.SUCCESS) {
      setsharedUser(addCheckAtt(data));
      setSearchResultforSharedUser(addCheckAtt(data));
    }
  };

  const editSharedUserPermission = async (registryId, userId, payload) => {
    const {
      data,
      status_code: statusCode
    } = await api.editSharedUserPermission(registryId, userId, payload);
    if (statusCode === api.status.SUCCESS) {
      setShowAlerMessage(true);
      setAlerMessage(['success', 'Permission has been updated successfully']);
      setTimeout(() => setShowAlerMessage(false), 1000);
    } else {
      setShowAlerMessage(true);
      setAlerMessage(['error', 'User Permission hasn`t been updated ']);
      setTimeout(() => setShowAlerMessage(false), 1000);
    }
  };

  const shareRegistry = async (registryId, payload) => {
    const { data, status_code: statusCode } = await api.shareRegistry(
      registryId,
      payload
    );
    if (statusCode === api.status.SUCCESS) {
      clearShareUserSearch();
      setSelectedSharedDoctors([]);
      getPendingUser(registryId, '');
      setIsDeleted(true);
      setShowAlerMessage(true);
      setAlerMessage(['success', 'Invitation has been sent successfully']);
      setTimeout(() => setShowAlerMessage(false), 1000);
    } else {
      setShowAlerMessage(true);
      setAlerMessage([
        'error',
        'The registry you are trying to use does not exist'
      ]);
      setTimeout(() => setShowAlerMessage(false), 1000);
    }
  };

  const inviteUser = () => {
    let data = new Array();
    for (let i = 0; i < selectedSharedDoctors.length; i++) {
      const user_id = selectedSharedDoctors[i].id;
      let permission = selectedSharedDoctors[i].permission;
      data[i] = { user_id, permission };
    }

    shareRegistry(registryId, { data });
  };

  const deleteRegistryShareUser = async (registryId, deletedUserId) => {
    const { data, status_code: statusCode } = await api.deleteRegistryShareUser(
      registryId,
      deletedUserId
    );
    if (statusCode === api.status.SUCCESS) {
      setIsDeleted(true);
      setShowAlerMessage(true);
      setAlerMessage(['success', 'Shares has been deleted successfully']);
      setTimeout(() => setShowAlerMessage(false), 1000);
    } else {
      setShowAlerMessage(true);
      setAlerMessage([
        'error',
        'The registry you are trying to use does not exist'
      ]);
      setTimeout(() => setShowAlerMessage(false), 1000);
    }
  };

  const getAcceptedUser = async (registryId, searchValue) => {
    const { data, status_code: statusCode } = await api.getAcceptedUser(
      registryId,
      searchValue
    );
    if (statusCode === api.status.SUCCESS) {
      setDoctors(addCheckAtt(data));
      setSearchResultforDoctors(addCheckAtt(data));
    }
  };

  const getPendingUser = async (registryId, searchValue) => {
    const { data, status_code: statusCode } = await api.getPendingUser(
      registryId,
      searchValue
    );

    if (statusCode === api.status.SUCCESS) {
      setInvitedDoctors(addCheckAtt(data));
      setSearchResultforInvitedDoctors(addCheckAtt(data));
    }
  };

  const doSearchInvitedDoctor = (searchValue) => {
    getPendingUser(registryId, searchValue);
  };

  const handleExpandDoctorsClick = () => {
    setExpandDoctors(!expandDoctors);
  };

  const handleSearchChange = (event: any) => {
    setSearchShareValue(event.target.value);
  };

  const handleSearchInvitedDoctorChange = (event: any) => {
    setSearchInvitedValue(event.target.value);
  };

  const handleSearchDoctorChange = (event: any) => {
    setSearchDoctorValue(event.target.value);
  };

  const handleExpandInvitedDoctorsClick = () => {
    setExpandedInvitedDoctors(!expandInvitedDoctors);
  };

  const handleExpandShareRegistryClick = () => {
    setExpandShareRegistry(!expandShareRegistry);
  };

  const clearShareUserSearch = () => {
    if (searchShareValue !== '') {
      setSearchShareValue('');
      setSearchResultforSharedUser([]);
    }
  };
  const clearInvitedUserSearch = () => {
    if (searchInvitedValue !== '') {
      setSearchInvitedValue('');
      getPendingUser(registryId, '');
    }
  };
  const clearDoctorsSearch = () => {
    if (searchDoctorValue !== '') {
      setSearchDoctorValue('');
      getAcceptedUser(registryId, '');
    }
  };
  const handleChange = (e) => {
    setViewOption(e.target.name);
  };
  const handleDoctorsChange = (e) => {
    setViewOptionDoctors(e.target.name);
  };

  React.useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getPendingUser(registryId, '');
      getAcceptedUser(registryId, '');
    }

    return () => {
      unmounted = true;
    };
  }, []);
  React.useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (searchShareValue.length >= 3) {
        getShareUser(registryId, searchShareValue);
      }
      if (searchDoctorValue.length >= 3) {
        getAcceptedUser(registryId, searchDoctorValue);
      }
      if (searchInvitedValue.length >= 3) {
        getPendingUser(registryId, searchInvitedValue);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [searchShareValue, searchDoctorValue, searchInvitedValue]);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (isDeleted) {
        getRegistryById();
      }
    }

    return () => {
      unmounted = true;
    };
  }, [getRegistryById, isDeleted]);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getRegistryById();
    }

    return () => {
      unmounted = true;
    };
  }, []);
  return (
    <div className={classes.root}>
      <Snackbar open={showAlerMessage}>
        <Alert
          onClose={() => setShowAlerMessage(false)}
          color={alerMessage[0]}
          severity={alerMessage[0]}
        >
          {alerMessage[1]}
        </Alert>
      </Snackbar>{' '}
      <Grid
        item={true}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.headerContainer}
      >
        <>
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.registeryName}
          >
            <NavLink className={classes.link} to="/registries">
              <Typography variant="h1" className={classes.inline}>
                {'Registries > '}
              </Typography>
            </NavLink>
            <Typography
              onClick={() => {
                history.push('/registry/' + registryId);
              }}
              variant="h1"
              className={[classes.link, classes.inline, classes.cursor].join(
                ' '
              )}
            >
              {`${registryData.name} > `}
            </Typography>
            <Typography
              color="primary"
              variant="body2"
              className={classes.inline}
            >
              Invitations
            </Typography>
          </Grid>
        </>
      </Grid>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          title={<Typography variant="h2">Pending Invitations</Typography>}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandInvitedDoctors
              })}
              onClick={handleExpandInvitedDoctorsClick}
              aria-expanded={expandInvitedDoctors}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />

        <Collapse in={expandInvitedDoctors} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              item={true}
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="center"
            >
              <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid
                item={true}
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.selectHeader}
              >
                {!isClickSearchMobileInvited ? (
                  <Grid
                    item={true}
                    xs={isClickSearchMobileInvited ? 8 : 11}
                    sm={isClickSearchMobileInvited ? 8 : 11}
                    md={8}
                    lg={8}
                    xl={8}
                    className={classes.selectSection}
                  >
                    <Typography variant="h3" className={classes.inline}>
                      Pending Invitations
                    </Typography>
                  </Grid>
                ) : null}

                <Grid
                  item={true}
                  xs={isClickSearchMobileInvited ? 12 : 1}
                  sm={isClickSearchMobileInvited ? 12 : 1}
                  md={4}
                  lg={4}
                  xl={4}
                  className={
                    isClickSearchMobileInvited
                      ? classes.search
                      : classes.searchMobileDoctor
                  }
                >
                  {!matchesWeb && !isClickSearchMobileInvited ? (
                    <SearchIcon
                      onClick={() => setIsClickSearchMobileInvited(true)}
                    />
                  ) : null}
                  {matchesWeb || isClickSearchMobileInvited ? (
                    <TextField
                      InputProps={{
                        endAdornment:
                          searchInvitedValue.length > 0 ? (
                            <InputAdornment position="start">
                              <ClearIcon
                                onClick={clearInvitedUserSearch}
                                className={classes.cursor}
                              ></ClearIcon>
                            </InputAdornment>
                          ) : null,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search"
                      margin="normal"
                      fullWidth
                      value={searchInvitedValue}
                      onChange={(event) =>
                        handleSearchInvitedDoctorChange(event)
                      }
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Box
                className={
                  searchResultforInvitedDoctors.length > 0 ||
                  searchResultforInvitedDoctors.length > 0
                    ? classes.list
                    : ''
                }
              >
                {searchResultforInvitedDoctors &&
                searchResultforInvitedDoctors.length > 0 ? (
                  searchResultforInvitedDoctors.map((doctor: any, index) => (
                    <Fragment key={doctor.user.id}>
                      <Grid
                        item={true}
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.doctorInfo}
                      >
                        <Grid
                          item={true}
                          xs={11}
                          sm={11}
                          md={11}
                          lg={11}
                          xl={11}
                          className={classes.leftSection}
                        >
                          <img
                            className={classes.doctorImage}
                            src={
                              doctor.user.user_media
                                ? doctor.user.user_media.image.href_small
                                : DoctorPlaceholder
                            }
                            alt="Doctor Placeholder"
                          />
                          <Box className={classes.doctorBox}>
                            <Typography
                              variant="h3"
                              noWrap
                              className={classes.noWrapWidth}
                            >
                              {`${doctor.user.name}`}
                              {doctor.user.user_role
                                ? ` (${doctor.user.user_role.nice_name})`
                                : ''}
                            </Typography>
                            <Typography
                              variant="caption"
                              noWrap
                              className={classes.noWrapWidth}
                            >
                              {doctor.user.user_profile &&
                                doctor.user.user_profile.speciality &&
                                doctor.user.user_profile.speciality.name}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item={true} xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography className={classes.pending}>
                            Pending
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ))
                ) : (
                  <Box className={classes.imageBox}>
                    <Box>
                      <img
                        src={isDarkTheme ? emptySaerchDark : emptySaerch}
                        alt="emptyView"
                      ></img>
                    </Box>
                    <Box className={classes.emptySearch}>
                      {' '}
                      <Typography variant="caption">
                        Start typing to get results!
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          title={<Typography variant="h2">Current Collaborators</Typography>}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandDoctors
              })}
              onClick={handleExpandDoctorsClick}
              aria-expanded={expandDoctors}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />

        <Collapse in={expandDoctors} timeout="auto" unmountOnExit>
          <CardContent>
            {' '}
            <Grid
              item={true}
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="center"
            >
              <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid
                item={true}
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.selectHeader}
              >
                {!isClickSearchMobileDoctors ? (
                  <Grid
                    item={true}
                    xs={isClickSearchMobileDoctors ? 8 : 11}
                    sm={isClickSearchMobileDoctors ? 8 : 11}
                    md={8}
                    lg={8}
                    xl={8}
                    className={classes.selectSection}
                  >
                    <Typography variant="h3" className={classes.inline}>
                      {registryData.name} is shared with{' '}
                      {registryData.countShared} users
                    </Typography>
                  </Grid>
                ) : null}

                <Grid
                  item={true}
                  xs={isClickSearchMobileDoctors ? 12 : 1}
                  sm={isClickSearchMobileDoctors ? 12 : 1}
                  md={4}
                  lg={4}
                  xl={4}
                  className={
                    isClickSearchMobileDoctors
                      ? classes.search
                      : classes.searchMobileDoctor
                  }
                >
                  {!matchesWeb && !isClickSearchMobileDoctors ? (
                    <SearchIcon
                      onClick={() => setIsClickSearchMobileDoctors(true)}
                    />
                  ) : null}
                  {matchesWeb || isClickSearchMobileDoctors ? (
                    <TextField
                      InputProps={{
                        endAdornment:
                          searchDoctorValue.length > 0 ? (
                            <InputAdornment position="start">
                              <ClearIcon
                                onClick={clearDoctorsSearch}
                                className={classes.cursor}
                              ></ClearIcon>
                            </InputAdornment>
                          ) : null,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search"
                      margin="normal"
                      fullWidth
                      value={searchDoctorValue}
                      onChange={(event) => handleSearchDoctorChange(event)}
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Box
                className={
                  searchResultforDoctors.length > 0 ||
                  searchResultforDoctors.length > 0
                    ? classes.list
                    : ''
                }
              >
                {searchResultforDoctors && searchResultforDoctors.length > 0 ? (
                  searchResultforDoctors.map((doctor: any, index) => (
                    <>
                      <Grid
                        item={true}
                        container
                        key={doctor.user.id}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.doctorInfo}
                      >
                        <Grid
                          item={true}
                          xs={8}
                          sm={8}
                          md={10}
                          lg={10}
                          xl={10}
                          className={classes.leftSection}
                        >
                          <img
                            className={classes.doctorImage}
                            src={
                              doctor.user.user_media
                                ? doctor.user.user_media.image.href_small
                                : DoctorPlaceholder
                            }
                            alt="Doctor Placeholder"
                          />
                          <Box className={classes.doctorBox}>
                            <Typography
                              variant="h3"
                              noWrap
                              className={classes.noWrapWidth}
                            >
                              {`${doctor.user.name}`}
                              {doctor.user.user_role
                                ? ` (${doctor.user.user_role.nice_name})`
                                : ''}
                            </Typography>
                            <Typography
                              variant="caption"
                              noWrap
                              className={classes.noWrapWidth}
                            >
                              {doctor.user.user_profile &&
                                doctor.user.user_profile.speciality &&
                                doctor.user.user_profile.speciality.name}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid
                          item={true}
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          xl={2}
                          className={classes.search}
                        >
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              fullWidth
                              variant="outlined"
                              id="demo-simple-select-outlined"
                              onChange={handleDoctorsChange}
                              value={
                                doctor.view === 'ALL'
                                  ? 'All cases'
                                  : 'Own cases'
                              }
                            >
                              <MenuItem value="" selected>
                                This user can view:
                              </MenuItem>
                              <MenuItem
                                selected={doctor.view === 'ALL' ? true : false}
                                value="All cases"
                                onClick={() => {
                                  setViewOptionDoctors('All cases');

                                  let newSelectedDoctors = searchResultforDoctors.map(
                                    (element) =>
                                      element.user.id === doctor.user.id
                                        ? {
                                            ...element,

                                            view: Permission.ALL
                                          }
                                        : element
                                  );

                                  setSearchResultforDoctors(newSelectedDoctors);
                                  editSharedUserPermission(
                                    registryId,
                                    doctor.user.id,
                                    { permission: 'ALL' }
                                  );
                                }}
                              >
                                All cases
                              </MenuItem>
                              <MenuItem
                                selected={doctor.view === 'MINE' ? true : false}
                                value="Own cases"
                                onClick={() => {
                                  setViewOptionDoctors('Own cases');

                                  let newSelectedDoctors = searchResultforDoctors.map(
                                    (element) =>
                                      element.user.id === doctor.user.id
                                        ? {
                                            ...element,

                                            view: Permission.MINE
                                          }
                                        : element
                                  );

                                  setSearchResultforDoctors(newSelectedDoctors);
                                  editSharedUserPermission(
                                    registryId,
                                    doctor.user.id,
                                    { permission: 'MINE' }
                                  );
                                }}
                              >
                                Own cases
                              </MenuItem>
                              <MenuItem
                                className={classes.removeButton}
                                onClick={() => {
                                  setSearchResultforDoctors(
                                    searchResultforDoctors.filter(
                                      (element) =>
                                        element.user.id !== doctor.user.id
                                    )
                                  );
                                  deleteRegistryShareUser(
                                    registryId,
                                    doctor.user.id
                                  );
                                }}
                              >
                                Remove
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Divider />
                      </Grid>
                    </>
                  ))
                ) : (
                  <Box className={classes.imageBox}>
                    <Box>
                      <img
                        src={isDarkTheme ? emptySaerchDark : emptySaerch}
                        alt="emptyView"
                      ></img>
                    </Box>
                    <Box className={classes.emptySearch}>
                      {' '}
                      <Typography variant="caption">
                        Start typing to get results!
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
      <Card className={classes.card}>
        <CardHeader
          color="primary"
          title={<Typography variant="h2">Share registry</Typography>}
          subheader={
            <>
              {' '}
              <Typography variant="caption">
                Share registry with your colleagues!
              </Typography>
              <Typography variant="h5">
                Invited users will receive emails with their invitations.{' '}
              </Typography>
            </>
          }
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandShareRegistry
              })}
              onClick={handleExpandShareRegistryClick}
              aria-expanded={expandShareRegistry}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />

        <Collapse in={expandShareRegistry} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              item={true}
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="center"
            >
              <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid
                item={true}
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.selectHeader}
              >
                <Grid
                  item={true}
                  xs={5}
                  sm={5}
                  md={7}
                  lg={7}
                  xl={7}
                  className={classes.selectPart}
                >
                  {selectedSharedDoctors.length > 0 ||
                  searchResultforSharedUser.length > 0 ? (
                    <>
                      {selectedSharedDoctors.length === sharedUser.length ? (
                        <CheckBoxIcon
                          className={classes.icon}
                          onClick={() => {
                            let selectedAllDoctors;
                            selectedSharedDoctors.length === sharedUser.length
                              ? (selectedAllDoctors = sharedUser.map((obj) => ({
                                  ...obj,
                                  isChecked: false
                                })))
                              : (selectedAllDoctors = searchResultforSharedUser.map(
                                  (obj) => ({
                                    ...obj,
                                    isChecked: true
                                  })
                                ));
                            if (
                              selectedSharedDoctors.length === sharedUser.length
                            ) {
                              setSelectedSharedDoctors([]);
                              setSearchResultforSharedUser(selectedAllDoctors);
                            } else {
                              let newSelected = [
                                ...selectedSharedDoctors,
                                ...selectedAllDoctors
                              ];

                              setSelectedSharedDoctors(newSelected);
                              setSearchResultforSharedUser([]);
                            }
                          }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          className={classes.icon}
                          onClick={() => {
                            let selectedAllDoctors;
                            selectedSharedDoctors.length === sharedUser.length
                              ? (selectedAllDoctors = sharedUser.map((obj) => ({
                                  ...obj,
                                  isChecked: false
                                })))
                              : (selectedAllDoctors = searchResultforSharedUser.map(
                                  (obj) => ({
                                    ...obj,
                                    isChecked: true
                                  })
                                ));
                            if (
                              selectedSharedDoctors.length === sharedUser.length
                            ) {
                              setSelectedSharedDoctors([]);
                              setSearchResultforSharedUser(selectedAllDoctors);
                            } else {
                              let newSelected = [
                                ...selectedSharedDoctors,
                                ...selectedAllDoctors
                              ];

                              setSelectedSharedDoctors(newSelected);
                              setSearchResultforSharedUser([]);
                            }
                          }}
                        />
                      )}

                      <Typography variant="h3" className={classes.inline}>
                        Select all
                      </Typography>
                    </>
                  ) : null}
                </Grid>
                <Grid item={true} xs={1} sm={1} md={1} lg={1} xl={1}>
                  {selectedSharedDoctors.length > 0 ? (
                    <span
                      className={[classes.inviteButton, classes.cursor].join(
                        ' '
                      )}
                      onClick={inviteUser}
                    >
                      <PersonAddIcon className={classes.inviteIcon} />
                      {matchesWeb ? (
                        <Typography variant="subtitle2">Invite</Typography>
                      ) : null}
                    </span>
                  ) : null}
                </Grid>
                <Grid
                  item={true}
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  className={
                    isClickSearchMobile ? classes.search : classes.searchMobile
                  }
                >
                  {!matchesWeb && !isClickSearchMobile ? (
                    <SearchIcon onClick={() => setIsClickSearchMobile(true)} />
                  ) : null}
                  {matchesWeb || isClickSearchMobile ? (
                    <TextField
                      InputProps={{
                        endAdornment:
                          searchShareValue.length > 0 ? (
                            <InputAdornment position="start">
                              <ClearIcon
                                onClick={clearShareUserSearch}
                                className={classes.cursor}
                              ></ClearIcon>
                            </InputAdornment>
                          ) : null,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search"
                      margin="normal"
                      fullWidth
                      value={searchShareValue}
                      onChange={(event) => handleSearchChange(event)}
                    />
                  ) : null}
                </Grid>
                <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Box
                className={
                  selectedSharedDoctors.length > 0 ||
                  searchResultforSharedUser.length > 0
                    ? classes.list
                    : ''
                }
              >
                {selectedSharedDoctors && selectedSharedDoctors.length > 0
                  ? selectedSharedDoctors.map((doctor: any, index) => (
                      <Fragment key={doctor.id}>
                        <Grid
                          item={true}
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className={classes.doctorInfo}
                        >
                          <Grid
                            item={true}
                            xs={8}
                            sm={8}
                            md={9}
                            lg={10}
                            xl={10}
                            className={classes.leftSection}
                          >
                            <Checkbox
                              classes={{
                                root: classes.checkRoot,
                                checked: classes.checked
                              }}
                              checked={doctor.isChecked}
                              onChange={() => {
                                let newSelectedDoctors = selectedSharedDoctors.filter(
                                  (selectedDoctor) =>
                                    selectedDoctor.id !== doctor.id
                                );
                                let unCheckedDoctor = selectedSharedDoctors.filter(
                                  (selectedDoctor) =>
                                    selectedDoctor.id === doctor.id
                                );

                                if (searchShareValue !== '') {
                                  searchResultforSharedUser.unshift({
                                    ...unCheckedDoctor[0],
                                    isChecked: false
                                  });
                                }

                                setSelectedSharedDoctors(newSelectedDoctors);
                              }}
                            />
                            <img
                              className={classes.doctorImage}
                              src={
                                doctor.user_media
                                  ? doctor.user_media.image.href_small
                                  : DoctorPlaceholder
                              }
                              alt="Doctor Placeholder"
                            />
                            <Box className={classes.doctorBox}>
                              <Typography
                                variant="h3"
                                noWrap
                                className={classes.noWrapWidth}
                              >
                                {` ${doctor.name}`}
                                {doctor?.user_role
                                  ? ` (${doctor.user_role?.nice_name})`
                                  : ''}
                              </Typography>
                              <Typography
                                variant="caption"
                                noWrap
                                className={classes.noWrapWidth}
                              >
                                {doctor.user_profile &&
                                  doctor.user_profile.speciality &&
                                  doctor.user_profile.speciality.name}
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            item={true}
                            xs={4}
                            sm={4}
                            md={3}
                            lg={2}
                            xl={2}
                            className={classes.search}
                          >
                            <FormControl variant="outlined" fullWidth>
                              <Select
                                fullWidth
                                variant="outlined"
                                id="demo-simple-select-outlined"
                                onChange={handleChange}
                                value={
                                  doctor.permission === 'ALL'
                                    ? 'All cases'
                                    : 'Own cases'
                                }
                              >
                                <MenuItem value="" selected>
                                  This user can view:
                                </MenuItem>
                                <MenuItem
                                  selected={
                                    doctor.permission === 'ALL' ? true : false
                                  }
                                  value="All cases"
                                  onClick={() => {
                                    setViewOption('All cases');

                                    let newSelectedDoctorsPerm = selectedSharedDoctors.map(
                                      (element) =>
                                        element.id === doctor.id
                                          ? {
                                              ...element,

                                              permission: Permission.ALL
                                            }
                                          : element
                                    );

                                    setSelectedSharedDoctors(
                                      newSelectedDoctorsPerm
                                    );
                                  }}
                                >
                                  All cases
                                </MenuItem>
                                <MenuItem
                                  selected={
                                    doctor.permission === 'MINE' ? true : false
                                  }
                                  value="Own cases"
                                  onClick={() => {
                                    setViewOption('Own cases');

                                    let newSelectedDoctorsPerm = selectedSharedDoctors.map(
                                      (element) =>
                                        element.id === doctor.id
                                          ? {
                                              ...element,

                                              permission: Permission.MINE
                                            }
                                          : element
                                    );

                                    setSelectedSharedDoctors(
                                      newSelectedDoctorsPerm
                                    );
                                  }}
                                >
                                  Own cases
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item={true}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <Divider />
                        </Grid>
                      </Fragment>
                    ))
                  : null}
                {searchResultforSharedUser &&
                searchResultforSharedUser.length > 0 ? (
                  searchResultforSharedUser.map((doctor: any, index) => (
                    <Fragment key={doctor.id}>
                      <Grid
                        item={true}
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.doctorInfo}
                      >
                        <Grid
                          item={true}
                          xs={8}
                          sm={8}
                          md={9}
                          lg={10}
                          xl={10}
                          className={classes.leftSection}
                        >
                          <Checkbox
                            classes={{
                              root: classes.checkRoot,
                              checked: classes.checked
                            }}
                            checked={doctor.isChecked}
                            onChange={() => {
                              let newSelectedDoctor = searchResultforSharedUser.map(
                                (element) =>
                                  element.id === doctor.id
                                    ? {
                                        ...element,
                                        isChecked: !doctor.isChecked
                                      }
                                    : element
                              );

                              let newSelectedDoctors = newSelectedDoctor.filter(
                                (selectedDoctor) =>
                                  selectedDoctor.id !== doctor.id
                              );
                              let selectedDoctor = newSelectedDoctor.filter(
                                (selectedDoctor) =>
                                  selectedDoctor.id === doctor.id
                              );
                              setSearchResultforSharedUser(newSelectedDoctors);
                              let newSelected = selectedSharedDoctors.concat(
                                selectedDoctor
                              );
                              setSelectedSharedDoctors(newSelected);
                            }}
                          />
                          <img
                            className={classes.doctorImage}
                            src={
                              doctor.user_media
                                ? doctor.user_media.image.href_small
                                : DoctorPlaceholder
                            }
                            alt="Doctor Placeholder"
                          />
                          <Box className={classes.doctorBox}>
                            <Typography
                              variant="h3"
                              noWrap
                              className={classes.noWrapWidth}
                            >
                              {` ${doctor.name}`}
                              {doctor?.user_role
                                ? ` (${doctor.user_role?.nice_name})`
                                : ''}
                            </Typography>
                            <Typography
                              variant="caption"
                              noWrap
                              className={classes.noWrapWidth}
                            >
                              {doctor.user_profile &&
                                doctor.user_profile.speciality &&
                                doctor.user_profile.speciality.name}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid
                          item={true}
                          xs={4}
                          sm={4}
                          md={3}
                          lg={2}
                          xl={2}
                          className={classes.search}
                        >
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              fullWidth
                              variant="outlined"
                              id="demo-simple-select-outlined"
                              onChange={handleChange}
                              name={viewOption}
                              defaultValue="Own cases"
                            >
                              <MenuItem value="">This user can view:</MenuItem>
                              <MenuItem
                                value="All cases"
                                onClick={() => {
                                  setViewOption('All cases');
                                  let newSelectedDoctors = searchResultforSharedUser.map(
                                    (element) =>
                                      element.id === doctor.id
                                        ? {
                                            ...element,

                                            permission: Permission.ALL
                                          }
                                        : element
                                  );
                                  setSearchResultforSharedUser(
                                    newSelectedDoctors
                                  );
                                }}
                              >
                                All cases
                              </MenuItem>
                              <MenuItem
                                value="Own cases"
                                onClick={() => {
                                  setViewOption('Own cases');
                                  let newSelectedDoctors = searchResultforSharedUser.map(
                                    (element) =>
                                      element.id === doctor.id
                                        ? {
                                            ...element,

                                            permission: Permission.MINE
                                          }
                                        : element
                                  );
                                  setSearchResultforSharedUser(
                                    newSelectedDoctors
                                  );
                                }}
                              >
                                Own cases
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ))
                ) : selectedSharedDoctors.length === 0 ? (
                  <Box className={classes.imageBox}>
                    <Box>
                      <img
                        src={isDarkTheme ? emptySaerchDark : emptySaerch}
                        alt="emptyView"
                      ></img>
                    </Box>
                    <Box className={classes.emptySearch}>
                      {' '}
                      <Typography variant="caption">
                        Start typing to get results!
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

export default Invitation;
