import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Theme,
  MenuItem,
  Select,
  TextField,
  Typography,
  Menu,
  ListItem,
  List,
  Collapse
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import inviteDialogStyle from './inviteDialogStyle';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

interface UserInfoPropsType {
  isOpen: boolean;
  userInfo: any;
  editPermission: (a: string, i: number) => void;
  revoke: (id: number) => void;
  width?: number;
  onClose: () => void;
}

const UserInfo: React.FC<UserInfoPropsType> = (props) => {
  const {
    isOpen,
    userInfo,
    width,
    onClose,
    editPermission,
    revoke,
    children
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    paper: {
      [theme.breakpoints.up('md')]: {
        width: width
      }
    }
  }));
  const dialogClasses = useStyles();
  const classes = inviteDialogStyle();

  const [openCOllapse, setOpenCOllapse] = React.useState<boolean>(false);
  const handleClickOpen = () => {
    setOpenCOllapse(!openCOllapse);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      classes={{ paper: dialogClasses.paper }}
    >
      <Box className={classes.userInfoHeader}>
        <Typography variant="subtitle1" className={classes.title}>
          User info
        </Typography>

        <CloseIcon
          className={classes.closeIcon}
          onClick={() => {
            onClose();
          }}
        />
      </Box>
      <Grid container item={true} className={classes.infoContainer}>
        <Grid item={true} xs={6}>
          <Box className={classes.boxHeader}>
            <PersonIcon color="secondary" className={classes.headerIcon} />{' '}
            <Typography variant="h4" className={classes.f14}>
              User name
            </Typography>
          </Box>
          <Box className={classes.dataBox}>
            <Typography variant="h6">
              {' '}
              {userInfo?.end_user?.first_name
                ? `${userInfo?.end_user?.first_name} ${userInfo?.end_user?.last_name}`
                : userInfo?.end_user?.email.substring(
                    0,
                    userInfo?.end_user?.email.indexOf('@')
                  )}
            </Typography>
          </Box>
        </Grid>
        <Grid item={true} xs={6} className={classes.popupIcon}>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                <MoreVertIcon
                  {...bindTrigger(popupState)}
                  className={classes.popup}
                  color="primary"
                ></MoreVertIcon>

                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <Typography variant="h6" className={classes.listItem}>
                      {' '}
                      <SettingsApplicationsIcon className={classes.listIcon} />
                      Change permission
                    </Typography>

                    {openCOllapse ? (
                      <ExpandLess className={classes.collapsedIcon} />
                    ) : (
                      <ExpandMore className={classes.collapsedIcon} />
                    )}
                  </MenuItem>
                  <Collapse
                    in={openCOllapse}
                    timeout="auto"
                    unmountOnExit
                    className={classes.collapsed}
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        onClick={() => {
                          editPermission('EDITIOR', userInfo?.end_user?.id);
                          popupState.close();
                          onClose();
                        }}
                      >
                        {' '}
                        <Typography variant="h6" className={classes.listItem}>
                          {' '}
                          <EditIcon className={classes.listIcon} />
                          Editor
                        </Typography>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          editPermission('VIEWER', userInfo?.end_user?.id);
                          popupState.close();
                          onClose();
                        }}
                      >
                        <Typography variant="h6" className={classes.listItem}>
                          {' '}
                          <VisibilityIcon className={classes.listIcon} />
                          Viewer
                        </Typography>
                      </ListItem>
                    </List>
                  </Collapse>
                  <MenuItem
                    onClick={() => {
                      revoke(userInfo?.end_user?.id);
                      popupState.close();
                      onClose();
                    }}
                  >
                    <Typography variant="h6" className={classes.listItem}>
                      {' '}
                      <RemoveCircleIcon className={classes.listIcon} />
                      Revoke
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </Grid>
        <Grid item={true} xs={12}>
          <Box className={classes.boxHeader}>
            <AssignmentIndIcon
              color="secondary"
              className={classes.headerIcon}
            />{' '}
            <Typography variant="h4" className={classes.f14}>
              Role
            </Typography>
          </Box>
          <Box className={classes.dataBox}>
            <Typography variant="h6">
              {userInfo?.end_user?.user_role?.nice_name
                ? userInfo?.end_user?.user_role?.nice_name
                : 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item={true} xs={12}>
          <Box className={classes.boxHeader}>
            <EmailIcon color="secondary" className={classes.headerIcon} />{' '}
            <Typography variant="h4" className={classes.f14}>
              Email address
            </Typography>
          </Box>
          <Box className={classes.dataBox}>
            <Typography variant="h6">{userInfo?.end_user?.email}</Typography>
          </Box>
        </Grid>
        <Grid item={true} xs={12}>
          <Box className={classes.boxHeader}>
            <StayCurrentPortraitIcon
              color="secondary"
              className={classes.headerIcon}
            />{' '}
            <Typography variant="h4" className={classes.f14}>
              Phone number
            </Typography>
          </Box>
          <Box className={classes.dataBox}>
            {' '}
            <Typography variant="h6">
              {userInfo?.end_user?.phone ? userInfo?.end_user?.phone : 'N/A'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default UserInfo;
