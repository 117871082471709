import {
  Box,
  Button,
  Dialog,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import PaymentStyle from './PlanStyles';
import Free from '../../assets/PromotionalSite/Plans/FDark.svg';
import Moderate from '../../assets/PromotionalSite/Plans/MDark.svg';
import Busy from '../../assets/PromotionalSite/Plans/BDark.svg';
import Unlimited from '../../assets/PromotionalSite/Plans/UDark.svg';
import Payment from '../../scenes/app/payPal/PayPal';
import { getUser } from '../../utils/index';
import * as api from '../../services/apiService';
import { Alert } from '@material-ui/lab';

const BillingDialog = (props) => {
  const {
    isOpenBilling,
    planName,
    value,
    lastPrice,
    cost,
    setNewPlan,
    plan,
    code,
    setCode,
    prevPlan,
    subscriptionId,
    setIsChanged,
    userToken,
    isActiveCode,
    onClose,
    handleCloseBillingDialog
  } = props;
  const classes = PaymentStyle();
  const [paymentMethod, setPaymentMethod] = React.useState<string>('');
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<any[]>([]);

  const createSubscription = React.useCallback(async (payload) => {
    const { data, status_code: statusCode } = await api.createSubscription(
      payload
    );
    if (statusCode === api.status.SUCCESS) {
      setAlertMessage(['success', 'Subscribe successfully']);
      setShowAlert(true);
      setNewPlan(true);
      setTimeout(() => {
        setShowAlert(false);
        onClose();
      }, 2000);
    } else {
      setAlertMessage(['error', data.message]);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  }, []);

  const UpdateSubscription = React.useCallback(
    async (subscription_id, payload) => {
      const { data, status_code: statusCode } = await api.UpdateSubscription(
        subscription_id,
        payload
      );
      if (statusCode === api.status.SUCCESS) {
        setAlertMessage(['success', 'Subscribe successfully']);
        setShowAlert(true);
        setNewPlan(true);
        setIsChanged(true);
        setTimeout(() => {
          setShowAlert(false);
          onClose();
        }, 2000);
      } else {
        setAlertMessage(['error', data.message]);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      }
    },
    []
  );

  const deleteSubscription = React.useCallback(async (subscription_id) => {
    const { data, status_code: statusCode } = await api.deleteSubscription(
      subscription_id
    );
    if (statusCode === api.status.SUCCESS) {
      setAlertMessage(['success', 'Unsubscribe successfully']);
      setShowAlert(true);
      setNewPlan(true);
      setTimeout(() => {
        setShowAlert(false);
        onClose();
      }, 2000);
    } else {
      setAlertMessage(['error', data.message]);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    setCode('');
    if (paymentMethod && prevPlan === 'Free') {
      let paylod;
      if (isActiveCode) {
        paylod = {
          payment_method_nonce: paymentMethod,
          plan_id: plan?.braintree_plan_id,
          promotion_code: code
        };
      } else {
        paylod = {
          payment_method_nonce: paymentMethod,
          plan_id: plan?.braintree_plan_id
        };
      }
      createSubscription(paylod);
    } else if (paymentMethod && prevPlan !== 'Free') {
      let paylod;
      if (isActiveCode) {
        paylod = {
          payment_method_nonce: paymentMethod,
          plan_id: plan?.braintree_plan_id,
          promotion_code: code
        };
      } else {
        paylod = {
          payment_method_nonce: paymentMethod,
          plan_id: plan?.braintree_plan_id
        };
      }
      UpdateSubscription(subscriptionId, paylod);
    }
  }, [paymentMethod]);

  return (
    <>
      <Snackbar open={showAlert} color={alertMessage[0]}>
        <Alert onClose={() => setShowAlert(false)} severity={alertMessage[0]}>
          {alertMessage[1]}
        </Alert>
      </Snackbar>
      <Dialog
        open={isOpenBilling}
        onClose={onClose}
      >
        <Box className={classes.dialogHeader}>
          <Typography variant="subtitle1" className={classes.title}>
            Billing info
          </Typography>

          <CloseIcon
            className={classes.closeIcon}
            onClick={handleCloseBillingDialog}
          />
        </Box>
        <Box className={classes.formBilling}>
          <Typography variant="h3" className={classes.headerBillingInfo}>
            Enter your billing information to renew{' '}
            <span className={classes.highlitText}>({planName})</span> plan
          </Typography>
          <Paper className={classes.BillingInfoHeader}>
            <img
              src={
                plan?.plan?.name === 'Unlimited'
                  ? Unlimited
                  : plan?.plan?.name === 'Moderate'
                  ? Moderate
                  : plan?.plan?.name === 'Busy'
                  ? Busy
                  : plan?.plan?.name === 'Free'
                  ? Free
                  : Free
              }
              alt="planName"
              className={classes.planImage}
            />
            <Typography
              variant="subtitle1"
              className={[classes.inline, classes.planName].join(' ')}
            >
              {planName}
            </Typography>
            <Typography
              color="secondary"
              className={[classes.inline, classes.shortcut].join(' ')}
            >
              {planName === 'Busy' ? '(Most popular)' : ''}
            </Typography>
            {isActiveCode && plan?.plan?.name !== 'Free' ? (
              <Typography variant="h3" className={classes.price}>
                <span className={classes.beforeDisscount}>
                  ${plan?.cost}/{plan?.period}
                </span>{' '}
                (Total fee with the promotional code is{' '}
                <span className={classes.highlitText}>${lastPrice}</span>/
                {plan?.period})
              </Typography>
            ) : (
              <Typography variant="h3" className={classes.price}>
                ${plan?.cost}/{plan?.period}
              </Typography>
            )}
          </Paper>
          {planName !== 'Free' ? (
            <>
              <Typography variant="h3" className={classes.pay}>
                Pay using PayPal
              </Typography>
              <Typography
                variant="caption"
                align="center"
                className={classes.footerBillingInfo}
              >
                You will be directed to the secure PayPal payment screen
              </Typography>
              <Box className={classes.payment}>
                <Payment
                  planId={plan?.braintree_plan_id}
                  clientToken={userToken}
                  setPaymentMethod={setPaymentMethod}
                />
              </Box>
            </>
          ) : null}
          {planName === 'Free' ? (
            <Box
              mt={2}
              justifyContent="center"
              className={classes.buttonContainer}
            >
              <Button
                onClick={() => deleteSubscription(subscriptionId)}
                variant="contained"
                color="primary"
                className={classes.subscribeButton}
              >
                Subscribe
              </Button>
            </Box>
          ) : null}
        </Box>
      </Dialog>
    </>
  );
};

export default BillingDialog;
