import {
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  Box,
  Link,
  Paper,
  Typography
} from '@material-ui/core';
import React from 'react';
import { importCasesStyles } from './Styles';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LinearWithValueLabel from '../LinearProgress/LinearProgressWithLabel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import * as api from '../../services/apiService';
import DeleteOutlineIcon from '@material-ui/icons/Delete';
import { date } from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import { doctorParams } from '../../types';

interface ImportCasePropsType {
  isOpen: boolean;
  title?: string;
  firstOption: string;
  userId: number;
  onClose: () => void;
  registryId: number;
  setImportCaseMsg: (show: string[]) => void;
  setShowImportCaseMsg: (show: boolean) => void;
  handleOptionOne: () => void;
  setIsImported: (isImported: boolean) => void;
}

const ImportCase: React.FC<ImportCasePropsType> = (props) => {
  const {
    isOpen,
    title,
    firstOption,
    userId,
    handleOptionOne,
    onClose,
    setImportCaseMsg,
    setShowImportCaseMsg,
    setIsImported,
    registryId,
    children
  } = props;
  const location = useLocation().pathname;
  const { doctorId } = useParams<doctorParams>();
  const [showFiles, setshowfiles] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<FileList>();
  const [error, setError] = React.useState(false);
  const [buttonName, setButtonName] = React.useState<string>('Select File');
  const [isUpload, setIsUpload] = React.useState<boolean>(false);
  const [done, setDone] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (done) {
      setButtonName('Done');
      setIsImported(true);
      if (!error) {
        setShowImportCaseMsg(true);
        setTimeout(() => {
          setShowImportCaseMsg(false);
        }, 4000);
      }
    }
  }, [done]);

  const handleImportCase = async (files, user_id) => {
    if (registryId === 0) {
      if (location.includes('/shared-dashboard/')) {
        const {
          status_code: statusCode,
          data
        } = await api.procedures_import_shared(files, doctorId, user_id);
        if (statusCode === api.status.SUCCESS) {
          if (data?.pass_count <= 0) {
            setError(true);
            setButtonName('Try again');
            setShowImportCaseMsg(true);
            setTimeout(() => {
              setShowImportCaseMsg(false);
            }, 4000);
            setImportCaseMsg(['error', `${data.message}`]);
          } else {
            setImportCaseMsg(['success', `${data.message}`]);
          }
        } else {
          setError(true);
          setButtonName('Try again');
          setShowImportCaseMsg(true);
          setTimeout(() => {
            setShowImportCaseMsg(false);
          }, 4000);
          setImportCaseMsg(['error', data]);
        }
      } else {
        const { status_code: statusCode, data } = await api.procedures_import(
          files,
          user_id
        );
        if (statusCode === api.status.SUCCESS) {
          if (data?.pass_count <= 0) {
            setError(true);
            setButtonName('Try again');
            setShowImportCaseMsg(true);
            setTimeout(() => {
              setShowImportCaseMsg(false);
            }, 4000);
            setImportCaseMsg(['error', `${data.message}`]);
          } else {
            setImportCaseMsg(['success', `${data.message}`]);
          }
        } else {
          setError(true);
          setButtonName('Try again');
          setShowImportCaseMsg(true);
          setTimeout(() => {
            setShowImportCaseMsg(false);
          }, 4000);
          setImportCaseMsg(['error', data]);
        }
      }
    } else {
      const {
        status_code: statusCode,
        data
      } = await api.procedures_import_registry(files, registryId);
      if (statusCode === api.status.SUCCESS) {
        if (data?.pass_count <= 0) {
          setError(true);
          setButtonName('Try again');
          setShowImportCaseMsg(true);
          setTimeout(() => {
            setShowImportCaseMsg(false);
          }, 4000);
          setImportCaseMsg(['error', `${data.message}`]);
        } else {
          setImportCaseMsg(['success', `${data.message}`]);
        }
      } else {
        setError(true);
        setButtonName('Try again');
        setShowImportCaseMsg(true);
        setTimeout(() => {
          setShowImportCaseMsg(false);
        }, 4000);
        setImportCaseMsg(['error', data]);
      }
    }
  };

  const humanFileSize = (size) => {
    if (size < 1024) return size + ' B';
    let i = Math.floor(Math.log(size) / Math.log(1024));
    let num: string | number = size / Math.pow(1024, i);
    let round = Math.round(num);
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
    return `${num} ${'KMGTPEZY'[i - 1]}B`;
  };

  React.useEffect(() => { }, [error]);

  const handleCloseDialog = () => {
    onClose();
    setshowfiles(false);
    setTimeout(function () {
      setButtonName('Select File');
    }, 4000);
  };

  const handleButtonClick = () => {
    if (buttonName === 'Upload') {
      setIsUpload(true);
      handleImportCase(files, userId);
    } else if (buttonName === 'Done') {
      handleCloseDialog();
      setFiles(undefined);
    } else if (buttonName === 'Try again') {
      setButtonName('Select File');
      setshowfiles(false);
      setFiles(undefined);
    } else if (buttonName === 'Select File') {
      setshowfiles(true);
      setError(false);
    }
  };

  const handleFileSelcted = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFiles(files);
      setshowfiles(true);
      setIsUpload(false);
      setButtonName('Upload');
    }
  };

  const classes = importCasesStyles();

  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.paper }}>
      <div className={classes.header}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>

        <CloseIcon
          className={classes.closeIcon}
          onClick={() => {
            handleCloseDialog();
            setButtonName('Select File');
            setFiles(undefined);
          }}
        />
      </div>
      <Paper className={classes.importCasePaper} elevation={0}>
        <Box mb={2} className={classes.flexColumn}>
          <Typography variant="h6" className={classes.importCaseHeader}>
            Upload EXCEL/CSV file to import cases{' '}
          </Typography>
          <Link
            className={classes.sampleLink}
            href={
              registryId === 0
                ? "https://firebasestorage.googleapis.com/v0/b/medsolace-bac48.appspot.com/o/Report.xlsx?alt=media&token=cc63242a-dc4d-4acc-9ca3-67155ce9e966"
                : process.env.REACT_APP_API_URL +
                `/registries/${registryId}/procedures/sample-csv-file`
              // registryId === 0
              //   ? process.env.REACT_APP_API_URL + '/procedures_import'
              //   : process.env.REACT_APP_API_URL +
              //     `/registries/${registryId}/procedures/sample-csv-file`
            }
            color="secondary"
          >
            Download sample file{' '}
          </Link>
        </Box>
        {showFiles && files && files.length !== 0 ? (
          <Box p={1} className={classes.box}>
            <div className={classes.fileInfo}>
              <span>
                <InsertDriveFileIcon color="primary" />
              </span>
              <Typography variant="h6" className={classes.fileName}>
                {files[0].name}{' '}
              </Typography>
              <Typography variant="h5" className={classes.fileSize}>
                {humanFileSize(files[0].size)}
              </Typography>
              {!isUpload && !error ? (
                <DeleteOutlineIcon
                  color="error"
                  className={classes.deleteButton}
                  onClick={() => {
                    setshowfiles(false);
                    setButtonName('Select File');
                    setFiles(undefined);
                  }}
                />
              ) : null}
            </div>

            <div>
              {isUpload && !error ? (
                <LinearWithValueLabel
                  icon={
                    <HighlightOffIcon
                      color="disabled"
                      onClick={() => {
                        setButtonName('Select File');
                        setFiles(undefined);
                      }}
                    />
                  }
                  setDone={setDone}
                  setshowfiles={setshowfiles}
                />
              ) : !error ? (
                <hr className={classes.firstLine} />
              ) : null}
              {error ? (
                <div className={classes.fileInfo}>
                  <hr className={classes.errorLine} />
                  <ErrorOutlineIcon color="error" />
                </div>
              ) : null}
            </div>
          </Box>
        ) : null}
      </Paper>
      <div>
        {buttonName === 'Select File' && !error ? (
          <input
            accept=".xlsx, .csv"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={(e) => handleFileSelcted(e)}
            onClick={(event) => (event.currentTarget.value = '')}
          />
        ) : null}

        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            className={
              isUpload && buttonName === 'Upload'
                ? classes.uploadButton
                : classes.disableduploadButton
            }
            onClick={handleButtonClick}
            disabled={isUpload && buttonName === 'Upload'}
          >
            {buttonName}
          </Button>
        </label>
        <span>
          <Typography className={classes.importCaseFooter}>
            For help uploading your cases, please contact <Link color='secondary'>Cases@mslogger.com</Link>
          </Typography>
        </span>
      </div>
    </Dialog>
  );
};

export default ImportCase;
