import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import RegistryDetailsStyle from './Styles';
import * as api from '../../../services/apiService';
import GroupIcon from '@material-ui/icons/Group';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { RegistryDataType } from '../../../types';
import { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import history from '../../../utils/history';
import Dashboard from '../dashboard/index';
import { Alert } from '@material-ui/lab';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { getUser } from '../../../utils';
import PopupState from 'material-ui-popup-state';
import DeleteDialog from '../../../components/Dialogs/DeleteDialog';

type registryParams = {
  registryId: string;
};

const RegistryDetails = (props) => {
  const {
    registryMessage,
    setRegistryMessage,
    showAddRegistryMessage,
    setShowAddRegistryMessage
  } = props;
  const classes = RegistryDetailsStyle();
  const user = getUser();
  const { registryId } = useParams<registryParams>();
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [registryIndicator, setRegistryIndicator] = React.useState<boolean>(
    false
  );

  const [registryMessageDelete, setRegistryMessageDelete] = React.useState<
    any[]
  >([]);
  const [showMessageDelete, setShowMessageDelete] = React.useState<boolean>(
    false
  );
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState(false);
  const [registryData, setRegistryData] = React.useState<RegistryDataType>({
    name: '',
    description: ''
  });

  const handleDeleteRegistry = () => {
    deleteRegistry(registryId);
  };

  const getRegistryById = React.useCallback(async () => {
    setRegistryIndicator(true);

    if (registryId) {
      const { data, status_code: statusCode } = await api.getRegistryById(
        registryId
      );
      if (statusCode === api.status.SUCCESS) {
        setRegistryData({
          name: data.name,
          description: data.description
        });
        if (data.owner.id === user.id) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }
      setRegistryIndicator(false);
    }
  }, [registryId]);

  const deleteRegistry = React.useCallback(async (registryId) => {
    setRegistryIndicator(true);
    const { data, status_code: statusCode } = await api.deleteRegistry(
      registryId
    );
    if (statusCode === api.status.SUCCESS) {
      history.push('/registries');
      let message = ['success', data.message];
      setRegistryMessage(message);
      setShowAddRegistryMessage(true);
      setTimeout(() => setShowAddRegistryMessage(false), 4000);
    } else {
      let message = ['error', data.message];
      setRegistryMessageDelete(message);
      setShowMessageDelete(true);
      setTimeout(() => setShowMessageDelete(false), 1000);
    }
    setRegistryIndicator(false);
  }, []);

  const handleClose = () => {
    setRegistryIndicator(false);
  };
  useEffect(() => {}, [
    props.showAddCaseRegistryMessage,
    props.addCaseRegistryMessage
  ]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getRegistryById();
    }

    return () => {
      unmounted = true;
    };
  }, [getRegistryById]);

  return (
    <div className={classes.root}>
      <Snackbar open={props.showAddCaseRegistryMessage}>
        <Alert
          onClose={() => props.setShowAddCaseRegistryMessage(false)}
          color={props.addCaseRegistryMessage[0]}
          severity={props.addCaseRegistryMessage[0]}
        >
          {props.addCaseRegistryMessage[1]}
        </Alert>
      </Snackbar>{' '}
      <Snackbar open={showMessageDelete}>
        <Alert
          onClose={() => setShowMessageDelete(false)}
          color="error"
          severity="error"
        >
          {registryMessageDelete[1]}
        </Alert>
      </Snackbar>{' '}
      <DeleteDialog
        isOpen={isOpenDeleteDialog}
        title={'Delete registry'}
        firstOption={'Delete'}
        secondOption={'Cancel'}
        handleOptionOne={handleDeleteRegistry}
        handleOptionTwo={() => setIsOpenDeleteDialog(false)}
        onClose={() => setIsOpenDeleteDialog(false)}
        height={198}
        width={507}
      >
        Are you sure you want to delete {registryData.name}?
      </DeleteDialog>
      <Dialog open={registryIndicator} onClose={handleClose}>
        <CircularProgress className={classes.indicator} />
      </Dialog>
      <Grid
        item={true}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.headerContainer}
      >
        <>
          <Grid
            item={true}
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            className={classes.registeryName}
          >
            <NavLink className={classes.link} to="/registries">
              <Typography variant="h1" className={classes.inline}>
                {'Registries > '}
              </Typography>
            </NavLink>
            <Typography
              color="primary"
              variant="body2"
              className={classes.inline}
            >
              {registryData.name}
            </Typography>
          </Grid>
          {isAdmin ? (
            <>
              {!matchesMobile ? (
                <Grid
                  item={true}
                  xs={6}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  className={classes.popupContainer}
                >
                  <div className={classes.editCotainer}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <>
                          <Box
                            bgcolor="primary.main"
                            className={classes.mr}
                            {...bindTrigger(popupState)}
                          >
                            <MoreVertIcon
                              className={classes.popIcon}
                              onClick={() => {}}
                            />
                          </Box>
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem
                              onClick={() => {
                                history.push('/edit-registry/' + registryId);
                                popupState.close();
                              }}
                            >
                              <Typography
                                variant="h6"
                                className={classes.popItem}
                              >
                                <EditIcon className={classes.popupstateIcon} />
                                Edit registry
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setIsOpenDeleteDialog(true);
                                popupState.close();
                              }}
                            >
                              <Typography
                                variant="h6"
                                className={classes.popItem}
                              >
                                <DeleteOutlineIcon
                                  className={classes.popupstateIcon}
                                />
                                Delete registry
                              </Typography>
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </PopupState>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GroupIcon />}
                      className={classes.invitationButton}
                      onClick={() =>
                        history.push('/registry/Invitation/' + registryId)
                      }
                    >
                      Invitations
                    </Button>
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid
                    item={true}
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    className={classes.popupContainer}
                  >
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <>
                          <Box
                            bgcolor="primary.main"
                            className={classes.mr}
                            {...bindTrigger(popupState)}
                          >
                            <MoreVertIcon
                              onClick={() => {}}
                              className={classes.popIcon}
                            />
                          </Box>

                          <Menu {...bindMenu(popupState)}>
                            <MenuItem
                              onClick={() => {
                                history.push('/edit-registry/' + registryId);
                                popupState.close();
                              }}
                            >
                              <Typography
                                variant="h6"
                                className={classes.popItem}
                              >
                                <EditIcon className={classes.popupstateIcon} />
                                Edit registry
                              </Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setIsOpenDeleteDialog(true);
                                popupState.close();
                              }}
                            >
                              <Typography
                                variant="h6"
                                className={classes.popItem}
                              >
                                <DeleteOutlineIcon
                                  className={classes.popupstateIcon}
                                />
                                Delete registry
                              </Typography>
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </PopupState>
                  </Grid>
                  <Grid
                    item={true}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.popupContainer}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GroupIcon />}
                      className={classes.invitationButton}
                      onClick={() =>
                        history.push('/registry/Invitation/' + registryId)
                      }
                    >
                      Invitations
                    </Button>
                  </Grid>
                </>
              )}
            </>
          ) : null}
        </>
      </Grid>
      {registryData.description ? (
        <Paper elevation={0}>
          {' '}
          <Grid
            item={true}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.description}
          >
            <Typography variant="h3">Description</Typography>
            <Typography variant="caption">
              {registryData.description}
            </Typography>
          </Grid>
        </Paper>
      ) : null}
      <Dashboard
        title="Registry"
        registryId={registryId}
        caseMessage={props.addCaseRegistryMessage}
        showEditCaseMessage={props.showAddCaseRegistryMessage}
        setShowEditCaseMessage={props.setShowAddCaseRegistryMessage}
      />
    </div>
  );
};

export default RegistryDetails;
