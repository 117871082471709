import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

dayjs.extend(utc);
export const format = (date, mask, isUTC) => {
    let dayjsDate = dayjs(date);
    if (isUTC) {
      dayjsDate = dayjsDate.utc();
    }
    return dayjsDate.format(mask);
  };

export const parse = (dateText, mask, isutc = true) => {
  let dayjsDate;
  if (isutc) {
    dayjsDate = dayjs.utc(dateText, mask);
  } else {
    dayjsDate = dayjs(dateText, mask);
  }

  return dayjsDate.toDate();
};
  