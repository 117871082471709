import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Control, Controller } from 'react-hook-form';
import React from 'react';
import { FormHelperText } from '@material-ui/core';
import useStyles from '../../styles/globalStyles';

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  helperText,
  setIsBlocking,
  renderValue = '',
  flag = '',
  ...props
}) => {
  const labelId = `${name}-label`;

  const onChange = () => {
    setIsBlocking(true);
  };
  return (
    <FormControl fullWidth {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        fullWidth
        as={
          renderValue.length == 0 ? (
            <Select fullWidth labelId={labelId} label={label} onOpen={onChange}>
              {children}
            </Select>) : (
            <Select fullWidth labelId={labelId} label={label} onOpen={onChange} renderValue={() => {
              return (
                <span>
                  <span className={flag}></span>
                  <span> +{renderValue}</span>
                </span>
              )
            }}>
              {children}
            </Select>
          )
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default ReactHookFormSelect;
