import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import inviteDialogStyle from './inviteDialogStyle';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { useFieldArray, useForm } from 'react-hook-form';
import * as api from '../../services/apiService';
import ReactHookFormSelect from '../select/ReactHookFormSelect';
import { getUser } from '../../utils';

interface InviteUserPropsType {
  isOpen: boolean;
  setAlertMsg: (x: string) => void;
  setShowAlert: (x: boolean) => void;
  onClose: () => void;
}

const InviteUser: React.FC<InviteUserPropsType> = (props) => {
  const { isOpen, setAlertMsg, setShowAlert, onClose, children } = props;
  const classes = inviteDialogStyle();
  const user = getUser();
  const [errorIndex, setErrorIndex] = useState<number>(-1);
  const [errorMsg, setErrorMsg] = useState<any>('');

  const permission = [
    { value: 'EDITOR', label: 'Editor' },
    { value: 'VIEWER', label: 'Viewer' }
  ];

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      users: [{ email: '', permission: 'EDITOR' }]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users'
  });

  const inviteUser = async (userId, values) => {
    const { data, status_code: statusCode } = await api.inviteUser(
      userId,
      values
    );
    if (statusCode === api.status.SUCCESS) {
      const finalResponse = data.filter(
        (item) => !item.includes('invited before')
      );

      setShowAlert(true);
      setAlertMsg('Invitations have been sent!');
      setTimeout(() => {
        setShowAlert(false);
        onClose();
      }, 2000);
    }
  };
  const onSubmit = (values: any) => {
    setErrorIndex(-1);

    for (let i = 0; i < values.users.length; i++) {
      if (!validateEmail(values.users[i].email)) {
        setErrorIndex(i);
        setErrorMsg('Email is not valid');
        break;
      } else {
        if (errorIndex === -1) {
          inviteUser(user?.id, values);
        }
      }
    }
  };

  useEffect(() => {
    setValue('users', [{ email: '', permission: 'EDITOR' }]);
    setErrorIndex(-1);
    setErrorMsg('');
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        fullWidth
      >
        <Box className={classes.dialogHeader}>
          <Typography variant="subtitle1" className={classes.title}>
            Invite users
          </Typography>

          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              onClose();
            }}
          />
        </Box>
        <Grid container>
          <Grid item={true} xs={12} className={classes.header}>
            <Box className={classes.rightBox}>
              <PersonAddIcon color="secondary" className={classes.mr3} />
              <Typography variant="h2">Invite users</Typography>
            </Box>
            <Box>
              <AddIcon
                color="secondary"
                onClick={() => {
                  append({ email: '', permission: 'EDITOR' });
                }}
              />
            </Box>
          </Grid>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => {
              return (
                <>
                  <Grid className={classes.inputContainer} key={item.email}>
                    {' '}
                    <Grid item={true} xs={7} md={8} className={classes.mr10}>
                      <TextField
                        required
                        variant="outlined"
                        name={`users[${index}].email`}
                        defaultValue={item.email ? `${item.email}` : ''}
                        label="Email address"
                        margin="normal"
                        fullWidth
                        inputRef={register()}
                      />
                      {index === errorIndex && (
                        <p className={classes.errormsg}>{errorMsg}</p>
                      )}
                    </Grid>
                    <Grid item={true} xs={3} md={3} className={classes.mt3}>
                      <ReactHookFormSelect
                        name={`users[${index}].permission`}
                        label="Permission"
                        defaultValue={
                          item.permission ? `${item.permission}` : ''
                        }
                        control={control}
                        variant="outlined"
                        margin="normal"
                        setIsBlocking={() => {}}
                        error={false}
                        helperText={false}
                      >
                        {permission.length > 0
                          ? permission.map((per: any) => (
                              <MenuItem key={per.value} value={per.value}>
                                {per.label}
                              </MenuItem>
                            ))
                          : null}
                      </ReactHookFormSelect>
                    </Grid>
                    {index !== 0 ? (
                      <RemoveCircleIcon
                        className={classes.removeIcon}
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    ) : null}
                  </Grid>
                </>
              );
            })}
            <Grid item={true} className={classes.inviteButtonContainer}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.inviteButton}
                type="submit"
              >
                Invite
              </Button>
            </Grid>
          </form>
        </Grid>
      </Dialog>
    </div>
  );
};

export default InviteUser;
