import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

function LinearProgressWithLabel(props) {
  const { setshowfiles } = props;
  return (
    <Box display="flex" alignItems="center">
      <Box width="80%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {props.color === 'Error' ? (
          <Typography variant="caption" color="textSecondary"></Typography>
        ) : (
          <Typography variant="caption" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        )}
      </Box>
      <Box onClick={() => setshowfiles(false)}>{props.icon}</Box>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

const LinearWithValueLabel = (props) => {
  const { icon, setDone, setshowfiles } = props;
  const classes = useStyles();

  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    if (progress < 100) {
      setTimeout(function () {
        setProgress((prevProgress) => prevProgress + 10);
      }, 1000);
    } else {
      setDone(true);
    }
  }, [progress]);

  return (
    <div className={classes.root}>
      {progress == 100 ? (
        <LinearProgressWithLabel
          value={progress}
          color="primary"
          icon={<CheckCircleOutlineIcon color="secondary" />}
        />
      ) : (
        <LinearProgressWithLabel
          color="primary"
          value={progress}
          icon={icon}
          setshowfiles={setshowfiles}
        />
      )}
    </div>
  );
};
export default LinearWithValueLabel;
