import { makeStyles, Theme, createStyles } from '@material-ui/core';
import background from '../../../assets/PromotionalSite/background.png';
import backgroundDark from '../../../assets/PromotionalSiteDark/backgroundDark.png';

const ourTeamStyle = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
        textAlign: 'center'
    },
    rootWeb: {
        backgroundImage: ` url(${background});`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        paddingTop: 250,
        paddingBottom:100
      },
      rootMobile: {
        backgroundImage: ` url(${background})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        paddingTop: 20
      },
      rootWebDark: {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backgroundDark});`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        paddingTop: 250,
        paddingBottom: 100
      },
      rootMobileDark: {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), url(${backgroundDark})`,
        backgroundSize: '100% 30%',
        backgroundRepeat: 'no-repeat'
      },
      centerDiv: {
        width: '85%',
        display: 'flex',
        margin: 'auto',
        justifyContent: 'center',
        padding: 4,
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
          marginTop: 0
        },
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column-reverse',
          paddingTop: 0,
          paddingBottom: 60,
          marginTop: 0,
          width: '90%'
        },
        [theme.breakpoints.down('lg')]: {
          flexDirection: 'column-reverse',
          paddingTop: 0,
          width: '90%'
        }
      },
      ipadImag: {
        [theme.breakpoints.only('lg')]: {
          marginTop: 50,
          marginBottom: 100

        },
      },
      imgBox: {
        display: 'flex',
        alignItems: 'center',
      },
      descBox: {
          padding: 18
      },
      w24: {
          width: 24,
          marginTop: 8
      },
      imgStyle: {
        width: 200,
        padding: '0 18px 0 0',
        [theme.breakpoints.down('lg')]: {
            width: 160,
        },
      },
      cardStyle: {
          height: 440,
          padding: 20,
          borderRadius: 23,
          [theme.breakpoints.down('lg')]: {
            height: 440,
          },
          [theme.breakpoints.down('md')]: {
            height: 480,
          },
          [theme.breakpoints.down('sm')]: {
            height: 'auto',
          },
          ['@media screen and (max-width: 320px)']:{
            height: 'auto',
          },
          ['@media screen and (max-width: 290px)']:{
            height: 'auto',
          },
      },
      ourTeam: {
        fontSize: 72,
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          fontSize: 24,
          marginTop: 18,
          marginBottom: 18
        },
      },
      dFlex: {
          display: 'flex'
      },
      mb12: {
          marginBottom: 4,
          fontWeight: 500,
          fontSize: 28,
          [theme.breakpoints.down('md')]: {
            fontSize: 18,
          },
      },
      mb24: {
          marginBottom: 12,
          fontSize: 20,
          [theme.breakpoints.down('md')]: {
            fontSize: 12,
          },
      },
      footer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        margin: 'auto',
        position: 'absolute',
        bottom: -100,
        padding: 4,
        [theme.breakpoints.up('xl')]: {},

        [theme.breakpoints.down('lg')]: {
          flexDirection: 'column',
          alignItems: 'center',
          top: '100%'
        },
        [theme.breakpoints.down('md')]: {
          marginTop: 30
        }
      },
      howSection: {
        position: 'relative',
        margin: '20px 120px',
        [theme.breakpoints.down('md')]: {
          margin: '20px 0px'
        }
      },
      titledescStyle: {
        marginBottom: 200,
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 26,
        width: '80%',
        maxWidth:1200,
        lineHeight: 1.37,
        [theme.breakpoints.down('md')]: {
          marginBottom: 0,
          fontSize: 18,
        },
      },
      ourTeamBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }

  })
);

export default ourTeamStyle;
