import React from 'react';
import { match } from 'react-router-dom';
import * as api from '../../../services/apiService';
import { setCookie } from '../../../utils/sessionUtils';
import history from '../../../utils/history';
import { setUser, getUser } from '../../../utils';

const VerifyAccount = ({ match }: { match: match<any> }) => {
  const token = match ? match.params.token : '';

  const verify = async () => {
    const user = getUser();

    const { status_code: statusCode } = await api.verifyAccount({
      token: decodeURIComponent(token)
    });

    if (statusCode === api.status.SUCCESS) {
      if (user) {
        user['verified'] = true;
        setCookie('user', JSON.stringify(user));
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
      }
      history.push('/dashboard');
    } else {
      history.push('/signin');
    }
  };

  verify();

  return <div></div>;
};

export default VerifyAccount;
