import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import React from 'react';
import ConfirmCloseDialogStyle from './Styles';

interface ConfirmDialogPropsType {
  isOpen: boolean;
  title?: string;
  firstOption: string;
  secondOption: string;
  height?: number;
  width?: number;
  onClose: () => void;
  handleOptionOne: () => void;
  handleOptionTwo: () => void;
}

const DeleteDialog: React.FC<ConfirmDialogPropsType> = (props) => {
  const {
    isOpen,
    title,
    height,
    width,
    firstOption,
    secondOption,
    handleOptionOne,
    handleOptionTwo,
    onClose,
    children
  } = props;

  const classes = ConfirmCloseDialogStyle();
  const handleConfirm = () => {
    handleOptionTwo();
    onClose();
  };

  const handleExport = () => {
    handleOptionTwo();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle disableTypography>
        <Typography variant="subtitle1">{title}</Typography>
      </DialogTitle>
      <Box>
        <Typography variant="h1" className={classes.DialogBody}>
          {children}
        </Typography>
      </Box>
      <Box
        className={[classes.dialogActionDelete, classes.actionFooter].join(' ')}
      >
        {firstOption === 'Delete' || firstOption === 'Remove' || firstOption === 'Delete anyway' || title === 'Logout' ? (
          <>
            <Button
              className={classes.actionDeleteButton}
              type="submit"
              variant="outlined"
              onClick={handleOptionOne}
            >
              {firstOption}
            </Button>
            <Button
              className={classes.actionButton}
              onClick={title ? handleConfirm : handleExport}
              type="submit"
              variant="contained"
              color="secondary"
            >
              {secondOption}
            </Button>
          </>
        ) : (
          <>
            <Button
              className={classes.actionButton}
              onClick={handleOptionOne}
              color="primary"
            >
              {firstOption}
            </Button>
            <Button
              className={classes.actionButton}
              onClick={title ? handleConfirm : handleExport}
              color="primary"
              autoFocus
            >
              {secondOption}
            </Button>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default DeleteDialog;
