import { makeStyles, Theme, createStyles } from '@material-ui/core';

const signUpStyle = makeStyles((theme: Theme) =>
  createStyles({
    emailMessage: {
      textAlign: 'left',
      paddingTop: 20,
      paddingBottom: 32
    },

    dialogHeader: {
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 22
    },
    error: {
      color: '#ff3b30'
    },
    title: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 500,
      marginTop: 13,
      marginBottom: 15,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    closeIcon: {
      marginRight: 10,
      color: '#aaaaaa'
    },
    formBilling: {
      margin: '0px 40px 30px',
      display: 'flex',

      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        margin: '0px 10px 30px'
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    headerBillingInfo: {
      textAlign: 'center',
      marginBottom: 30
    },
    highlitText: {
      color: '#ffad2c',
      fontSize: 14
    },
    beforeDisscount: {
      fontSize: 14,
      textDecoration: 'line-through'
    },
    inline: {
      display: 'inline'
    },
    planName: {
      fontWeight: 500
    },
    shortcut: {
      paddingLeft: 6
    },
    price: {
      marginBottom: 5,
      marginTop: 5
    },
    pay: {
      textAlign: 'center',
      margin: '20px 0px 12px'
    },
    planImage: {
      position: 'absolute',
      top: -1,
      right: 20
    },
    BillingInfoHeader: {
      border: '1px solid rgba(170, 170, 170, 0.5)',
      padding: 20,
      position: 'relative',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: 7
      }
    },
    payment: {
      [theme.breakpoints.up('lg')]: {
        width: 400
      }
    },
    footerBillingInfo: {
      textAlign: 'center',
      marginBottom: 20
    },
    accountIcon: {
      marginRight: 5
    },
    phoneBox: {
      alignSelf: 'center',
      marginBottom: -8
    },
    strongPassword: {
      display: 'flex',
      justifyContent: 'flex-end',

      paddingRight: 4
    },
    weakPassword: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 12,
      color: '#ffad2c',
      paddingRight: 4
    },
    infoHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'baseline',
      color: '#4aa5ff'
    },

    paperWithPadding: {
      border: '1px solid rgba(170, 170, 170, 0.5)',
      margin: 16,
      padding: 10
    },

    mt22: {
      marginTop: -22
    },

    termsFooter: {
      padding: 8,
      color: '#2f2f2f',
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid rgba(170, 170, 170, 0.5)',
      marginLeft: 10,
      marginTop: 4,
      paddingLeft: 22
    },
    paper: {
      border: '1px solid rgba(170, 170, 170, 0.5)',
      margin: 5,
      [theme.breakpoints.down('xs')]: {
        margin: 17
      }
    },
    toggelIcon: {
      width: 38,
      height: 36,
      borderRadius: 12
    },
    countryName: {
      marginLeft: 8
    },
    mt13: {
      marginTop: 12
    },
    toggelOffIcon: {
      color: '#aaaaaa',
      width: 38,
      height: 36,
      borderRadius: 12
    },
    gridBox: {
      ['@media screen and (min-width: 1300px)']:{
        maxWidth: '40%'
    },
    }
  })
);

export default signUpStyle;
