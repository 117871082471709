import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingTop: 32,
      paddingBottom: 56,
      paddingRight: 90,
      paddingLeft: 90,
      maxWidth: 500,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        paddingTop: 32,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 56
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 24,
        paddingBottom: 62,
        paddingRight: 12,
        paddingLeft: 12,
        marginTop: 15,
        minWidth: 'auto'
      }
    },
    email: {
      color: '#ffad2c'
    },
    emailText: {
      width: '80%'
    },
    PaswordStrength: {
      width: '100%'
    },

    descriptionIcon: {
      marginRight: 10,
      marginLeft: 20
    },
    privacy: {
      marginLeft: 4,
      textDecoration: 'underline'
    },
    bigBox: {
      margin: 15,
      display: 'flex',
      justifyContent: 'center',
      marginTop: 200,
    },
    fullWidth: {
      width: '100%'
    },
    paperVerified: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(5),
      textAlign: 'center',

      [theme.breakpoints.down('xs')]: {
        paddingTop: 24,
        paddingBottom: 62,
        paddingRight: 8,
        paddingLeft: 8,
        margin: 15
      },
      [theme.breakpoints.only('xl')]: {
        paddingTop: 24,
        paddingBottom: 62,
        paddingRight: 30,
        paddingLeft: 70,
        margin: 15
      }
    },
    paperVerifiedalone: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      textAlign: 'center',

      [theme.breakpoints.down('xs')]: {
        paddingTop: 24,
        paddingBottom: 62,
        paddingRight: 8,
        paddingLeft: 8,
        margin: 15
      },
      [theme.breakpoints.only('xl')]: {
        paddingTop: 24,
        paddingBottom: 62,
        paddingRight: 30,
        paddingLeft: 30,
        margin: 15
      }
    },
    signUptitle: {
      margin: 'auto',
      textAlign: 'center'
    },
    paperSignIn: {
      paddingTop: 32,
      paddingBottom: 56,
      paddingRight: 90,
      paddingLeft: 90,
      textAlign: 'center',
      maxWidth: 500,
      [theme.breakpoints.down('md')]: {
        paddingTop: 32,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 56
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 24,
        paddingBottom: 62,
        paddingRight: 12,
        paddingLeft: 12,
        marginTop: 15,
        minWidth: 'auto'
      }
    },

    forgetPasswordHeader: {
      color: '#aaaaaa'
    },
    showPassword: {
      color: '#aaaaaa'
    },
    box: {
      display: 'flex',
      marginTop: -74,
      width: '100%'
    },
    boxSignIn: {
      display: 'flex',
      marginTop: -74,
      width: '100%',
      height: '100vh'
    },
    boxVerify: {
      flexBasis: 0
    },
    stepperBox: {
      height: '110vh',
      display: 'flex',
      marginTop: -74,
      width: '100%'
    },
    termsBox: {
      height: 376,
      width: '95%',
      margin: '8px 0px',
      overflowY: 'scroll',
      paddingLeft: 23
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    containerSignUp: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '10px 10% 60px',
      [theme.breakpoints.down('xs')]: {
        padding: '10px 4% 60px'
      }
    },
    textField: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1)
    },
    dense: {
      marginTop: theme.spacing(2)
    },
    menu: {
      width: 200
    },
    button: {
      margin: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    cardButton: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      padding: 8,
      height: 36
    },
    highlightButton: {
      width: '100%',
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      padding: 8,
      height: 36,
      backgroundColor: '#ffad2c',
      color: '#fff',
      marginTop: 66
    },
    highlightButtonreset: {
      width: '100%',
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(1),
      padding: 8,
      height: 36,
      backgroundColor: '#ffad2c',
      color: '#fff',
      marginTop: 20
    },
    strongPassword: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 12,
      color: '#4aa5ff',
      paddingRight: 4
    },
    normalPassword: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 12,
      color: '#ffad2c',
      paddingRight: 4
    },
    weakPassword: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 12,
      paddingRight: 4
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    forgetPasswordLink: {
      cursor: 'pointer',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    resendLink: {
      cursor: 'pointer',
      marginTop: 32,
      color: '#4aa5ff',
      textAlign: 'left',
      textDecoration: 'underline'
    },
    indicator: {
      margin: 15
    },
    textLeft: {
      textAlign: 'left'
    }
  })
);

export default useStyles;
