import cookie from 'js-cookie';

export const setCookie = (key, value) => {
  // @ts-ignore
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
      path: '/'
      // domain: ".dev-caselog.medsolace.com"
    });
  }
};

export const removeCookie = (key) => {
  // @ts-ignore
  cookie.remove(key, {
    expires: 1,
    path: '/'
    // domain: ".dev-caselog.medsolace.com"
  });
};

export const getCookie = (key) => {
  // @ts-ignore
  return cookie.get(key);
};
