import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const importCasesStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'none'
    },
    uploadButton: {
      width: '90%',
      margin: 20,
      fontWeight: 300
    },
    disableduploadButton: {
      width: '90%',
      margin: 20,
      fontWeight: 300
    },
    closeIcon: {
      marginRight: 10,
      color: '#aaaaaa'
    },
    title: {
      textAlign: 'center',

      fontSize: 20,
      fontWeight: 500,
      marginTop: 13,
      marginBottom: 15,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    header: {
      marginTop: 10,
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 22
    },
    fileInfo: {
      display: 'flex',
      alignItems: 'center'
    },
    box: {
      border: '1px solid rgba(170, 170, 170, 0.25)'
    },
    fileBox: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column'
    },
    importCasePaper: {
      padding: '6px 20px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column'
    },

    importCaseHeader: {
      marginRight: 5
    },
    importCaseFooter: {
      marginLeft: 5,
      marginBottom: 5,
      fontSize: 14
    },
    link: {
      color: 'blue',
      textDecoration: 'underline'
    },
    sampleLink: {
      textDecoration: 'underline',
      fontSize: 16
    },
    fileName: {
      marginBottom: 5
    },
    fileSize: {
      marginBottom: 5,
      marginLeft: 3
    },
    inline: {
      display: 'inline'
    },
    firstLine: {
      height: 8,
      background: 'rgba(170, 170, 170, 0.25)',
      borderRadius: 5
    },
    errorLine: {
      height: 8,
      background: '#ff3b30',
      borderRadius: 5,
      width: '90%'
    },
    deleteButton: {
      marginLeft: 'auto',
      fontSize: 12,
      width: 19,
      height: 19
    },
    paper: {
      width: 448,
      height: 'auto',
      margin: 16,
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        width: 343
      }
    },

    sample: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    }
  })
);
